import { defineMessages, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { Menu, Placeholder, Tab } from 'semantic-ui-react';
import { Icon, RenderBlocks } from '@plone/volto/components';
import addDocumentSVG from '@plone/volto/icons/add-document.svg';
import penSVG from '@plone/volto/icons/pen.svg';
import { flattenToAppURL } from '@plone/volto/helpers';
import config from '@plone/volto/registry';

const messages = defineMessages({
  addSubpage: {
    id: 'Add subpage',
    defaultMessage: 'Add subpage',
  },
  edit: {
    id: 'Edit',
    defaultMessage: 'Edit',
  },
});

const Loading = () => (
  <Placeholder fluid>
    <Placeholder.Paragraph>
      <Placeholder.Line />
      <Placeholder.Line />
      <Placeholder.Line />
      <Placeholder.Line />
      <Placeholder.Line />
      <Placeholder.Line />
      <Placeholder.Line />
    </Placeholder.Paragraph>
    <Placeholder.Paragraph>
      <Placeholder.Line />
      <Placeholder.Line />
      <Placeholder.Line />
      <Placeholder.Line />
      <Placeholder.Line />
    </Placeholder.Paragraph>
  </Placeholder>
);

const PersonSubPages = ({ personUrl, subpages, editAction = false }) => {
  const intl = useIntl();
  let panes = subpages.map((item) => {
    return {
      menuItem: (
        /* By default Menu.Items are rendered as a-elements.
           This leads to nested a-elements if `editAction` is present -> invalid HTML.
           So we use button-elements instead. This is also considered best practice
           regarding accessibility.
           (https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles/tab_role#best_practices)
        */
        <Menu.Item as="button" key={item['@id']}>
          {item.title}
          {editAction && (
            <Link
              to={flattenToAppURL(item['@id']) + '/edit'}
              className="edit"
              title={intl.formatMessage(messages.edit)}
            >
              <Icon
                name={penSVG}
                size="18px"
                className="circled"
                title={intl.formatMessage(messages.edit)}
              />
            </Link>
          )}
        </Menu.Item>
      ),
      render: () => (
        <Tab.Pane>
          {item.blocks ? (
            <RenderBlocks
              blocksConfig={config.blocks.blocksConfig}
              content={item}
            />
          ) : (
            <Loading />
          )}
        </Tab.Pane>
      ),
    };
  });
  if (editAction) {
    panes.push({
      menuItem: (
        <Menu.Item key="add-subpage">
          <Link
            to={`${personUrl}/add?type=Document`}
            className="add"
            title={intl.formatMessage(messages.addSubpage)}
          >
            <Icon
              name={addDocumentSVG}
              size="18px"
              className="circled"
              title={intl.formatMessage(messages.addSubpage)}
            />
          </Link>
        </Menu.Item>
      ),
    });
  }
  return (
    <>
      <Tab
        className="tabs"
        menu={{ secondary: true, pointing: true }}
        panes={panes}
      />
    </>
  );
};

export default PersonSubPages;
