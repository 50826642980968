import React from 'react';
import { defineMessages } from 'react-intl';
import { connect } from 'react-redux';

import ObjectListWidget from '@plone/volto/components/manage/Widgets/ObjectListWidget';

const messages = defineMessages({
  remarks: {
    id: 'Remarks',
    defaultMessage: 'Remarks',
  },
  addItem: {
    id: 'Add item',
    defaultMessage: 'Add item',
  },
  field: {
    id: 'Field',
    defaultMessage: 'Field',
  },
  remark: {
    id: 'Remark',
    defaultMessage: 'Remark',
  },
});

const ItemSchema = (intl, fieldsChoice) => {
  return {
    title: intl.formatMessage(messages.remarks),
    addMessage: intl.formatMessage(messages.addItem),
    properties: {
      field: {
        title: intl.formatMessage(messages.field),
        type: 'string',
        factory: 'Choice',
        choices: fieldsChoice,
      },
      remark: {
        title: intl.formatMessage(messages.remark),
      },
    },
    fieldsets: [
      {
        id: 'default',
        title: intl.formatMessage(messages.remarks),
        fields: ['field', 'remark'],
      },
    ],
    required: ['field', 'remark'],
  };
};

const FieldsRemarksBase = (props) => {
  const fieldsChoice = props?.ct_schema?.properties
    ? Object.keys(props?.ct_schema?.properties).map((key) => [
        key,
        props.ct_schema.properties[key].title,
      ])
    : [];
  const itemSchema = ItemSchema(props.intl, fieldsChoice);
  return (
    <ObjectListWidget
      schema={itemSchema}
      {...props}
      value={props.value?.items || props.default?.items || []}
      onChange={(id, value) => props.onChange(id, { items: value })}
    />
  );
};

const FieldsRemarksWidget = connect((state, props) => ({
  ct_schema: state.schema.schema,
}))(FieldsRemarksBase);

export default FieldsRemarksWidget;
