import { defineMessages, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Button, Popup } from 'semantic-ui-react';
import { Plug } from '@plone/volto/components/manage/Pluggable';
import { Icon } from '@plone/volto/components';
import copySVG from '@plone/volto/icons/copy.svg';
import linkSVG from '@plone/volto/icons/link.svg';

const messages = defineMessages({
  copy: {
    id: 'Copy',
    defaultMessage: 'copy',
  },
  short_url: {
    id: 'Short URL',
    defaultMessage: 'Short URL',
  },
});

const ShortURLWidget = () => {
  const intl = useIntl();

  const isLoading = useSelector((state) => state.content.get.loading);
  const shortUrl = useSelector(
    (state) => state.content?.data?.short_url || false,
  );

  async function copyTextToClipboard(text) {
    if ('clipboard' in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand('copy', true, text);
    }
  }

  const copyLink = () => {
    copyTextToClipboard(shortUrl);
  };

  return shortUrl && !isLoading ? (
    <Plug pluggable="main.toolbar.bottom" id="short-url">
      <Popup
        hoverable
        wide
        className="popup-short-url"
        position="right center"
        trigger={
          <Button>
            <Icon name={linkSVG} />
          </Button>
        }
      >
        <b>{intl.formatMessage(messages.short_url)}</b>
        <br />
        <a href={shortUrl || '#'} target="_blank" rel="noreferrer">
          {shortUrl}
        </a>
        {'  '}
        <Button
          title={intl.formatMessage(messages.copy)}
          size="mini"
          onClick={copyLink}
        >
          <Icon name={copySVG} size="24px" />
        </Button>
      </Popup>
    </Plug>
  ) : (
    <></>
  );
};

export default ShortURLWidget;
