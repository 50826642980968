import { FormattedMessage } from 'react-intl';
import { ConditionalLink, PreviewImage } from '@plone/volto/components';
import { flattenToAppURL } from '@plone/volto/helpers';

const SummaryItem = ({
  item,
  ctaText,
  hideImages,
  hideDescriptions,
  isEditMode,
  overrideImage,
}) => {
  return (
    <div className="summary-item">
      {!hideImages && (
        <div className="img-wrapper">
          <ConditionalLink
            to={flattenToAppURL(item['@id'])}
            condition={!isEditMode}
          >
            <PreviewImage item={overrideImage || item} alt="" />
          </ConditionalLink>
        </div>
      )}
      <div className="listing-body">
        <div className="head awh">
          <ConditionalLink
            to={flattenToAppURL(item['@id'])}
            condition={!isEditMode}
          >
            {item.title ? item.title : item['@id']}
          </ConditionalLink>
        </div>
        {!hideDescriptions && item.description && (
          <div className="description">{item.description}</div>
        )}
      </div>
      <span className="cta-link">
        <ConditionalLink
          to={flattenToAppURL(item['@id'])}
          condition={!isEditMode}
        >
          {ctaText || (
            <FormattedMessage id="Read more" defaultMessage="Read more" />
          )}
        </ConditionalLink>
      </span>
    </div>
  );
};

export default SummaryItem;
