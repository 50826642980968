import { useState } from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { Button, Icon, Image, Modal, Placeholder } from 'semantic-ui-react';
import { Icon as VoltoIcon } from '@plone/volto/components';
import FullscreenSVG from '@plone/volto/icons/fullscreen.svg';

const messages = defineMessages({
  tooltipLightboxIndicator: {
    id: 'Click to enlarge image',
    defaultMessage: 'Click to enlarge image',
  },
});

const LightboxModal = ({
  src,
  caption = '',
  open,
  setOpen,
  indicatorPosition = 'right',
}) => {
  const [hasLoaded, setHasLoaded] = useState(false);
  const intl = useIntl();
  return (
    <>
      <button
        className={
          indicatorPosition === 'left'
            ? 'lightbox-indicator left'
            : 'lightbox-indicator'
        }
        tabIndex={-1}
        title={intl.formatMessage(messages.tooltipLightboxIndicator)}
        onClick={() => setOpen(true)}
      >
        <VoltoIcon name={FullscreenSVG} size="24px" />
      </button>

      <Modal
        className="lightbox"
        closeIcon={true}
        dimmer="blurring"
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        open={open}
        size={'fullscreen'}
      >
        <Modal.Content>
          <Image
            src={src}
            onLoad={() => {
              setHasLoaded(true);
            }}
          />
          {!hasLoaded && (
            <Placeholder>
              <Placeholder.Image rectangular />
            </Placeholder>
          )}
          {caption && <span className="image-caption">{caption}</span>}
        </Modal.Content>

        <Modal.Actions>
          <a href={src} download>
            <Button
              onClick={() => setOpen(false)}
              icon
              labelPosition="left"
              size="small"
              positive
            >
              <Icon name="download" />
              <FormattedMessage id="Download" defaultMessage="Download" />
            </Button>
          </a>
          <Button onClick={() => setOpen(false)} size="small">
            <FormattedMessage id="Close" defaultMessage="Close" />
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  );
};

export default LightboxModal;
