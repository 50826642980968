import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import ObjectListWidget from '@plone/volto/components/manage/Widgets/ObjectListWidget';

const messages = defineMessages({
  labelFunding: {
    id: 'Funding',
    defaultMessage: 'Funding',
  },
  labelAddFunding: {
    id: 'Add funding',
    defaultMessage: 'Add funding',
  },
  labelShow: {
    id: 'Show?',
    defaultMessage: 'Show?',
  },
  labelFunder: {
    id: 'Funder',
    defaultMessage: 'Funder',
  },
  labelURL: {
    id: 'URL',
    defaultMessage: 'URL',
  },
  labelAmount: {
    id: 'Amount',
    defaultMessage: 'Amount',
  },
  labelFundingCode: {
    id: 'Funding code',
    defaultMessage: 'Funding code',
  },
  labelStart: {
    id: 'Start date',
    defaultMessage: 'Start date',
  },
  labelEnd: {
    id: 'End date',
    defaultMessage: 'End date',
  },
  labelLogo: {
    id: 'Logo',
    defaultMessage: 'Logo',
  },
});

const ItemSchema = (intl) => {
  return {
    title: intl.formatMessage(messages.labelFunding),
    addMessage: intl.formatMessage(messages.labelAddFunding),
    properties: {
      show: {
        title: intl.formatMessage(messages.labelShow),
        type: 'boolean',
      },
      funder: {
        title: intl.formatMessage(messages.labelFunder),
        type: 'string',
      },
      url: {
        title: intl.formatMessage(messages.labelURL),
        type: 'string',
      },
      amount: {
        title: intl.formatMessage(messages.labelAmount),
        type: 'number',
      },
      funding_code: {
        title: intl.formatMessage(messages.labelFundingCode),
        type: 'string',
      },
      start: {
        title: intl.formatMessage(messages.labelStart),
        type: 'date',
        widget: 'date',
      },
      end: {
        title: intl.formatMessage(messages.labelEnd),
        type: 'date',
        widget: 'date',
      },
      logo: {
        title: intl.formatMessage(messages.labelLogo),
        type: 'object',
      },
    },
    fieldsets: [
      {
        id: 'default',
        title: intl.formatMessage(messages.labelFunding),
        fields: [
          'show',
          'funder',
          'url',
          'amount',
          'funding_code',
          'start',
          'end',
          'logo',
        ],
      },
    ],
    required: ['funder'],
  };
};

const FundingWidget = (props) => {
  const intl = useIntl();
  const itemSchema = ItemSchema(intl);
  return (
    <ObjectListWidget
      schema={itemSchema}
      {...props}
      value={props.value?.items || props.default?.items || []}
      onChange={(id, value) => props.onChange(id, { items: value })}
    />
  );
};

export default FundingWidget;
