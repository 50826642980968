import { FormattedMessage } from 'react-intl';
import { Message } from 'semantic-ui-react';
import HeadingsView from './Headings';
import PersonsView from './Persons';

const View = (props) => {
  const heading = props.data.heading_data?.lsf_heading || false;
  const persons = heading === false ? props.data.items || false : false;
  if (heading) return <HeadingsView {...props} />;
  if (persons?.length > 0) return <PersonsView {...props} />;
  return (
    <Message warning>
      <FormattedMessage
        id="Plase select heading or person(s)."
        defaultMessage="Plase select heading or person(s)."
      />
    </Message>
  );
};

export default View;
