import EditOrig from '@eeacms/volto-accordion-block-original/components/manage/Blocks/Accordion/Edit';

const Edit = (props) => {
  return props?.data?.show_uniko_arrow ? (
    <div className="acc-with-arrow">
      <EditOrig {...props} />
    </div>
  ) : (
    <EditOrig {...props} />
  );
};

export default Edit;
