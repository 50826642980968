/**
 * LSF headings actions.
 * @module actions/lsf/headings
 */

import { GET_LSF_HEADINGS } from '../../constants/ActionTypes';

/**
 * Get LSF headings.
 * @function getLSFHeadings
 * @param {string} url Context url
 * @param {string} root The root heading whichs sub headings should be returned (mutually to semester)
 * @param {string} semester The semester whichs root headings should be returned (mutually to root)
 * @returns {Object}
 */
export function getLSFHeadings(root = null, semester = null) {
  let url = `/@lsf-headings`;
  let subrequest = null;
  if (root) {
    url += `?root=${root}`;
    subrequest = root;
  }
  if (semester) {
    url += `?semester=${semester}`;
    subrequest = semester;
  }
  return {
    type: GET_LSF_HEADINGS,
    subrequest,
    request: {
      op: 'get',
      path: url,
    },
  };
}
