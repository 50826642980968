/**
 * Add your action types here.
 * @module constants/ActionTypes
 * @example
 * export const UPDATE_CONTENT = 'UPDATE_CONTENT';
 */

export const GET_CARD = 'GET_CARD';
export const GET_LSF_HEADINGS = 'GET_LSF_HEADINGS';
export const GET_LSF_LECTURE = 'GET_LSF_LECTURE';
export const GET_LSF_LECTURES = 'GET_LSF_LECTURES';
export const GET_RESEARCH_PROJECTS = 'GET_RESEARCH_PROJECTS';
export const GET_TRANSLATE = 'GET_TRANSLATE';
