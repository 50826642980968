import InlineForm from '@plone/volto/components/manage/Form/InlineForm';
import { Icon, SidebarPortal } from '@plone/volto/components';
import PublicationsSVG from '@plone/volto/icons/content-listing.svg';
import PublicationsSchema from './schema';
import View from './View';

const Edit = (props) => {
  const schema = PublicationsSchema(props.intl);
  return (
    <>
      <SidebarPortal selected={props.selected}>
        <InlineForm
          schema={schema}
          title={schema.title}
          icon={<Icon name={PublicationsSVG} />}
          onChangeField={(id, value) => {
            props.onChangeBlock(props.block, {
              ...props.data,
              [id]: value,
            });
          }}
          formData={props.data}
        />
      </SidebarPortal>
      <View data={props.data} isEditMode={true} />
    </>
  );
};

export default Edit;
