import { useEffect, useState } from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Grid, Input, Loader } from 'semantic-ui-react';
import { searchContent } from '@plone/volto/actions';
import { flattenToAppURL } from '@plone/volto/helpers';
import { DegreeProgramCard, NavPills } from '@package/components';

const messages = defineMessages({
  search: {
    id: 'Search',
    defaultMessage: 'Search',
  },
});

const SubjectProfileSearchView = ({ content }) => {
  const [searchInput, setSearchInput] = useState('');
  const dispatch = useDispatch();
  const intl = useIntl();

  let pathQuery = [];
  try {
    /* we abuse the field `preview_caption` to store path query
         (should be an array of strings)
      */
    pathQuery = JSON.parse(content.preview_caption);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
  }

  useEffect(() => {
    dispatch(
      searchContent('/', {
        /* CT `SubjectProfile` is for  `Fächersteckbriefe`
           and `Document` is for `Fächer`-Landingpages
        */
        portal_type: ['SubjectProfile', 'Document'],
        fullobjects: true,
        b_size: 999,
        'path.query': pathQuery,
        'path.depth': 1,
      }),
    );
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [dispatch]);

  const compareItems = (a, b) => {
    const cmpA = a.title;
    const cmpB = b.title;
    return cmpA < cmpB ? -1 : cmpA > cmpB ? 1 : 0;
  };

  const items = useSelector(
    (state) =>
      state.search?.items
        /* filter by text */
        .filter(
          (i) =>
            searchInput.length === 0 ||
            i.title.toLowerCase().indexOf(searchInput.toLowerCase()) > -1,
        )
        .sort(compareItems) || [],
  );

  const hasLoaded = useSelector((state) => state.search?.loaded || false);

  return (
    <Container id="program-search-container">
      {content.relatedItems?.length > 0 && (
        <NavPills
          items={
            content.relatedItems?.length > 0
              ? content.relatedItems.map((i) => {
                  return { url: flattenToAppURL(i['@id']), title: i.title };
                })
              : []
          }
          position="right"
        />
      )}
      <h1 className="documentFirstHeading">{content.title}</h1>
      <Input
        className="in-search"
        placeholder={intl.formatMessage(messages.search, {
          count: items.length,
        })}
        fluid
        icon="search"
        iconPosition="left"
        value={searchInput}
        onChange={(event) => setSearchInput(event.target.value)}
      />
      <div className="results">
        {hasLoaded ? (
          <>
            <div className="tools">
              <span>
                {content.head_title || (
                  <FormattedMessage
                    id="Subject profiles"
                    defaultMessage="Subject profiles"
                  />
                )}{' '}
                <strong>({items.length})</strong>
              </span>
            </div>
            <Grid>
              {items.map((item) => (
                <Grid.Column
                  mobile={12}
                  tablet={6}
                  computer={6}
                  largeScreen={4}
                  widescreen={4}
                  key={item['@id']}
                >
                  <DegreeProgramCard content={item} />
                </Grid.Column>
              ))}
            </Grid>
          </>
        ) : (
          <>
            <Loader size="large" inline="centered" active>
              <FormattedMessage id="Loading" defaultMessage="Loading" /> ...
            </Loader>
          </>
        )}
      </div>
    </Container>
  );
};

export default SubjectProfileSearchView;
