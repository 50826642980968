import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getVocabulary } from '@plone/volto/actions';
import { SelectWidget } from '@plone/volto/components';

const SemestersSelectWidget = (props) => {
  const dispatch = useDispatch();
  const semesters = useSelector((state) => state.vocabularies['lsf.semesters']);
  useEffect(() => {
    dispatch(getVocabulary({ vocabNameOrURL: 'lsf.semesters' }));
  }, [dispatch]);

  const onChangeSemester = (elid, value) => {
    props.onChange(props.id, value);
  };

  return semesters && semesters.items ? (
    <SelectWidget
      id={`${props.id}-semesters`}
      title={`Semester:`}
      choices={semesters.items.map((i) => [i.value, i.label])}
      onChange={onChangeSemester}
      value={props.value}
    ></SelectWidget>
  ) : (
    <></>
  );
};

export default SemestersSelectWidget;
