/**
 * Vocabularies actions.
 * @module actions/vocabularies/vocabularies
 */

import { GET_VOCABULARY } from '@plone/volto/constants/ActionTypes';
import { flattenToAppURL } from '@plone/volto/helpers';
import { isUrl } from '@plone/volto/helpers/Url/Url';
import { getVocabName } from '@plone/volto/helpers/Vocabularies/Vocabularies';

import { getVocabularyTokenTitle } from '@plone/volto-original/actions/vocabularies/vocabularies.js';

/*
 adapt to provide context to vocabulary endpoint
 (https://github.com/plone/volto/issues/3216)
*/
const getVocabulary = ({
  vocabNameOrURL,
  query = null,
  start = 0,
  size,
  subrequest,
}) => {
  const vocabulary = getVocabName(vocabNameOrURL);

  /* new */
  const vocabPath = isUrl(vocabNameOrURL)
    ? flattenToAppURL(vocabNameOrURL)
    : `/@vocabularies/${vocabulary}`;
  /* new */

  let queryString = `b_start=${start}${size ? '&b_size=' + size : ''}`;

  if (query) {
    queryString = `${queryString}&title=${query}`;
  }
  return {
    type: GET_VOCABULARY,
    vocabulary: vocabNameOrURL,
    start,
    request: {
      op: 'get',
      /* adapted */
      path: `${vocabPath}?${queryString}`,
      /* adapted */
    },
    subrequest,
  };
};

export { getVocabulary, getVocabularyTokenTitle };
