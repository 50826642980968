/* 
    Adapts `renderLinkElement` from `volto/packages/volto-slate` to fix
    https://gitlab.uni-koblenz.de/cms-developer/volto-uniko/-/issues/712

    Problem: `useIntl()` is always called in `renderLinkElement` - even if
             it is not available in some rare special cases.
    Fix:     Move `useIntl()` into component `CopyToast` which is only
             mounted when `useIntl()` is actually available.
*/
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { UniversalLink, Toast } from '@plone/volto/components';
import { messages, addAppURL } from '@plone/volto/helpers';
import useClipboard from '@plone/volto/hooks/clipboard/useClipboard';
import config from '@plone/volto/registry';
import linkSVG from '@plone/volto/icons/link.svg';

const CopyToast = () => {
  const intl = useIntl();
  return (
    <Toast
      info
      title={intl && intl.formatMessage(messages.success)}
      content={intl && intl.formatMessage(messages.urlClipboardCopy)}
    />
  );
};

const renderLinkElement = (tagName) => {
  function LinkElement({
    attributes,
    children,
    mode = 'edit',
    className = null,
  }) {
    const { slate = {} } = config.settings;
    const Tag = tagName;
    const slug = attributes.id || '';
    const location = useLocation();
    const token = useSelector((state) => state.userSession.token);
    const appPathname = addAppURL(location.pathname);
    // eslint-disable-next-line no-unused-vars
    const [copied, copy, setCopied] = useClipboard(
      appPathname.concat(`#${slug}`),
    );
    return !token || slate.useLinkedHeadings === false ? (
      <Tag {...attributes} className={className} tabIndex={0}>
        {children}
      </Tag>
    ) : (
      <Tag {...attributes} className={className} tabIndex={0}>
        {children}
        {mode === 'view' && slug && (
          <UniversalLink
            className="anchor"
            aria-hidden="true"
            tabIndex={-1}
            href={`#${slug}`}
          >
            <style>
              {/* Prettify the unstyled flash of the link icon on development */}
              {`
                a.anchor svg {
                  height: var(--anchor-svg-height, 24px);
                }
                `}
            </style>
            <svg
              {...linkSVG.attributes}
              dangerouslySetInnerHTML={{ __html: linkSVG.content }}
              height={null}
              onClick={() => {
                copy();
                toast.info(<CopyToast />);
              }}
            ></svg>
          </UniversalLink>
        )}
      </Tag>
    );
  }
  LinkElement.displayName = `${tagName}LinkElement`;
  return LinkElement;
};

export default renderLinkElement;
