import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getContent } from '@plone/volto/actions';
import { Helmet, flattenToAppURL } from '@plone/volto/helpers';
import { Card, Container, Grid, Icon, Image, List } from 'semantic-ui-react';
import PersonSubPages from './PersonSubPages/PersonSubPages';
import PersonContact from './PersonCard/PersonContact';

const VisitingHours = ({ hours, asTable = false }) => {
  if (!asTable) return <div className="hours">{hours}</div>;

  let rows = [];

  hours.split('\n').forEach((line) => {
    let left,
      right = '';
    if (line.indexOf(':') > -1) {
      const parts = line.split(':');
      left = parts.shift().trim() + ':';
      right = parts.join(':').trim();
    } else {
      left = line;
      right = ' ';
    }
    rows.push([left, right]);
  });

  return (
    <div className="hours">
      <span>
        <FormattedMessage id="Visiting hours" defaultMessage="Visiting hours" />
      </span>
      <table>
        <tbody>
          {rows.map((row, index) => (
            <tr key={`visisting-hours-${index}`}>
              <td>{row[0]}</td>
              <td>{row[1]}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const MetaData = ({ content, functions }) => {
  const primaryFunction = functions.length > 0 ? functions[0] : false;

  return (
    <Card className="person-meta">
      <Card.Content>
        <Grid columns={content.visitingHours ? 3 : 2} doubling stackable>
          {primaryFunction?.orgUnit?.title && (
            <Grid.Row className="org">
              <Grid.Column>{primaryFunction.orgUnit.title}</Grid.Column>
            </Grid.Row>
          )}
          <Grid.Row>
            <Grid.Column width={4}>
              <div className="address">
                {content.address && content.address}
                {content.office && (
                  <div>
                    <FormattedMessage id="Office" defaultMessage="Office" />{' '}
                    {content.office}
                  </div>
                )}
              </div>
            </Grid.Column>
            <Grid.Column width={content.visitingHours ? 4 : 8}>
              <PersonContact person={content} showWeb={true} />
            </Grid.Column>
            {content.visitingHours && (
              <Grid.Column>
                <VisitingHours
                  hours={content.visitingHours}
                  asTable={content.visitingHoursAsTable}
                />
              </Grid.Column>
            )}
          </Grid.Row>
        </Grid>
      </Card.Content>
    </Card>
  );
};

const PersonView = (props) => {
  const { content } = props;

  const functionsUrl = `${flattenToAppURL(
    content['@id'],
  )}/@functions-of-person`;
  const functionsSubrequest = useSelector(
    (state) => state.content?.subrequests?.[functionsUrl],
  );
  const dispatch = useDispatch();
  const functions = functionsSubrequest?.data?.items || [];
  useEffect(() => {
    /* dont dispatch if subrequests has already been loaded */
    if (functionsSubrequest?.loaded !== true)
      dispatch(getContent(flattenToAppURL(functionsUrl), null, functionsUrl));
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [dispatch, functionsUrl]);

  return (
    <Container id="page-document">
      {content.photo ? (
        <Image
          src={flattenToAppURL(
            content.photo.scales?.preview?.download ||
              content.photo.download ||
              '',
          )}
          size="small"
          alt={content.image_caption}
          circular
        />
      ) : (
        <div className="image person-image-placeholder" floated="right"></div>
      )}
      <Helmet title={content.title} />
      <h1 className="documentFirstHeading">{content.title}</h1>
      {content.description ? (
        <p className="documentDescription">{content.description}</p>
      ) : (
        <p className="documentDescription">&nbsp;</p>
      )}
      <MetaData content={content} functions={functions} />
      {content.remark && (
        <div className="infobox">
          <div>
            <Icon.Group size="small">
              <Icon size="big" name="square full" />
              <Icon name="info" inverted />
            </Icon.Group>
          </div>
          {content.remark}
        </div>
      )}
      <Grid className="person-body">
        <Grid.Row>
          <Grid.Column mobile={16} tablet={16} computer={8}>
            {content.additionalText && (
              <div
                dangerouslySetInnerHTML={{
                  __html: content.additionalText.data,
                }}
              />
            )}
          </Grid.Column>
          <Grid.Column mobile={16} tablet={16} computer={4}>
            {functions.length > 0 && (
              <div className="functions">
                <div className="head">
                  <FormattedMessage id="Functions" defaultMessage="Functions" />
                </div>
                <List>
                  {functions.map((item) => {
                    return item ? (
                      <List.Item key={item['@id']}>
                        <Link to={flattenToAppURL(item['@id'])}>
                          {item.title}
                        </Link>
                        {item.orgUnit && (
                          <>
                            <br />
                            {' ('}
                            <Link to={flattenToAppURL(item.orgUnit['@id'])}>
                              {item.orgUnit.title}
                            </Link>
                            {')'}
                          </>
                        )}
                      </List.Item>
                    ) : (
                      <></>
                    );
                  })}
                </List>
              </div>
            )}
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <PersonSubPages url={flattenToAppURL(content['@id'])} />
    </Container>
  );
};

export default PersonView;
