import { flattenToAppURL, isInternalURL } from '@plone/volto/helpers';

export function getTeaserImageURL(href, image) {
  if (image) {
    if (isInternalURL(image['@id'])) {
      return `${image['@id']}/@@images/image/`;
    } else {
      return image['@id'];
    }
  } else {
    return `${href['@id']}/@@images/${href.image_field || 'preview_image'}`;
  }
}

export function getTeaserImageSrcSet(href, image) {
  const url = getTeaserImageURL(href, image);
  return [
    flattenToAppURL(`${url}/preview 400w`),
    flattenToAppURL(`${url}/teaser 600w`),
    flattenToAppURL(`${url}/large 800w`),
    flattenToAppURL(`${url}/larger 1000w`),
    flattenToAppURL(`${url}/great 1200w`),
  ];
}
