import React from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import { Icon as SemanticUIIcon, Label } from 'semantic-ui-react';
import { getVocabulary } from '@plone/volto/actions';
import { ConditionalLink, Icon } from '@plone/volto/components';
import { injectLazyLibs } from '@plone/volto/helpers/Loadable/Loadable';
import calendarSVG from '@plone/volto/icons/calendar.svg';
import { TypeIcon } from '@package/components';

const SearchResults = ({ items, isEditMode, moment: momentlib }) => {
  const messages = defineMessages({
    newsDateTitle: {
      id: 'newsDateTitle',
      defaultMessage: 'Date of the news item',
    },
    eventDateTitle: {
      id: 'eventDateTitle',
      defaultMessage: 'Date of the event',
    },
  });

  const intl = useIntl();
  const moment = momentlib.default;
  moment.locale(intl.locale);

  const dispatch = useDispatch();
  const orgUnitsVocab = useSelector(
    (state) => state.vocabularies['ecampus.orgunits'] || false,
  );
  const orgUnitIdToName = (ouId) => {
    if (orgUnitsVocab === false || orgUnitsVocab.loaded === false) return false;
    const res = orgUnitsVocab.items?.filter((i) => i.value === ouId);
    if (res.length < 1) return false;
    return res[0].label;
  };
  if (
    orgUnitsVocab === false ||
    (orgUnitsVocab.loaded === false && orgUnitsVocab.loading === false)
  )
    dispatch(
      getVocabulary({
        vocabNameOrURL: 'ecampus.orgunits',
        size: 99999,
      }),
    );

  return (
    <div className="search-results items">
      {items.map((item) => {
        let date = false;
        let dateTitle = false;
        if (item['@type'] === 'News Item') {
          date = item?.currentFrom || item?.created || false;
          dateTitle = intl.formatMessage(messages.newsDateTitle);
        }
        if (item['@type'] === 'Event') {
          date = item.start || false;
          dateTitle = intl.formatMessage(messages.eventDateTitle);
        }
        return (
          <div className="listing-item" key={item['@id']}>
            <ConditionalLink condition={!isEditMode} to={item['@id']}>
              <div className="listing-body">
                <h4>
                  {date && (
                    <span className="pub-date" title={dateTitle}>
                      <Icon name={calendarSVG} /> {moment(date).format('ll')}
                    </span>
                  )}
                  <TypeIcon type={item['@type']} typeLabel={item.Type} />
                  {item.title}
                </h4>
                {item.description && <p>{item.description}</p>}
                <span className="location">{item['@id']}</span>
                <span className="mod-date">
                  <FormattedMessage
                    id="Last modified"
                    defaultMessage="Last modified"
                  />
                  : {moment(item.ModificationDate).format('ll')}
                </span>
                {item.Subject?.length + item.affiliated_org_units?.length >
                  0 && (
                  <div className="tags">
                    {item.Subject?.length > 0 && (
                      <Label.Group tag>
                        {item.Subject.map((subject) => (
                          <Label key={`subject-${subject}`}>#{subject}</Label>
                        ))}
                      </Label.Group>
                    )}
                    {item.affiliated_org_units?.length > 0 && (
                      <Label.Group>
                        {item.affiliated_org_units.map((ou) => {
                          const label = orgUnitIdToName(ou);
                          return (
                            label !== false && (
                              <Label key={`aou-${ou}`}>
                                <SemanticUIIcon name="home" />
                                {label}
                              </Label>
                            )
                          );
                        })}
                      </Label.Group>
                    )}
                  </div>
                )}
              </div>
            </ConditionalLink>
          </div>
        );
      })}
    </div>
  );
};

export default injectLazyLibs(['moment'])(SearchResults);
