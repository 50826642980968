import { injectIntl } from 'react-intl';
import { Button, Container, Grid, Icon, List } from 'semantic-ui-react';
import placeholderJPG from '@package/assets/placeholder_project.jpg';
import placeholderWEBP from '@package/assets/placeholder_project.webp';
import { NavPills, PreviewHero } from '@package/components';
import ToCView from '@package/components/Blocks/ToC/View';
import { FakePersonCard, MetaBoxItem } from './DegreeProgram/utils';

const MetaBox = ({ content, intl }) => {
  return (
    <div className="metabox">
      <MetaBoxItem
        title={'Stunden'}
        value={`${content.hours} Stunden / Woche${
          content.part_time && ',\nTeilzeit'
        }`}
        intl={intl}
      />
      <MetaBoxItem
        title="Entgeldgruppe"
        value={`${content.pay}${
          content.time_limit && `,\nbefristet bis ${content.time_limit}`
        }`}
        intl={intl}
      />
      <MetaBoxItem title="Kennziffer" value={content.code} intl={intl} />
      <MetaBoxItem
        title="Bewerbungsfrist"
        value={content.application_deadline}
        intl={intl}
      />{' '}
      <a href="#">
        <Button>Bewerben</Button>
      </a>
    </div>
  );
};

const JobPostView = ({ intl }) => {
  const content = {
    title: 'Sekretär*in',
    description: 'Kurzbeschreibung',
    org_unit:
      'Fachbereich 3: Mathematik/Naturwissenschaften Institut für Integrierte Naturwissenschaften, Abteilung Physik',
    hours: '19,5',
    part_time: true,
    pay: '9a, TV-L',
    time_limit: '29.04.2023',
    code: '60/2023',
    application_deadline: '10.09.2023',
    tasks: [
      'Erledigung allgemeiner Sekretariatsarbeiten (z.B. interne und externe Kommunikation, Postbearbeitung',
      'Terminüberwachung, organisatorische Vor- und Nachbereitung von Sitzungen/Konferenzen (national und',
      'international), Dienstreisen, Beschaffung inklusive der damit verbundenen Prüfungen, Erstellung und Pflege von',
      'Formularen und -verzeichnissen, Protokollführung, Bestellung und Verwaltung von Büromaterialien,',
      'Mittelüberwachung inklusive Drittmittel, Raum- und Schlüsselverwaltung, Archivierung und Datenpflege, etc.)',
      'Mitarbeit in Lehr-, Studien-, Prüfungs- und Forschungsangelegenheiten',
      'Funktion als erste Anlaufstelle für Studierende, Mitarbeitende, Lehrbeauftragte und Gäste',
    ],
    requirements: [
      'Voraussetzung ist eine abgeschlossene einschlägige Berufsausbildung (z. B. als Kauffrau/-mann für Bürokommunikation/-management)',
      'Entsprechende einschlägige Berufserfahrung im Sekretariatsbereich wird erwartet',
      'Sehr gute EDV- (Internet, E-Mail, MS-Office-Programme) sowie sichere Rechtschreibkenntnisse',
      'Gutes mündliches und schriftliches Ausdrucksvermögen  Erfahrung im Umgang mit Publikumsverkehr',
      'Englischkenntnisse sind von Vorteil  Erfahrungen im Hochschulbereich sind von Vorteil',
      'Zuverlässigkeit, Organisationstalent, Flexibilität, Teamfähigkeit, Offenheit und Kommunikationsfreudigkeit',
      'Bereitschaft sich in hochschulspezifische EDV-Programme, Abläufe und Vorschriften einzuarbeiten',
    ],
    offer: [
      {
        icon: 'money',
        text: 'Vergütung nach Entgeltgruppe 6 TV-L',
      },
      {
        icon: 'basketball ball',
        text: 'Vielfältiges Sportprogramm mit gesundheitsfördernden Angeboten ',
      },
      {
        icon: 'group',
        text: 'Eine kollegiale Arbeitsatmosphäre',
      },
      {
        icon: 'rocket',
        text:
          'Entfaltungsmöglichkeiten und Gestaltungsspielraum in einem dynamischen Umfeld ',
      },
      {
        icon: 'graduation',
        text: 'Umfangreiche Möglichkeiten zur Fort- und Weiterbildung',
      },
    ],
    application_info:
      'Die Universität Koblenz begrüßt Bewerbungen aller Altersgruppen, unabhängig vonGeschlechtsidentität, Behinderung, ethnischem oder kulturellem Hintergrund, Religion,Weltanschauung oder sexueller Orientierung. Wir streben eine Erhöhungdes Frauenanteils an und sind daher an Bewerbungen vonFrauen besonders interessiert. Sie werden bei gleichwertigerEignung und Befähigung im Falle einer Unterrepräsentanz bevorzugt berücksichtigt.Schwerbehinderte Menschen werdenbei sonst gleicher fachlicher und persönlicher Eignung bevorzugt berücksichtigt.',
    further_links: [
      {
        title: 'Fachbereich 3: Mathematik / Naturwissenschaften',
        url: 'https://www.uni-koblenz.de/de/mathematik-naturwissenschaften',
      },
      {
        title: 'Anderer Link',
        url: 'https://www.uni-koblenz.de/de/mathematik-naturwissenschaften',
      },
    ],
    contact: [],
  };

  return (
    <Container id="program-container" className="job-post">
      <PreviewHero
        heroImage={
          <picture>
            <source type="image/webp" srcSet={placeholderWEBP} />
            <img src={placeholderJPG} alt="" />
          </picture>
        }
        caption={content.title}
        subCaption={`<span class="mwd">(m/w/d)</span>im ${content.org_unit}`}
      />
      <MetaBox content={content} intl={intl} />
      <Grid reversed="tablet computer">
        <Grid.Row>
          <Grid.Column mobile={12} tablet={2} computer={4}>
            <ToCView
              data={{ position: 'fixed' }}
              items={[
                {
                  key: 'tasks',
                  text: 'Ihre Aufgaben',
                  type: 'h2',
                },
                {
                  key: 'requirements',
                  text: 'Was Sie mitbringen',
                  type: 'h2',
                },
                {
                  key: 'offer',
                  text: 'Was wir bieten',
                  type: 'h2',
                },
                {
                  key: 'info',
                  text: 'Informationen zur Bewerbung',
                  type: 'h2',
                },
                ...(content.further_links?.length > 0
                  ? [
                      {
                        key: 'further_links',
                        text: 'Weitere Links',
                        type: 'h2',
                      },
                    ]
                  : []),
                {
                  key: 'contact',
                  text: 'Kontakt',
                  type: 'h2',
                },
              ]}
            />
          </Grid.Column>
          <Grid.Column mobile={12} tablet={10} computer={8}>
            <p>{content.description}</p>
            <h2 id="tasks">Ihre Aufgaben</h2>
            <List bulleted>
              {content.tasks.map((task) => (
                <List.Item>{task}</List.Item>
              ))}
            </List>
            <h2 id="requirements">Was Sie mitbringen</h2>
            <List bulleted>
              {content.requirements.map((requirement) => (
                <List.Item>{requirement}</List.Item>
              ))}
            </List>
            <h2 id="offer">Was wir bieten</h2>
            <Grid className="what-we-offer">
              <Grid.Column computer={12} largeScreen={6} widescreen={6}>
                <List>
                  {content.offer.map((item) => (
                    <List.Item>
                      <Icon name={item.icon} circular inverted />
                      {item.text}
                    </List.Item>
                  ))}
                </List>
              </Grid.Column>
              <Grid.Column computer={12} largeScreen={6} widescreen={6}>
                <List>
                  {content.offer.map((item) => (
                    <List.Item>
                      <Icon name={item.icon} circular inverted />
                      {item.text}
                    </List.Item>
                  ))}
                </List>
              </Grid.Column>
            </Grid>
            <h2 id="info">Informationen zur Bewerbung</h2>
            <p>{content.application_info}</p>
            {content.further_links?.length > 0 && (
              <>
                <h2 id="further_links">Weitere Links</h2>
                <NavPills items={content.further_links} />
              </>
            )}
            <h2 id="contact">Kontakt</h2>
            <h3>Leiter Referat 21</h3>
            <FakePersonCard
              head="Frank Müller"
              subhead="Referat 21: Arbeits- und Tarifrecht, Organisation, Wahlen"
              phone="+49 261 287 - 1710"
              email="frankmueller@uni-koblenz.de"
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Container>
  );
};

export default injectIntl(JobPostView);
