import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getContent } from '@plone/volto/actions';
import { flattenToAppURL } from '@plone/volto/helpers';
import { DegreeProgramCard, DegreeProgramSummary } from '@package/components';

const View = ({ data, isEditMode }) => {
  const url =
    data?.href?.length > 0 && data.href[0]['@id']
      ? flattenToAppURL(data.href[0]['@id'])
      : false;
  const subrequest = useSelector((state) => state.content.subrequests?.[url]);
  const program = subrequest?.data || false;
  const dispatch = useDispatch();
  useEffect(() => {
    if (url !== false && subrequest?.loaded !== true)
      dispatch(getContent(url, null, url));
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [dispatch, url]);

  return program ? (
    data.variant === 'summary' ? (
      <DegreeProgramSummary
        content={program}
        showDescription={data.show_description}
        showMaterials={data.show_materials}
        linkDisabled={isEditMode}
      />
    ) : (
      <DegreeProgramCard
        content={program}
        showDescription={data.show_description}
        showMaterials={data.show_materials}
        linkDisabled={isEditMode}
      />
    )
  ) : (
    <></>
  );
};

export default View;
