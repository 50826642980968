import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { flattenToAppURL } from '@plone/volto/helpers';
import { Placeholder } from 'semantic-ui-react';
import { NavPills } from '@package/components';

const messages = defineMessages({
  relatedItemsTitle: {
    id: 'Related items',
    defaultMessage: 'Related items',
  },
});

const View = ({ data, properties, editable }) => {
  const intl = useIntl();
  const relatedItems = (properties?.relatedItems || [])
    .filter((i) => i && i.can_view === true)
    .map((item) => {
      return {
        title: item.title,
        url: flattenToAppURL(item['@id']),
      };
    });
  const heading =
    data?.heading || intl.formatMessage(messages.relatedItemsTitle);

  if (relatedItems.length === 0) {
    if (editable) {
      return (
        <Placeholder fluid>
          <Placeholder.Line length="full" />
          <i>
            <FormattedMessage
              id="Related items appear here."
              defaultMessage="Related items appear here."
            />
          </i>
        </Placeholder>
      );
    }
    return <></>;
  }

  return (
    <>
      <hr />
      <h3>{heading}</h3>
      <NavPills items={relatedItems} disableLinks={editable} />
    </>
  );
};

export default View;
