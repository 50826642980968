import { defineMessages } from 'react-intl';

const messages = defineMessages({
  labelImageCardSettings: {
    id: 'Settings',
    defaultMessage: 'Settings',
  },
  labelLinkTo: {
    id: 'Link to',
    defaultMessage: 'Link to',
  },
});

const ImageCardBlockSchema = (intl) => {
  return {
    title: intl.formatMessage(messages.labelImageCardSettings),

    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: ['href'],
      },
    ],

    properties: {
      href: {
        title: intl.formatMessage(messages.labelLinkTo),
        widget: 'object_browser',
        mode: 'link',
        selectedItemAttrs: ['Title', 'Description'],
      },
    },

    required: [],
  };
};

export default ImageCardBlockSchema;
