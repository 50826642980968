import { useState } from 'react';
import { Input, Table } from 'semantic-ui-react';
import { UniKOAccordion } from '@package/components';
import './ndf.less';

const Offers = ({ offers, category }) => {
  return (
    <>
      {category.description && <b>{category.description}</b>}
      <Table className="ndf-table" basic="very" compact selectable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Was?</Table.HeaderCell>
            <Table.HeaderCell>Wer?</Table.HeaderCell>
            <Table.HeaderCell>Wann?</Table.HeaderCell>
            <Table.HeaderCell>Wo?</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {offers.map((offer) => (
            <Table.Row key={`offer-${offer.id}`}>
              <Table.Cell>
                <a
                  href={`https://ndf.uni-koblenz.de/offer/${offer.id}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {offer.title}
                </a>
              </Table.Cell>
              <Table.Cell>{offer.provider}</Table.Cell>
              <Table.Cell style={{ whiteSpace: 'nowrap' }}>
                {offer.time_start.slice(0, -3)} - {offer.time_end.slice(0, -3)}{' '}
                Uhr
              </Table.Cell>
              <Table.Cell style={{ whiteSpace: 'nowrap' }}>
                {offer.location}
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </>
  );
};

const NdF = () => {
  const [searchInput, setSearchInput] = useState('');
  const [categories, setCategories] = useState(false);
  async function updateData() {
    const url = 'https://ndf.uni-koblenz.de/api/offers';
    const formData = new FormData();
    if (searchInput) formData.append('keyword', searchInput);
    const res = await fetch(url, {
      method: 'POST',
      body: formData,
    }).then((response) => response.json());
    setCategories(res);
  }
  if (categories === false && __CLIENT__) updateData();

  const items = categories
    ? categories.map((c) => {
        return {
          title: `${c.name} (${c.offers.length})`,
          content: <Offers offers={c.offers} category={c} />,
        };
      })
    : [];

  const onChange = (evt) => {
    setSearchInput(evt.target.value);
    updateData();
  };
  return (
    <div className="ndf-program">
      <Input
        className="search"
        onChange={onChange}
        value={searchInput}
        placeholder="Suchbegriff"
      />
      <UniKOAccordion items={items} />
    </div>
  );
};

export default NdF;
