import React from 'react';
import { defineMessages } from 'react-intl';

import ObjectListWidget from '@plone/volto/components/manage/Widgets/ObjectListWidget';

const messages = defineMessages({
  material: {
    id: 'Material',
    defaultMessage: 'Material',
  },
  addMaterial: {
    id: 'Add item',
    defaultMessage: 'Add item',
  },
  kind: {
    id: 'Kind',
    defaultMessage: 'Kind',
  },
  remark: {
    id: 'Remark',
    defaultMessage: 'Remark',
  },
  url: {
    id: 'URL',
    defaultMessage: 'URL',
  },
});

const ItemSchema = (intl) => {
  return {
    title: intl.formatMessage(messages.material),
    addMessage: intl.formatMessage(messages.addMaterial),
    properties: {
      kind: {
        title: intl.formatMessage(messages.kind),
        type: 'string',
        factory: 'Choice',
        choices: [
          ['moha', 'Modulhandbuch'],
          ['stupla', 'Studienverlaufsplan'],
          ['po', 'Prüfungsordnung'],
        ],
      },
      remark: {
        title: intl.formatMessage(messages.remark),
      },
      url: {
        title: intl.formatMessage(messages.url),
        widget: 'url',
      },
    },
    fieldsets: [
      {
        id: 'default',
        title: intl.formatMessage(messages.material),
        fields: ['kind', 'remark', 'url'],
      },
    ],
    required: ['kind', 'url'],
  };
};

const MaterialsWidget = (props) => {
  const itemSchema = ItemSchema(props.intl);
  return (
    <ObjectListWidget
      schema={itemSchema}
      {...props}
      value={props.value?.items || props.default?.items || []}
      onChange={(id, value) => props.onChange(id, { items: value })}
    />
  );
};

export default MaterialsWidget;
