import { FormattedMessage } from 'react-intl';
import { Message } from 'semantic-ui-react';

const View = ({ data }) => {
  if (
    !data ||
    !data.url ||
    !data.url.startsWith(
      'https://videoakademie.ko-ld.de/Panopto/Pages/Viewer.aspx?id=',
    )
  )
    return (
      <Message warning>
        <FormattedMessage
          id="Please enter a valid URL of a Panopto video."
          defaultMessage="Please enter a valid URL of a Panopto video."
        />
      </Message>
    );
  /* see src/utils.js */
  const ratios = {
    landscape: '56.25', // 16:9
    square: '100.0', // 1:1
    portrait: '177.77', // 9:16
  };
  const aspectRatio = ratios[data.aspect_ratio || 'landscape'];
  const alignment = data.alignment || 'center';
  const maxWidth = data.max_width || '100%';
  const interactivity = data.interactivity || 'none';
  const hideTitle = data.hide_title || false;
  const src = `${data.url.replace(
    'Viewer.aspx',
    'Embed.aspx',
  )}&autoplay=false&offerviewer=false&showtitle=${!hideTitle}&showbrand=false&captions=false&interactivity=${interactivity}`;
  const wrapperStyle = {
    ...(alignment === 'center' && {
      marginLeft: 'auto',
      marginRight: 'auto',
    }),
    ...(alignment === 'left' && {
      marginRight: 'auto',
    }),
    ...(alignment === 'right' && {
      marginLeft: 'auto',
    }),
    maxWidth: maxWidth,
  };
  return (
    <div className="panopto-video-wrapper" style={wrapperStyle}>
      <div
        className="panopto-video-container"
        style={{ paddingBottom: aspectRatio + '%' }}
      >
        <iframe
          width="auto"
          src={src}
          allowFullScreen
          title="Embedded Panopto Video"
        ></iframe>
      </div>
    </div>
  );
};

export default View;
