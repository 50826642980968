import React, { useEffect, useRef } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';
import { Container } from 'semantic-ui-react';
import { Icon } from '@plone/volto/components';
import clearSVG from '@plone/volto/icons/clear.svg';
import zoomSVG from '@plone/volto/icons/zoom.svg';
import { Partial } from '@package/components';

const messages = defineMessages({
  openSearch: {
    id: 'Open search',
    defaultMessage: 'Open search',
  },
});

const MegaSearch = (props) => {
  const intl = useIntl();
  const { isOpen, setIsOpen } = props;

  const searchRef = useRef(null);

  /* close on path change */
  const location = useLocation();
  React.useEffect(() => {
    setIsOpen(false);
  }, [location.pathname, setIsOpen]);

  React.useEffect(() => {
    if (isOpen && searchRef?.current) searchRef.current.focus();

    /* permit scrolling of content when mega menu is open,
       to avoid strange behavior if user wants to scroll
       within mega menu: */
    document.getElementsByTagName('body')[0].style.overflow = isOpen
      ? 'hidden'
      : 'auto';
  }, [isOpen]);

  const onKeyDown = (e) => {
    if (e.keyCode === 27 && isOpen) setIsOpen(false);
  };

  /* onloaded callback of Partial is called everytime search results
     are refreshed, which makes it the ideal place to hook in and
     grab the current search term(s).
  */
  const onPartialLoaded = () => {
    if (isOpen === false) return;
    const input = document.querySelector('.mega-search .search-input input');
    if (input?.value?.length > 0) {
      /* report custom event to Plausible
         (https://plausible.io/docs/custom-event-goals#trigger-custom-events-manually-with-a-javascript-function)
      */
      window.plausible =
        window.plausible ||
        function () {
          (window.plausible.q = window.plausible.q || []).push(arguments);
        };
      window.plausible('Search', { props: { term: input.value } });
    }
  };

  useEffect(() => {
    return () => {
      const input = document.querySelector('.mega-search .search-input input');
      if (input) input.removeEventListener('input');
    };
  }, []);

  return (
    <>
      <button
        className="btn-mega-search"
        onClick={() => setIsOpen(!isOpen)}
        tabIndex={0}
        aria-label={intl.formatMessage(messages.openSearch)}
      >
        {isOpen === false && <Icon name={zoomSVG} size="28px" />}
        {isOpen === true && <Icon name={clearSVG} size="28px" />}
      </button>
      <div
        className={isOpen ? 'mega-overlay search show' : 'mega-overlay search'}
        ref={searchRef}
        role="search"
      >
        <Container
          className="mega-search"
          tabIndex={isOpen ? 0 : -1}
          onKeyDown={onKeyDown}
        >
          {isOpen && <Partial name="psearch" onloaded={onPartialLoaded} />}
        </Container>
      </div>
    </>
  );
};

export default MegaSearch;
