import { Icon as IconOrig } from 'semantic-ui-react';

/*
  Custom component to avoid ugly warnings when using
  custom icons (defined in `icons.less`) by clearing
  the `name` property of the original Icon component.
  (https://github.com/Semantic-Org/Semantic-UI-React/issues/955)
*/
const Icon = (props) => {
  if (
    props.name === 'bluesky' ||
    props.name === 'mastodon' ||
    props.name === 'x-twitter'
  )
    return <IconOrig {...props} className={props.name} name="" />;
  return <IconOrig {...props} />;
};

export default Icon;
