import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import { getVocabulary } from '@plone/volto/actions';
import { Label, Icon } from 'semantic-ui-react';

function SelectFacetFilterListEntry(props) {
  const { facet, isEditMode, setFacets, facets } = props;
  const dispatch = useDispatch();
  const orgUnitsVocab = useSelector(
    (state) => state.vocabularies['ecampus.orgunits']?.items || false,
  );
  const orgUnitIdToName = (ouId) => {
    if (orgUnitsVocab === false) return ouId;
    const res = orgUnitsVocab.filter((i) => i.value === ouId);
    if (res.length < 1) return ouId;
    return res[0].label;
  };
  return typeof facets[facet] === 'string' ? (
    <Label size="small">
      {facets[facet]}
      <Icon
        name="delete"
        onClick={() => {
          !isEditMode &&
            setFacets({
              ...facets,
              [facet]: '',
            });
        }}
      />
    </Label>
  ) : (
    <>
      {facets[facet].map((entry, i) => {
        let text = entry;
        if (facet === 'portal_type') text = <FormattedMessage id={text} />;
        if (facet === 'affiliated_org_units') {
          if (orgUnitsVocab === false)
            dispatch(
              getVocabulary({
                vocabNameOrURL: 'ecampus.orgunits',
                size: 99999,
              }),
            );
          text = orgUnitIdToName(entry);
        }
        return (
          <Label size="small" key={i}>
            {text}
            <Icon
              name="delete"
              onClick={() => {
                const entries = facets[facet].filter((item) => item !== entry);
                !isEditMode &&
                  setFacets({
                    ...facets,
                    [facet]: entries,
                  });
              }}
            />
          </Label>
        );
      })}
    </>
  );
}

export default SelectFacetFilterListEntry;
