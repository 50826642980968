import { FormattedMessage } from 'react-intl';
import { Placeholder } from 'semantic-ui-react';

const View = ({ properties, metadata, editable }) => {
  const description = properties?.description || metadata?.description || false;
  return (
    <div className="content-description">
      {description ? (
        description
      ) : editable ? (
        <Placeholder fluid>
          <Placeholder.Line length="full" />
          <i>
            <FormattedMessage
              id="Description appears here."
              defaultMessage="Description appears here."
            />
          </i>
        </Placeholder>
      ) : (
        <></>
      )}
    </div>
  );
};

export default View;
