/**
 * Anontools component.
 * @module components/theme/Anontools/Anontools
 */

import React, { Component } from 'react';
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import { Icon } from '@plone/volto/components';
import userSVG from '@plone/volto/icons/user.svg';

const messages = defineMessages({
  titleLogin: {
    id: 'Log in',
    defaultMessage: 'Log in',
  },
});

/**
 * Anontools container class.
 */
export class Anontools extends Component {
  /**
   * Property types.
   * @property {Object} propTypes Property types.
   * @static
   */
  static propTypes = {
    token: PropTypes.string,
    location: PropTypes.object.isRequired,
  };

  /**
   * Default properties.
   * @property {Object} defaultProps Default properties.
   * @static
   */
  static defaultProps = {
    token: null,
  };

  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
  render() {
    const url = this.props.location.pathname.endsWith('/')
      ? this.props.location.pathname
      : `${this.props.location.pathname}/`;
    const loginUrl =
      url.indexOf('/login/') > -1
        ? this.props.location.pathname
        : url.split('/').length > 2
        ? `${url}login`
        : `/${this.props.intl.locale}/login`;
    return (
      !this.props.token && (
        /* intentionally no Link component, since we need a real
           page reload to trigger Shibboleth login
        */
        <a
          className="login"
          aria-label={this.props.intl.formatMessage(messages.titleLogin)}
          title={this.props.intl.formatMessage(messages.titleLogin)}
          href={loginUrl}
          tabIndex={0}
        >
          <span>
            <FormattedMessage id="Log in" defaultMessage="Log in" />
          </span>
          <Icon name={userSVG} />
        </a>
      )
    );
  }
}

export default connect((state) => ({
  token: state.userSession.token,
}))(injectIntl(withRouter(Anontools)));
