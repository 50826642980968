import { Container } from 'semantic-ui-react';
import { flattenToAppURL } from '@plone/volto/helpers';
import FileViewOrig from '@plone/volto-original/components/theme/View/FileView';

const FileView = (props) => {
  const content = props.content;
  const file = content.file;
  const src = `${flattenToAppURL(content['@id'])}/@@display-file/file`;
  if (file['content-type'] === 'application/pdf') {
    return (
      <Container>
        <h1 className="documentFirstHeading">
          {content.title}
          {content.subtitle && ` - ${content.subtitle}`}
        </h1>
        {content.description && (
          <p className="documentDescription">{content.description}</p>
        )}
        <iframe
          title={file.filename}
          src={src}
          width="100%"
          height="700"
        ></iframe>
        <br />
        <br />
        {content.file?.download && (
          <a href={flattenToAppURL(content.file.download)}>
            {content.file.filename}
          </a>
        )}
      </Container>
    );
  }
  return <FileViewOrig {...props} />;
};

export default FileView;
