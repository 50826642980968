import { defineMessages } from 'react-intl';

const messages = defineMessages({
  source: {
    id: 'Source',
    defaultMessage: 'Source',
  },
  descrSource: {
    id: 'Select content or copy an URL into this field.',
    defaultMessage: 'Select content or copy an URL into this field.',
  },
  width: {
    id: 'Width',
    defaultMessage: 'Width',
  },
  fullWidth: {
    id: 'Full width',
    defaultMessage: 'Full width',
  },
  autoWidth: {
    id: 'Auto',
    defaultMessage: 'Auto',
  },
});

export const AudioSchema = ({ formData, intl }) => {
  return {
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: [...(formData.href ? ['href', 'width'] : [])],
      },
    ],

    properties: {
      href: {
        title: intl.formatMessage(messages.source),
        widget: 'url',
      },
      width: {
        title: intl.formatMessage(messages.width),
        type: 'string',
        factory: 'Choice',
        choices: [
          ['auto', intl.formatMessage(messages.autoWidth)],
          ['full', intl.formatMessage(messages.fullWidth)],
        ],
        default: 'auto',
      },
    },
    required: [],
  };
};

export default AudioSchema;
