import { useEffect, useState } from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { Message, Placeholder } from 'semantic-ui-react';
import { ConditionalLink } from '@plone/volto/components';
import { NewsItemsListing } from '@package/components';

const messages = defineMessages({
  rss_load_error: {
    id: 'rss_load_error',
    defaultMessage: 'Could not load RSS feed.',
  },
  rss_no_url: {
    id: 'rss_no_url',
    defaultMessage: 'No URL specified.',
  },
});

const getTagTextSafe = (xmlObj, tagName) => {
  const els = xmlObj.getElementsByTagName(tagName);
  if (els.length === 0) return '';
  return els[0].textContent || '';
};

/* converts XML element from the RSS feed into a JSON object,
   ready to be fed into NewsItemsListing
*/
const xmlItemToJson = (xmlItem) => {
  let res = {};
  for (let i = 0; i < xmlItem.childNodes.length; i++) {
    const child = xmlItem.childNodes[i];
    if (child.tagName) res[child.tagName] = child.textContent;

    /* convert HTML description into plain text */
    const htmlDescrStr = res.description;
    const htmlDescrObj = new DOMParser().parseFromString(
      htmlDescrStr,
      'text/html',
    );
    const imgs = htmlDescrObj.getElementsByTagName('img');
    if (imgs.length > 0) res.external_img_src = imgs[0].src;
    let descrArr = [];
    for (let j = 0; j < htmlDescrObj.childNodes.length; j++) {
      if (htmlDescrObj.childNodes[j].textContent)
        descrArr.push(htmlDescrObj.childNodes[j].textContent);
    }
    res.description = descrArr.join('\n');
    res['@id'] = res.link;
    res.Date = res.pubDate;
  }
  return res;
};

const View = ({ data, isEditMode }) => {
  const intl = useIntl();
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [feed, setFeed] = useState(null);
  const [items, setItems] = useState(null);
  const url = data?.rss_feed_url || false;
  useEffect(() => {
    if (!url || url.length === 0)
      setError(intl.formatMessage(messages.rss_no_url));
    else if (!loading) {
      setError(false);
      setLoading(true);
      setItems(null);
      setFeed(null);
      fetch(url)
        .then((res) => {
          if (!res.ok) {
            setError(intl.formatMessage(messages.rss_load_error));
            setLoading(false);
          } else return res.text();
        })
        .then((text) => {
          const parser = new DOMParser();
          const doc = parser.parseFromString(text, 'application/xml');
          const errorNode = doc.querySelector('parsererror');
          if (errorNode) {
            setError(intl.formatMessage(messages.rss_load_error));
            setLoading(false);
          } else {
            const xmlItems = doc.getElementsByTagName('item');
            let jsonItems = [];
            for (let i = 0; i < xmlItems.length; i++)
              jsonItems.push(xmlItemToJson(xmlItems[i]));
            setFeed({
              title: getTagTextSafe(doc, 'title'),
              link: getTagTextSafe(doc, 'link'),
            });
            setItems(jsonItems);
            setLoading(false);
          }
        })
        .catch(() => {
          setError(intl.formatMessage(messages.rss_load_error));
          setLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url]);
  if (error) return <Message error>{error}</Message>;
  return loading ? (
    <Placeholder fluid>
      <Placeholder.Line />
      <Placeholder.Line />
      <Placeholder.Line />
    </Placeholder>
  ) : items?.length > 0 ? (
    <>
      {data.show_title && (
        <ConditionalLink
          condition={!isEditMode && data.link_title && feed.link?.length > 0}
          href={feed.link}
          target="_blank"
        >
          <h2>{feed.title}</h2>
        </ConditionalLink>
      )}
      <div className="block listing">
        <NewsItemsListing
          items={items}
          show_images={data.show_images || false}
          isEditMode={isEditMode}
        />
      </div>
    </>
  ) : (
    <p>
      <FormattedMessage
        id="No results found."
        defaultMessage="No results found."
      />
    </p>
  );
};

export default View;
