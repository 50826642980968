import { defineMessages } from 'react-intl';

const messages = defineMessages({
  labelJobPostMetadata: {
    id: 'Job post metadata',
    defaultMessage: 'Job post metadata',
  },
  labelDefault: {
    id: 'Default',
    defaultMessage: 'Default',
  },
  labelSelectPerson: {
    id: 'Select person',
    defaultMessage: 'Select person',
  },
  labelDownloads: {
    id: 'Downloads',
    defaultMessage: 'Downloads',
  },
  descrDownloads: {
    id: 'Select files',
    defaultMessage: 'Select files',
  },
  labelContacts: {
    id: 'Contacts',
    defaultMessage: 'Contacts',
  },
  labelContact: {
    id: 'Contact',
    defaultMessage: 'Contact',
  },
  labelAddContact: {
    id: 'Add contact',
    defaultMessage: 'Add contact',
  },
  title: {
    id: 'Title',
    defaultMessage: 'Title',
  },
  name: {
    id: 'Name',
    defaultMessage: 'Name',
  },
  address: {
    id: 'Address',
    defaultMessage: 'Address',
  },
  email: {
    id: 'E-Mail',
    defaultMessage: 'E-Mail',
  },
  phone: {
    id: 'Telephone',
    defaultMessage: 'Telephone',
  },
  links: {
    id: 'Links',
    defaultMessage: 'Links',
  },
  onePerLine: {
    id: 'One entry per line',
    defaultMessage: 'One entry per line',
  },
  labelHours: {
    id: 'job_post_hours',
    defaultMessage: 'Hours',
  },
  labelDeadline: {
    id: 'Application deadline',
    defaultMessage: 'Application deadline',
  },
  labelApplyText: {
    id: 'job_post_cta_text',
    defaultMessage: 'Call-to-action text',
  },
  labelApplyMailTo: {
    id: 'job_post_mail_to',
    defaultMessage: 'Application mail receiver',
  },
  labelApplyMailSubject: {
    id: 'job_post_mail_subject',
    defaultMessage: 'Application mail subject',
  },
  applyNow: {
    id: 'Apply now',
    defaultMessage: 'Apply now',
  },
  defaultSubject: {
    id: 'job_post_default_subject',
    defaultMessage: 'Application for ',
  },
});

const ContactSchema = (intl) => {
  return {
    title: intl.formatMessage(messages.labelContact),
    addMessage: intl.formatMessage(messages.labelAddContact),

    fieldsets: [
      {
        id: 'default',
        title: intl.formatMessage(messages.labelDefault),
        fields: ['title', 'name', 'address', 'email', 'phone', 'links'],
      },
    ],

    properties: {
      title: {
        title: intl.formatMessage(messages.title),
        default: intl.formatMessage(messages.labelContact),
      },
      name: {
        title: intl.formatMessage(messages.name),
      },
      address: {
        title: intl.formatMessage(messages.address),
        widget: 'textarea',
        default: 'Universität Koblenz\nUniversitätsstraße 1\n56070 Koblenz',
      },
      email: {
        title: intl.formatMessage(messages.email),
      },
      phone: {
        title: intl.formatMessage(messages.phone),
      },
      links: {
        title: intl.formatMessage(messages.links),
        description: intl.formatMessage(messages.onePerLine),
        widget: 'textarea',
      },
    },

    required: ['title', 'name'],
  };
};

export const MetadataJobPostSchema = (intl) => {
  const MyContactSchema = ContactSchema(intl);
  return {
    title: intl.formatMessage(messages.labelJobPostMetadata),
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: [
          'hours',
          'deadline',
          'contacts',
          'downloads',
          'cta_text',
          'apply_mail_to',
          'apply_mail_subject',
        ],
      },
    ],

    properties: {
      hours: {
        title: intl.formatMessage(messages.labelHours),
      },
      deadline: {
        title: intl.formatMessage(messages.labelDeadline),
        type: 'date',
        widget: 'date',
      },
      contacts: {
        title: intl.formatMessage(messages.labelContacts),
        schema: MyContactSchema,
        widget: 'object_list',
      },
      downloads: {
        title: intl.formatMessage(messages.labelDownloads),
        description: intl.formatMessage(messages.descrDownloads),
        widget: 'object_browser',
        widgetOptions: {
          pattern_options: { selectableTypes: ['File', 'Image'] },
        },
        selectedItemAttrs: ['Title', 'Description', '@type'],
      },
      cta_text: {
        title: intl.formatMessage(messages.labelApplyText),
        default: intl.formatMessage(messages.applyNow),
      },
      apply_mail_to: {
        title: intl.formatMessage(messages.labelApplyMailTo),
      },
      apply_mail_subject: {
        title: intl.formatMessage(messages.labelApplyMailSubject),
        default: intl.formatMessage(messages.defaultSubject),
      },
    },

    required: [
      'hours',
      'deadline',
      'cta_text',
      'apply_mail_to',
      'apply_mail_subject',
    ],
  };
};

export default MetadataJobPostSchema;
