import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import ObjectListWidget from '@plone/volto/components/manage/Widgets/ObjectListWidget';

const messages = defineMessages({
  labelProjectPartner: {
    id: 'Project partner',
    defaultMessage: 'Project partner',
  },
  labelAddPProjectPartner: {
    id: 'Add project partner',
    defaultMessage: 'Add project partner',
  },
  labelName: {
    id: 'Name',
    defaultMessage: 'Name',
  },
  labelURL: {
    id: 'URL',
    defaultMessage: 'URL',
  },
  labelLogo: {
    id: 'Logo',
    defaultMessage: 'Logo',
  },
});

const ItemSchema = (intl) => {
  return {
    title: intl.formatMessage(messages.labelProjectPartner),
    addMessage: intl.formatMessage(messages.labelAddPProjectPartner),
    properties: {
      name: {
        title: intl.formatMessage(messages.labelName),
        type: 'string',
      },
      url: {
        title: intl.formatMessage(messages.labelURL),
        type: 'string',
      },
      logo: {
        title: intl.formatMessage(messages.labelLogo),
        type: 'object',
      },
    },
    fieldsets: [
      {
        id: 'default',
        title: intl.formatMessage(messages.labelProjectPartner),
        fields: ['name', 'url', 'logo'],
      },
    ],
    required: ['name'],
  };
};

const PartnerWidget = (props) => {
  const intl = useIntl();
  const itemSchema = ItemSchema(intl);
  return (
    <ObjectListWidget
      schema={itemSchema}
      {...props}
      value={props.value?.items || props.default?.items || []}
      onChange={(id, value) => props.onChange(id, { items: value })}
    />
  );
};

export default PartnerWidget;
