import { useReducer } from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { Message, Table } from 'semantic-ui-react';
import { ConditionalLink, FormattedDate } from '@plone/volto/components';
import { flattenToAppURL } from '@plone/volto/helpers';
import _ from 'lodash';

const messages = defineMessages({
  status_finished: {
    id: 'status_finished',
    defaultMessage: 'Finished',
  },
  status_running: {
    id: 'status_running',
    defaultMessage: 'Running',
  },
});

function tableRowsReducer(state, action) {
  switch (action.type) {
    case 'CHANGE_SORT':
      if (state.column === action.column) {
        return {
          ...state,
          rows: state.rows.slice().reverse(),
          direction:
            state.direction === 'ascending' ? 'descending' : 'ascending',
        };
      }

      return {
        column: action.column,
        rows: _.sortBy(state.rows, [action.column + '_sortkey', action.column]),
        direction: 'ascending',
      };
    default:
      throw new Error();
  }
}

const TableListing = ({ items, isEditMode, query, ...props }) => {
  const intl = useIntl();

  const queryQuery = query?.query || [];

  const isDoctoratesTable = queryQuery.find(
    (crit) => crit.i === 'portal_type' && crit.v.indexOf('Doctorate') > -1,
  )
    ? true
    : false;

  const onlyFinishedDoctorates = isDoctoratesTable
    ? queryQuery.find(
        (crit) =>
          crit.i === 'research_project_status' &&
          crit.v.indexOf('status_finished') > -1,
      )
      ? true
      : false
    : false;

  const onlyRunningDoctorates = isDoctoratesTable
    ? queryQuery.find(
        (crit) =>
          crit.i === 'research_project_status' &&
          crit.v.indexOf('status_running') > -1,
      )
      ? true
      : false
    : false;

  const item2table = (item) => {
    if (isDoctoratesTable)
      return {
        title: (
          <ConditionalLink
            condition={!isEditMode}
            to={flattenToAppURL(item['@id'])}
          >
            {item.title}
          </ConditionalLink>
        ),
        title_sortkey: item.title.toLowerCase(),
        name: `${item.prename} ${item.surname}`,
        Supervisor: item.supervisor,
        status: item.status ? intl.formatMessage(messages[item.status]) : '',
        ...(onlyRunningDoctorates && {
          'Admission to doctoral student status': (
            <FormattedDate date={item.admission_date} />
          ),
          'Admission to doctoral student status_sortkey': item.admission_date,
        }),
        ...(onlyFinishedDoctorates && {
          'Finish date': <FormattedDate date={item.finish_date} />,
          'Finish date_sortkey': item.finish_date,
        }),
      };

    /* default table layout */
    return {
      title: (
        <ConditionalLink
          condition={!isEditMode}
          to={flattenToAppURL(item['@id'])}
        >
          {item.title}
        </ConditionalLink>
      ),
      title_sortkey: item.title.toLowerCase(),
      description: item.description,
      Type: item.portal_type
        ? intl.formatMessage({ id: item.portal_type })
        : '',
    };
  };
  const rowsUnsorted = items.map((item) => item2table(item));

  const [state, dispatch] = useReducer(tableRowsReducer, {
    column: null,
    rows: rowsUnsorted,
    direction: null,
  });
  const { column, rows, direction } = state;

  if (rows.length === 0)
    return (
      <Message warning>
        <FormattedMessage
          id="No results found."
          defaultMessage="No results found."
        />
      </Message>
    );

  return (
    <Table sortable celled>
      <Table.Header>
        <Table.Row>
          {Object.keys(rows[0])
            .filter((key) => !key.endsWith('_sortkey'))
            .map((key) => (
              <Table.HeaderCell
                key={`h-${key}`}
                sorted={column === key ? direction : null}
                onClick={() => dispatch({ type: 'CHANGE_SORT', column: key })}
              >
                {intl.formatMessage({ id: key })}
              </Table.HeaderCell>
            ))}
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {rows.map((row, index) => (
          <Table.Row key={`ti-${index}`}>
            {Object.keys(row)
              .filter((key) => !key.endsWith('_sortkey'))
              .map((key) => (
                <Table.Cell key={`tc-${index}-${key}`}>{row[key]}</Table.Cell>
              ))}
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
};

export default TableListing;
