import React from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { flattenToAppURL } from '@plone/volto/helpers';
import { injectLazyLibs } from '@plone/volto/helpers/Loadable/Loadable';
import { Card, Container, Grid, Image } from 'semantic-ui-react';
import { PersonCard, PreviewHero } from '@package/components';
import LogoDE from '@package/svgs/logos/LogoDE.svg';
import { prettyPrintURL } from '@package/utils';
import placeholderJPG from '@package/assets/placeholder_project.jpg';
import placeholderWEBP from '@package/assets/placeholder_project.webp';
import { replaceImgShortCodes, replaceVideoShortCodes } from '@package/utils';

const messages = defineMessages({
  titleHead: {
    id: 'Head',
    defaultMessage: 'Head',
  },
  titleParticipant: {
    id: 'Project team',
    defaultMessage: 'Project team',
  },
});

const ProjectPersons = ({ persons, isHead }) => {
  const intl = useIntl();
  const functionTitle = isHead
    ? intl.formatMessage(messages.titleHead)
    : intl.formatMessage(messages.titleParticipant);
  return (
    <Grid columns={2} doubling stackable>
      {persons?.map((person) => {
        return person ? (
          <Grid.Column key={person['@id']}>
            <PersonCard
              person={person}
              override={{ function: functionTitle }}
              layout="default"
            />
          </Grid.Column>
        ) : (
          <></>
        );
      })}
    </Grid>
  );
};

const ProjectPartners = ({ partners }) => {
  return (
    <Grid doubling stackable className="fupa-block">
      {partners.items.map((partner) => {
        return (
          partner && (
            <Grid.Row key={partner['@id']} columns={2}>
              <Grid.Column className="meta-item">
                <span>{partner.name}</span>
                {partner.url && (
                  <b>
                    <a href={partner.url} target="_blank" rel="noreferrer">
                      {prettyPrintURL(partner.url)}
                    </a>
                  </b>
                )}
              </Grid.Column>
              <Grid.Column>
                {partner.logo && (
                  <Image
                    className="fupa-logo"
                    src={`data:${partner.logo['content-type']};${partner.logo.encoding},${partner.logo.data}`}
                  />
                )}
              </Grid.Column>
            </Grid.Row>
          )
        );
      })}
    </Grid>
  );
};

const ProjectOrgUnits = ({ orgunits }) => {
  const getOrgUnitLogo = (orgunit) => {
    /* @TODO: check if orgunit has a logo or select corresponding faculty logo? */
    return LogoDE;
  };
  return (
    <Grid doubling stackable className="fupa-block">
      {orgunits
        ?.filter((o) => o)
        .map((orgunit) => {
          const url = `https://www.uni-koblenz.de${flattenToAppURL(
            orgunit['@id'],
          )}`;
          return orgunit ? (
            <Grid.Row key={orgunit['@id']} columns={2}>
              <Grid.Column className="meta-item">
                <span>{orgunit.title}</span>
                <b>
                  <a href={url} target="_blank" rel="noreferrer" title={url}>
                    {prettyPrintURL(url)}
                  </a>
                </b>
              </Grid.Column>
              <Grid.Column>
                <Image
                  className="fupa-logo"
                  src={getOrgUnitLogo(orgunit)}
                  alt={orgunit.title}
                  title={orgunit.title}
                />
              </Grid.Column>
            </Grid.Row>
          ) : (
            <></>
          );
        })}
    </Grid>
  );
};

const ProjectFundings = ({ fundings }) => {
  return (
    <Grid doubling stackable className="fupa-block">
      {fundings.items.map((item) => {
        return (
          <Grid.Row key={item['@id']} columns={2}>
            <Grid.Column className="meta-item">
              <span>{item.funder}</span>
              {item.url && (
                <b>
                  <a href={item.url} target="_blank" rel="noreferrer">
                    {prettyPrintURL(item.url)}
                  </a>
                </b>
              )}
            </Grid.Column>
            <Grid.Column>
              {item.logo && (
                <Image
                  className="fupa-logo"
                  src={`data:${item.logo['content-type']};${item.logo.encoding},${item.logo.data}`}
                />
              )}
            </Grid.Column>
          </Grid.Row>
        );
      })}
    </Grid>
  );
};

const ProjectMeta = ({ content, moment: momentlib }) => {
  const intl = useIntl();
  const moment = momentlib.default;
  moment.locale(intl.locale);
  const fStart = content.start ? moment(content.start).format('ll') : false;
  const fEnd = content.end ? moment(content.end).format('ll') : false;
  const durationStr = fStart && fEnd ? `${fStart} - ${fEnd}` : fStart;
  return (
    <Card className="meta-data">
      <Grid columns={4} doubling stackable>
        <Grid.Column className="meta-item">
          <span>
            <FormattedMessage id="Duration" defaultMessage="Duration" />
          </span>
          <b>{durationStr}</b>
        </Grid.Column>
        <Grid.Column className="meta-item">
          <span>
            <FormattedMessage id="Status" defaultMessage="Status" />
          </span>
          <b>{content.status?.title}</b>
        </Grid.Column>
        <Grid.Column className="meta-item">
          {content.url && (
            <>
              <span>
                <FormattedMessage id="Website" defaultMessage="Website" />
              </span>
              <b>
                <a
                  href={content.url}
                  target="_blank"
                  rel="noreferrer"
                  title={content.url}
                >
                  <FormattedMessage id="Visit" defaultMessage="Visit" />
                </a>
              </b>
            </>
          )}
        </Grid.Column>
        <Grid.Column className="meta-item">
          {content.email && content.showEmail && (
            <>
              <span>
                <FormattedMessage id="Contact" defaultMessage="Contact" />
              </span>
              <b>
                <a href={`mailto:${content.email}`}>
                  {content.email.replace('@', '(at)')}
                </a>
              </b>
            </>
          )}
        </Grid.Column>
      </Grid>
    </Card>
  );
};

const ProjectMetaData = injectLazyLibs(['moment'])(ProjectMeta);

const ResearchProjectView = ({ content }) => {
  const metaData = content.start || content.end || content.logo;
  return (
    <Container id="project-container">
      {content.preview_image ? (
        <PreviewHero
          heroImage={
            <img src={flattenToAppURL(content.preview_image.download)} alt="" />
          }
          caption={content.title}
        />
      ) : (
        <PreviewHero
          heroImage={
            <picture>
              <source type="image/webp" srcSet={placeholderWEBP} />
              <img src={placeholderJPG} alt="" />
            </picture>
          }
          caption={content.title}
        />
      )}
      {content.logo && (
        <Image
          src={flattenToAppURL(content.logo.scales.preview.download)}
          size="small"
          rounded
          floated="right"
        />
      )}
      {content.acronym && <h2>{content.acronym}</h2>}
      {content.description && <p>{content.description}</p>}
      {metaData && <ProjectMetaData content={content} />}
      {content.text?.data?.length > 12 && (
        <div
          className="project-text"
          dangerouslySetInnerHTML={{
            __html: replaceVideoShortCodes(
              replaceImgShortCodes(content.text.data),
              content.videos?.items || [],
            ),
          }}
        />
      )}
      <hr className="full-width" />
      {content.publications?.data?.length > 12 && (
        <>
          <Grid doubling stackable>
            <Grid.Row columns={2}>
              <Grid.Column>
                <h2>
                  <FormattedMessage
                    id="Publications"
                    defaultMessage="Publications"
                  />
                </h2>
              </Grid.Column>
              <Grid.Column>
                <div
                  dangerouslySetInnerHTML={{
                    __html: content.publications.data,
                  }}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <hr className="full-width" />
        </>
      )}
      {content.assignments?.data?.length > 12 && (
        <>
          <Grid doubling stackable>
            <Grid.Row columns={2}>
              <Grid.Column>
                <h2>
                  <FormattedMessage
                    id="Assignments"
                    defaultMessage="Assignments"
                  />
                </h2>
              </Grid.Column>
              <Grid.Column>
                <div
                  dangerouslySetInnerHTML={{
                    __html: content.assignments.data,
                  }}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <hr className="full-width" />
        </>
      )}
      {(content.fundings?.items?.length > 0 ||
        content.partners?.items?.length > 0 ||
        content.participatingOrgUnits?.length > 0) && (
        <>
          <Grid doubling stackable>
            <Grid.Row columns={1}>
              <Grid.Column>
                <h2>
                  <FormattedMessage
                    id="Fundings & Partners"
                    defaultMessage="Fundings & Partners"
                  />
                </h2>
                <br />
              </Grid.Column>
            </Grid.Row>
            {content.participatingOrgUnits?.length > 0 && (
              <Grid.Row columns={2}>
                <Grid.Column>
                  <strong>
                    <FormattedMessage
                      id="Participating organizational units"
                      defaultMessage="Participating organizational units"
                    />
                  </strong>
                </Grid.Column>
                <Grid.Column>
                  <ProjectOrgUnits orgunits={content.participatingOrgUnits} />
                </Grid.Column>
              </Grid.Row>
            )}
            {content.partners?.items?.length > 0 && (
              <Grid.Row columns={2}>
                <Grid.Column>
                  <strong>
                    <FormattedMessage
                      id="project_partners"
                      defaultMessage="Project partners"
                    />
                  </strong>
                </Grid.Column>
                <Grid.Column>
                  <ProjectPartners partners={content.partners} />
                </Grid.Column>
              </Grid.Row>
            )}
            {content.fundings?.items?.length > 0 && (
              <Grid.Row columns={2}>
                <Grid.Column>
                  <strong>
                    <FormattedMessage
                      id="project_funded_by"
                      defaultMessage="Funded by"
                    />
                  </strong>
                </Grid.Column>
                <Grid.Column>
                  <ProjectFundings fundings={content.fundings} />
                </Grid.Column>
              </Grid.Row>
            )}
          </Grid>
          <hr className="full-width" />
        </>
      )}
      {(content.isHeadOf?.length > 0 || content.isParticipant?.length > 0) && (
        <>
          <h2>
            <FormattedMessage id="Contact" defaultMessage="Contact" />
          </h2>
          {content.isHeadOf?.length > 0 && (
            <ProjectPersons persons={content.isHeadOf} isHead={true} />
          )}
          {content.isParticipant?.length > 0 && (
            <ProjectPersons persons={content.isParticipant} isHead={false} />
          )}
        </>
      )}
    </Container>
  );
};

export default ResearchProjectView;
