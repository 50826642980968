import { defineMessages } from 'react-intl';

const messages = defineMessages({
  divider: {
    id: 'Divider',
    defaultMessage: 'Divider',
  },
  kind: {
    id: 'Kind',
    defaultMessage: 'Kind',
  },
  line: {
    id: 'Separator line',
    defaultMessage: 'Separator line',
  },
  break: {
    id: 'Line break',
    defaultMessage: 'Line break',
  },
});

export const DividerSchema = (intl) => {
  return {
    title: intl.formatMessage(messages.divider),
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: ['kind'],
      },
    ],

    properties: {
      kind: {
        title: intl.formatMessage(messages.kind),
        type: 'string',
        factory: 'Choice',
        choices: [
          ['hr', intl.formatMessage(messages.line)],
          ['br', intl.formatMessage(messages.break)],
        ],
        default: 'hr',
      },
    },
    required: [],
  };
};

export default DividerSchema;
