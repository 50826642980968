/**
 * Language selector component.
 * @module components/LanguageSelector/LanguageSelector
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { useSelector } from 'react-redux';
import { find, map } from 'lodash';

import { Helmet, langmap, flattenToAppURL } from '@plone/volto/helpers';

import config from '@plone/volto/registry';

import { defineMessages, FormattedMessage, useIntl } from 'react-intl';

import { Icon, Popup } from 'semantic-ui-react';

const messages = defineMessages({
  switchLanguageTo: {
    id: 'Switch to',
    defaultMessage: 'Switch to',
  },
});

const LanguageSelector = (props) => {
  const intl = useIntl();
  const currentLang = useSelector((state) => state.intl.locale);
  const translations = useSelector(
    (state) => state.content.data?.['@components']?.translations?.items,
  );
  const closests = useSelector(
    (state) =>
      state.content.data?.['@components']?.translations?.closests || [],
  );

  const { settings } = config;

  return settings.isMultilingual ? (
    <div className="language-selector">
      {map(settings.supportedLanguages, (lang) => {
        const translation = find(translations, { language: lang });
        if (lang !== currentLang) {
          const closest = find(closests, { language: lang });
          return translation ? (
            <Link
              aria-label={`${intl.formatMessage(
                messages.switchLanguageTo,
              )} ${langmap[lang].nativeName.toLowerCase()}`}
              to={
                translation ? flattenToAppURL(translation['@id']) : `/${lang}`
              }
              title={langmap[lang].nativeName}
              onClick={() => {
                props.onClickAction();
              }}
              key={`language-selector-${lang}`}
            >
              {lang.toUpperCase()}
            </Link>
          ) : (
            <Popup
              hoverable
              key={`popup-${lang}`}
              on="click"
              trigger={<span className="disabled">{lang.toUpperCase()}</span>}
              wide
            >
              <Popup.Header>
                <Icon name="unlink"></Icon>{' '}
                <FormattedMessage id="no_translation" />
              </Popup.Header>
              <Popup.Content>
                <FormattedMessage id="no_translation_description" />{' '}
                <Link
                  aria-label={`${intl.formatMessage(
                    messages.switchLanguageTo,
                  )} ${langmap[lang].nativeName.toLowerCase()}`}
                  to={closest ? flattenToAppURL(closest['@id']) : `/${lang}`}
                  title={langmap[lang].nativeName}
                  onClick={() => {
                    props.onClickAction();
                  }}
                >
                  <FormattedMessage id="next_translated_parent" />
                </Link>
                .
              </Popup.Content>
            </Popup>
          );
        }
      })}
    </div>
  ) : (
    <Helmet>
      <html lang={settings.defaultLanguage} />
    </Helmet>
  );
};

LanguageSelector.propTypes = {
  onClickAction: PropTypes.func,
};

LanguageSelector.defaultProps = {
  onClickAction: () => {},
};

export default LanguageSelector;
