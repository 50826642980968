import { Icon } from '@plone/volto/components';
import { getContentIcon } from '@plone/volto/helpers';

const TypeIcon = ({ type, typeLabel, size = 36 }) => {
  return (
    <Icon
      name={getContentIcon(type)}
      alt={typeLabel}
      className="type"
      size={size}
    />
  );
};

export default TypeIcon;
