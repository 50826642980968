import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Message } from 'semantic-ui-react';
import { getLSFLecture } from '@package/actions';
import Veranstaltung from './Veranstaltung';

const View = (props) => {
  const { data, isEditMode } = props;
  const lectureId = data?.lecture_id || false;
  const subrequestKey = lectureId;
  const subrequest = useSelector(
    (state) => state.lsf_lecture.subrequests?.[subrequestKey],
  );
  const dispatch = useDispatch();
  useEffect(() => {
    if (lectureId && subrequest?.loaded !== true)
      dispatch(getLSFLecture('/', lectureId, subrequestKey));
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [dispatch, lectureId, subrequestKey]);

  const lecture = subrequest?.lecture;
  return lecture && Object.keys(lecture).length > 0 ? (
    <Veranstaltung
      data={lecture}
      hideHeader={data.hide_header}
      hideComment={data.hide_comment}
      isEditMode={isEditMode}
    />
  ) : (
    <Message warning>
      <FormattedMessage
        id="No matching lectures found."
        defaultMessage="No matching lectures found."
      />
    </Message>
  );
};

export default View;
