import { useEffect, useMemo } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { RenderBlocks } from '@plone/volto/components';
import { Tab } from 'semantic-ui-react';

const useQuery = (location) => {
  const { search } = location;
  return useMemo(() => new URLSearchParams(search), [search]);
};

const View = (props) => {
  const { data, metadata } = props;
  const tabsData = data.data || { block: {}, blocks_layout: { items: [] } };
  const tabsList = tabsData.blocks_layout?.items || [];
  const tabs = tabsData.blocks || {};

  const history = useHistory();
  const location = useLocation();

  const query = useQuery(location);
  const activeTab = query.get('activeTab') || '';
  const activeBlockId = activeTab.slice(0, 36);
  const activeIndex = activeBlockId === props.id ? activeTab.slice(37) : 0;

  useEffect(() => {
    if (activeIndex !== 0 && __CLIENT__) {
      try {
        const sel = `div[id='${activeBlockId}'] > .ui.pointing.secondary.menu:first-child > a:nth-child(${
          parseInt(activeIndex) + 1
        })`;
        const el = document.querySelector(sel);
        if (el) el.click();
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  const addQueryParam = (key, value) => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set(key, value);

    history.push({
      hash: location.hash,
      pathname: location.pathname,
      search: searchParams.toString(),
    });
  };

  const onTabChange = (evt, data) => {
    const fullId = `${props.id}-${data.activeIndex}`;
    addQueryParam('activeTab', fullId);
  };

  const panes = tabsList.map((id) => {
    return {
      id: id,
      menuItem: tabs[id].title || `Tab ${tabsList.indexOf(id) + 1}`,
      pane: (
        <Tab.Pane key={id}>
          <div
            id={tabs[id]?.title || `Tab ${tabsList.indexOf(id) + 1}`}
            className="tab-name"
          >
            <div tabIndex={0} role="tabpanel" id={'tab-pane-' + id}>
              <RenderBlocks {...props} metadata={metadata} content={tabs[id]} />
            </div>
          </div>
        </Tab.Pane>
      ),
    };
  });

  return (
    <Tab
      id={props.id}
      className="tabs-block"
      menu={{ secondary: true, pointing: true }}
      panes={panes}
      renderActiveOnly={false}
      onTabChange={onTabChange}
    />
  );
};

export default View;
