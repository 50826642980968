/**
 * Research projects actions.
 * @module actions/relations/projects
 */

import { GET_RESEARCH_PROJECTS } from '../../constants/ActionTypes';

/**
 * Get research projects.
 * @function getResearchProjects
 * @param {string} url Context url
 * @returns {Object}
 */
export function getResearchProjects(
  url,
  sortKey = false,
  reverse = false,
  subrequest = null,
) {
  url = `${url}/@projects-of-person`;
  let params = [];
  if (sortKey) params.push(`sort=${sortKey}`);
  if (reverse) params.push(`reverse=True`);
  if (params.length > 0) url += `?${params.join('&')}`;
  return {
    type: GET_RESEARCH_PROJECTS,
    subrequest,
    request: {
      op: 'get',
      path: url,
    },
  };
}
