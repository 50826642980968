import { toPublicURL } from '@plone/volto/helpers';
import { extractDescription } from './utils';

const WebPage = (content) => {
  const jsonObj = {
    '@context': 'https://schema.org',
    '@type': 'WebPage',
    mainEntityOfPage: {
      '@type': 'WebPage',
      '@id': toPublicURL(content['@id']),
    },
    name: content.title,
    headline: content.title,
    image: [],
    datePublished: content.created,
    dateModified: content.modified,
    publisher: {
      '@type': 'Organization',
      name: 'Universität Koblenz',
      url: 'https://www.uni-koblenz.de',
      logo: {
        '@type': 'ImageObject',
        url: 'https://www.uni-koblenz.de/Service/medien/logode.svg',
      },
    },
  };
  const descr =
    content.description?.length > 0
      ? content.description
      : extractDescription(content);
  jsonObj.abstract = descr;
  jsonObj.description = descr;

  if (content.preview_image?.download) {
    jsonObj.image.push(toPublicURL(content.preview_image.download));
  }
  if (content.preview_image?.scales) {
    for (const scale in content.preview_image.scales) {
      jsonObj.image.push(
        toPublicURL(content.preview_image.scales[scale].download),
      );
    }
  }

  return jsonObj;
};

export default WebPage;
