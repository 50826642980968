import { Card, Image } from 'semantic-ui-react';
import { UniversalLink } from '@plone/volto/components';
import { flattenToAppURL } from '@plone/volto/helpers';

const Inner = ({ data }) => (
  <Card className={'card-block awh'} fluid>
    {data.url && (
      <Image
        src={`${flattenToAppURL(data.url)}/@@images/image`}
        wrapped
        ui={false}
      />
    )}
    <Card.Content>
      <Card.Header>{data.title}</Card.Header>
      <Card.Description>{data.description}</Card.Description>
    </Card.Content>
  </Card>
);

const View = (props) =>
  props.data.href && props.data.href.length > 0 ? (
    <UniversalLink
      href={flattenToAppURL(props.data.href[0]['@id'])}
      className={'card-block-wrapper'}
    >
      <Inner {...props} />
    </UniversalLink>
  ) : (
    <Inner {...props} />
  );

export default View;
