import { Container } from 'semantic-ui-react';
import Worklist from './Worklist';

const WorklistPage = (props) => {
  return (
    <Container>
      <Worklist />
    </Container>
  );
};

export default WorklistPage;
