import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Dimmer, Loader } from 'semantic-ui-react';
import { loginRenew } from '@plone/volto/actions';
import { ContentPlaceholder } from '@package/components';

/* This component calls @login-renew endpoint.
   When user is authenticated via Shibboleth and
   backend is configured properly, client receives
   a login token and user will be logged in.
   (see https://gitlab.uni-koblenz.de/cms-developer/volto-uniko/-/issues/742)
*/
const Shibboleth = () => {
  const token = useSelector((state) => state.userSession?.token || false);
  if (token && __CLIENT__)
    window.location.href = window.location.href.replace('/login', '');

  const dispatch = useDispatch();
  useEffect(() => {
    if (__CLIENT__) dispatch(loginRenew());
  }, [dispatch]);

  return (
    <Container className="view-wrapper">
      <Dimmer.Dimmable as={Container} dimmed={true}>
        <ContentPlaceholder />
        <Dimmer active={true} className="embedded">
          <Loader size="large" inline="centered" active>
            <FormattedMessage
              id="Login is performed"
              defaultMessage="Login is performed"
            />
          </Loader>
        </Dimmer>
      </Dimmer.Dimmable>
    </Container>
  );
};

export default Shibboleth;
