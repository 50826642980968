/* This component acts as a wrapper:
    1. loads the full person data (if possible)
    2. renders card
*/
import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Card, Icon, Placeholder } from 'semantic-ui-react';
import { ConditionalLink, Icon as VoltoIcon } from '@plone/volto/components';
import { getContent } from '@plone/volto/actions';
import { flattenToAppURL } from '@plone/volto/helpers';
import { getCard } from '@package/actions';
import PersonCardImage from './PersonCardImage';
import PersonContact from './PersonContact';
import arrowSVG from '@package/svgs/icons/arrow-uniko.svg';

const PersonCard = (props) => {
  const person = props['person'];
  const override = props['override'] || {};
  const additionalClasses = props['additional-classes'] || [];
  const hideContact = props['hide-contact'];
  const hideDescription = props['hide-description'];
  const hideImage = props['hide-image'];
  const hideLink = props['hide-link'];
  const hideFunction = props['hide-function'];
  const hideRemark = props['hide-remark'];
  const hideTitles = props['hide-titles'];
  const dontHydrate = props['no-hydration'] || false;
  const wholeLink = props['whole-link'] || false;
  const linkDisabled =
    props['link-disabled'] || person['@id'] === false || false;
  const isExternal = props['is-external'] || false;

  const url = flattenToAppURL(person['@id']);
  let personFull = false;
  const dispatch = useDispatch();
  const subrequest = useSelector((state) => state.card?.subrequests?.[url]);
  useEffect(() => {
    if (!dontHydrate && url && subrequest?.loaded !== true) {
      dispatch(getCard(url));
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [dispatch, url, dontHydrate]);

  if (subrequest?.data) personFull = subrequest.data;

  if (url === false || dontHydrate) {
    /* person['@id'] === false means no internal
       page exists for this person -> we can only
       display the external data already stored in person
    */
    personFull = person;
  }

  const location = useLocation();

  /* find function with highest similarity to current path */
  const findClosestFunction = (functions) => {
    const curPath = location.pathname.split('/');

    const similarity = (path1, path2) => {
      let i = 0;
      let minLen = path1.length < path2.length ? path1.length : path2.length;
      while (i < minLen && path1[i] === path2[i]) {
        i += 1;
      }
      return i;
    };

    let closest = functions[0];
    let highestSim = similarity(
      curPath,
      flattenToAppURL(functions[0]['@id']).split('/'),
    );
    for (let i = 1; i < functions.length; i++) {
      const sim = similarity(
        curPath,
        flattenToAppURL(functions[i]['@id']).split('/'),
      );
      if (sim > highestSim) {
        closest = functions[i];
        highestSim = sim;
      }
    }
    return closest;
  };

  const functionsUrl = url ? `${url}/@functions-of-person` : false;
  const contentSubrequests = useSelector((state) => state.content.subrequests);
  const functionsSubReq = contentSubrequests?.[functionsUrl];
  const functions = functionsSubReq?.data?.items || [];
  useEffect(() => {
    if (
      functionsSubReq?.loaded !== true &&
      functionsUrl !== false &&
      !dontHydrate
    )
      dispatch(getContent(flattenToAppURL(functionsUrl), null, functionsUrl));
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [dispatch, functionsUrl, dontHydrate]);

  const primaryFunction = hideFunction
    ? false
    : override.function
    ? { title: override.function }
    : functions.length > 0
    ? findClosestFunction(functions)
    : false;

  return personFull ? (
    <div className={['person-card'].concat(additionalClasses).join(' ')}>
      <Card className="default" href={wholeLink ? url : ''}>
        {!hideImage && (
          <PersonCardImage
            person={personFull}
            linkDisabled={linkDisabled || wholeLink}
          />
        )}
        <Card.Content>
          <Card.Header>
            <ConditionalLink
              to={url}
              condition={!linkDisabled && !wholeLink}
              title={personFull.title}
              key={personFull['@id']}
            >
              {hideTitles
                ? [personFull.surname, personFull.prename].join(', ')
                : personFull.title}
            </ConditionalLink>
          </Card.Header>
          {(primaryFunction ||
            (!hideDescription && personFull.description) ||
            !hideContact) && (
            <Card.Meta>
              {primaryFunction && (
                <span className="function">{primaryFunction.title}</span>
              )}
              {!hideDescription && personFull.description && (
                <span className="description">{personFull.description}</span>
              )}
              {!hideContact && (
                <PersonContact
                  person={personFull}
                  restrictPhoneNrs={2}
                  showMail={true}
                  linkDisabled={linkDisabled}
                  override={override}
                />
              )}
            </Card.Meta>
          )}
          {!hideLink && (
            <span className="cta-link">
              <ConditionalLink
                to={url}
                condition={!linkDisabled && !wholeLink}
                title={personFull.title}
                key={personFull['@id']}
              >
                {isExternal ? (
                  <FormattedMessage
                    id="Visit external profile"
                    defaultMessage="Visit external profile"
                  />
                ) : (
                  <FormattedMessage
                    id="Visit profile"
                    defaultMessage="Visit profile"
                  />
                )}
              </ConditionalLink>
            </span>
          )}
        </Card.Content>
        {!hideRemark && personFull.remark && (
          <div className="infobox">
            <div>
              <Icon.Group size="small">
                <Icon size="big" name="square full" />
                <Icon name="info" inverted />
              </Icon.Group>
            </div>
            {personFull.remark}
          </div>
        )}
        {wholeLink && <VoltoIcon name={arrowSVG} />}
      </Card>
    </div>
  ) : (
    <div className={['person-card'].concat(additionalClasses).join(' ')}>
      <Card className="default" href={wholeLink ? url : ''}>
        {!hideImage && (
          <PersonCardImage
            person={person}
            linkDisabled={linkDisabled || wholeLink}
          />
        )}
        <Card.Content>
          <Card.Header>
            <ConditionalLink
              to={url}
              condition={!linkDisabled && !wholeLink}
              title={person.title}
              key={person['@id']}
            >
              {person.title}
            </ConditionalLink>
          </Card.Header>
          {!hideDescription && <Card.Meta>{person.description}</Card.Meta>}
          <Placeholder>
            <Placeholder.Line />
            <Placeholder.Line />
          </Placeholder>
          <br />
          {!hideLink && (
            <span className="cta-link">
              <ConditionalLink
                to={url}
                condition={!linkDisabled && !wholeLink}
                title={person.title}
                key={person['@id']}
              >
                {isExternal ? (
                  <FormattedMessage
                    id="Visit external profile"
                    defaultMessage="Visit external profile"
                  />
                ) : (
                  <FormattedMessage
                    id="Visit profile"
                    defaultMessage="Visit profile"
                  />
                )}
              </ConditionalLink>
            </span>
          )}
        </Card.Content>
      </Card>
    </div>
  );
};

export default PersonCard;
