import React from 'react';
import { useIntl } from 'react-intl';
import ObjectListWidget from '@plone/volto/components/manage/Widgets/ObjectListWidget';
import { PanoptoVideoSchema } from '@package/components/Blocks/PanoptoVideo/schema';

const VideosWidget = (props) => {
  const intl = useIntl();
  const itemSchema = PanoptoVideoSchema(intl);
  return (
    <ObjectListWidget
      schema={itemSchema}
      {...props}
      value={props.value?.items || props.default?.items || []}
      onChange={(id, value) => props.onChange(id, { items: value })}
    />
  );
};

export default VideosWidget;
