/* 
    This wrapper makes sure dates are formatted in British English style.
    Can NOT be used globally, since it does only support displaying dates
    (without time) in only this specific format.
*/
import { useSelector } from 'react-redux';
import { FormattedDate } from '@plone/volto/components';

const FormattedDateGB = ({ date }) => {
  function addOrdinalSuffix(day) {
    if (day > 3 && day < 21) return day + 'th';
    switch (day % 10) {
      case 1:
        return day + 'st';
      case 2:
        return day + 'nd';
      case 3:
        return day + 'rd';
      default:
        return day + 'th';
    }
  }
  const toDate = typeof date === 'string' ? new Date(date) : date;
  const day = toDate.getDate();
  const month = new Intl.DateTimeFormat('en-GB', { month: 'long' }).format(
    toDate,
  );
  const year = toDate.getFullYear();
  const formattedDay = addOrdinalSuffix(day);
  const formattedDate = `${formattedDay} of ${month} ${year}`;
  return <time dateTime={date}>{formattedDate}</time>;
};

const FormattedDateWrapper = (props) => {
  const language = useSelector((state) => props.locale || state.intl.locale);
  return language === 'en' ? (
    <FormattedDateGB {...props} />
  ) : (
    <FormattedDate {...props} />
  );
};

export default FormattedDateWrapper;
