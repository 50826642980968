import React from 'react';
import PropTypes from 'prop-types';
import { Segment } from 'semantic-ui-react';
import { useIntl, FormattedMessage } from 'react-intl';
import { BlockDataForm, Icon } from '@plone/volto/components';
import { AudioSchema } from './schema';
import volumeSVG from '@plone/volto/icons/volume.svg';

const AudioSidebar = (props) => {
  const { data, block, onChangeBlock } = props;
  const intl = useIntl();
  const schema = AudioSchema({ formData: data, intl });
  return (
    <>
      <header className="header pulled">
        <h2>
          <FormattedMessage
            id="Embed audio file"
            defaultMessage="Embed audio file"
          />
        </h2>
      </header>

      <Segment className="sidebar-metadata-container" secondary attached>
        {data.href ? (
          <>{data.href.split('/').slice(-1)[0]}</>
        ) : (
          <>
            <FormattedMessage
              id="No audio file selected"
              defaultMessage="No audio file selected"
            />
            <Icon name={volumeSVG} size="100px" color="#b8c6c8" />
          </>
        )}
      </Segment>
      <BlockDataForm
        schema={schema}
        title={schema.title}
        onChangeField={(id, value) => {
          onChangeBlock(block, {
            ...data,
            [id]: value,
          });
        }}
        onChangeBlock={onChangeBlock}
        formData={data}
        block={block}
      />
    </>
  );
};

AudioSidebar.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  block: PropTypes.string.isRequired,
  onChangeBlock: PropTypes.func.isRequired,
};

export default AudioSidebar;
