/* Extends ArrayWidget
   to be able to set context dependent default values,
   for certain content types
   (https://gitlab.uni-koblenz.de/cms-developer/volto-uniko/-/issues/337)
*/
import { connect } from 'react-redux';
import { compose } from 'redux';
import { ArrayWidget } from '@plone/volto/components';

const SubjectsWidget = (props) => {
  const last = window.location.toString().split('/').slice(-1)[0];
  const additionalDefaultValue =
    last === 'add?type=Event'
      ? props.lang === 'de'
        ? 'Termin'
        : 'Event'
      : last === 'add?type=News%20Item'
      ? props.lang === 'de'
        ? 'Meldung'
        : 'News Item'
      : false;

  if (
    !props.value &&
    additionalDefaultValue &&
    window.location.pathname.endsWith('/add') &&
    props.widgetOptions?.defaults?.length > 0
  )
    props.onChange(
      props.id,
      props.widgetOptions.defaults.concat([additionalDefaultValue]),
    );
  return <ArrayWidget {...props} />;
};

export default compose(
  connect((state, props) => ({
    content: state.content.data,
    lang: state.intl.locale,
  })),
)(SubjectsWidget);
