import { useEffect } from 'react';
import { defineMessages } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuid } from 'uuid';
import { Card, List } from 'semantic-ui-react';
import { getContent } from '@plone/volto/actions';
import { ConditionalLink, RenderBlocks } from '@plone/volto/components';
import { PersonContact } from '@package/components';

export const messages = defineMessages({
  exam_rules: {
    id: 'Examination regulations',
    defaultMessage: 'Examination regulations',
  },
  module_manual: {
    id: 'Module manual',
    defaultMessage: 'Module manual',
  },
  study_schedule: {
    id: 'Study schedule',
    defaultMessage: 'Study schedule',
  },
  semesters: {
    id: 'semesters',
    defaultMessage: 'semesters',
  },
  apply_program: {
    id: 'Apply now',
    defaultMessage: 'Apply now',
  },
  contact: {
    id: 'Contact',
    defaultMessage: 'Contact',
  },
  form: {
    id: 'Form',
    defaultMessage: 'Form',
  },
  study_period: {
    id: 'Standard period of study',
    defaultMessage: 'Standard period of study',
  },
  admission_restriction: {
    id: 'Admission restriction',
    defaultMessage: 'Admission restriction',
  },
  yes: {
    id: 'Yes',
    defaultMessage: 'Yes',
  },
  no: {
    id: 'No',
    defaultMessage: 'No',
  },
  language: {
    id: 'Language',
    defaultMessage: 'Language',
  },
  format: {
    id: 'Format',
    defaultMessage: 'Format',
  },
  in_presence: {
    id: 'In presence',
    defaultMessage: 'In presence',
  },
  for_eu_applicants: {
    id: 'for EU citizens',
    defaultMessage: 'for EU citizens',
  },
  for_non_eu_applicants: {
    id: 'for non-EU citizens',
    defaultMessage: 'for non-EU citizens',
  },
});

export const ppMaterial = (key, intl) => {
  if (key === 'po') return intl.formatMessage(messages.exam_rules);
  if (key === 'moha') return intl.formatMessage(messages.module_manual);
  if (key === 'stupla') return intl.formatMessage(messages.study_schedule);
};

export const ppValue = (value, intl, listsAsLists = false) =>
  value === null ? (
    ''
  ) : value === 'Ja' ? (
    intl.formatMessage(messages.yes)
  ) : value === 'Nein' ? (
    intl.formatMessage(messages.no)
  ) : value === true ? (
    intl.formatMessage(messages.yes)
  ) : value === false ? (
    intl.formatMessage(messages.no)
  ) : typeof value === 'string' ? (
    value
  ) : Array.isArray(value) ? (
    value.length > 1 && listsAsLists ? (
      <List bulleted>
        {value.map((v) => (
          <List.Item key={`${uuid()}`}>{ppValue(v, intl)}</List.Item>
        ))}
      </List>
    ) : (
      value.map((v) => ppValue(v, intl)).join(', ')
    )
  ) : typeof value === 'object' && 'title' in value ? (
    value.title
  ) : (
    JSON.stringify(value)
  );

export const MetaBoxItem = ({ title, value, intl }) => {
  return (
    <div>
      <div>{title}</div>
      <div className="value">{ppValue(value, intl)}</div>
    </div>
  );
};

export const FakePersonCard = ({
  head,
  subhead,
  url = false,
  phone = false,
  email = false,
}) => {
  const fakePerson = {};
  if (phone) {
    fakePerson.telephone = {
      items: [
        {
          number: phone,
        },
      ],
    };
  }
  if (email) fakePerson.email = email;
  return (
    <div className="person-card">
      <Card>
        <Card.Content>
          <Card.Header>
            <ConditionalLink condition={url !== false} to={url ? url : ''}>
              {head}
            </ConditionalLink>
          </Card.Header>
          {subhead}
          <Card.Meta>
            <PersonContact person={fakePerson} />
          </Card.Meta>
        </Card.Content>
      </Card>
    </div>
  );
};

export const ContactForm = ({ url }) => {
  const contentSubrequests = useSelector((state) => state.content.subrequests);
  const dispatch = useDispatch();
  const fullPage = contentSubrequests?.[url]?.data;
  useEffect(() => {
    if (!fullPage) dispatch(getContent(url, null, url, null, true));
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [dispatch, url]);
  if (fullPage)
    setTimeout(() => {
      /* hack to avoid (failing) scrolling to form top when it has been submitted
         (see: https://gitlab.uni-koblenz.de/cms-developer/volto-uniko/-/issues/626)
      */
      if (document) {
        const form = document.querySelector('.contact-form .block.form form');
        if (form) form.id = '';
      }
    }, 0);
  return fullPage ? (
    <div className="contact-form">
      <RenderBlocks path={url} content={fullPage} />
    </div>
  ) : (
    <></>
  );
};

export const getPlausibleClassForApplyButton = (content) => {
  /* used to track Apply button clicks via Plausible
       (https://plausible.io/docs/custom-event-goals)
    */
  const plausibleClasses = [
    {
      key: 'plausible-event-name',
      val: 'Clicked+Apply',
    },
    {
      key: 'plausible-event-program',
      val: content.title.replaceAll(' ', '+'),
    },
    {
      key: 'plausible-event-degree',
      val: content.degree.map((d) => d.token).join(','),
    },
  ];
  return plausibleClasses.map((i) => `${i.key}=${i.val}`).join(' ');
};
