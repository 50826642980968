import { Card, Placeholder } from 'semantic-ui-react';

const VeranstaltungPlaceholder = () => {
  return (
    <Card fluid className="lsf-lecture">
      <Card.Content>
        <Placeholder fluid>
          <Placeholder.Header>
            <Placeholder.Line />
          </Placeholder.Header>
          <br />
          <Placeholder.Line />
          <Placeholder.Line />
        </Placeholder>
      </Card.Content>
    </Card>
  );
};

export default VeranstaltungPlaceholder;
