import { useEffect, useRef, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { Portal } from 'react-portal';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Container, Message, Icon } from 'semantic-ui-react';
import { emailNotification } from '@plone/volto/actions';
import { Form, Toolbar, Toast } from '@plone/volto/components';
import { getBaseUrl, Helmet } from '@plone/volto/helpers';

const messages = defineMessages({
  send: {
    id: 'Send',
    defaultMessage: 'Send',
  },
  contactForm: {
    id: 'Contact form',
    defaultMessage: 'Contact form',
  },
  default: {
    id: 'Default',
    defaultMessage: 'Default',
  },
  name: {
    id: 'Name',
    defaultMessage: 'Name',
  },
  from: {
    id: 'From',
    defaultMessage: 'From',
  },
  subject: {
    id: 'Subject',
    defaultMessage: 'Subject',
  },
  message: {
    id: 'Message',
    defaultMessage: 'Message',
  },
  error: {
    id: 'Error',
    defaultMessage: 'Error',
  },
  messageSent: {
    id: 'Email sent',
    defaultMessage: 'Email sent',
  },
  back: {
    id: 'Back',
    defaultMessage: 'Back',
  },
  success: {
    id: 'Success',
    defaultMessage: 'Success',
  },
});

/* https://dev.to/alexandprivate/nextprops-in-react-functional-components-1jc2 */
function usePrevPropValue(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

/* adapt to add parameter `path` to `emailNotification` to fix
   https://gitlab.uni-koblenz.de/cms-developer/volto-uniko/-/issues/649
*/
const ContactForm = ({ error }) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const history = useHistory();
  const location = useLocation();

  const params = Object.fromEntries(
    location.search
      .slice(1)
      .split('&')
      .filter((x) => x.indexOf('=') > -1)
      .map((x) => x.split('=')),
  );

  const [isClientSide, setIsClientSide] = useState(false);
  useEffect(() => {
    setIsClientSide(true);
  }, []);

  const onSubmit = (data) => {
    const prevPath = document ? params.from || document.referrer || '' : '';
    const error = params?.error || false;
    dispatch(
      emailNotification(
        data.from,
        data.message,
        data.name,
        data.subject,
        prevPath,
        error,
      ),
    );
  };

  const onCancel = () => {
    history.goBack();
  };

  const loading = useSelector((state) => state.emailNotification.loading);
  const loaded = useSelector((state) => state.emailNotification.loaded);
  const prevLoaded = usePrevPropValue(loaded);

  if (prevLoaded === false && loaded === true) {
    toast.success(
      <Toast
        success
        title={intl.formatMessage(messages.success)}
        content={intl.formatMessage(messages.messageSent)}
      />,
    );
  }

  return (
    <div id="contact-form">
      <Container id="view">
        <Helmet title={intl.formatMessage(messages.contactForm)} />
        {error && (
          <Message
            icon="warning"
            negative
            attached
            header={intl.formatMessage(messages.error)}
            content={error.message}
          />
        )}
        <Form
          onSubmit={onSubmit}
          onCancel={onCancel}
          formData={{ blocksLayoutFieldname: {} }}
          submitLabel={intl.formatMessage(messages.send)}
          resetAfterSubmit
          title={intl.formatMessage(messages.contactForm)}
          loading={loading}
          schema={{
            fieldsets: [
              {
                fields: ['name', 'from', 'subject', 'message'],
                id: 'default',
                title: intl.formatMessage(messages.default),
              },
            ],
            properties: {
              name: {
                title: intl.formatMessage(messages.name),
                type: 'string',
              },
              from: {
                title: intl.formatMessage(messages.from),
                type: 'email',
              },
              subject: {
                title: intl.formatMessage(messages.subject),
                type: 'string',
              },
              message: {
                title: intl.formatMessage(messages.message),
                type: 'string',
                widget: 'textarea',
              },
            },
            required: ['name', 'subject', 'from', 'message'],
          }}
        />
        {isClientSide && (
          <Portal node={document.getElementById('toolbar')}>
            <Toolbar
              pathname={location.pathname}
              hideDefaultViewButtons
              inner={
                <Link to={`${getBaseUrl(location.pathname)}`} className="item">
                  <Icon
                    name="arrow left"
                    size="big"
                    color="blue"
                    title={intl.formatMessage(messages.back)}
                  />
                </Link>
              }
            />
          </Portal>
        )}
      </Container>
    </div>
  );
};

export default ContactForm;
