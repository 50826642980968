// Update types.json with this command:
// curl -d '{ "configFile": "fs/res/searchPublication.xml", "requestedKeys": [{ "key": "PublicationTypeValue", "version": -1 }] }' -H "Content-Type: application/json" -X POST https://ecampus.uni-koblenz.de/qisserver/api/v1/cs/sys/genericSearch/loadSearchFormData | jq '.[0]["options"]'
import publicationTypes from './types.json';
import { defineMessages } from 'react-intl';

const messages = defineMessages({
  labelPublicationSettings: {
    id: 'Settings',
    defaultMessage: 'Settings',
  },
  labelPersonOrOrgUnit: {
    id: 'Person or organizational unit',
    defaultMessage: 'Person or organizational unit',
  },
  labelAddPersonOrOrgUnit: {
    id: 'Add person or organizational unit',
    defaultMessage: 'Add person or organizational unit',
  },
  labelDefault: {
    id: 'Default',
    defaultMessage: 'Default',
  },
  labelSelectPersonOrOrgUnit: {
    id: 'Select person or organizational unit',
    defaultMessage: 'Select person or organizational unit',
  },
  labelPersonsOrOrgUnits: {
    id: 'Persons or organizational units',
    defaultMessage: 'Persons or organizational unit',
  },
  labelLimit: {
    id: 'Limit to',
    defaultMessage: 'Limit to',
  },
  labelAdditionalPublications: {
    id: 'Additional publications',
    defaultMessage: 'Additional publications',
  },
  descriptionAdditionalPublications: {
    id: 'Enter an ID and press return.',
    defaultMessage: 'Enter an ID and press return.',
  },
  labelECampus: {
    id: 'Show link to eCampus',
    defaultMessage: 'Show link to eCampus',
  },
  descrECampus: {
    id: 'Only possible if only a single person is selected.',
    defaultMessage: 'Only possible if only a single person is selected.',
  },
  labelCitationStyle: {
    id: 'Citation style',
    defaultMessage: 'Citation style',
  },
  labelDivideByYear: {
    id: 'Divide by year',
    defaultMessage: 'Divide by year',
  },
  labelFilterByType: {
    id: 'Filter by type',
    defaultMessage: 'Filter by type',
  },
  descrFilterByType: {
    id: 'Select one or multiple publication types to filter by.',
    defaultMessage: 'Select one or multiple publication types to filter by.',
  },
});

const PersonOrOrgUnitSchema = (intl) => {
  return {
    title: intl.formatMessage(messages.labelPersonOrOrgUnit),
    addMessage: intl.formatMessage(messages.labelAddPersonOrOrgUnit),

    fieldsets: [
      {
        id: 'default',
        title: intl.formatMessage(messages.labelDefault),
        fields: ['href'],
      },
    ],

    properties: {
      href: {
        title: intl.formatMessage(messages.labelSelectPersonOrOrgUnit),
        widget: 'object_browser',
        widgetOptions: {
          pattern_options: { selectableTypes: ['Person', 'OrgUnit'] },
        },
        mode: 'link',
        selectedItemAttrs: ['ecampus_id', 'portal_type'],
      },
    },
  };
};

const PublicationsSchema = (intl) => {
  const MyPersonOrOrgUnitSchema = PersonOrOrgUnitSchema(intl);
  return {
    title: intl.formatMessage(messages.labelPublicationSettings),

    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: [
          'citation_style',
          'items',
          'pubsids',
          'link_to_ecampus',
          'divide_by_year',
          'filter_by_type',
          'limit',
        ],
      },
    ],

    properties: {
      items: {
        title: intl.formatMessage(messages.labelPersonsOrOrgUnits),
        schema: MyPersonOrOrgUnitSchema,
        widget: 'object_list',
      },
      link_to_ecampus: {
        title: intl.formatMessage(messages.labelECampus),
        description: intl.formatMessage(messages.descrECampus),
        type: 'boolean',
        default: false,
      },
      pubsids: {
        title: intl.formatMessage(messages.labelAdditionalPublications),
        description: intl.formatMessage(
          messages.descriptionAdditionalPublications,
        ),
        type: 'array',
        creatable: true,
      },
      limit: {
        title: intl.formatMessage(messages.labelLimit),
        type: 'number',
        default: 50,
      },
      divide_by_year: {
        title: intl.formatMessage(messages.labelDivideByYear),
        type: 'boolean',
        default: false,
      },
      citation_style: {
        title: intl.formatMessage(messages.labelCitationStyle),
        type: 'string',
        factory: 'Choice',
        /* those come from eCampus */
        choices: [
          ['apa-old-doi-prefix', 'APA (englisch)'],
          ['harvard-anglia-ruskin-university', 'Harvard (englisch)'],
          [
            'harvard-gesellschaft-fur-bildung-und-forschung-in-europa',
            'Harvard (deutsch)',
          ],
          ['advanced-materials', 'Advanced Materials'],
          ['dependent/journal-of-the-acm', 'Journal of the ACM'],
          ['dependent/proceedings-of-the-ieee', 'Proceedings of the IEEE'],
        ],
        default: false,
      },
      filter_by_type: {
        title: intl.formatMessage(messages.labelFilterByType),
        description: intl.formatMessage(messages.descrFilterByType),
        type: 'string',
        factory: 'Choice',
        choices: publicationTypes
          ? publicationTypes
              .sort((a, b) =>
                a.label < b.label ? -1 : a.label > b.label ? 1 : 0,
              )
              .map((o) => [o.id, o.label])
          : [],
        isMulti: true,
      },
    },

    required: [],
  };
};

export default PublicationsSchema;
