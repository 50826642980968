import { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { Loader, Segment } from 'semantic-ui-react';
import { SelectWidget } from '@plone/volto/components';
import { ECAMPUS_API_URL } from '@package/components/Blocks/Publications/constants';
import Publication from '@package/components/Blocks/Publications/Publication';

const View = (props) => {
  const curYear = new Date().getFullYear();
  const [isDirty, setIsDirty] = useState(true);
  const [pubs, setPubs] = useState(false);
  const [year, setYear] = useState(curYear);

  const url = `${ECAMPUS_API_URL}/cs/sys/genericSearch/loadSearchResult`;

  const postData = {
    search: {
      groups: [
        {
          criteria: [
            {
              field: 'Publication.releaseYear',
              value: year,
            },
          ],
          criteriaGroups: [],
          groupLabel: 'fs.res.common.basicData',
        },
      ],
      configFile: 'fs/res/searchPublication.xml',
    },
    offset: 0,
    limit: 10,
  };

  useEffect(() => {
    if (isDirty) {
      fetch(url, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(postData),
      })
        .then(function (response) {
          setIsDirty(false);
          return response.json();
        })
        .then(function (data) {
          setPubs(data);
        });
    }
  }, [postData, isDirty]);

  const yearOptions = [];
  for (let i = curYear; i >= 2003; i--) yearOptions.push([i, i]);

  const onChangeYear = (id, value) => {
    setYear(value);
    setIsDirty(true);
  };

  return (
    <>
      <SelectWidget
        id="select-year-bib"
        title="Jahr auswählen"
        onChange={onChangeYear}
        placeholder="Jahr auswählen"
        choices={yearOptions}
        value={year}
      />
      {isDirty && (
        <Segment>
          <Loader size="large" inline="centered" active>
            <FormattedMessage
              id="Loading publications ..."
              defaultMessage="Loading publications ..."
            />
          </Loader>
        </Segment>
      )}
      {pubs &&
        pubs.data.map((pubData, index) => {
          return (
            <Publication
              key={`pub-${props.id}-${index}`}
              data={pubData}
              columns={pubs.columns}
              linksDisabled={props.isEditMode}
            />
          );
        })}
    </>
  );
};

export default View;
