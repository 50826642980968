import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { Button } from 'semantic-ui-react';
import { FormattedDate, UniversalLink } from '@plone/volto/components';

const DownloadLink = ({ download }) => {
  if (download['@type'] === 'File')
    return (
      <a href={`${download['@id']}/@@download/file`}>
        <FormattedMessage id="Download" defaultMessage="Download" />
      </a>
    );

  return (
    <Link to={download['@id']} target="_blank">
      <FormattedMessage id="Download" defaultMessage="Download" />
    </Link>
  );
};

const Contact = ({ data }) => {
  return (
    <div className="contact meta-item">
      <span>{data?.title}</span>
      <b>{data?.name}</b>
      {data?.address && <div className="address">{data.address}</div>}
      {data.email && <a href={`mailto:${data.email}`}>{data.email}</a>}
      {data.phone && <a href={`tel:${data.phone}`}>{data.phone}</a>}
      {data.links?.length > 0 && (
        <div className="links">
          {data.links.split('\n').map((link) => (
            <UniversalLink href={link}>{link}</UniversalLink>
          ))}
        </div>
      )}
    </div>
  );
};

const View = ({ data, metadata }) => {
  return (
    <div className="meta-post">
      <div className="date meta-item">
        <span>
          <FormattedMessage id="job_post_hours" defaultMessage="Hours" />
        </span>
        <b>{data.hours && data.hours}</b>
      </div>
      <div className="date meta-item">
        <span>
          <FormattedMessage
            id="Application deadline"
            defaultMessage="Application deadline"
          />
        </span>
        <b>{data.deadline && <FormattedDate date={data.deadline} />}</b>
      </div>
      {data.contacts?.length > 0 &&
        data.contacts.map((contact) => (
          <Contact key={contact['@id']} data={contact} />
        ))}
      {data.downloads?.length > 0 && (
        <div className="downloads meta-item">
          <span>
            <FormattedMessage id="Downloads" defaultMessage="Downloads" />
          </span>
          {data.downloads.map((download) => {
            return (
              <div key={download['@id']} className="download">
                <b>{download.Title}</b>
                <DownloadLink download={download} />
              </div>
            );
          })}
        </div>
      )}
      {data.cta_text && data.apply_mail_to && (
        <div className="downloads meta-item">
          <a
            href={`mailto:${data.apply_mail_to}?subject=${data.apply_mail_subject}`}
          >
            <Button primary block>
              {data.cta_text}
            </Button>
          </a>
        </div>
      )}
    </div>
  );
};

export default View;
