import { defineMessages } from 'react-intl';

const messages = defineMessages({
  labelDefault: {
    id: 'Default',
    defaultMessage: 'Default',
  },
  labelSelectPerson: {
    id: 'Select person',
    defaultMessage: 'Select person',
  },
  labelDownloads: {
    id: 'Downloads',
    defaultMessage: 'Downloads',
  },
  descrDownloads: {
    id: 'Select files',
    defaultMessage: 'Select files',
  },
  labelContacts: {
    id: 'Contacts',
    defaultMessage: 'Contacts',
  },
  labelContact: {
    id: 'Contact',
    defaultMessage: 'Contact',
  },
  labelAddContact: {
    id: 'Add contact',
    defaultMessage: 'Add contact',
  },
  title: {
    id: 'Title',
    defaultMessage: 'Title',
  },
  name: {
    id: 'Name',
    defaultMessage: 'Name',
  },
  address: {
    id: 'Address',
    defaultMessage: 'Address',
  },
  email: {
    id: 'E-Mail',
    defaultMessage: 'E-Mail',
  },
  phone: {
    id: 'Telephone',
    defaultMessage: 'Telephone',
  },
  links: {
    id: 'Links',
    defaultMessage: 'Links',
  },
  onePerLine: {
    id: 'One entry per line',
    defaultMessage: 'One entry per line',
  },
  labelAuthor: {
    id: 'Author',
    defaultMessage: 'Author',
  },
});

const ContactSchema = (intl) => {
  return {
    title: intl.formatMessage(messages.labelContact),
    addMessage: intl.formatMessage(messages.labelAddContact),

    fieldsets: [
      {
        id: 'default',
        title: intl.formatMessage(messages.labelDefault),
        fields: ['title', 'name', 'address', 'email', 'phone', 'links'],
      },
    ],

    properties: {
      title: {
        title: intl.formatMessage(messages.title),
        default: intl.formatMessage(messages.labelContact),
      },
      name: {
        title: intl.formatMessage(messages.name),
      },
      address: {
        title: intl.formatMessage(messages.address),
        widget: 'textarea',
        default: 'Universität Koblenz\nUniversitätsstraße 1\n56070 Koblenz',
      },
      email: {
        title: intl.formatMessage(messages.email),
      },
      phone: {
        title: intl.formatMessage(messages.phone),
      },
      links: {
        title: intl.formatMessage(messages.links),
        description: intl.formatMessage(messages.onePerLine),
        widget: 'textarea',
      },
    },

    required: ['title', 'name'],
  };
};

export const MetadataPostSchema = (intl) => {
  const MyContactSchema = ContactSchema(intl);
  return {
    title: 'Post Metadata',
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: ['author', 'contacts', 'downloads'],
      },
    ],

    properties: {
      author: {
        title: intl.formatMessage(messages.labelAuthor),
        widget: 'object_browser',
        widgetOptions: {
          pattern_options: { selectableTypes: ['Person'] },
        },
        mode: 'link',
        selectedItemAttrs: ['Title'],
      },
      contacts: {
        title: intl.formatMessage(messages.labelContacts),
        schema: MyContactSchema,
        widget: 'object_list',
      },
      downloads: {
        title: intl.formatMessage(messages.labelDownloads),
        description: intl.formatMessage(messages.descrDownloads),
        widget: 'object_browser',
        widgetOptions: {
          pattern_options: { selectableTypes: ['File', 'Image'] },
        },
        selectedItemAttrs: ['Title', 'Description', '@type'],
      },
    },

    required: [],
  };
};

export default MetadataPostSchema;
