import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl';
import { Input, Message } from 'semantic-ui-react';
import { getLSFLectures } from '@package/actions';
import { SemestersSelectWidget } from '@package/components';
import { getCurrentSemesterID } from '@package/utils';
import LecturesSorted from './LecturesSorted';
import VeranstaltungPlaceholder from './VeranstaltungPlaceholder';

const messages = defineMessages({
  labelSearch: {
    id: 'Search in lectures',
    defaultMessage: 'Search in lectures',
  },
});

const View = (props) => {
  const intl = props.intl;

  const { data, isEditMode } = props;

  const persons = data.items || null;

  const [semester, setSemester] = useState(getCurrentSemesterID());

  const subrequestKey = `${semester}ps${persons
    .map((p) => p['@id'])
    .join('-')}`;

  const subrequest = useSelector(
    (state) => state.lsf_lectures.subrequests?.[subrequestKey],
  );
  const dispatch = useDispatch();
  React.useEffect(() => {
    if (persons) {
      const personsIds = persons
        .filter((p) => p.href?.length > 0 && p.href[0].his_person_id)
        .map((p) => p.href[0].his_person_id)
        .join(',');
      if (personsIds.length > 0 && subrequest?.loaded !== true) {
        dispatch(
          getLSFLectures('/', true, subrequestKey, null, personsIds, semester),
        );
      }
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [dispatch, persons, semester, subrequestKey]);

  const lectures = subrequest?.items;

  const [searchInput, setSearchInput] = useState('');

  return (
    <div className="block-lectures">
      {persons && (
        <>
          <SemestersSelectWidget
            value={semester}
            onChange={(_, value) => {
              setSemester(value);
            }}
          />

          {data.searchable && (
            <Input
              fluid
              placeholder={intl.formatMessage(messages.labelSearch)}
              type="text"
              value={searchInput}
              onChange={(event) => setSearchInput(event.target.value)}
            />
          )}
        </>
      )}
      {subrequest?.loading ? (
        <>
          <VeranstaltungPlaceholder />
          <VeranstaltungPlaceholder />
        </>
      ) : subrequest?.loaded && lectures?.length > 0 ? (
        <LecturesSorted
          lectures={lectures}
          sortMode={data.sort_mode}
          filterLanguage={data.filter_language}
          searchInput={searchInput}
          isEditMode={isEditMode}
        />
      ) : (
        <Message warning>
          <FormattedMessage
            id="No matching lectures found."
            defaultMessage="No matching lectures found."
          />
        </Message>
      )}
    </div>
  );
};

export default injectIntl(View);
