/**
 * LSF lectures actions.
 * @module actions/lsf/lectures
 */

import {
  GET_LSF_LECTURE,
  GET_LSF_LECTURES,
} from '@package/constants/ActionTypes';

/**
 * Get LSF lectures.
 * @function getLSFLectures
 * @param {string} url Context url
 * @param {string} heading LSF heading
 * @returns {Object}
 */
export function getLSFLectures(
  url,
  fullObjects = false,
  subrequest = null,
  heading = null,
  person = null,
  semester = null,
) {
  url = `${url}/@lsf-lectures`;
  var params = [];
  if (person) params.push(`person=${person}`);
  if (heading) params.push(`heading=${heading}`);
  if (semester) params.push(`semester=${semester}`);
  if (fullObjects) params.push(`fullobjects=true`);
  if (params.length > 0) url += `?${params.join('&')}`;
  return {
    type: GET_LSF_LECTURES,
    subrequest,
    request: {
      op: 'get',
      path: url,
    },
  };
}

/**
 * Get LSF lecture.
 * @function getLSFLecture
 * @param {string} url Context url
 * @param {string} lectureId LSF lecture id
 * @returns {Object}
 */
export function getLSFLecture(url, lectureId, subrequest = null) {
  url = `${url}/@lsf-lecture?id=${lectureId}`;
  return {
    type: GET_LSF_LECTURE,
    subrequest,
    request: {
      op: 'get',
      path: url,
    },
  };
}
