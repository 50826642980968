import { defineMessages } from 'react-intl';

const messages = defineMessages({
  labelPanoptoVideo: {
    id: 'Panopto Video',
    defaultMessage: 'Panopto Video',
  },
  labelVideoUrl: {
    id: 'Video URL',
    defaultMessage: 'Video URL',
  },
  descrVideoUrl: {
    id: `Go to your video on Panopto. Click 'share' and then 'copy link'.`,
    defaultMessage: `Go to your video on Panopto. Click 'share' and then 'copy link'.`,
  },
  labelInteractivity: {
    id: 'Interactivity',
    defaultMessage: 'Interactivity',
  },
  labelAll: {
    id: 'interactivity_all',
    defaultMessage: 'All',
  },
  labelSearchAndRating: {
    id: 'Search and Rating only',
    defaultMessage: 'Search and Rating only',
  },
  labelNone: {
    id: 'interactivity_none',
    defaultMessage: 'None',
  },
  labelHideTitle: {
    id: 'Hide title',
    defaultMessage: 'Hide title',
  },
  labelAspectRatio: {
    id: 'Aspect ratio',
    defaultMessage: 'Aspect ratio',
  },
  labelMaxWidth: {
    id: 'Maximum width',
    defaultMessage: 'Maximum width',
  },
  descrMaxWidth: {
    id: 'descr_max_width',
    defaultMessage: 'In pixels or percentage (examples: `200px` or `50%`).',
  },
  labelAlignment: {
    id: 'Alignment',
    defaultMessage: 'Alignment',
  },
  labelPosLeft: {
    id: 'Left',
    defaultMessage: 'Left',
  },
  labelPosCenter: {
    id: 'Center',
    defaultMessage: 'Center',
  },
  labelPosRight: {
    id: 'Right',
    defaultMessage: 'Right',
  },
});

export const PanoptoVideoSchema = (intl) => {
  return {
    title: intl.formatMessage(messages.labelPanoptoVideo),
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: [
          'url',
          'hide_title',
          'interactivity',
          'aspect_ratio',
          'max_width',
          'alignment',
        ],
      },
    ],
    properties: {
      url: {
        title: intl.formatMessage(messages.labelVideoUrl),
        description: intl.formatMessage(messages.descrVideoUrl),
      },
      interactivity: {
        title: intl.formatMessage(messages.labelInteractivity),
        type: 'string',
        factory: 'Choice',
        choices: [
          ['all', intl.formatMessage(messages.labelAll)],
          ['search', intl.formatMessage(messages.labelSearchAndRating)],
          ['none', intl.formatMessage(messages.labelNone)],
        ],
        default: 'none',
      },
      hide_title: {
        title: intl.formatMessage(messages.labelHideTitle),
        type: 'boolean',
        default: false,
      },
      aspect_ratio: {
        title: intl.formatMessage(messages.labelAspectRatio),
        type: 'string',
        factory: 'Choice',
        choices: [
          ['landscape', 'Landscape'], // 16:9
          ['square', 'Square'], // 1:1
          ['portrait', 'Portrait'], // 9:16
        ],
        default: 'landscape',
      },
      max_width: {
        title: intl.formatMessage(messages.labelMaxWidth),
        description: intl.formatMessage(messages.descrMaxWidth),
      },
      alignment: {
        title: intl.formatMessage(messages.labelAlignment),
        type: 'string',
        factory: 'Choice',
        choices: [
          ['center', intl.formatMessage(messages.labelPosCenter)],
          ['left', intl.formatMessage(messages.labelPosLeft)],
          ['right', intl.formatMessage(messages.labelPosRight)],
        ],
        default: 'center',
      },
    },
    required: [],
  };
};

export default PanoptoVideoSchema;
