import { FormattedMessage } from 'react-intl';
import InlineForm from '@plone/volto/components/manage/Form/InlineForm';
import { Icon, SidebarPortal } from '@plone/volto/components';
import divideSVG from '@plone/volto/icons/divide-horizontal.svg';
import DividerSchema from './schema';

const Edit = (props) => {
  const schema = DividerSchema(props.intl);
  return (
    <>
      <SidebarPortal selected={props.selected}>
        <InlineForm
          schema={schema}
          title={schema.title}
          icon={<Icon name={divideSVG} />}
          onChangeField={(id, value) => {
            props.onChangeBlock(props.block, {
              ...props.data,
              [id]: value,
            });
          }}
          formData={props.data}
        />
      </SidebarPortal>
      {props.data.kind === 'br' ? (
        <center style={{ opacity: 0.6 }}>
          <i>
            <FormattedMessage id="Line break" defaultMessage="Line break" /> ⏎
          </i>
        </center>
      ) : (
        <>
          <br />
          <hr />
          <br />
        </>
      )}
    </>
  );
};

export default Edit;
