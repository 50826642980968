import { useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { Placeholder } from 'semantic-ui-react';
import { ECAMPUS_API_URL } from './constants';

const messages = defineMessages({
  viewOnEcampus: {
    id: 'View on eCampus',
    defaultMessage: 'View on eCampus',
  },
});

const PublicationByCitationStyle = ({ pubUrl, pubId, citationStyle }) => {
  const [html, setHtml] = useState(false);
  const intl = useIntl();

  const url = `${ECAMPUS_API_URL}/fs/res/publication/${pubId}/details?citeFormat=html`;
  if (html === false) {
    fetch(url)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        const citation = data.citations.find(
          (c) => c.length > 0 && c[0] === citationStyle,
        );
        if (citation) {
          let html = citation[1];

          /* if global identifiers contain simple links (starting with http/s)
             we want to replace them with working html links
          */
          data.globalIdentifiers.forEach((i) => {
            if (i.identifier.startsWith('http')) {
              const replacement = `<a href="${i.identifier}" target="_blank">${i.identifier}</a>`;
              html = html.replace(i.identifier, replacement);
            }
          });

          /* replace DOIs with working html links too */
          if (citationStyle === 'apa-old-doi-prefix') {
            const pattern =
              /\b(?:doi:\s*)(10[.][0-9]{4,}[^\s"/<>]*\/[^\s"<>]+)\b/g;
            const replacement =
              '<a href="https://doi.org/$1" target="_blank">doi:$1</a>';
            html = html.replace(pattern, replacement);
          }
          if (citationStyle === 'dependent/journal-of-the-acm') {
            const pattern =
              /\b(?:DOI:https:\/\/doi\.org\/\s*)(10[.][0-9]{4,}[^\s"/<>]*\/[^\s"<>]+)\b/g;
            const replacement =
              'DOI:<a href="https://doi.org/$1" target="_blank">https://doi.org/$1</a>';
            html = html.replace(pattern, replacement);
          }

          if (citationStyle !== 'advanced-materials') {
            /* E-Campus delivers wrongly encoded en dash (Halbgeviertstrich) for page ranges.
               Until it is officially fixed, we have to fix it ourselves here.
            */
            html = html.replace(' &#38; ndash;', '–');
          }

          // if option is selected, append icon linking to corresponding ecampus page
          const linkIconHtml = `<a href=${pubUrl} class="ecampus-link" target="_blank" rel="noreferrer" title="${intl.formatMessage(
            messages.viewOnEcampus,
          )}"><i class="external alternate icon"></i></a></div>`;
          const pattern =
            citationStyle === 'advanced-materials' ||
            citationStyle === 'dependent/proceedings-of-the-ieee'
              ? /<div class="csl-right-inline">((.|\n)*?)<\/div>/g
              : /<div class="csl-entry">((.|\n)*?)<\/div>/g;
          const replacement =
            '<div class="csl-entry">$1' + linkIconHtml + '</div>';
          html = html.replace(pattern, replacement);

          setHtml(html);
        } else
          setHtml(
            `<div>Citation style <i>${citationStyle}</i> not found for <a href="${pubUrl}">${pubId}</a>.`,
          );
      });
  }
  return (
    <div className="publication">
      {html ? (
        <div
          dangerouslySetInnerHTML={{
            __html: html,
          }}
        />
      ) : (
        <Placeholder fluid>
          <Placeholder.Line />
          <Placeholder.Line />
          <Placeholder.Line />
        </Placeholder>
      )}
    </div>
  );
};

export default PublicationByCitationStyle;
