import { useEffect, useRef, useState } from 'react';
import { ConditionalLink } from '@plone/volto/components';
import cx from 'classnames';

const FixedToC = ({ items, isEditMode = false }) => {
  const [active, setActive] = useState(0);
  const [isFixed, setIsFixed] = useState(false);
  const [isAbsoluted, setIsAbsoluted] = useState(false);
  const [initialOffsetTop, setInitialOffsetTop] = useState(false);
  const [width, setWidth] = useState('100%');

  const refToC = useRef(null);

  /* property `offsetTop` is relative to parent.
     in order to get the absolute value we need to iterate.
  */
  const getOffsetTop = (element) => {
    let offsetTop = 0;
    while (element) {
      offsetTop += element.offsetTop;
      element = element.offsetParent;
    }
    return offsetTop;
  };

  if (initialOffsetTop === false && refToC.current) {
    setInitialOffsetTop(getOffsetTop(refToC.current));
  }

  useEffect(() => {
    if (!isEditMode) {
      const refEls =
        typeof document === 'undefined'
          ? []
          : items
              .map((i) => document.getElementById(i[0]) || false)
              .filter((i) => i !== false);

      const offsetBottom =
        typeof document !== 'undefined' && refToC?.current
          ? document.body.scrollHeight -
            document.getElementById('footer').clientHeight -
            refToC.current.clientHeight -
            168 // obviously
          : document.body.scrollHeight;

      const onScrolled = () => {
        setIsFixed(window.scrollY > initialOffsetTop);
        if (window.scrollY > offsetBottom) {
          setIsAbsoluted(offsetBottom);
        } else {
          setIsAbsoluted(false);
        }

        let match = 0;
        refEls.forEach((el, index) => {
          if (
            window.innerHeight - el.getBoundingClientRect().y >
            window.innerHeight - 100
          )
            match = index;
        });
        if (match !== active) setActive(match);
      };
      window.addEventListener('scroll', onScrolled);

      const onResize = () => {
        if (refToC?.current) {
          const paddingLeft = window
            .getComputedStyle(refToC.current.parentElement, null)
            .getPropertyValue('padding-left');
          const paddingRight = window
            .getComputedStyle(refToC.current.parentElement, null)
            .getPropertyValue('padding-right');
          setWidth(
            (
              refToC.current.parentElement.clientWidth -
              parseInt(paddingLeft) -
              parseInt(paddingRight)
            ).toString() + 'px',
          );
        }
      };
      window.addEventListener('resize', onResize);

      onResize();
      if (initialOffsetTop !== false) onScrolled();

      return () => {
        window.removeEventListener('scroll', onScrolled);
        window.addEventListener('resize', onResize);
      };
    }
  }, [active, initialOffsetTop, isEditMode, items]);

  const style = {
    ...(!isEditMode &&
      refToC?.current && {
        width: width,
      }),
    ...(isAbsoluted !== false && { position: 'absolute', bottom: 0 }),
  };

  return (
    <div
      className={cx('fixed-toc', { fixed: isFixed && !isAbsoluted })}
      ref={refToC}
      style={style}
    >
      <table>
        <tbody>
          {items.map((item, index) => (
            <tr
              className={cx(
                { active: index === active },
                item.length > 2 ? item[2] : '',
              )}
              key={`toc-${index}`}
            >
              <td>
                <ConditionalLink condition={!isEditMode} href={`#${item[0]}`}>
                  {item[1]}
                </ConditionalLink>
              </td>
              <td className="bar"></td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default FixedToC;
