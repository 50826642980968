/*
    Wrapper for Voltos UniversalLink component
    to properly support anchor links.
*/
import { flattenToAppURL } from '@plone/volto/helpers/Url/Url';
/* eslint-disable-next-line import/no-unresolved */
import UniversalLinkOrig from '@plone/volto-original/components/manage/UniversalLink/UniversalLink';
import { scrollToAnchor } from '@package/utils';

const UniversalLink = (props) => {
  const isExternalUrl = (url) => {
    if (url.startsWith('/ext/')) return true;
    return (
      (url.startsWith('http://') || url.startsWith('https://')) &&
      (url.startsWith('https://www.uni-koblenz.de/Service') ||
        !url.startsWith('https://www.uni-koblenz.de'))
    );
  };

  const href = props.href ? flattenToAppURL(props.href) : '';

  /* make sure external links open in new tab */
  const openLinkInNewTab = props.openLinkInNewTab || isExternalUrl(href);

  /* we do not want UniversalLink to create a download link,
     since this would fail if the document is not published externally
     (https://gitlab.uni-koblenz.de/cms-developer/volto-uniko/-/issues/468)
  */
  let modifiedUrl = href.replace('@@download/file', '');

  /* leading `ext:` can be used to enforce opening a link in a new tab */
  if (modifiedUrl.startsWith('/ext/')) modifiedUrl = modifiedUrl.slice(4);

  if (href.startsWith('#')) {
    return (
      <a
        href={href}
        onClick={(e) => {
          scrollToAnchor(href.slice(1));
          e.preventDefault();
        }}
      >
        {props.children}
      </a>
    );
  }
  return (
    <UniversalLinkOrig
      {...props}
      href={modifiedUrl}
      openLinkInNewTab={openLinkInNewTab}
    />
  );
};

export default UniversalLink;
