import { Container, Grid } from 'semantic-ui-react';
import { Icon } from '@package/components';
import Fade from 'react-reveal/Fade';

const ppNumber = (number, thousandsSep) => {
  const pattern = /^[0-9]+$/;
  const isInt = pattern.test(number);
  if (isInt) {
    if (thousandsSep.length === 0) return number;
    return parseInt(number).toLocaleString().replace(',', thousandsSep);
  }
  return number;
};

const StatsItem = ({ number, label, thousandsSep, icon = false }) => {
  return (
    <div className="stats-item">
      <Fade top cascade>
        <div className="number">
          {icon && (
            <>
              <Icon name={icon} />{' '}
            </>
          )}
          {ppNumber(number, thousandsSep)}
        </div>
      </Fade>
      <Fade>
        <div className="label">{label}</div>
      </Fade>
    </div>
  );
};

const InfosItem = ({ number, label, thousandsSep, icon = false }) => {
  return (
    <Fade>
      <div className="stats-item centered">
        <div className="icon-head">
          {icon && (
            <>
              <Icon name={icon} />{' '}
            </>
          )}
        </div>
        <div className="sub-head">{ppNumber(number, thousandsSep)}</div>
        <div
          className="label"
          dangerouslySetInnerHTML={{
            __html: label?.replaceAll('\\n', '<br/>'),
          }}
        ></div>
      </div>
    </Fade>
  );
};
const View = ({ data, isEditMode }) => {
  const items = data?.items || [];
  const thousandsSep = data?.thousands_separator || '';
  return (
    <div className="stats-container fullwidth">
      <Container>
        <Grid columns={data.col_count ?? 4} doubling stackable>
          <Grid.Row>
            {items.map((item, index) => {
              return (
                <Grid.Column key={`stat-${index}`}>
                  {data.variant === 'info-uli' ? (
                    <InfosItem
                      number={item.number}
                      label={item.label}
                      thousandsSep={thousandsSep}
                      icon={item.icon}
                    />
                  ) : (
                    <StatsItem
                      number={item.number}
                      label={item.label}
                      thousandsSep={thousandsSep}
                      icon={item.icon}
                    />
                  )}
                </Grid.Column>
              );
            })}
          </Grid.Row>
        </Grid>
      </Container>
    </div>
  );
};

export default View;
