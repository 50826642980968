import { FormattedMessage } from 'react-intl';
import { MaybeWrap, UniversalLink } from '@plone/volto/components';

const NavBox = ({ item, disableLinks }) => {
  return (
    <MaybeWrap
      condition={!disableLinks}
      as={UniversalLink}
      href={item.url}
      aria-label={item.title}
    >
      <div className="nav-box awh">
        {item.title ? (
          <span
            dangerouslySetInnerHTML={{
              __html: item.title?.replaceAll('\\n', '<br/>'),
            }}
          ></span>
        ) : (
          <FormattedMessage
            id="Enter title ..."
            defaultMessage="Enter title ..."
          />
        )}
      </div>
    </MaybeWrap>
  );
};

const NavBoxes = ({ items, disableLinks }) => {
  return (
    <nav className="nav-boxes">
      {items.map((item, index) => {
        return <NavBox key={index} item={item} disableLinks={disableLinks} />;
      })}
    </nav>
  );
};

export default NavBoxes;
