import * as ReactDOMServer from 'react-dom/server';
import { MemoryRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import configureStore from 'redux-mock-store';
import {
  serializeNodes,
  serializeNodesToText,
} from '@plone/volto-slate/editor/render';

/* Iterates over all blocks (in correct order)
   and returns array where each entry is the
   serialized text or html of the
   corresponding block.
*/
export const extractTexts = (content, as_html = false) => {
  if (!content) return '';
  if (!content.blocks_layout?.items) return '';
  let res = [];
  content.blocks_layout.items.forEach((uid) => {
    const block = content.blocks[uid];
    if (block) {
      /* if block has property `blocks_layout`, we assume it has subblocks */
      if (Object.keys(block).indexOf('blocks_layout') > -1) {
        /* if its an accordion item we want to include the panel title as well */
        if (block['@type'] === 'accordionPanel' && block.title?.length > 0) {
          res = res.concat([as_html ? `<b>${block.title}</b>` : block.title]);
        }
        res = res.concat(extractTexts(block, as_html));
      }
      if (block['@type'] === 'accordion' && block.data) {
        res = res.concat(extractTexts(block.data, as_html));
      }
      if (
        as_html &&
        block['@type'] === 'c2a_button' &&
        block.href?.length > 0 &&
        block.href[0]['@id']
      ) {
        const url = block.href[0]['@id'];
        const label = block.title || block.href[0].title || url;
        const html = `<a href="${url}">${label}</a>`;
        res = res.concat([html]);
      }
      if (block['@type'] === 'columnsBlock' && block.data) {
        res = res.concat(extractTexts(block.data, as_html));
      }
      if (block['@type'] === 'slate') {
        if (as_html) {
          const mockStore = configureStore();
          const html = ReactDOMServer.renderToStaticMarkup(
            <Provider store={mockStore({ userSession: { token: false } })}>
              <MemoryRouter>{serializeNodes(block.value || [])}</MemoryRouter>
            </Provider>,
          );
          res = res.concat([html]);
        } else {
          res = res.concat(serializeNodesToText(block.value || []));
        }
      }
    }
  });
  return res.filter((text) => text.length > 0);
};

export const extractDescription = (content, max_length = 256) => {
  const text = extractTexts(content, false).join('\n');
  if (text.length > max_length) return text.slice(0, max_length) + ' ...';
  return text;
};
