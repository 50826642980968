import { useState } from 'react';
import { Accordion } from 'semantic-ui-react';
import { Icon } from '@plone/volto/components';
import ArrowSVG from '@package/svgs/icons/arrow-uniko.svg';

const UniKOAccordion = ({ items, htmlContent = false }) => {
  const [activeIndex, setActiveIndex] = useState(-1);
  return (
    <div className="accordion-block">
      {items.map((item, index) => (
        <Accordion key={`faq-${index}`} fluid>
          <Accordion.Title
            active={activeIndex === index}
            index={index}
            onClick={() => {
              setActiveIndex(activeIndex === index ? -1 : index);
            }}
          >
            <Icon name={ArrowSVG} />
            {item.title}
          </Accordion.Title>
          <Accordion.Content active={activeIndex === index}>
            {htmlContent ? (
              <div
                dangerouslySetInnerHTML={{
                  __html: item.content,
                }}
              ></div>
            ) : (
              item.content
            )}
          </Accordion.Content>
        </Accordion>
      ))}
    </div>
  );
};

export default UniKOAccordion;
