import React from 'react';
import InlineForm from '@plone/volto/components/manage/Form/InlineForm';
import { Icon, SidebarPortal } from '@plone/volto/components';
import { getBaseUrl } from '@plone/volto/helpers';
import calendarSVG from '@plone/volto/icons/calendar.svg';
import View from './View';
import RSSFeedSchema from './schema';

const Edit = (props) => {
  const schema = RSSFeedSchema(props.intl);
  return (
    <>
      <SidebarPortal selected={props.selected}>
        <InlineForm
          schema={schema}
          title={schema.title}
          icon={<Icon name={calendarSVG} />}
          onChangeField={(id, value) => {
            props.onChangeBlock(props.block, {
              ...props.data,
              [id]: value,
            });
          }}
          formData={props.data}
        />
      </SidebarPortal>
      <View
        id={props.block}
        data={props.data}
        path={getBaseUrl(props.pathname)}
        isEditMode
      />
    </>
  );
};

export default Edit;
