import React, { useState } from 'react';
import { compose } from 'redux';
import { Button } from 'semantic-ui-react';
import { SidebarPopup } from '@plone/volto/components';
import { withContentNavigation } from '@plone/volto/components/theme/Navigation/withContentNavigation';
import ContextNavigation from '@plone/volto/components/theme/Navigation/ContextNavigation';

const SidebarNavigation = (props) => {
  const [isVisible, setIsVisible] = useState(false);
  const [isBtnVisible] = useState(false);

  /* disable/close when no context items are found
  const location = useLocation();
  React.useEffect(() => {
    if (props.navigation) {
      var myItems = [];
      props.navigation.items.forEach((el) => {
        if (el['@id'].endsWith(location.pathname)) return (myItems = el.items);
      });
      if (myItems.length === 0) {
        if (isVisible) setIsVisible(false);
        setIsBtnVisible(false);
      } else {
        if (!isVisible) setIsVisible(true);
        setIsBtnVisible(true);
      }
      // console.log(myItems);
      // console.log(isBtnVisible);
    }
  }, [props.navigation]);
  */

  return (
    <>
      <Button
        className={
          isBtnVisible ? 'toggle-sidebar-nav' : 'toggle-sidebar-nav hidden'
        }
        onClick={() => setIsVisible(!isVisible)}
      >
        <span className="hamburger-box">
          <span className="hamburger-inner"></span>
        </span>
      </Button>
      <SidebarPopup open={isVisible}>
        <div className="sidebar-nav-content">
          <button
            className="hamburger hamburger--collapse is-active close"
            aria-label="Close menu"
            title="Close menu"
            type="button"
            onClick={() => setIsVisible(false)}
          >
            <span className="hamburger-box">
              <span className="hamburger-inner"></span>
            </span>
          </button>
          <ContextNavigation
            params={{
              currentFolderOnly: true,
            }}
          />
        </div>
      </SidebarPopup>
    </>
  );
};

export default compose(withContentNavigation)(SidebarNavigation);
