/* eCampus search results usually consist of a `data` array containing
   items which are simple arrays and a `columns` array specifying which 
   array index corresponds to which field name.
   This function converts a `data` item into a object with correct field names.
*/
export const normalizeECampusSearchResultItem = (item, columns) => {
  let res = {};
  columns.forEach((col, index) => {
    res[col.id] = item[index];
  });
  return res;
};
