import { useLocation } from 'react-router-dom';
import { Helmet } from '@plone/volto/helpers';
import Article from './Article';
import Event from './Event';
import OrgUnit from './OrgUnit';
import Person from './Person';
import UniKoblenz from './UniKoblenz';
import WebPage from './WebPage';

const JsonLd = ({ content }) => {
  let jsonObj = false;

  /* see https://schema.org/ */

  if (content['@type'] === 'Document') {
    jsonObj = WebPage(content);
  }

  if (content['@type'] === 'News Item') {
    jsonObj = Article(content);
  }

  if (content['@type'] === 'Event') {
    jsonObj = Event(content);
  }

  if (content['@type'] === 'Person') {
    jsonObj = Person(content);
  }

  if (content['@type'] === 'OrgUnit') {
    jsonObj = OrgUnit(content);
  }

  const location = useLocation();
  if (
    jsonObj === false &&
    (location.pathname.length < 5 ||
      location.pathname.startsWith('/de/universitaet/') ||
      location.pathname.startsWith('/en/university'))
  )
    jsonObj = UniKoblenz();

  if (jsonObj === false) return <></>;

  return (
    <Helmet
      script={[
        {
          type: 'application/ld+json',
          innerHTML: JSON.stringify(jsonObj),
        },
      ]}
    ></Helmet>
  );
};

export default JsonLd;
