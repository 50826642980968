import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Grid } from 'semantic-ui-react';
import { getContent } from '@plone/volto/actions';
import { ConditionalLink } from '@plone/volto/components';
import { flattenToAppURL } from '@plone/volto/helpers';
import { PersonCard } from '@package/components';

const View = (props) => {
  const data = props['data'];
  const linkDisabled = props['link-disabled'] || false;

  const url =
    data.href?.length > 0 && data.href[0]
      ? flattenToAppURL(data.href[0]['@id'])
      : false;

  const subrequest = useSelector((state) => state.content.subrequests?.[url]);
  const dispatch = useDispatch();
  const functionFull = subrequest?.data;

  React.useEffect(() => {
    if (url && subrequest?.loaded !== true) {
      dispatch(getContent(url, null, url));
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [dispatch, url]);

  return functionFull ? (
    <div className="person-function">
      {data.show_title && (
        <h2>
          <ConditionalLink
            to={flattenToAppURL(functionFull['@id'])}
            condition={data.show_link && !linkDisabled}
          >
            {functionFull.title}
          </ConditionalLink>
        </h2>
      )}
      {data.show_description && functionFull.description && (
        <p className="documentDescription">{functionFull.description}</p>
      )}
      {functionFull.personsWithFunction.length > 0 ? (
        <Grid
          columns={
            functionFull.personsWithFunction.length === 1
              ? 1
              : data.grid_columns || 2
          }
          doubling
          stackable
        >
          {functionFull.personsWithFunction?.map((person) => {
            return person ? (
              <Grid.Column key={person['@id']}>
                <PersonCard
                  person={person}
                  hide-image={data.hide_images}
                  hide-description={data.hide_description}
                  hide-remark={data.hide_remark}
                  hide-function={data.hide_function}
                  link-disabled={linkDisabled}
                  override={functionFull}
                />
              </Grid.Column>
            ) : (
              <></>
            );
          })}
        </Grid>
      ) : (
        <span>
          <FormattedMessage
            id="no_persons_with_function"
            defaultMessage="No persons have yet been assigned to this function."
          />
        </span>
      )}
    </div>
  ) : (
    <></>
  );
};

export default View;
