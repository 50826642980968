import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';
import { useIntl } from 'react-intl';
import { Container, Grid } from 'semantic-ui-react';
import { getContent, queryRelations } from '@plone/volto/actions';
import { flattenToAppURL } from '@plone/volto/helpers';
import placeholderJPG from '@package/assets/placeholder_project.jpg';
import placeholderWEBP from '@package/assets/placeholder_project.webp';
import { NavPills, PreviewHero, UniKOAccordion } from '@package/components';
import ToCView from '@package/components/Blocks/ToC/View';
import { replaceImgShortCodes, replaceVideoShortCodes } from '@package/utils';
import DegreeProgramCard from './DegreeProgramCard';
import schemaDE from './subject_profile_de.json';
import schemaEN from './subject_profile_en.json';
import './program.less';
import './subject.less';
import {
  ContactForm,
  MetaBoxItem,
  messages,
  ppMaterial,
  ppValue,
} from './utils';

const RichTextFieldBase = ({
  id,
  title,
  html,
  hideDivider = false,
  videos = [],
}) => {
  return (
    <div className={cx('field', id)}>
      <h2 id={id}>{title}</h2>
      {html.length > 12 && (
        <div
          dangerouslySetInnerHTML={{
            __html: replaceVideoShortCodes(replaceImgShortCodes(html), videos),
          }}
        />
      )}
      {!hideDivider && <hr className="divider fullwidth" />}
    </div>
  );
};

const RichTextField = ({ id, schema, content, hideDivider = false }) => {
  const title = schema.properties[id]?.title;
  let html = content[id]?.data || '';
  return (
    <RichTextFieldBase
      id={id}
      title={title}
      html={html}
      hideDivider={hideDivider}
      videos={content.videos?.items || []}
    />
  );
};

const MetaBox = ({ content, intl, schema }) => {
  return (
    <div className="metabox">
      <MetaBoxItem
        title={schema.properties.study_period.title}
        value={`${content.study_period} ${intl.formatMessage(
          messages.semesters,
        )}`}
        intl={intl}
      />
      <MetaBoxItem
        title={intl.formatMessage(messages.language)}
        value={content.teaching_language}
        intl={intl}
      />
      <MetaBoxItem
        title={intl.formatMessage(messages.format)}
        value={
          content.program_format?.length > 0
            ? content.program_format
            : intl.formatMessage(messages.in_presence)
        }
        intl={intl}
      />
      <MetaBoxItem
        title={intl.formatMessage(messages.admission_restriction)}
        value={
          content.admission_restriction === true
            ? intl.formatMessage(messages.yes)
            : ppValue(content.admission_restriction, intl)
        }
        intl={intl}
      />
      <div>{/* placeholder for layouting purposes */}</div>
    </div>
  );
};

/* wrapper around DegreeProgramCard that loads full object,
   to pass as `content` prop
*/
const DegreeProgramCardWrapper = ({ content }) => {
  const url = flattenToAppURL(content['@id']);
  let contentFull = false;
  const dispatch = useDispatch();
  const subrequest = useSelector((state) => state.content?.subrequests?.[url]);
  useEffect(() => {
    if (url && subrequest?.loaded !== true) {
      dispatch(getContent(url, null, url));
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [dispatch, url]);
  if (subrequest?.data) contentFull = subrequest.data;
  return <DegreeProgramCard content={contentFull || content} />;
};

const SubjectProfileView = ({ content }) => {
  const intl = useIntl();
  const schema = intl.locale === 'en' ? schemaEN : schemaDE;
  const image = content.preview_image || content.image || false;
  const hasForm = content.contact_form?.length > 0 && content.contact_form[0];

  const richTextFields = [
    'contents_structure',
    'prospects',
    'personal_requirements',
    'formal_requirements',
    'stay_abroad',
    'application_information',
  ].filter(
    (id) => (content[id]?.data || '').replaceAll('<p></p>', '').length > 0,
  );

  const tocIds = richTextFields.map((id) => id);
  tocIds.splice(0, 0, 'overview');
  if (content.faqs?.items?.length > 0) tocIds.push('faqs');
  if (hasForm) {
    tocIds.push('form');
    schema.properties['form'] = {
      title: content.form_title || intl.formatMessage(messages.form),
    };
  }

  const materials = content.materials?.items?.filter((i) => i.url);

  /* get referencing DegreePrograms */
  const dispatch = useDispatch();
  const subRequestKey = content['@id'];
  const relationsRequest = useSelector(
    (state) => state.relations?.subrequests[subRequestKey] || false,
  );
  useEffect(() => {
    if (
      !relationsRequest?.loading &&
      !relationsRequest.loaded &&
      !relationsRequest.error
    )
      dispatch(
        queryRelations(
          null,
          false,
          subRequestKey,
          null,
          flattenToAppURL(subRequestKey),
        ),
      );
  }, [dispatch, relationsRequest, subRequestKey]);
  const relatedPrograms = (
    relationsRequest?.data?.related_subjects?.items || []
  ).map((i) => i.source);

  return (
    <Container id="program-container" className="subject">
      {image ? (
        <PreviewHero
          heroImage={
            <img src={flattenToAppURL(image.scales.huge.download)} alt="" />
          }
          caption={content.formatted_title || content.title}
          subCaption={schema.title}
        />
      ) : (
        <PreviewHero
          heroImage={
            <picture>
              <source type="image/webp" srcSet={placeholderWEBP} />
              <img src={placeholderJPG} alt="" />
            </picture>
          }
          caption={content.title}
          subCaption={schema.title}
        />
      )}
      <MetaBox content={content} intl={intl} schema={schema} />
      <Grid reversed="tablet computer">
        <Grid.Row>
          <Grid.Column mobile={12} tablet={2} computer={4}>
            <ToCView
              data={{ position: 'fixed' }}
              items={tocIds.map((id) => {
                return {
                  key: id,
                  text: schema.properties[id].title,
                  type: 'h2',
                };
              })}
            />
          </Grid.Column>
          <Grid.Column mobile={12} tablet={10} computer={8}>
            <div className="program-description">{content.description}</div>
            <RichTextField id="overview" content={content} schema={schema} />
            {relatedPrograms.length > 0 && (
              <>
                <h2 id="programs">Studiengänge</h2>
                <div className="programs">
                  {relatedPrograms.map((program) => (
                    <DegreeProgramCardWrapper content={program} />
                  ))}
                </div>
                <hr className="divider fullwidth" />
              </>
            )}
            {richTextFields.map((id, index) => (
              <div key={`field-richtext-${index}`}>
                <>
                  <RichTextField
                    id={id}
                    content={content}
                    schema={schema}
                    hideDivider={id === 'contents_structure'}
                  />
                  {id === 'contents_structure' && materials?.length > 0 && (
                    <>
                      <NavPills
                        items={materials.map((i) => {
                          return {
                            url: i.url,
                            title:
                              i.remark?.length > 0
                                ? `${ppMaterial(i.kind, intl)} (${i.remark})`
                                : ppMaterial(i.kind, intl),
                          };
                        })}
                      />
                    </>
                  )}
                  {id === 'contents_structure' && (
                    <hr className="divider fullwidth" />
                  )}
                </>
              </div>
            ))}
            {content.faqs?.items?.length > 0 && (
              <>
                <h2 id="faqs">{schema.properties['faqs'].title}</h2>
                <UniKOAccordion
                  items={content.faqs.items.map((faq) => {
                    return { title: faq.question, content: faq.answer };
                  })}
                />
              </>
            )}
            {hasForm && (
              <>
                <hr className="divider fullwidth" />
                <h2 id="form">
                  {content.form_title || intl.formatMessage(messages.form)}
                </h2>
                {content.contact_form?.length > 0 &&
                  content.contact_form[0] && (
                    <ContactForm
                      url={flattenToAppURL(content.contact_form[0]['@id'])}
                    />
                  )}
              </>
            )}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Container>
  );
};

export default SubjectProfileView;
