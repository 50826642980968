import cx from 'classnames';

const View = ({ data, metadata }) => {
  return data.kind === 'br' ? (
    <br />
  ) : (
    /* existence of metadata means we are within a columns block,
       which means we do not want `fullwidth` class
    */
    <hr className={cx('divider', { fullwidth: !metadata })} />
  );
};

export default View;
