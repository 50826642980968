import { FormattedMessage } from 'react-intl';
import { MaybeWrap, UniversalLink } from '@plone/volto/components';
import cx from 'classnames';

const NavPill = ({ item, disableLinks }) => {
  return (
    <MaybeWrap
      condition={!disableLinks}
      as={UniversalLink}
      href={item.url}
      aria-label={item.title}
    >
      <div className="nav-pill">
        {item.title || (
          <FormattedMessage
            id="Enter title ..."
            defaultMessage="Enter title ..."
          />
        )}
      </div>
    </MaybeWrap>
  );
};

const NavPills = ({ items, position = 'left', disableLinks }) => {
  return (
    <nav className={cx('nav-pills', position)}>
      {items.map((item, index) => {
        return <NavPill key={index} item={item} disableLinks={disableLinks} />;
      })}
    </nav>
  );
};

export default NavPills;
