/*
  MegaBurgerMenu 🍔 concept:
  - Retrieve items via `@megamenu` endpoint
  - Depth: 3 levels
  - User should be able to navigate through all levels without leaving the menu
  - If user selected level 0 item: list contents of level 1
  - If user selected level 0 & level 1 item: list contents of level 2
  - Selections can be revoked
  - If item has no subitems, directly link to it instead of
    listing (non existend) subitems
*/

import React, { useRef, useState } from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import cx from 'classnames';
import { Grid, List } from 'semantic-ui-react';
import Fade from 'react-reveal/Fade';
import { getContent } from '@plone/volto/actions';
import { ConditionalLink, Icon, UniversalLink } from '@plone/volto/components';
import { flattenToAppURL, isCmsUi } from '@plone/volto/helpers';
import IconClear from '@plone/volto/icons/clear.svg';
import IconHome from '@plone/volto/icons/home.svg';
import MegaFooter from './MegaFooter';

const messages = defineMessages({
  labelUnselect: {
    id: 'Clear selection',
    defaultMessage: 'Clear selection',
  },
  openMainNav: {
    id: 'Show main navigation',
    defaultMessage: 'Show main navigation',
  },
});

const ColumnSecondary = ({ item, onClickItem, onUnselect }) => {
  const intl = useIntl();
  const noLink = item['type'] === 'Folder';
  return (
    <Fade left duration={500} distance={'150px'}>
      <div className="col-secondary">
        <div className="flex">
          <ConditionalLink
            condition={!noLink}
            to={flattenToAppURL(item['@id'])}
          >
            <span
              className={cx('head', 'sm', 'awh', { 'no-link': noLink })}
              role={noLink ? 'heading' : 'menuitem'}
            >
              {item.title}
            </span>
          </ConditionalLink>
          {onUnselect && (
            <Icon
              className="unselect"
              onClick={onUnselect}
              name={IconClear}
              title={intl.formatMessage(messages.labelUnselect)}
            />
          )}
        </div>
        <List>
          {item.subitems.map((subitem, index) => {
            return onClickItem ? (
              <List.Item key={`${subitem['@id']}-${index}`}>
                <UniversalLink href={subitem['@id']} role="menuitem">
                  {subitem.title}
                </UniversalLink>
              </List.Item>
            ) : (
              <List.Item key={subitem['@id']}>
                <UniversalLink
                  href={flattenToAppURL(subitem['@id'])}
                  role="menuitem"
                >
                  {subitem.title}
                </UniversalLink>
              </List.Item>
            );
          })}
        </List>
      </div>
    </Fade>
  );
};

const SubItems = ({ items }) => {
  return (
    <div className="sub-items">
      {items.map((item, index) => {
        return (
          <UniversalLink
            key={`${item['@id']}-si-${index}`}
            href={flattenToAppURL(item['@id'])}
            role="menuitem"
          >
            {item.title}
          </UniversalLink>
        );
      })}
    </div>
  );
};

const MegaBurgerMenu = (props) => {
  const { isOpen, setIsOpen } = props;

  const intl = useIntl();
  const dispatch = useDispatch();
  const location = useLocation();

  const currentLang = useSelector((state) => state.intl.locale);
  const menuRef = useRef(null);
  let megaMenuContent = false;

  /* selectedItems array is false when nothing is selected, otherwise:
      selectedItems[0] = {selected nav item of level 0} or false
      selectedItems[1] = {selected nav item of level 1} or false
  */
  const [selectedItems, setSelectedItems] = useState(false);
  const [lastOpenLocation, setLastOpenLocation] = useState(false);
  const [wasAutoclosed, setWasAutoclosed] = useState(false);

  /* remove last part of path if its a cms path (like `/edit` or `/add`) */
  let url;
  if (isCmsUi(location.pathname)) {
    const parts = location.pathname.split('/').slice(0, -1);
    if (parts.length < 2 || ['de', 'en'].indexOf(parts[1]) === -1)
      parts.unshift(intl.locale);
    parts.push('@megamenu');
    url = parts.join('/');
  } else {
    url = `${location.pathname}/@megamenu`;
  }

  const subrequest = useSelector((state) => state.content.subrequests?.[url]);

  /* if request failed (for example 404), get language roots
     menu items as fallback */
  if (subrequest?.error) url = `/${intl.locale}/@megamenu`;
  megaMenuContent = subrequest?.data;

  React.useEffect(() => {
    if (subrequest?.loaded !== true) dispatch(getContent(url, null, url));
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [dispatch, url]);

  /* close on location change */
  React.useEffect(() => {
    if (isOpen) {
      setIsOpen(false);
      setWasAutoclosed(true);
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [location]);

  React.useEffect(() => {
    if (isOpen === true) {
      if (window.location.pathname !== lastOpenLocation)
        setSelectedItems(false);
      setLastOpenLocation(window.location.pathname);
      if (menuRef?.current) menuRef.current.focus();
    }
    /* permit scrolling of content when mega menu is open,
       to avoid strange behavior if user wants to scroll
       within mega menu: */
    document.getElementsByTagName('body')[0].style.overflow = isOpen
      ? 'hidden'
      : 'auto';
  }, [isOpen, lastOpenLocation, setSelectedItems, setLastOpenLocation]);

  React.useEffect(() => {
    if (typeof document !== 'undefined') {
      const els = document.getElementsByClassName('mega-overlay');
      if (els.length > 0) {
        els[0].scrollTo(0, 0);
      }
    }
  }, [selectedItems]);

  /* listen for user to click `back` button in browser
     and if new path had menu open, open it again
  */
  React.useEffect(() => {
    const handlePop = () => {
      if (
        !isOpen &&
        wasAutoclosed &&
        lastOpenLocation === window.location.pathname
      ) {
        setIsOpen(true);
      }
    };
    window.addEventListener('popstate', handlePop);
    return () => {
      window.removeEventListener('popstate', handlePop);
    };
  }, [lastOpenLocation, isOpen, wasAutoclosed, setIsOpen]);

  const onBurgerClick = () => {
    if (isOpen) {
      setWasAutoclosed(false);
      setIsOpen(false);
    } else {
      setIsOpen(true);
    }
  };

  const onKeyDown = (e) => {
    if (e.keyCode === 27 && isOpen) setIsOpen(false);
  };

  return (
    <>
      <button
        onClick={onBurgerClick}
        className={cx('hamburger hamburger--spin', {
          'is-active': isOpen,
        })}
        tabIndex={0}
        aria-label={intl.formatMessage(messages.openMainNav)}
      >
        <span className="inner">
          <span className="hamburger-box">
            <span className="hamburger-inner"></span>
          </span>
          <span className="label">
            <FormattedMessage id="Menu" defaultMessage="Menu" />
          </span>
        </span>
      </button>
      <div
        id="navigation"
        className={isOpen ? 'mega-overlay show' : 'mega-overlay'}
        onKeyDown={onKeyDown}
        role="menu"
        tabIndex={isOpen ? 0 : -1}
        ref={menuRef}
      >
        {megaMenuContent && selectedItems === false ? (
          <Grid id="mega-overlay-container" container stackable>
            <Grid.Column width={12}>
              {location.pathname.split('/').length > 2 && (
                <Link className="home" to={`/${currentLang}`}>
                  <Icon name={IconHome} />{' '}
                  <FormattedMessage
                    id="University of Koblenz"
                    defaultMessage="University of Koblenz"
                  />
                </Link>
              )}
            </Grid.Column>
            <Grid.Column
              computer={megaMenuContent?.items_secondary?.length > 1 ? 4 : 8}
              tablet={12}
            >
              <List className="main-items">
                {(megaMenuContent?.items || []).map((item, index) => {
                  const hasSubItems = item.subitems.length > 0;
                  return (
                    <List.Item
                      key={`${item['@id']}-mi-${index}}`}
                      onClick={() => {
                        if (hasSubItems) setSelectedItems([item, false]);
                      }}
                      className="head awh"
                      tabIndex={0}
                    >
                      {hasSubItems ? (
                        <span role="menuitem">{item.title}</span>
                      ) : (
                        <UniversalLink href={item['@id']} role="menuitem">
                          {item.title}
                        </UniversalLink>
                      )}
                    </List.Item>
                  );
                })}
              </List>
              {megaMenuContent?.items_sub?.length > 0 && (
                <SubItems items={megaMenuContent.items_sub} />
              )}
            </Grid.Column>
            <Grid.Column
              computer={megaMenuContent?.items_secondary?.length > 1 ? 8 : 4}
              tablet={12}
            >
              <Grid reversed="computer">
                <Grid.Column
                  computer={
                    megaMenuContent?.items_secondary?.length > 1 ? 6 : 12
                  }
                  tablet={12}
                >
                  {megaMenuContent?.items_secondary?.length > 0 && (
                    <ColumnSecondary
                      item={megaMenuContent.items_secondary[0]}
                      onClickItem={(i) => setSelectedItems([i, false])}
                    />
                  )}
                </Grid.Column>
                {megaMenuContent?.items_secondary?.length > 1 && (
                  <Grid.Column computer={6} tablet={12}>
                    <ColumnSecondary
                      item={megaMenuContent.items_secondary[1]}
                      onClickItem={(i) => setSelectedItems([i, false])}
                    />
                  </Grid.Column>
                )}
              </Grid>
            </Grid.Column>
          </Grid>
        ) : megaMenuContent ? (
          <Grid id="mega-overlay-container" container stackable>
            <Grid.Column width={6}>
              <div className="main-items">
                <div className="flex">
                  <UniversalLink
                    className="head awh"
                    href={flattenToAppURL(selectedItems[0]['@id'])}
                    role="menuitem"
                  >
                    {selectedItems[0].title}
                  </UniversalLink>
                  <Icon
                    className="unselect"
                    onClick={() => setSelectedItems(false)}
                    name={IconClear}
                    title={intl.formatMessage(messages.labelUnselect)}
                    aria-label={intl.formatMessage(messages.labelUnselect)}
                  />
                </div>
                {selectedItems[0]?.description && (
                  <div className="description">
                    {selectedItems[0].description}
                  </div>
                )}
                {megaMenuContent?.hide_overview === false && (
                  <>
                    <br />
                    <UniversalLink
                      className="head xs awh"
                      href={flattenToAppURL(selectedItems[0]['@id'])}
                      role="menuitem"
                    >
                      <FormattedMessage
                        id="Overview"
                        defaultMessage="Overview"
                      />{' '}
                      {selectedItems[0].title}
                    </UniversalLink>
                  </>
                )}
              </div>
            </Grid.Column>
            {selectedItems[1] === false ? (
              <Grid.Column width={6} className="col-level-1">
                <Fade left duration={500} distance={'150px'}>
                  <List>
                    {selectedItems[0]?.subitems.map((item, index) => {
                      return item.subitems.length > 0 ? (
                        <List.Item
                          key={`${item['@id']}-hsa-${index}}`}
                          className="head sm awh"
                          onClick={() =>
                            setSelectedItems([selectedItems[0], item])
                          }
                        >
                          <span role="menuitem">{item.title}</span>
                        </List.Item>
                      ) : (
                        <List.Item className="head sm awh" key={item['@id']}>
                          <UniversalLink
                            href={flattenToAppURL(item['@id'])}
                            role="menuitem"
                          >
                            {item.title}
                          </UniversalLink>
                        </List.Item>
                      );
                    })}
                  </List>
                </Fade>
              </Grid.Column>
            ) : (
              <Grid.Column width={6} className="col-level-2">
                <ColumnSecondary
                  item={selectedItems[1]}
                  onUnselect={() => setSelectedItems([selectedItems[0], false])}
                />
              </Grid.Column>
            )}
          </Grid>
        ) : (
          <></>
        )}
        <MegaFooter socialUrls={megaMenuContent?.social_urls || []} />
      </div>
    </>
  );
};

export default MegaBurgerMenu;
