import { defineMessages } from 'react-intl';

const messages = defineMessages({
  Source: {
    id: 'Source',
    defaultMessage: 'Source',
  },
  imageOverride: {
    id: 'Image override',
    defaultMessage: 'Image override',
  },
  title: {
    id: 'Title',
    defaultMessage: 'Title',
  },
  description: {
    id: 'Description',
    defaultMessage: 'Description',
  },
  head_title: {
    id: 'head_title',
    defaultMessage: 'Head title',
  },
  teaser: {
    id: 'Teaser',
    defaultMessage: 'Teaser',
  },
  variation: {
    id: 'Variation',
    defaultMessage: 'Variation',
  },
  summary: {
    id: 'Summary',
    defaultMessage: 'Summary',
  },
  featured: {
    id: 'Featured',
    defaultMessage: 'Featured',
  },
  default: {
    id: 'Default',
    defaultMessage: 'Default',
  },
  hideImage: {
    id: 'Hide image',
    defaultMessage: 'Hide image',
  },
  hideDescription: {
    id: 'Hide description',
    defaultMessage: 'Hide description',
  },
  labelCTAText: {
    id: 'Call-to-Action',
    defaultMessage: 'Call-to-Action',
  },
  readMore: {
    id: 'Read more',
    defaultMessage: 'Read more',
  },
});

export const TeaserSchema = ({ intl }) => {
  return {
    title: intl.formatMessage(messages.teaser),
    fieldsets: [
      {
        id: 'default',
        title: intl.formatMessage(messages.default),
        fields: [
          'href',
          'variation',
          'title',
          'head_title',
          'description',
          'preview_image',
          'hide_image',
          'hide_description',
          'cta_text',
        ],
      },
    ],

    properties: {
      href: {
        title: intl.formatMessage(messages.Source),
        widget: 'object_browser',
        mode: 'link',
        selectedItemAttrs: [
          'title',
          'head_title',
          'description',
          'hasPreviewImage',
          'image_field',
          'image_scales',
        ],
        allowExternals: true,
      },
      variation: {
        title: intl.formatMessage(messages.variation),
        type: 'string',
        factory: 'Choice',
        choices: [
          ['summary', intl.formatMessage(messages.summary)],
          ['featured', intl.formatMessage(messages.featured)],
        ],
        default: 'summary',
      },
      title: {
        title: intl.formatMessage(messages.title),
      },
      head_title: {
        title: intl.formatMessage(messages.head_title),
      },
      description: {
        title: intl.formatMessage(messages.description),
        widget: 'textarea',
      },
      preview_image: {
        title: intl.formatMessage(messages.imageOverride),
        widget: 'object_browser',
        mode: 'image',
        allowExternals: true,
      },
      hide_image: {
        title: intl.formatMessage(messages.hideImage),
        type: 'boolean',
      },
      hide_description: {
        title: intl.formatMessage(messages.hideDescription),
        type: 'boolean',
      },
      cta_text: {
        title: intl.formatMessage(messages.labelCTAText),
        type: 'text',
        default: intl.formatMessage(messages.readMore),
      },
    },
    required: [],
  };
};

export default TeaserSchema;
