import React, { useState } from 'react';
import { Anontools, LanguageSelector } from '@plone/volto/components';
import { BodyClass } from '@plone/volto/helpers';
import { MegaBurgerMenu, MegaSearch } from '@package/components';

const MegaContainer = (props) => {
  const [isOpenBurgerMenu, setIsOpenBurgerMenu] = useState(false);
  const [isOpenSearch, setIsOpenSearch] = useState(false);

  React.useEffect(() => {
    if (isOpenBurgerMenu) setIsOpenSearch(false);
  }, [isOpenBurgerMenu]);

  React.useEffect(() => {
    if (isOpenSearch) setIsOpenBurgerMenu(false);
  }, [isOpenSearch]);

  return (
    <div className="menu-wrapper">
      <BodyClass
        className={isOpenBurgerMenu || isOpenSearch ? 'show-megaoverlay' : ''}
      />
      <MegaBurgerMenu
        isOpen={isOpenBurgerMenu}
        setIsOpen={setIsOpenBurgerMenu}
      />
      <Anontools />
      <MegaSearch isOpen={isOpenSearch} setIsOpen={setIsOpenSearch} />
      <LanguageSelector />
    </div>
  );
};

export default MegaContainer;
