import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { getContent } from '@plone/volto/actions';
import { BodyClass } from '@plone/volto/helpers';
import { HeroGallery } from '@package/components';

import './fsgallery.less';

const FullscreenGallery = ({ location }) => {
  const content = useSelector((state) => state.content?.data || false);

  // remove trailing `/fullscreen_gallery` from pathname
  const url = location.pathname.split('/').slice(0, -1).join('/');
  const dispatch = useDispatch();
  if (content === false) {
    dispatch(getContent(url));
  }

  const showOverlays = location.search.indexOf('overlays=1') > -1;

  // slide-change-interval in seconds can be specified via GET parameter
  // (default: 60s)
  const intervalParams = location.search
    .slice(1)
    .split('&')
    .filter((i) => i.startsWith('interval='));
  const intervalParam =
    intervalParams.length > 0
      ? Number.parseInt(intervalParams[0].split('=').pop())
      : 60;

  // refresh-interval in seconds can be specified via GET parameter
  // (default: 3600s)
  const autoRefreshParams = location.search
    .slice(1)
    .split('&')
    .filter((i) => i.startsWith('refresh='));
  const autoRefreshParam =
    autoRefreshParams.length > 0
      ? Number.parseInt(autoRefreshParams[0].split('=').pop())
      : 3600;

  if (__CLIENT__) {
    setTimeout(() => {
      dispatch(getContent(url));
    }, autoRefreshParam * 1000);
  }

  return (
    <>
      <BodyClass className="fullscreen-gallery-view" />
      {content ? (
        (content.items || []).length > 0 ? (
          <div className="herogallery">
            <HeroGallery
              items={content.items}
              widthCapped={false}
              slideInterval={intervalParam * 1000}
              showOverlays={showOverlays}
              showBullets={showOverlays}
            />
          </div>
        ) : (
          <span>
            <FormattedMessage
              id="No matching contents found."
              defaultMessage="No matching contents found."
            />
          </span>
        )
      ) : (
        <span>
          <FormattedMessage id="Loading" defaultMessage="Loading" /> ...
        </span>
      )}
    </>
  );
};

export default FullscreenGallery;
