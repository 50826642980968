import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Grid, Loader, Message, Segment } from 'semantic-ui-react';
import { ECAMPUS_BASE_URL, ECAMPUS_API_URL } from '../Publications/constants';
import ResearchProjectCard from './Card';

const fetchProjectsByOrgUnit = async (orgUnitLid, statusFilter) => {
  const url = `${ECAMPUS_API_URL}/cs/sys/genericSearch/loadSearchResult`;

  const postData = {
    search: {
      groups: [
        {
          criteria: [
            {
              field:
                'Project.members.positions{positionType.hiskeyId==1}.orgunitObj.currentVersions.lid',
              value: orgUnitLid,
            },
          ],
        },
        {
          criteria: [
            {
              field: 'Project.projectStatus.id',
              value: statusFilter,
            },
          ],
        },
      ],
      configFile: 'fs/res/searchProject.xml',
    },
    offset: 0,
    limit: -1,
  };

  const response = await fetch(url, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(postData),
  });

  const data = await response.json();
  if (data.data?.length > 0) {
    return data.data.map((item) => {
      return {
        id: item[5],
        title: item[0],
        start: item[2],
        end: item[3],
        url: `${ECAMPUS_BASE_URL}/qisserver/a/fs.res.frontend/finance/project/view/${item[5]}`,
        status: statusFilter === '6' ? 'Abgeschlossen' : 'Laufend',
      };
    });
  }

  return [];
};

const View = ({ data, isEditMode }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [projects, setProjects] = useState([]);
  /* key is used to determine if relevant data has changed to
     trigger a data reload via eCampus API
  */
  const [dataKey, setDataKey] = useState('');

  const items =
    data?.items?.length > 0
      ? data.items
          .filter((i) => i.href?.length > 0 && i.href[0].ecampus_id)
          .map((i) => i.href[0])
      : [];

  const personIds = items
    .filter((i) => i.portal_type === 'Person' && i.ecampus_id)
    .map((i) => i.ecampus_id);

  const orgUnitIds = items
    .filter((i) => i.portal_type === 'OrgUnit' && i.ecampus_id)
    .map((i) => i.ecampus_id);

  const newDataKey = `${personIds.toString()}-${orgUnitIds.toString()}-${
    data.filter_by_status
  }`;

  useEffect(() => {
    const fetchProjects = async () => {
      setIsLoading(true);

      try {
        const resultsPersons = await Promise.all(
          personIds.map(async (ecampusId) => {
            const response = await fetch(
              `${ECAMPUS_BASE_URL}/qisserver/api/v1/cs/psv/person/${ecampusId}/details`,
            );
            const data = await response.json();
            if (data?.extendedData?.projects?.listViewData?.length > 0) {
              return data.extendedData.projects.listViewData.map((p) => {
                const detailParts = p.details.split(' | ');
                const [start, end] = detailParts[2].split(' - ');
                return {
                  id: p.viewUrl.split('/').pop(),
                  title:
                    p.objectName ||
                    p._foreigntext?.en?.objectName ||
                    'Untitled',
                  url: `${ECAMPUS_BASE_URL}/qisserver/a${p.viewUrl.slice(2)}`,
                  status: detailParts[1],
                  start: start,
                  end: end,
                };
              });
            }
            return [];
          }),
        );

        const resultsOrgUnitsOngoing =
          data.filter_by_status === 'completed'
            ? []
            : await Promise.all(
                orgUnitIds.map((id) => fetchProjectsByOrgUnit(id, '5')),
              );

        const resultsOrgUnitsCompleted =
          data.filter_by_status === 'ongoing'
            ? []
            : await Promise.all(
                orgUnitIds.map((id) => fetchProjectsByOrgUnit(id, '6')),
              );

        /* use filters with findIndex to eliminate duplicates */
        let results = resultsPersons.flat();
        results.concat(
          resultsOrgUnitsOngoing
            .flat()
            .filter((i) => results.findIndex((r) => r.id === i.id) === -1),
        );
        results.concat(
          resultsOrgUnitsCompleted
            .flat()
            .filter((i) => results.findIndex((r) => r.id === i.id) === -1),
        );

        setProjects(results);
        setDataKey(newDataKey);
      } finally {
        setIsLoading(false);
      }
    };

    if (
      personIds.length + orgUnitIds.length > 0 &&
      !isLoading &&
      newDataKey !== dataKey
    ) {
      fetchProjects();
    } else if (personIds.length + orgUnitIds.length === 0) {
      if (projects.length > 0) setProjects([]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personIds, orgUnitIds, data]);

  if (projects && projects.length > 0) {
    if (data.sort_by === 'start')
      projects.sort((a, b) => new Date(a.start) - new Date(b.start));
    else if (data.sort_by === 'end')
      projects.sort((a, b) => new Date(a.end) - new Date(b.end));
    else projects.sort((a, b) => a.title.localeCompare(b.title));
    if (data.sort_reverse) projects.reverse();
  }

  if (isLoading) {
    return (
      <Segment>
        <Loader size="large" inline="centered" active>
          <FormattedMessage
            id="Loading projects ..."
            defaultMessage="Loading projects ..."
          />
        </Loader>
      </Segment>
    );
  }

  if (!projects || projects.length === 0) {
    return (
      <Message warning>
        <FormattedMessage
          id="No matching projects found."
          defaultMessage="No matching projects found."
        />
      </Message>
    );
  }

  const ongoingProjects = projects.filter((p) => p.status === 'Laufend');
  const completedProjects = projects.filter(
    (p) => p.status === 'Abgeschlossen',
  );

  return (
    <>
      {ongoingProjects.length > 0 && (
        <>
          {!data.filter_by_status && (
            <h3>
              <FormattedMessage
                id="Ongoing projects"
                defaultMessage="Ongoing projects"
              />
            </h3>
          )}
          <Grid container columns={data.grid_columns || 1} stackable>
            {ongoingProjects.map((project, index) => (
              <Grid.Column key={`project-${index}`} className="stretched">
                <ResearchProjectCard
                  project={project}
                  linksDisabled={isEditMode}
                  hideState={data.filter_by_status}
                />
              </Grid.Column>
            ))}
          </Grid>
        </>
      )}
      {completedProjects.length > 0 && (
        <>
          {!data.filter_by_status && (
            <h3>
              <FormattedMessage
                id="Completed projects"
                defaultMessage="Completed projects"
              />
            </h3>
          )}
          <Grid container columns={data.grid_columns || 1} stackable>
            {completedProjects.map((project, index) => (
              <Grid.Column key={`project-${index}`} className="stretched">
                <ResearchProjectCard
                  project={project}
                  linksDisabled={isEditMode}
                  hideState={data.filter_by_status}
                />
              </Grid.Column>
            ))}
          </Grid>
        </>
      )}
    </>
  );
};

export default View;
