import { FormattedMessage } from 'react-intl';
import { Message } from 'semantic-ui-react';
import Veranstaltung from '@package/components/Blocks/Lecture/Veranstaltung';

const eliminateDuplicates = (lectures) => {
  return lectures.filter(
    (value, index, self) =>
      index ===
      self.findIndex((t) => t.VeranstaltungID === value.VeranstaltungID),
  );
};

const searchMatches = (compare, searchInput) => {
  if (compare === undefined) return false;
  return compare.toLowerCase().indexOf(searchInput.toLowerCase()) > -1;
};

const filterLecture = (lecture, filterLanguage, searchInput) => {
  if (filterLanguage) {
    if (
      filterLanguage === 'de' &&
      (lecture.usprache || lecture.VeranstSprache) === '2'
    )
      return false;
    if (
      filterLanguage === 'en' &&
      (lecture.usprache || lecture.VeranstSprache) !== '2'
    )
      return false;
  }
  if (searchInput.length === 0) return true;
  if (searchMatches(lecture.VArt, searchInput)) return true;
  if (searchMatches(lecture.VBezeichnung, searchInput)) return true;
  if (searchMatches(JSON.stringify(lecture.VLehrperson), searchInput))
    return true;
  return false;
};

/* clean title to group lectures and associated exercises */
const cleanGroupedTitle = (title) => {
  const indicators = ['übung zu', 'übung', 'exercise for', 'exercise'];
  let res = title.trim().toLowerCase();
  let doSuffix = false;
  indicators.forEach((indicator) => {
    if (res.indexOf(indicator) > -1) doSuffix = true;
    res = res.replace(indicator, '');
    res = res.trim();
  });
  /* make sure exercises are ranked below lectures */
  if (doSuffix) res += 'x';
  return res;
};

const sortLectures = (lectures, mode) => {
  if (mode === 'id') {
    return lectures.sort((a, b) =>
      a.VeranstaltungID < b.VeranstaltungID
        ? -1
        : a.VeranstaltungID > b.VeranstaltungID
        ? 1
        : 0,
    );
  }

  if (mode === 'title') {
    return lectures.sort((a, b) => {
      const titleA = a.VBezeichnung.trim().toLowerCase();
      const titleB = b.VBezeichnung.trim().toLowerCase();
      return titleA < titleB ? -1 : titleA > titleB ? 1 : 0;
    });
  }

  if (mode === 'type') {
    return lectures.sort((a, b) => {
      const typeA = a.VArt.trim().toLowerCase();
      const typeB = b.VArt.trim().toLowerCase();
      const titleA = a.VBezeichnung.trim().toLowerCase();
      const titleB = b.VBezeichnung.trim().toLowerCase();
      const keyA = typeA + titleA;
      const keyB = typeB + titleB;
      return keyA < keyB ? -1 : keyA > keyB ? 1 : 0;
    });
  }

  /* default: title_grouped */
  return lectures.sort((a, b) => {
    const titleA = cleanGroupedTitle(a.VBezeichnung);
    const titleB = cleanGroupedTitle(b.VBezeichnung);
    return titleA < titleB ? -1 : titleA > titleB ? 1 : 0;
  });
};

const LecturesSorted = ({
  lectures,
  sortMode = 'title_grouped',
  filterLanguage,
  searchInput = '',
  isEditMode,
}) => {
  const toShow = sortLectures(
    eliminateDuplicates(lectures).filter((lecture) =>
      filterLecture(lecture, filterLanguage, searchInput),
    ),
    sortMode,
  );

  if (toShow.length === 0)
    return (
      <Message warning>
        <FormattedMessage
          id="No matching lectures found."
          defaultMessage="No matching lectures found."
        />
      </Message>
    );

  return toShow.map((item, index) => {
    return (
      <Veranstaltung
        data={item}
        key={item.VeranstaltungID + index}
        hideDates={true}
        hideKLIPSLink={true}
        isEditMode={isEditMode}
      />
    );
  });
};

export default LecturesSorted;
