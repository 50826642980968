import { defineMessages } from 'react-intl';

const messages = defineMessages({
  labelLecture: {
    id: 'Lecture',
    defaultMessage: 'Lecture',
  },
  labelLSFLectureId: {
    id: 'Lecture ID',
    defaultMessage: 'Lecture ID',
  },
  descrLSFLectureId: {
    id: 'See `LV-Lennung` on the lecture page in KLIPS.',
    defaultMessage: 'See `LV-Lennung` on the lecture page in KLIPS.',
  },
  labelHideHeader: {
    id: 'Hide title',
    defaultMessage: 'Hide title',
  },
  labelHideComment: {
    id: 'Hide remark',
    defaultMessage: 'Hide remark',
  },
  labelDefault: {
    id: 'Default',
    defaultMessage: 'Default',
  },
});

const LectureBlockSchema = (intl) => {
  return {
    title: intl.formatMessage(messages.labelLecture),

    fieldsets: [
      {
        id: 'default',
        title: intl.formatMessage(messages.labelDefault),
        fields: ['lecture_id', 'hide_header', 'hide_comment'],
      },
    ],

    properties: {
      lecture_id: {
        title: intl.formatMessage(messages.labelLSFLectureId),
        description: intl.formatMessage(messages.descrLSFLectureId),
      },
      hide_header: {
        title: intl.formatMessage(messages.labelHideHeader),
        type: 'boolean',
      },
      hide_comment: {
        title: intl.formatMessage(messages.labelHideComment),
        type: 'boolean',
      },
    },

    required: ['lecture_id'],
  };
};

export default LectureBlockSchema;
