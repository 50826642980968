import { useEffect, useMemo, useState } from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { Button, Icon, Input, Table } from 'semantic-ui-react';
import _ from 'lodash';

const messages = defineMessages({
  search_for_name_phone_or_room: {
    id: 'Search for name, phone or room number.',
    defaultMessage: 'Search for name, phone or room number.',
  },
  call: {
    id: 'Call person',
    defaultMessage: 'Call person',
  },
  copy: {
    id: 'Copy',
    defaultMessage: 'Copy',
  },
  writeEmail: {
    id: 'Write email',
    defaultMessage: 'Write email',
  },
});

async function copyTextToClipboard(text) {
  if ('clipboard' in navigator) {
    return await navigator.clipboard.writeText(text);
  } else {
    return document.execCommand('copy', true, text);
  }
}

const PhoneLink = ({ person, intl }) => {
  const code = person.campus === 'KO' ? '261 287' : '6131 37460';
  const isAlreadyFullNr = person.num.startsWith('0');
  return (
    <span>
      {isAlreadyFullNr ? (
        person.num
      ) : (
        <>
          0{code} - <strong>{person.num}</strong>
        </>
      )}
      <Button
        icon
        href={
          isAlreadyFullNr
            ? `tel:${person.num}`
            : `tel:+49${code.replace(' ', '')}${person.num}`
        }
        title={intl.formatMessage(messages.call)}
        size="mini"
        floated="right"
      >
        <Icon name="phone" />
      </Button>
      <Button
        icon
        onClick={() => {
          isAlreadyFullNr
            ? copyTextToClipboard(person.num)
            : copyTextToClipboard(`0${code} ${person.num}`);
        }}
        title={intl.formatMessage(messages.copy)}
        size="mini"
        floated="right"
      >
        <Icon name="copy" />
      </Button>
    </span>
  );
};

const View = () => {
  const intl = useIntl();
  const [searchInput, setSearchInput] = useState('');
  const [results, setResults] = useState(false);

  const debouncedSearch = useMemo(
    () =>
      _.debounce((url) => {
        fetch(url)
          .then((res) => res.json())
          .then((data) => {
            const results = Object.entries(data)
              .map((entry) => entry[1])
              .sort((a, b) =>
                a.sortkey < b.sortkey ? -1 : a.sortkey > b.sortkey ? 1 : 0,
              );
            setResults(results);
          });
      }, 500),
    [],
  );

  useEffect(() => {
    if (searchInput.length > 0) {
      const url = `https://www.uni-koblenz.de/tel-json.cgi?action=s;search=${searchInput}`;
      debouncedSearch(url);
    }
  }, [searchInput, debouncedSearch]);

  const onClickMail = (mail) => {
    window.location.href = `mailto:${mail}`;
  };

  return (
    <div className="phone-book">
      <Input
        fluid
        placeholder={intl.formatMessage(messages.search_for_name_phone_or_room)}
        value={searchInput}
        onChange={(event) => setSearchInput(event.target.value)}
      />
      <div className="results">
        {results && searchInput.length > 0 ? (
          <Table celled selectable>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>
                  <FormattedMessage id="Name" defaultMessage="Name" />
                </Table.HeaderCell>
                <Table.HeaderCell>
                  <FormattedMessage id="Phone" defaultMessage="Phone" />
                </Table.HeaderCell>
                <Table.HeaderCell>
                  <FormattedMessage id="Room" defaultMessage="Room" />
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {results.map((entry, index) => (
                <Table.Row key={`row-res-${index}`}>
                  <Table.Cell>
                    {entry.name}, {entry.vname}
                    {entry.mail && (
                      <Button
                        icon
                        onClick={() => {
                          onClickMail(entry.mail);
                        }}
                        title={intl.formatMessage(messages.writeEmail)}
                        size="mini"
                        floated="right"
                      >
                        <Icon name="mail" />
                      </Button>
                    )}
                  </Table.Cell>
                  <Table.Cell>
                    <PhoneLink person={entry} intl={intl} />
                  </Table.Cell>
                  <Table.Cell>
                    {[entry.building || '', entry.room || ''].join(' ').trim()}
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        ) : (
          <FormattedMessage
            id="Start typing to search."
            defaultMessage="Start typing to search."
          />
        )}
      </div>
    </div>
  );
};

export default View;
