import { Button, Grid } from 'semantic-ui-react';
import { FormattedDate, UniversalLink } from '@plone/volto/components';
import { PersonCard } from '@package/components';
import Program from './Program';
/* we could use voltos `getSchema` action to retrieve it
   dynamically. But this endpoint is not available for anons,
   and causes uneccessary loading time.
   Can be updated like this:
      - edit a DegreeProgram
      - catch response of API call to `/@types/DegreeProgram`
      - (or use React components inspector on the form and copy value of `schema` props)
      - prettyfied with mohsen1.prettify-json
*/
import schemaDE from './certificate_program_schema_de.json';
import schemaEN from './certificate_program_schema_en.json';
import { messages, MetaBoxItem, ppValue } from './utils';

const MetaBoxCert = ({ content, intl, schema }) => {
  const isAbiRequired =
    content.admission_requirements.findIndex(
      (r) => r.toLowerCase().indexOf('abi') > -1,
    ) === -1;
  const isCertificate =
    schema.properties.title.behavior ===
    'uniko.types.content.certificate_program.ICertificateProgram';
  /* used to track Apply button clicks via Plausible
       (https://plausible.io/docs/custom-event-goals)
    */
  const plausibleClasses = [
    {
      key: 'plausible-event-name',
      val: 'Clicked+Apply',
    },
    {
      key: 'plausible-event-program',
      val: content.title.replaceAll(' ', '+'),
    },
    {
      key: 'plausible-event-degree',
      val: content.degree.map((d) => d.token).join(','),
    },
  ];
  return (
    <div
      className="metabox"
      style={content.color ? { backgroundColor: content.color } : {}}
    >
      <MetaBoxItem
        title={schema.properties.study_period.title}
        value={
          isCertificate
            ? content.study_period
            : `${content.study_period} ${intl.formatMessage(
                messages.semesters,
              )}`
        }
        intl={intl}
      />
      <MetaBoxItem
        title={'Ohne Abitur'}
        value={ppValue(isAbiRequired, intl)}
        intl={intl}
      />
      <MetaBoxItem
        title={intl.formatMessage(messages.format)}
        value={
          content.program_format?.length > 0
            ? content.program_format
            : intl.formatMessage(messages.in_presence)
        }
        intl={intl}
      />
      <div>
        <div>{schema.properties.program_start.title}</div>
        <div className="value">
          <FormattedDate date={content.program_start} />
        </div>
      </div>
      {content.cta_2_link && content.cta_2_label && (
        <UniversalLink href={content.cta_2_link}>
          <Button target="_blank">{content.cta_2_label}</Button>
        </UniversalLink>
      )}
      <UniversalLink
        href={
          content.cta_link
            ? content.cta_link
            : 'https://bewerbung.uni-koblenz.de'
        }
        target="_blank"
      >
        <Button
          className={plausibleClasses.map((i) => `${i.key}=${i.val}`).join(' ')}
        >
          {content?.cta_label
            ? content.cta_label
            : intl.formatMessage(messages.apply_program)}
        </Button>
      </UniversalLink>
    </div>
  );
};

const ContactZfuw = ({ content, intl }) => {
  const subject_advisory_service = content.subject_advisory_service?.items
    ?.filter((p) => p?.href?.length > 0 && p.href[0]['@id'])
    .map((p) => {
      return {
        id: p.href[0]['@id'],
        override_phone: p.override_phone,
        override_email: p.override_email,
      };
    });
  const advisors = content.advisors?.items
    ?.filter((p) => p?.href?.length > 0 && p.href[0]['@id'])
    .map((p) => {
      return {
        id: p.href[0]['@id'],
        override_phone: p.override_phone,
        override_email: p.override_email,
      };
    });
  return (
    <>
      <h2 id="contact">{intl.formatMessage(messages.contact)}</h2>
      {subject_advisory_service?.length > 0 && (
        <>
          <h3>Wissenschaftliche Leitung</h3>
          <Grid>
            <Grid.Row>
              {subject_advisory_service.map((p) => (
                <Grid.Column mobile={12} computer={6} key={`sas-${p.id}`}>
                  <PersonCard
                    person={{ '@id': p.id }}
                    hide-function={true}
                    hide-link={true}
                    override={{
                      ...(p.override_phone && {
                        telephone: {
                          items: [{ number: p.override_phone }],
                        },
                      }),
                      ...(p.override_email && {
                        email: p.override_email,
                      }),
                    }}
                  />
                </Grid.Column>
              ))}
            </Grid.Row>
          </Grid>
        </>
      )}
      {advisors?.length > 0 && (
        <>
          <h3>Koordination und Sachbearbeitung</h3>
          <Grid>
            <Grid.Row>
              {advisors.map((p) => (
                <Grid.Column mobile={12} computer={4} key={`adv-${p.id}`}>
                  <PersonCard
                    person={{ '@id': p.id }}
                    hide-function={true}
                    hide-link={true}
                    override={{
                      ...(p.override_phone && {
                        telephone: {
                          items: [{ number: p.override_phone }],
                        },
                      }),
                      ...(p.override_email && {
                        email: p.override_email,
                      }),
                    }}
                  />
                </Grid.Column>
              ))}
            </Grid.Row>
          </Grid>
        </>
      )}
    </>
  );
};

const CertificateProgram = ({ content, intl }) => {
  const simpleFields = [
    'program_format',
    'degree',
    'ects_credits',
    'study_period',
    'program_start',
    'application_deadline_sose',
    'application_deadline_wise',
    'application_deadline_sose_internationals',
    'application_deadline_wise_internationals',
    'teaching_language',
    'type_of_school',
    'school_subjects',
    'admission_restriction',
    'admission_requirements',
    'further_requirements',
    'tuition_fee',
  ];
  return (
    <Program
      content={content}
      intl={intl}
      schemaDE={schemaDE}
      schemaEN={schemaEN}
      MetaBox={MetaBoxCert}
      simpleFields={simpleFields}
      ContactSection={ContactZfuw}
    />
  );
};

export default CertificateProgram;
