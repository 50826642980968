/* stolen from https://github.com/kitconcept/volto-light-theme/blob/main/packages/volto-light-theme/src/components/Blocks/Image/Edit.jsx */
import React, { useState } from 'react';
import cx from 'classnames';
import ImageSidebar from '@plone/volto/components/manage/Blocks/Image/ImageSidebar';
import SidebarPortal from '@plone/volto/components/manage/Sidebar/SidebarPortal';

import { flattenToAppURL, isInternalURL } from '@plone/volto/helpers/Url/Url';
import { withBlockExtensions } from '@plone/volto/helpers/Extensions';
import config from '@plone/volto/registry';

import { ImageInput } from '@plone/volto/components/manage/Widgets/ImageWidget';
import { LightboxModal } from '@package/components';

function Edit(props) {
  const [lightboxOpen, setLightboxOpen] = useState(false);

  const { data } = props;
  const Image = config.getComponent({ name: 'Image' }).component;
  const onSelectItem = React.useCallback(
    (url, item) => {
      const dataAdapter = props.blocksConfig[props.data['@type']].dataAdapter;
      dataAdapter({
        block: props.block,
        data: props.data,
        onChangeBlock: props.onChangeBlock,
        id: 'url',
        value: url,
        item,
      });
    },
    [props],
  );

  const handleChange = React.useCallback(
    async (id, image, { title, image_field, image_scales } = {}) => {
      const url = image ? image['@id'] || image : '';

      props.onChangeBlock(props.block, {
        ...props.data,
        url: flattenToAppURL(url),
        image_field,
        image_scales,
        alt: props.data.alt || title || '',
      });
    },
    [props],
  );

  return (
    <>
      <div
        className={cx(
          'block image align',
          {
            center: !Boolean(data.align),
          },
          data.align,
        )}
      >
        {data.url ? (
          <figure
            className={cx({
              'full-width': data.align === 'full',
              large: data.size === 'l',
              medium: data.size === 'm',
              small: data.size === 's',
            })}
          >
            <Image
              item={
                data.image_scales
                  ? {
                      '@id': data.url,
                      image_field: data.image_field,
                      image_scales: data.image_scales,
                    }
                  : undefined
              }
              src={
                data.image_scales
                  ? undefined
                  : isInternalURL(data.url)
                  ? // Backwards compat in the case that the block is storing the full server URL
                    (() => {
                      if (data.size === 'l')
                        return `${flattenToAppURL(data.url)}/@@images/image`;
                      if (data.size === 'm')
                        return `${flattenToAppURL(
                          data.url,
                        )}/@@images/image/preview`;
                      if (data.size === 's')
                        return `${flattenToAppURL(
                          data.url,
                        )}/@@images/image/mini`;
                      return `${flattenToAppURL(data.url)}/@@images/image`;
                    })()
                  : data.url
              }
              sizes={config.blocks.blocksConfig.image.getSizes(data)}
              alt={data.alt || ''}
              loading="lazy"
              responsive={true}
            />
            {data.lightbox && (
              <LightboxModal
                src={flattenToAppURL(
                  data.url + '/' + data.image_scales?.image[0]?.download || '',
                )}
                caption={data.caption}
                open={lightboxOpen}
                setOpen={setLightboxOpen}
                indicatorPosition={data.align}
              />
            )}
            {props.data.caption && (
              <figcaption className="image-caption">
                {props.data.caption}
              </figcaption>
            )}
          </figure>
        ) : (
          <ImageInput
            onChange={handleChange}
            placeholderLinkInput={data.placeholder}
            block={props.block}
            id={props.block}
            objectBrowserPickerType={'image'}
            onSelectItem={onSelectItem}
          />
        )}
        <SidebarPortal selected={props.selected}>
          <ImageSidebar {...props} />
        </SidebarPortal>
      </div>
    </>
  );
}

export default withBlockExtensions(Edit);
