/**
 * Footer component.
 * @module components/theme/Footer/Footer
 */

import React from 'react';
import { Container, Segment } from 'semantic-ui-react';
import { Partial } from '@package/components';

/**
 * Component to display the footer.
 * @function Footer
 * @param {Object} intl Intl object
 * @returns {string} Markup of the component
 */
const Footer = () => {
  return (
    <>
      <Segment role="contentinfo" id="footer">
        <div className="content">
          <div className="left-outer"></div>
          <Container fluid>
            <Partial name="pfooter" />
          </Container>
          <div className="right-outer"></div>
        </div>
        <div className="meta">
          <Container>
            <Partial name="below-footer" />
          </Container>
        </div>
      </Segment>
    </>
  );
};

export default Footer;
