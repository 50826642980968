import React from 'react';
import { bool, func, string } from 'prop-types';
import { Container } from 'semantic-ui-react';
import { ConditionalLink, Icon } from '@plone/volto/components';
import arrowSVG from '@package/svgs/icons/arrow-uniko.svg';

const Item = React.memo(
  ({
    title,
    description,
    fullscreen, // fullscreen version of img
    handleImageLoaded,
    isFullscreen,
    onImageError,
    original,
    originalAlt,
    originalHeight,
    originalWidth,
    originalTitle,
    sizes,
    srcSet,
    link,
    isEditMode,
    showOverlays = true,
  }) => {
    const itemSrc = isFullscreen ? fullscreen || original : original;

    /* special case for Ersti slide */
    let descriptionClasses = ['image-gallery-description'];
    if (title === 'Erstmal ankommen!' && link.endsWith('/de/erstsemesterwoche'))
      descriptionClasses.push('bg-filled');

    return (
      <React.Fragment>
        <ConditionalLink to={link} condition={!isEditMode && link.length > 0}>
          <img
            className="image-gallery-image"
            src={itemSrc}
            alt={originalAlt}
            srcSet={srcSet}
            height={originalHeight}
            width={originalWidth}
            sizes={sizes}
            title={originalTitle}
            onLoad={(event) => handleImageLoaded(event, original)}
            onError={onImageError}
          />
          {showOverlays && title && (
            <Container>
              <div className={descriptionClasses.join(' ')}>
                <div className="image-gallery-description-arrow">
                  <span>
                    <Icon name={arrowSVG} className="link" color="white" />
                  </span>
                </div>
                <div className="image-gallery-description-text">
                  <span>{title}</span>
                  {description && (
                    <span className="subhead">{description}</span>
                  )}
                </div>
              </div>
            </Container>
          )}
        </ConditionalLink>
      </React.Fragment>
    );
  },
);

Item.displayName = 'Item';

Item.propTypes = {
  description: string,
  fullscreen: string, // fullscreen version of img
  handleImageLoaded: func.isRequired,
  isFullscreen: bool,
  onImageError: func.isRequired,
  original: string.isRequired,
  originalAlt: string,
  originalHeight: string,
  originalWidth: string,
  originalTitle: string,
  sizes: string,
  srcSet: string,
  link: string,
};

Item.defaultProps = {
  description: '',
  fullscreen: '',
  isFullscreen: false,
  originalAlt: '',
  originalHeight: '',
  originalWidth: '',
  originalTitle: '',
  sizes: '',
  srcSet: '',
};

export default Item;
