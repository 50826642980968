import { FormattedMessage, useIntl } from 'react-intl';
import { Message } from 'semantic-ui-react';
import { flattenToAppURL } from '@plone/volto/helpers';

const View = ({ data, isEditMode }) => {
  const intl = useIntl();
  const src = data.href
    ? flattenToAppURL(
        data.href.endsWith('/file')
          ? data.href
          : data.href + '/@@download/file',
      )
    : false;
  return src ? (
    <audio
      controls
      src={src}
      style={{
        width: (data.width || 'auto') === 'full' ? '100%' : '',
      }}
    >
      <a href={src}>
        <FormattedMessage
          id="Download audio file."
          defaultMessage="Download audio file."
        />
      </a>
      {/* Empty track element to indicate no subtitles/captions available */}
      <track kind="captions" src="" srcLang={intl.locale || 'de'} default />
    </audio>
  ) : (
    <Message warning>
      <FormattedMessage
        id="Please upload or select audio file."
        defaultMessage="Please upload or select audio file."
      />
    </Message>
  );
};

export default View;
