import { FormattedMessage } from 'react-intl';
import { Button, Container, Icon } from 'semantic-ui-react';
import { useHistory, useLocation } from 'react-router-dom';

const Error = ({ message }) => {
  let history = useHistory();
  let location = useLocation();
  const receiver = 'cms@uni-koblenz.de';
  const subject = `Fehler auf ${location.pathname}`;
  const body = `Fehlermeldung: ${message}`;
  let mailToContent = `mailto:${receiver}?subject=${encodeURIComponent(
    subject,
  )}&body=${encodeURIComponent(body)}`;
  return (
    <Container>
      <h2>
        <FormattedMessage
          id="Sorry, something went wrong with your request"
          defaultMessage="Sorry, something went wrong with your request&nbsp;"
        />
      </h2>
      <p>
        <FormattedMessage
          id="Please report this error by providing the URL of the affected page and a detailed description of previous activities."
          defaultMessage="Please report this error by providing the URL of the affected page and a detailed description of previous activities."
        />
      </p>
      <br />
      <Button primary href={mailToContent}>
        <Icon name="bell"></Icon>
        <FormattedMessage id="Report error" defaultMessage="Report error" />
      </Button>
      <br />
      <br />
      <br />
      <br />
      {!__SERVER__ && (
        <>
          <Button as="a" onClick={() => history.goBack()}>
            <Icon name="chevron left" />
            <FormattedMessage
              id="Navigate back"
              defaultMessage="Navigate back"
            />
          </Button>
          <br />
          <br />
        </>
      )}
      <Button as="a" href="/">
        <Icon name="home" />
        <FormattedMessage
          id="return to the site root"
          defaultMessage="return to the site root"
        />
      </Button>
    </Container>
  );
};

export default Error;
