import { defineMessages } from 'react-intl';

const messages = defineMessages({
  toc: {
    id: 'Table of Contents',
    defaultMessage: 'Table of Contents',
  },
  labelHeading: {
    id: 'Heading',
    defaultMessage: 'Heading',
  },
  labelPosition: {
    id: 'Position',
    defaultMessage: 'Position',
  },
  labelPosLeft: {
    id: 'Left',
    defaultMessage: 'Left',
  },
  labelPosRight: {
    id: 'Right',
    defaultMessage: 'Right',
  },
  labelFixed: {
    id: 'Fixed',
    defaultMessage: 'Fixed',
  },
  hintFixed: {
    id: 'hint_fixed',
    defaultMessage:
      'This positioning only works correctly if the table of contents is placed in a column that does not contain any other blocks and extends to the end of the page.',
  },
});

export const ToCSchema = ({ formData, intl }) => {
  return {
    title: intl.formatMessage(messages.toc),
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: ['heading', 'position'],
      },
    ],

    properties: {
      heading: {
        title: intl.formatMessage(messages.labelHeading),
      },
      position: {
        title: intl.formatMessage(messages.labelPosition),
        description:
          formData?.position === 'fixed'
            ? intl.formatMessage(messages.hintFixed)
            : '',
        type: 'string',
        factory: 'Choice',
        choices: [
          ['left', intl.formatMessage(messages.labelPosLeft)],
          ['right', intl.formatMessage(messages.labelPosRight)],
          ['fixed', intl.formatMessage(messages.labelFixed)],
        ],
      },
    },
    required: [],
  };
};

export default ToCSchema;
