import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { NavBoxes, NavPills } from '@package/components';
import { getAnchorSlugOfBlock } from '@package/helpers';

const View = ({ data, isEditMode, metadata, properties }) => {
  const content = metadata ? metadata : properties;
  let items = (data?.items || []).map((item) => {
    /* convert into format Quicklinks component understands */
    const linkedItem = item?.href?.[0] || false;
    const href = linkedItem
      ? linkedItem['@id']
      : item.anchor
      ? `#${getAnchorSlugOfBlock(item.anchor.id, content)}`
      : '#';
    const title = item?.title
      ? item.title
      : linkedItem
      ? linkedItem.Title
      : item.anchor?.title
      ? item.anchor.title
      : 'Placeholder';
    return {
      title: title,
      url: href,
    };
  });

  const crumbs = useSelector((state) => state.breadcrumbs.items);
  if (data?.show_breadcrumbs > 0) {
    items = items.concat(
      crumbs
        .slice()
        .reverse()
        .slice(1, parseInt(data.show_breadcrumbs) + 1),
    );
  }

  return items.length === 0 ? (
    <FormattedMessage
      id="No items added yet."
      defaultMessage="No items added yet."
    />
  ) : data.variant === 'boxes' ? (
    <NavBoxes items={items} disableLinks={isEditMode} />
  ) : (
    <NavPills
      items={items}
      disableLinks={isEditMode}
      position={data.position}
    />
  );
};

export default View;
