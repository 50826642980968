import React from 'react';
import { Link } from 'react-router-dom';
import { Card, Container, Image } from 'semantic-ui-react';
import { Icon } from '@plone/volto/components';
import { getContentIcon } from '@plone/volto/helpers';
import withPagination from '@package/components/Views/withPagination';

const AlbumItem = ({ item }) => {
  return (
    <div className="album-item">
      <Link to={item.url}>
        <Card>
          {item.image_field && (
            <Image
              src={`${item.url}/@@images/${item.image_field}/preview`}
              alt={item.title}
              wrapped
              ui={false}
            />
          )}
          <Card.Content>
            <Card.Header>{item.title}</Card.Header>
            <Card.Meta>
              <Icon
                name={getContentIcon(item['@type'], item.is_folderish)}
                size="20px"
                title={item['@type']}
              />
              {item['@type']}
            </Card.Meta>
            <Card.Description>{item.description}</Card.Description>
          </Card.Content>
          <Card.Content extra>{item.review_state || 'No state'}</Card.Content>
        </Card>
      </Link>
    </div>
  );
};

const AlbumView = (props) => {
  const { content } = props;
  if (!content.items) return <></>;
  return (
    <Container className="album-wrapper">
      {content.items.map((item, index) => {
        return <AlbumItem item={item} key={item['@id']} />;
      })}
    </Container>
  );
};

export default withPagination(AlbumView);
