import React from 'react';
import PropTypes from 'prop-types';
import { ConditionalLink, UniversalLink } from '@plone/volto/components';
import { flattenToAppURL } from '@plone/volto/helpers';
import { isInternalURL } from '@plone/volto/helpers/Url/Url';
import { SummaryItem } from '@package/components';

const DefaultTemplate = ({
  items,
  linkTitle,
  linkHref,
  isEditMode,
  cta_text,
  show_preview_images,
  hide_descriptions,
}) => {
  let link = null;
  let href = linkHref?.[0]?.['@id'] || '';

  if (isInternalURL(href)) {
    link = (
      <ConditionalLink to={flattenToAppURL(href)} condition={!isEditMode}>
        {linkTitle || href}
      </ConditionalLink>
    );
  } else if (href) {
    link = <UniversalLink href={href}>{linkTitle || href}</UniversalLink>;
  }

  return (
    <>
      <div className="items">
        {items.map((item) => (
          <SummaryItem
            key={item['@id']}
            item={item}
            isEditMode={isEditMode}
            ctaText={cta_text}
            hideImages={show_preview_images ? false : true}
            hideDescriptions={hide_descriptions}
          />
        ))}
      </div>

      {link && <div className="footer">{link}</div>}
    </>
  );
};
DefaultTemplate.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
  linkMore: PropTypes.any,
  isEditMode: PropTypes.bool,
};
export default DefaultTemplate;
