import { defineMessages, useIntl } from 'react-intl';
import { Card } from 'semantic-ui-react';
import { ConditionalLink } from '@plone/volto/components';
import { flattenToAppURL } from '@plone/volto/helpers';
import { injectLazyLibs } from '@plone/volto/helpers/Loadable/Loadable';

const messages = defineMessages({
  projectDuration: {
    id: 'Project duration',
    defaultMessage: 'Project duration',
  },
});

const ResearchProjectCard = ({
  project,
  linksDisabled = false,
  moment: momentlib,
}) => {
  const intl = useIntl();

  const moment = momentlib.default;
  moment.locale(intl.locale);

  const fStart = project.start ? moment(project.start).format('ll') : false;
  const fEnd = project.end ? moment(project.end).format('ll') : false;
  const durationStr = fStart && fEnd ? `${fStart} - ${fEnd}` : fStart;

  const url = flattenToAppURL(project['@id']);

  return (
    <Card className="project-card">
      <Card.Content>
        <ConditionalLink condition={!linksDisabled} to={url}>
          {project.title}
        </ConditionalLink>
        {project.acronym && <Card.Meta>{project.acronym}</Card.Meta>}
      </Card.Content>
      {durationStr && (
        <Card.Content
          extra
          title={intl.formatMessage(messages.projectDuration)}
        >
          {durationStr}
        </Card.Content>
      )}
    </Card>
  );
};

export default injectLazyLibs(['moment'])(ResearchProjectCard);
