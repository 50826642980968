import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';
import { BodyClass, Helmet, toPublicURL } from '@plone/volto/helpers';
import config from '@plone/volto/registry';
import { scrollToAnchor } from '@package/utils';
import JsonLd from './JsonLd/JsonLd';

const HelmetAdapter = ({ content }) => {
  const intl = useIntl();
  const location = useLocation();

  const titleTemplate =
    intl.locale === 'de'
      ? '%s | Universität Koblenz'
      : '%s | University of Koblenz';

  /* we have a content expansion endpoint for `customizations` */
  const customizations = useSelector(
    (state) =>
      (state.content?.data &&
        (state.content.data['@components'] ?? {}).customizations) ||
      false,
  );

  let locationClasses = false;
  if (customizations && customizations.additional_classes) {
    const key = Object.keys(customizations.additional_classes).find((k) =>
      location.pathname.startsWith(k),
    );
    locationClasses = key ? customizations.additional_classes[key] : false;
  }

  const description = content?.description ?? '';

  const meta = [{ name: 'description', content: description }];

  const subjects = content?.subjects ?? [];
  if (subjects.length > 0)
    meta.push({
      name: 'subjects',
      content: subjects.map((s) => s.title).join(', '),
    });

  if (location.hash?.length > 0 && !location.hash.startsWith('#query'))
    scrollToAnchor(location.hash.slice(1));

  /* include `preview_image` in open graph data as well
     (by default only field named `image` is considered)
     see: https://gitlab.uni-koblenz.de/cms-developer/volto-uniko/-/issues/706
  */
  const imageField = 'preview_image';
  const contentImageInfo =
    content && imageField && content[imageField]?.scales?.large
      ? {
          url: content[imageField].scales.large.download,
          width: content[imageField].scales.large.width,
          height: content[imageField].scales.large.height,
        }
      : false;

  /* if present, we set `gclid` and `conversion_event_time` as custom
     properties to our page view event, to allow offline conversions
     import into Google Ads.
     (see https://plausible.io/docs/custom-props/for-pageviews)
  */
  const params = new URLSearchParams(location.search);
  const gclid = params.get('gclid') || false;
  const gcEventTime = gclid ? Math.floor(Date.now() / 1000) : false;

  return (
    <>
      <Helmet titleTemplate={titleTemplate} meta={meta}>
        {config.settings.plausible && __CLIENT__ && (
          <script
            defer
            {...(gclid ? { 'event-gclid': gclid } : {})}
            {...(gcEventTime
              ? { 'event-conversion_event_time': gcEventTime }
              : {})}
            data-domain={config.settings.plausible.dataDomain || ''}
            src={config.settings.plausible.src || ''}
          ></script>
        )}
        {contentImageInfo && (
          <meta
            property="og:image"
            content={toPublicURL(contentImageInfo.url)}
          />
        )}
        {contentImageInfo && (
          <meta property="og:image:width" content={contentImageInfo.width} />
        )}
        {contentImageInfo && (
          <meta property="og:image:height" content={contentImageInfo.height} />
        )}
        <link rel="icon" href="favicon.ico" sizes="any" type="image/x-icon" />
      </Helmet>
      {locationClasses && (
        <BodyClass className={locationClasses.replaceAll(',', ' ')} />
      )}
      {content && <JsonLd content={content} />}
    </>
  );
};

export default HelmetAdapter;
