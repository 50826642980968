import { defineMessages } from 'react-intl';

const messages = defineMessages({
  labelSettings: {
    id: 'Settings',
    defaultMessage: 'Settings',
  },
  labelDefault: {
    id: 'Default',
    defaultMessage: 'Default',
  },
  labelItem: {
    id: 'Item',
    defaultMessage: 'Item',
  },
  labelAddItem: {
    id: 'Add item',
    defaultMessage: 'Add item',
  },
  labelItems: {
    id: 'Items',
    defaultMessage: 'Items',
  },
  labelNumber: {
    id: 'Value',
    defaultMessage: 'Value',
  },
  labelLabel: {
    id: 'Label',
    defaultMessage: 'Label',
  },
  labelThousandsSeparator: {
    id: 'Thousands separator',
    defaultMessage: 'Thousands separator',
  },
  labelIcon: {
    id: 'FontAwesome Icon',
    defaultMessage: 'FontAwesome Icon',
  },
  descrIcon: {
    id:
      'Full list of available icons (copy & paste name): https://react.semantic-ui.com/elements/icon',
    defaultMessage:
      'Full list of available icons (copy & paste name): https://react.semantic-ui.com/elements/icon',
  },
  labelVariant: {
    id: 'Variant',
    defaultMessage: 'Variant',
  },
  labelStats: {
    id: 'Stats',
    defaultMessage: 'Stats',
  },
  labelInfoBlocks: {
    id: 'Info blocks',
    defaultMessage: 'Info blocks',
  },
  labelColumnsCount: {
    id: 'Count of columns',
    defaultMessage: 'Count of columns',
  },
});

const ItemSchema = (intl) => {
  return {
    title: intl.formatMessage(messages.labelItem),
    addMessage: intl.formatMessage(messages.labelAddItem),

    fieldsets: [
      {
        id: 'default',
        title: intl.formatMessage(messages.labelDefault),
        fields: ['icon', 'number', 'label'],
      },
    ],

    properties: {
      icon: {
        title: intl.formatMessage(messages.labelIcon),
        description: intl.formatMessage(messages.descrIcon),
      },
      number: {
        title: intl.formatMessage(messages.labelNumber),
        type: 'string',
      },
      label: {
        title: intl.formatMessage(messages.labelLabel),
        type: 'string',
      },
    },

    required: ['number', 'label'],
  };
};

const StatsSchema = (intl) => {
  const MyItemSchema = ItemSchema(intl);
  return {
    title: intl.formatMessage(messages.labelSettings),

    fieldsets: [
      {
        id: 'default',
        title: intl.formatMessage(messages.labelDefault),
        fields: ['items', 'thousands_separator', 'variant', 'col_count'],
      },
    ],

    properties: {
      items: {
        title: intl.formatMessage(messages.labelItems),
        schema: MyItemSchema,
        widget: 'object_list',
      },
      thousands_separator: {
        title: intl.formatMessage(messages.labelThousandsSeparator),
        type: 'string',
        default: '.',
      },
      variant: {
        title: intl.formatMessage(messages.labelVariant),
        type: 'string',
        factory: 'Choice',
        choices: [
          ['stats', intl.formatMessage(messages.labelStats)],
          ['info-uli', intl.formatMessage(messages.labelInfoBlocks)],
        ],
        default: 'stats',
      },
      col_count: {
        title: intl.formatMessage(messages.labelColumnsCount),
        type: 'number',
        default: 4,
      },
    },

    required: [],
  };
};

export default StatsSchema;
