import { defineMessages } from 'react-intl';

const messages = defineMessages({
  labelEmbedFunction: {
    id: 'Embed person function',
    defaultMessage: 'Embed person function',
  },
  labelSelectFunction: {
    id: 'Select person function',
    defaultMessage: 'Select person function',
  },
  labelShowTitle: {
    id: 'Show function title',
    defaultMessage: 'Show function title',
  },
  labelShowDescription: {
    id: 'Show function description',
    defaultMessage: 'Show function description',
  },
  labelShowLink: {
    id: 'Function title is a link',
    defaultMessage: 'Function title is a link',
  },
  labelColumnsCount: {
    id: 'Count of columns',
    defaultMessage: 'Count of columns',
  },
  labelHideImage: {
    id: 'Hide image',
    defaultMessage: 'Hide image',
  },
  labelHideDescription: {
    id: 'Hide description',
    defaultMessage: 'Hide description',
  },
  labelHideRemark: {
    id: 'Hide remark',
    defaultMessage: 'Hide remark',
  },
  labelHideFunction: {
    id: 'Hide function',
    defaultMessage: 'Hide function',
  },
  cardsLayout: {
    id: 'Cards layout',
    defaultMessage: 'Cards layout',
  },
});

const PersonFunctionBlockSchema = (intl) => {
  return {
    title: intl.formatMessage(messages.labelEmbedFunction),

    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: ['href', 'show_title', 'show_description', 'show_link'],
      },
      {
        id: 'cards_layout',
        title: intl.formatMessage(messages.cardsLayout),
        fields: [
          'grid_columns',
          'hide_images',
          'hide_description',
          'hide_remark',
          'hide_function',
        ],
      },
    ],

    properties: {
      href: {
        title: intl.formatMessage(messages.labelSelectFunction),
        widget: 'object_browser',
        widgetOptions: {
          pattern_options: { selectableTypes: ['PersonFunction'] },
        },
        mode: 'link',
        selectedItemAttrs: ['Title', 'Description'],
      },
      show_title: {
        title: intl.formatMessage(messages.labelShowTitle),
        type: 'boolean',
      },
      show_description: {
        title: intl.formatMessage(messages.labelShowDescription),
        type: 'boolean',
      },
      show_link: {
        title: intl.formatMessage(messages.labelShowLink),
        type: 'boolean',
      },
      grid_columns: {
        title: intl.formatMessage(messages.labelColumnsCount),
        type: 'integer',
        default: 2,
      },
      hide_images: {
        title: intl.formatMessage(messages.labelHideImage),
        type: 'boolean',
        default: false,
      },
      hide_description: {
        title: intl.formatMessage(messages.labelHideDescription),
        type: 'boolean',
        default: false,
      },
      hide_remark: {
        title: intl.formatMessage(messages.labelHideRemark),
        type: 'boolean',
        default: true,
      },
      hide_function: {
        title: intl.formatMessage(messages.labelHideFunction),
        type: 'boolean',
        default: true,
      },
    },

    required: ['href'],
  };
};

export default PersonFunctionBlockSchema;
