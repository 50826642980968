import { EMAIL_NOTIFICATION } from '@plone/volto/constants/ActionTypes';

/* adapt to add parameter `path` to transmission to fix
   https://gitlab.uni-koblenz.de/cms-developer/volto-uniko/-/issues/649
*/
export function emailNotification(
  from,
  message,
  name,
  subject,
  path = false,
  error = false,
) {
  return {
    type: EMAIL_NOTIFICATION,
    request: {
      op: 'post',
      path: '/@email-notification',
      data: {
        from,
        message,
        name,
        subject,
        path,
        error,
      },
    },
  };
}
