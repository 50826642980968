import { defineMessages } from 'react-intl';

const messages = defineMessages({
  labelSettings: {
    id: 'RSS feed settings',
    defaultMessage: 'RSS feed settings',
  },
  labelUrl: {
    id: 'RSS feed URL',
    defaultMessage: 'RSS feed URL',
  },
  labelShowImages: {
    id: 'Show images',
    defaultMessage: 'Show images',
  },
  descrShowImages: {
    id: 'rss_show_images_descr',
    defaultMessage:
      'Using the first images in the (HTML) description of an entry.',
  },
  labelShowTitle: {
    id: 'Show feed title',
    defaultMessage: 'Show feed title',
  },
  labelLinkTitle: {
    id: 'Link feed title',
    defaultMessage: 'Link feed title',
  },
});

const RSSFeedSchema = (intl) => {
  return {
    title: intl.formatMessage(messages.labelSettings),

    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: ['rss_feed_url', 'show_images', 'show_title', 'link_title'],
      },
    ],

    properties: {
      rss_feed_url: {
        title: intl.formatMessage(messages.labelUrl),
      },
      show_images: {
        title: intl.formatMessage(messages.labelShowImages),
        description: intl.formatMessage(messages.descrShowImages),
        type: 'boolean',
        default: false,
      },
      show_title: {
        title: intl.formatMessage(messages.labelShowTitle),
        type: 'boolean',
        default: false,
      },
      link_title: {
        title: intl.formatMessage(messages.labelLinkTitle),
        type: 'boolean',
        default: false,
      },
    },
    required: [],
  };
};

export default RSSFeedSchema;
