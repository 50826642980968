import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { FormattedDate, UniversalLink } from '@plone/volto/components';
import { flattenToAppURL } from '@plone/volto/helpers';

const DownloadLink = ({ download }) => {
  if (download['@type'] === 'File')
    return (
      <a href={`${download['@id']}/@@download/file`}>
        <FormattedMessage id="Download" defaultMessage="Download" />
      </a>
    );

  return (
    <Link to={download['@id']} target="_blank">
      <FormattedMessage id="Download" defaultMessage="Download" />
    </Link>
  );
};

const Contact = ({ data }) => {
  return (
    <div className="contact meta-item">
      <span>{data?.title}</span>
      <b>{data?.name}</b>
      {data?.address && <div className="address">{data.address}</div>}
      {data.email && <a href={`mailto:${data.email}`}>{data.email}</a>}
      {data.phone && <a href={`tel:${data.phone}`}>{data.phone}</a>}
      {data.links?.length > 0 && (
        <div className="links">
          {data.links.split('\n').map((link) => (
            <UniversalLink href={link}>{link}</UniversalLink>
          ))}
        </div>
      )}
    </div>
  );
};

const View = ({ data, metadata }) => {
  const date = metadata?.currentFrom || metadata?.created || new Date();

  return (
    <div className="meta-post">
      <div className="date meta-item">
        <span>
          <FormattedMessage
            id="Date of publication"
            defaultMessage="Date of publication"
          />
        </span>
        <b>
          <FormattedDate date={date} />
        </b>
      </div>
      {data.author?.length > 0 && (
        <div className="author meta-item">
          <span>
            <FormattedMessage id="Author" defaultMessage="Author" />
          </span>
          <b>
            <Link to={flattenToAppURL(data.author[0]['@id'])}>
              {data.author[0].Title}
            </Link>
          </b>
        </div>
      )}
      {data.contacts?.length > 0 &&
        data.contacts.map((contact) => (
          <Contact key={contact['@id']} data={contact} />
        ))}
      {data.downloads?.length > 0 && (
        <div className="downloads meta-item">
          <span>
            <FormattedMessage id="Downloads" defaultMessage="Downloads" />
          </span>
          {data.downloads.map((download) => {
            return (
              <div key={download['@id']} className="download">
                <b>{download.Title}</b>
                <DownloadLink download={download} />
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default View;
