/**
 * Breadcrumbs components.
 * @module components/theme/Breadcrumbs/Breadcrumbs
 */

import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Link } from 'react-router-dom';
import { defineMessages, injectIntl } from 'react-intl';

import { Icon } from '@plone/volto/components';
import { getBreadcrumbs } from '@plone/volto/actions';
import { getBaseUrl, hasApiExpander } from '@plone/volto/helpers';
import { withContentNavigation } from '@plone/volto/components/theme/Navigation/withContentNavigation';
import homeSVG from '@plone/volto/icons/home.svg';

import arrowSVG from '@package/svgs/icons/arrow-uniko.svg';

const messages = defineMessages({
  home: {
    id: 'Home',
    defaultMessage: 'Home',
  },
  breadcrumbs: {
    id: 'Breadcrumbs',
    defaultMessage: 'Breadcrumbs',
  },
});

/**
 * Breadcrumbs container class.
 */
export class BreadcrumbsComponent extends Component {
  state = {
    showChildren: false,
  };

  /**
   * Property types.
   * @property {Object} propTypes Property types.
   * @static
   */
  static propTypes = {
    getBreadcrumbs: PropTypes.func.isRequired,
    pathname: PropTypes.string.isRequired,
    root: PropTypes.string,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        url: PropTypes.string,
      }),
    ).isRequired,
  };

  constructor(props) {
    super(props);
    this.wheelListenerRegistered = false;
    this.handleWheel = this.handleWheel.bind(this);
  }

  handleWheel(evt) {
    /* We need this custom listener since the scrollbar is actually a
       horizontal scrollbar (rotated via CSS), which means usual mouse wheel
       scroll behavior does not apply here.
    */
    evt.preventDefault();

    const el = ReactDOM.findDOMNode(this);
    if (el) el.scrollLeft -= evt.deltaY;
  }

  componentDidMount() {
    if (!hasApiExpander('breadcrumbs', getBaseUrl(this.props.pathname))) {
      this.props.getBreadcrumbs(getBaseUrl(this.props.pathname));
    }
  }

  componentWillUnmount() {
    const el = ReactDOM.findDOMNode(this);
    if (el) {
      el.removeEventListener('wheel', this.handleWheel);
      this.wheelListenerRegistered = false;
    }
  }

  componentDidUpdate() {
    const el = ReactDOM.findDOMNode(this);
    if (el) {
      el.removeEventListener('wheel', this.handleWheel);
      el.addEventListener('wheel', this.handleWheel);
    }
  }

  /**
   * Component will receive props
   * @method componentWillReceiveProps
   * @param {Object} nextProps Next properties
   * @returns {undefined}
   */
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.pathname !== this.props.pathname) {
      if (!hasApiExpander('breadcrumbs', getBaseUrl(this.props.pathname))) {
        this.props.getBreadcrumbs(getBaseUrl(nextProps.pathname));
      }
    }
  }

  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
  render() {
    if (this.props.items.length < 2) return <></>;
    const reverseItems = [];
    for (let i = this.props.items.length - 1; i >= 0; i--) {
      reverseItems.push(this.props.items[i]);
    }

    const formatTitle = (title) => {
      const lower = title.toLowerCase();
      if (lower.indexOf('fachbereich') > -1 || lower.indexOf('faculty') > -1)
        return title.split(' ').slice(0, 2).join(' ').replace(':', '');
      const maxLen = 32;
      if (title.length <= maxLen) return title;
      return title.slice(0, maxLen) + ' ...';
    };

    return (
      <div
        id="vertical-breadcrumbs"
        className="breadcrumbs"
        role="navigation"
        aria-label={this.props.intl.formatMessage(messages.breadcrumbs)}
      >
        {reverseItems.map((item, index) => {
          if (index === 0 || item.exclude_from_nav)
            return <React.Fragment key={index} />;
          return (
            <React.Fragment key={index}>
              <Link to={item.url} title={item.title}>
                {formatTitle(item.title)}
              </Link>
              <Icon className="divider" name={arrowSVG} />
            </React.Fragment>
          );
        })}
        <Link
          to={this.props.root || '/'}
          className="section"
          title={this.props.intl.formatMessage(messages.home)}
        >
          <Icon name={homeSVG} size="18px" />
        </Link>
      </div>
    );
  }
}

const Breadcrumbs = compose(
  injectIntl,
  connect(
    (state) => ({
      items: state.breadcrumbs.items,
      root: state.breadcrumbs.root,
      content: state.content.data,
      subrequests: state.content.subrequests,
    }),
    { getBreadcrumbs },
  ),
)(BreadcrumbsComponent);

const UniKoBreadcrumbs = (props) => {
  return <Breadcrumbs params={{ currentFolderOnly: true }} {...props} />;
};

export default UniKoBreadcrumbs;
