import { defineMessages } from 'react-intl';

const messages = defineMessages({
  Default: {
    id: 'default',
    defaultMessage: 'Default',
  },
  Tabs: {
    defaultMessage: 'Tabs',
    id: 'tabs',
  },
  TabsBlock: {
    defaultMessage: 'Tabs block',
    id: 'tabs-block',
  },
  Title: {
    defaultMessage: 'Title',
    id: 'title',
  },
});

export const tabSchema = (intl) => {
  return {
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: ['title'],
      },
    ],

    properties: {
      title: {
        title: intl.formatMessage(messages.Title),
      },
    },
    required: [],
  };
};

export const blockSchema = (intl) => {
  return {
    title: intl.formatMessage(messages.TabsBlock),
    fieldsets: [
      {
        id: 'default',
        title: intl.formatMessage(messages.Default),
        fields: ['data'],
      },
    ],
    properties: {
      data: {
        title: intl.formatMessage(messages.Tabs),
        type: 'tabs',
        schema: tabSchema(intl),
      },
    },
    required: [],
  };
};
