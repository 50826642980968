import { useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { PersonCard } from '@package/components';
import { Grid, Input } from 'semantic-ui-react';

const messages = defineMessages({
  labelSearch: {
    id: 'Search',
    defaultMessage: 'Search',
  },
});

const PersonsListing = ({ items, isEditMode }) => {
  const intl = useIntl();
  const [searchInput, setSearchInput] = useState('');

  const searchFilter = (p) => {
    if (searchInput.length === 0) return true;
    const s = searchInput.toUpperCase();
    return (
      (p.prename || '').toUpperCase().indexOf(s) > -1 ||
      (p.surname || '').toUpperCase().indexOf(s) > -1
    );
  };

  let persons = items.filter((i) => i['@type'] === 'Person' && searchFilter(i));
  persons.sort((a, b) =>
    a.surname < b.surname ? -1 : a.surname > b.surname ? 1 : 0,
  );
  return (
    <>
      <Input
        fluid
        placeholder={intl.formatMessage(messages.labelSearch)}
        type="text"
        value={searchInput}
        onChange={(event) => setSearchInput(event.target.value)}
      />
      <div className="items persons">
        <Grid doubling stackable>
          {persons.map((person) => (
            <Grid.Column width={6}>
              <PersonCard
                additional-classes={['compact']}
                person={person}
                link-disabled={isEditMode}
                hide-contact={true}
                hide-description={true}
                hide-function={true}
                hide-image={true}
                hide-link={true}
                hide-remark={true}
                hide-titles={true}
                no-hydration={true}
                whole-link={true}
              />
            </Grid.Column>
          ))}
        </Grid>
      </div>
    </>
  );
};

export default PersonsListing;
