import React from 'react';
import PropTypes from 'prop-types';
import { ConditionalLink } from '@plone/volto/components';
import { flattenToAppURL } from '@plone/volto/helpers';
import { isInternalURL } from '@plone/volto/helpers/Url/Url';
import DefaultImageSVG from '@package/svgs/logos/uk.svg';
import moment from 'moment';

const NewsItemsListing = ({
  items,
  linkTitle,
  linkHref,
  show_images = false,
  isEditMode,
}) => {
  let link = null;
  let href = linkHref?.[0]?.['@id'] || '';

  if (isInternalURL(href)) {
    link = (
      <ConditionalLink to={flattenToAppURL(href)} condition={!isEditMode}>
        {linkTitle || href}
      </ConditionalLink>
    );
  } else if (href) {
    link = <a href={href}>{linkTitle || href}</a>;
  }

  return (
    <>
      <div className="items news">
        {items.map((item) => {
          let date2display = moment(item.currentFrom || item.Date) || false;
          if (!date2display.isValid()) date2display = false;
          else date2display = date2display.format('ll');
          const imgSrc = item.external_img_src
            ? item.external_img_src
            : item.image_field
            ? flattenToAppURL(
                `${item['@id']}/@@images/${item.image_field}/preview`,
              )
            : false;
          return (
            date2display && (
              <div className="listing-item" key={item['@id']}>
                <ConditionalLink item={item} condition={!isEditMode}>
                  {show_images && !imgSrc && (
                    <img src={DefaultImageSVG} alt="" title="" />
                  )}
                  {show_images && imgSrc && (
                    <img src={imgSrc} alt={item.title} />
                  )}
                  <div className="listing-body">
                    {date2display && <span>{date2display}</span>}
                    <span className="title">
                      {item.title ? item.title : item.id}
                    </span>
                    <p>{item.description}</p>
                  </div>
                </ConditionalLink>
              </div>
            )
          );
        })}
      </div>

      {link && <div className="footer">{link}</div>}
    </>
  );
};

NewsItemsListing.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
  linkMore: PropTypes.any,
  isEditMode: PropTypes.bool,
};

export default NewsItemsListing;
