/* Shadow original component to add
   DeepL-translation icon to the sidebar
   and implement corresponding functionality.
*/
import { useEffect, useState } from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import {
  Button,
  Dimmer,
  Header,
  Icon as SemanticUiIcon,
  Modal,
} from 'semantic-ui-react';
// eslint-disable-next-line import/no-unresolved
import TranslationObjectOrig from '@plone/volto-original/components/manage/Multilingual/TranslationObject';
import { setFormData } from '@plone/volto/actions';
import { Icon, Toast } from '@plone/volto/components';
import { flattenToAppURL } from '@plone/volto/helpers';
import { translate } from '@package/actions';
import DeepLSVG from '@package/svgs/icons/deepl.svg';

const SESS_STORAGE_DEEPL_HINT_ACCEPTED = 'uniko_deepl_hint_accepted';

const messages = defineMessages({
  translate: {
    id: 'Translate with DeepL',
    defaultMessage: 'Translate with DeepL',
  },
});

const TranslationObject = (props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isTranslated, setIsTranslated] = useState(null);
  const intl = useIntl();

  const dispatch = useDispatch();
  const translateRequest = useSelector((state) => state.translate);

  const path = flattenToAppURL(props.translationObject['@id']);
  const translationSubRequest = translateRequest.subrequests[path] || false;

  const doTranslate = () => {
    if (
      translationSubRequest === false ||
      translationSubRequest.loading === false
    ) {
      dispatch(translate(path));
      setIsTranslated(false);
    }
  };

  const formData = useSelector((state) => state.form?.global || {});
  const hasLoaded = translationSubRequest?.loaded;

  if (translationSubRequest.error) {
    toast.error(
      <Toast
        info
        title={`Error ${translationSubRequest.error.response.statusCode}`}
        content={translationSubRequest.error.message}
      />,
      {
        toastId: 'translation-error-toast',
        autoClose: false,
        closeButton: false,
        transition: null,
      },
    );
  }

  useEffect(() => {
    if (hasLoaded && isTranslated === false) {
      /* merge translation data into existing formData */
      dispatch(
        setFormData({
          ...formData,
          ...translationSubRequest.translation,
        }),
      );
      setIsTranslated(true);
      if (!sessionStorage.getItem(SESS_STORAGE_DEEPL_HINT_ACCEPTED)) {
        setIsModalOpen(true);
        sessionStorage.setItem(SESS_STORAGE_DEEPL_HINT_ACCEPTED, true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasLoaded, isTranslated]);

  const onCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <TranslationObjectOrig {...props} />
      {/* wrapper to move dimmer from the translation object
          to the editable content (which will receive the translation)
       */}
      <div
        style={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          left: '100%',
          top: '0',
        }}
      >
        <Dimmer
          active={translationSubRequest?.loading}
          style={{ left: '100% !important' }}
        >
          <Header as="h2" icon inverted>
            <Icon name={DeepLSVG} size="64px" />{' '}
            <FormattedMessage id="Translating" defaultMessage="Translating" />{' '}
            ...
          </Header>
        </Dimmer>
      </div>
      {/* dont use a Plug to the sidebar here, since this prevents following button
          to re-render properly to update the `doTranslate` function,
          when path has changed.
      */}
      <div
        style={{
          position: 'absolute',
          top: '-35px',
          left: 'calc(100% - 105px)',
          width: '100%',
          zIndex: 1,
        }}
      >
        <button
          key={path}
          onClick={doTranslate}
          title={intl.formatMessage(messages.translate)}
          style={{
            alignItems: 'center',
            background: '#0F2B46',
            border: 'none',
            borderRadius: '15px',
            color: 'white',
            columnGap: '10px',
            cursor: 'pointer',
            display: 'flex',
            fontSize: '15px',
            padding: '12px',
            maxWidth: '200px',
          }}
        >
          <Icon name={DeepLSVG} />
          <div>
            <FormattedMessage
              id="Translate with DeepL"
              defaultMessage="Translate with DeepL"
            />
          </div>
          <SemanticUiIcon name="arrow right" />
        </button>
      </div>
      <Modal dimmer open={isModalOpen}>
        <Modal.Header>
          <FormattedMessage
            id="Automated translation"
            defaultMessage="Automated translation"
          />
        </Modal.Header>
        <Modal.Content>
          <p>
            Bitte überprüfen Sie die automatisert übersetzten Inhalte, bevor Sie
            die Änderung übernehmen!
          </p>
          <p>
            Please check the automatically translated content before applying
            the changes!
          </p>
        </Modal.Content>
        <Modal.Actions>
          <Button positive onClick={onCloseModal}>
            Verstanden / Understood
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  );
};

export default TranslationObject;
