import { useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import FullCalendar from '@fullcalendar/react';
import iCalendarPlugin from '@fullcalendar/icalendar';
import timeGridPlugin from '@fullcalendar/timegrid';
import { Loader } from 'semantic-ui-react';
import { isUrl } from '@plone/volto/helpers/Url/Url';
import EventsCarousel from '@package/components/ListingVariations/EventsCarousel';

/* uses FullCalendar component only to fetch and parse the .ics file. */
const Events = ({ data, isEditMode }) => {
  const [events, setEvents] = useState(false);
  const calendarRef = useRef(null);

  useEffect(() => {
    /* toggle reload when block data changes */
    setEvents(false);
  }, [data]);

  let isFullCalendarLoading = false;
  const onLoading = (isLoading) => {
    if (isLoading === false) {
      isFullCalendarLoading = false;
      setTimeout(() => {
        if (!isFullCalendarLoading && calendarRef.current) {
          let remoteEvents = calendarRef.current.getApi().getEvents();
          setEvents(
            remoteEvents
              .map((event) => {
                return {
                  '@id': event.url,
                  title: event.title,
                  location: event.extendedProps?.location,
                  description: event.extendedProps?.description,
                  start: event.start,
                  /* Plone events call that attribute `whole_day` */
                  whole_day: event.allDay,
                };
              })
              .sort((a, b) =>
                a.start < b.start ? -1 : a.start > b.start ? 1 : 0,
              ),
          );
        }
      });
    } else {
      isFullCalendarLoading = true;
    }
  };

  let start = new Date();
  if (!data.only_upcoming) start.setFullYear(start.getFullYear() - 1);
  let end = new Date();
  end.setFullYear(end.getFullYear() + 1);
  const fcOptions = {
    plugins: [timeGridPlugin, iCalendarPlugin],
    initialView: 'timeGrid',
    visibleRange: {
      start: start,
      end: end,
    },
  };

  /* server-side rendering with FullCalendar does not work here,
     so we need to render after client-side hydration - as described here:
     https://gist.github.com/gaearon/e7d97cdf38a2907924ea12e4ebdf3c85#option-2-lazily-show-component-with-uselayouteffect
  */
  const [isClientSide, setIsClientSide] = useState(false);
  useEffect(() => {
    setIsClientSide(true);
  }, []);

  const displayEvents = events
    ? data.limit
      ? events.slice(0, data.limit)
      : events
    : [];

  return events !== false ? (
    <div className="block events">
      {displayEvents.length > 0 ? (
        <EventsCarousel
          items={displayEvents}
          isEditMode={isEditMode}
          show_time={data.show_time}
          show_description={data.show_description}
          no_infinity_scroll={data.no_infinity_scroll}
        />
      ) : (
        <FormattedMessage
          id="No events found."
          defaultMessage="No events found."
        />
      )}
    </div>
  ) : (
    <>
      <Loader size="large" inline="centered" active>
        <FormattedMessage
          id="Loading events ..."
          defaultMessage="Loading events ..."
        />
      </Loader>
      {isClientSide && isUrl(data.ics_url) && (
        <div style={{ display: 'none' }}>
          <FullCalendar
            ref={calendarRef}
            events={{
              url: data.ics_url,
              format: 'ics',
            }}
            loading={(isLoading) => onLoading(isLoading)}
            {...fcOptions}
          />
        </div>
      )}
    </>
  );
};

export default Events;
