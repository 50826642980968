/**
 * Mixture of EditImageBlock and EditHeroBlock:
 *  > Card-Header is an image
 *  > Card Title & Description are live editable
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { readAsDataURL } from 'promise-file-reader';
import {
  Button,
  Card,
  Dimmer,
  Input,
  Loader,
  Message,
} from 'semantic-ui-react';
import { defineMessages, injectIntl } from 'react-intl';
import loadable from '@loadable/component';
import { isEqual } from 'lodash';

import { Icon, SidebarPortal } from '@plone/volto/components';
import { createContent } from '@plone/volto/actions';
import { flattenToAppURL, getBaseUrl } from '@plone/volto/helpers';

import imageBlockSVG from '@plone/volto/components/manage/Blocks/Image/block-image.svg';
import clearSVG from '@plone/volto/icons/clear.svg';
import navTreeSVG from '@plone/volto/icons/nav.svg';
import aheadSVG from '@plone/volto/icons/ahead.svg';
import uploadSVG from '@plone/volto/icons/upload.svg';

/* additional imports */
import { Map } from 'immutable';

import InlineForm from '@plone/volto/components/manage/Form/InlineForm';
import ImageCardBlockSchema from './schema';

import { stateFromHTML } from 'draft-js-import-html';
import { Editor, DefaultDraftBlockRenderMap, EditorState } from 'draft-js';

const Dropzone = loadable(() => import('react-dropzone'));

const messages = defineMessages({
  ImageBlockInputPlaceholder: {
    id: 'Browse the site, drop an image, or type an URL',
    defaultMessage: 'Browse the site, drop an image, or type an URL',
  },
  title: {
    id: 'Title',
    defaultMessage: 'Title',
  },
  description: {
    id: 'Description',
    defaultMessage: 'Description',
  },
});

const blockTitleRenderMap = Map({
  unstyled: {
    element: 'span',
  },
});

const blockDescriptionRenderMap = Map({
  unstyled: {
    element: 'span',
  },
});

const extendedBlockRenderMap = DefaultDraftBlockRenderMap.merge(
  blockTitleRenderMap,
);

const extendedDescripBlockRenderMap = DefaultDraftBlockRenderMap.merge(
  blockDescriptionRenderMap,
);

class Edit extends Component {
  static propTypes = {
    selected: PropTypes.bool.isRequired,
    block: PropTypes.string.isRequired,
    index: PropTypes.number.isRequired,
    data: PropTypes.objectOf(PropTypes.any).isRequired,
    request: PropTypes.shape({
      loading: PropTypes.bool,
      loaded: PropTypes.bool,
    }).isRequired,
    pathname: PropTypes.string.isRequired,
    onChangeBlock: PropTypes.func.isRequired,
    onSelectBlock: PropTypes.func.isRequired,
    onDeleteBlock: PropTypes.func.isRequired,
    onFocusPreviousBlock: PropTypes.func.isRequired,
    onFocusNextBlock: PropTypes.func.isRequired,
    handleKeyDown: PropTypes.func.isRequired,
    createContent: PropTypes.func.isRequired,
    openObjectBrowser: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.onUploadImage = this.onUploadImage.bind(this);
    this.state = {
      uploading: false,
    };

    if (!__SERVER__) {
      let titleEditorState;
      let descriptionEditorState;
      if (props.data && props.data.title) {
        titleEditorState = EditorState.createWithContent(
          stateFromHTML(props.data.title),
        );
      } else {
        titleEditorState = EditorState.createEmpty();
      }
      if (props.data && props.data.description) {
        descriptionEditorState = EditorState.createWithContent(
          stateFromHTML(props.data.description),
        );
      } else {
        descriptionEditorState = EditorState.createEmpty();
      }
      this.state = {
        uploading: false,
        url: '',
        dragging: false,
        titleEditorState,
        descriptionEditorState,
        currentFocused: 'title',
      };
    }

    this.onChangeTitle = this.onChangeTitle.bind(this);
    this.onChangeDescription = this.onChangeDescription.bind(this);
  }

  componentDidMount() {
    if (this.props.selected) {
      this.titleEditor.focus();
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      this.props.request.loading &&
      nextProps.request.loaded &&
      this.state.uploading
    ) {
      this.setState({
        uploading: false,
      });
      this.props.onChangeBlock(this.props.block, {
        ...this.props.data,
        url: nextProps.content['@id'],
        alt: '',
      });
    }

    if (
      nextProps.data.title &&
      this.props.data.title !== nextProps.data.title &&
      !this.props.selected
    ) {
      const contentState = stateFromHTML(nextProps.data.title);
      this.setState({
        titleEditorState: nextProps.data.title
          ? EditorState.createWithContent(contentState)
          : EditorState.createEmpty(),
      });
    }

    if (
      nextProps.data.description &&
      this.props.data.description !== nextProps.data.description &&
      !this.props.selected
    ) {
      const contentState = stateFromHTML(nextProps.data.description);
      this.setState({
        descriptionEditorState: nextProps.data.description
          ? EditorState.createWithContent(contentState)
          : EditorState.createEmpty(),
      });
    }

    if (nextProps.selected !== this.props.selected) {
      if (this.state.currentFocused === 'title') {
        this.titleEditor.focus();
      } else {
        this.descriptionEditor.focus();
      }
    }
  }

  shouldComponentUpdate(nextProps) {
    return (
      this.props.selected ||
      nextProps.selected ||
      !isEqual(this.props.data, nextProps.data)
    );
  }

  onChangeTitle(titleEditorState) {
    this.setState({ titleEditorState }, () => {
      this.props.onChangeBlock(this.props.block, {
        ...this.props.data,
        title: titleEditorState.getCurrentContent().getPlainText(),
      });
    });
  }

  onChangeDescription(descriptionEditorState) {
    this.setState({ descriptionEditorState }, () => {
      this.props.onChangeBlock(this.props.block, {
        ...this.props.data,
        description: descriptionEditorState.getCurrentContent().getPlainText(),
      });
    });
  }

  onUploadImage = (e) => {
    e.stopPropagation();
    const file = e.target.files[0];
    this.setState({
      uploading: true,
    });
    readAsDataURL(file).then((data) => {
      const fields = data.match(/^data:(.*);(.*),(.*)$/);
      this.props.createContent(
        getBaseUrl(this.props.pathname),
        {
          '@type': 'Image',
          title: file.name,
          image: {
            data: fields[3],
            encoding: fields[2],
            'content-type': fields[1],
            filename: file.name,
          },
        },
        this.props.block,
      );
    });
  };

  /**
   * Change url handler
   * @method onChangeUrl
   * @param {Object} target Target object
   * @returns {undefined}
   */
  onChangeUrl = ({ target }) => {
    this.setState({
      url: target.value,
    });
  };

  /**
   * Submit url handler
   * @method onSubmitUrl
   * @param {object} e Event
   * @returns {undefined}
   */
  onSubmitUrl = () => {
    this.props.onChangeBlock(this.props.block, {
      ...this.props.data,
      url: this.state.url,
    });
  };

  resetSubmitUrl = () => {
    this.setState({
      url: '',
    });
  };

  /**
   * Drop handler
   * @method onDrop
   * @param {array} files File objects
   * @returns {undefined}
   */
  onDrop = (file) => {
    this.setState({
      uploading: true,
    });

    readAsDataURL(file[0]).then((data) => {
      const fields = data.match(/^data:(.*);(.*),(.*)$/);
      this.props.createContent(
        getBaseUrl(this.props.pathname),
        {
          '@type': 'Image',
          title: file[0].name,
          image: {
            data: fields[3],
            encoding: fields[2],
            'content-type': fields[1],
            filename: file[0].name,
          },
        },
        this.props.block,
      );
    });
  };

  /**
   * Keydown handler on Variant Menu Form
   * This is required since the ENTER key is already mapped to a onKeyDown
   * event and needs to be overriden with a child onKeyDown.
   * @method onKeyDownVariantMenuForm
   * @param {Object} e Event object
   * @returns {undefined}
   */
  onKeyDownVariantMenuForm = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      e.stopPropagation();
      this.onSubmitUrl();
    } else if (e.key === 'Escape') {
      e.preventDefault();
      e.stopPropagation();
      // TODO: Do something on ESC key
    }
  };
  onDragEnter = () => {
    this.setState({ dragging: true });
  };
  onDragLeave = () => {
    this.setState({ dragging: false });
  };

  node = React.createRef();

  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
  render() {
    const schema = ImageCardBlockSchema(this.props.intl);
    const { data } = this.props;
    const placeholder =
      this.props.data.placeholder ||
      this.props.intl.formatMessage(messages.ImageBlockInputPlaceholder);
    return (
      <div className="block image align center">
        <Card>
          {data.url ? (
            <>
              <div className="toolbar">
                <Button.Group>
                  <Button
                    icon
                    basic
                    onClick={() =>
                      this.props.onChangeBlock(this.props.block, {
                        ...this.props.data,
                        url: '',
                      })
                    }
                  >
                    <Icon name={clearSVG} size="24px" color="#e40166" />
                  </Button>
                </Button.Group>
              </div>
              <img
                src={`${flattenToAppURL(data.url)}/@@images/image`}
                alt={data.alt || ''}
              />
            </>
          ) : (
            <div>
              {this.props.editable && (
                <Dropzone
                  noClick
                  onDrop={this.onDrop}
                  onDragEnter={this.onDragEnter}
                  onDragLeave={this.onDragLeave}
                  className="dropzone"
                >
                  {({ getRootProps, getInputProps }) => (
                    <div {...getRootProps()}>
                      <Message>
                        {this.state.dragging && <Dimmer active></Dimmer>}
                        {this.state.uploading && (
                          <Dimmer active>
                            <Loader indeterminate>Uploading image</Loader>
                          </Dimmer>
                        )}
                        <div className="no-image-wrapper">
                          <img src={imageBlockSVG} alt="" />
                          <div className="toolbar-inner">
                            <Button.Group>
                              <Button
                                basic
                                icon
                                onClick={(e) => {
                                  e.stopPropagation();
                                  e.preventDefault();
                                  this.props.openObjectBrowser();
                                }}
                              >
                                <Icon name={navTreeSVG} size="24px" />
                              </Button>
                            </Button.Group>
                            <Button.Group>
                              <label className="ui button basic icon">
                                <Icon name={uploadSVG} size="24px" />
                                <input
                                  {...getInputProps({
                                    type: 'file',
                                    onChange: this.onUploadImage,
                                    style: { display: 'none' },
                                  })}
                                />
                              </label>
                            </Button.Group>
                            <Input
                              onKeyDown={this.onKeyDownVariantMenuForm}
                              onChange={this.onChangeUrl}
                              placeholder={placeholder}
                              value={this.state.url}
                              onClick={(e) => {
                                e.target.focus();
                              }}
                              onFocus={(e) => {
                                this.props.onSelectBlock(this.props.id);
                              }}
                            />
                            {this.state.url && (
                              <Button.Group>
                                <Button
                                  basic
                                  className="cancel"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    this.setState({ url: '' });
                                  }}
                                >
                                  <Icon name={clearSVG} size="30px" />
                                </Button>
                              </Button.Group>
                            )}
                            <Button.Group>
                              <Button
                                basic
                                primary
                                disabled={!this.state.url}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  this.onSubmitUrl();
                                }}
                              >
                                <Icon name={aheadSVG} size="30px" />
                              </Button>
                            </Button.Group>
                          </div>
                        </div>
                      </Message>
                    </div>
                  )}
                </Dropzone>
              )}
            </div>
          )}
          <Card.Content>
            <Card.Header>
              <Editor
                ref={(node) => {
                  this.titleEditor = node;
                }}
                readOnly={!this.props.editable}
                onChange={this.onChangeTitle}
                editorState={this.state.titleEditorState}
                blockRenderMap={extendedBlockRenderMap}
                handleReturn={() => true}
                placeholder={this.props.intl.formatMessage(messages.title)}
                blockStyleFn={() => 'title-editor'}
                onUpArrow={() => {
                  const selectionState = this.state.titleEditorState.getSelection();
                  const { titleEditorState } = this.state;
                  if (
                    titleEditorState
                      .getCurrentContent()
                      .getBlockMap()
                      .first()
                      .getKey() === selectionState.getFocusKey()
                  ) {
                    this.props.onFocusPreviousBlock(
                      this.props.block,
                      this.props.blockNode.current,
                    );
                  }
                }}
                onDownArrow={() => {
                  const selectionState = this.state.titleEditorState.getSelection();
                  const { titleEditorState } = this.state;
                  if (
                    titleEditorState
                      .getCurrentContent()
                      .getBlockMap()
                      .last()
                      .getKey() === selectionState.getFocusKey()
                  ) {
                    this.setState(() => ({ currentFocused: 'description' }));
                    this.descriptionEditor.focus();
                  }
                }}
              />
            </Card.Header>
            <Card.Description>
              <Editor
                ref={(node) => {
                  this.descriptionEditor = node;
                }}
                readOnly={!this.props.editable}
                onChange={this.onChangeDescription}
                editorState={this.state.descriptionEditorState}
                blockRenderMap={extendedDescripBlockRenderMap}
                handleReturn={() => true}
                placeholder={this.props.intl.formatMessage(
                  messages.description,
                )}
                onUpArrow={() => {
                  const selectionState = this.state.descriptionEditorState.getSelection();
                  const currentCursorPosition = selectionState.getStartOffset();

                  if (currentCursorPosition === 0) {
                    this.setState(() => ({ currentFocused: 'title' }));
                    this.titleEditor.focus();
                  }
                }}
                onDownArrow={() => {
                  const selectionState = this.state.descriptionEditorState.getSelection();
                  const { descriptionEditorState } = this.state;
                  const currentCursorPosition = selectionState.getStartOffset();
                  const blockLength = descriptionEditorState
                    .getCurrentContent()
                    .getFirstBlock()
                    .getLength();

                  if (currentCursorPosition === blockLength) {
                    this.props.onFocusNextBlock(
                      this.props.block,
                      this.props.blockNode.current,
                    );
                  }
                }}
              />
            </Card.Description>
          </Card.Content>
        </Card>
        <SidebarPortal selected={this.props.selected}>
          <InlineForm
            schema={schema}
            title={schema.title}
            onChangeField={(id, value) => {
              this.props.onChangeBlock(this.props.block, {
                ...this.props.data,
                [id]: value,
              });
            }}
            formData={this.props.data}
          />
        </SidebarPortal>
      </div>
    );
  }
}

export default compose(
  injectIntl,
  connect(
    (state, ownProps) => ({
      request: state.content.subrequests[ownProps.block] || {},
      content: state.content.subrequests[ownProps.block]?.data,
    }),
    { createContent },
  ),
)(Edit);
