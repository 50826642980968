import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Container, Grid } from 'semantic-ui-react';
import { injectLazyLibs } from '@plone/volto/helpers/Loadable/Loadable';

const Countdown = ({ data, moment: momentlib, isEditMode }) => {
  const [duration, setDuration] = useState(false);

  const intl = useIntl();
  const moment = momentlib.default;
  moment.locale(intl.locale);

  useEffect(() => {
    const updateDuration = () => {
      const now = new Date();
      const then = new Date(data.date);
      setDuration(moment.duration(then - now));
    };

    if (data?.date) {
      const interval = setInterval(() => {
        updateDuration();
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [data.date, moment]);

  if (!isEditMode && duration && duration._milliseconds < 0) return <></>;

  return (
    <div className="stats-container countdown fullwidth">
      <Container>
        {duration ? (
          <>
            {data?.text_above && (
              <Grid>
                <Grid.Column className="text-above">
                  {data.text_above}
                </Grid.Column>
              </Grid>
            )}
            <Grid columns={4} doubling stackable>
              <Grid.Row>
                <Grid.Column>
                  <div className="stats-item">
                    <div className="number">
                      {Math.floor(duration.asDays())}
                    </div>
                    <div className="label">
                      <FormattedMessage id="Days" defaultMessage="Days" />
                    </div>
                  </div>
                </Grid.Column>
                <Grid.Column>
                  <div className="stats-item">
                    <div className="number">{duration.hours()}</div>
                    <div className="label">
                      <FormattedMessage id="Hours" defaultMessage="Hours" />
                    </div>
                  </div>
                </Grid.Column>
                <Grid.Column>
                  <div className="stats-item">
                    <div className="number">{duration.minutes()}</div>
                    <div className="label">
                      <FormattedMessage id="Minutes" defaultMessage="Minutes" />
                    </div>
                  </div>
                </Grid.Column>
                <Grid.Column>
                  <div className="stats-item">
                    <div className="number">{duration.seconds()}</div>
                    <div className="label">
                      <FormattedMessage id="Seconds" defaultMessage="Seconds" />
                    </div>
                  </div>
                </Grid.Column>
              </Grid.Row>
            </Grid>
            {data?.text_below && (
              <Grid>
                <Grid.Column className="text-below">
                  {data.text_below}
                </Grid.Column>
              </Grid>
            )}
          </>
        ) : (
          <></>
        )}
      </Container>
    </div>
  );
};

export default injectLazyLibs(['moment'])(Countdown);
