import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import UniKoblenz from './UniKoblenz';

const Person = (content) => {
  const jsonObj = {
    '@context': 'https://schema.org',
    '@type': 'Person',
    name: content.title,
    affiliation: UniKoblenz(),
  };

  if (content.email) jsonObj.email = content.email;

  const phone =
    (content.telephone?.items || []).length > 0
      ? content.telephone.items[0]
      : false;
  if (phone)
    jsonObj.telephone = `${phone.country || ''} ${phone.area || ''} ${
      phone.number || ''
    }`
      .trim()
      .replace(/\(0\)/g, '');

  if (content.photo)
    jsonObj.image =
      content.photo.scales?.preview?.download || content.photo.download;

  const location = useLocation();
  jsonObj.url =
    content.homepageUrl && content.homepageUrl.length > 0
      ? content.homepageUrl
      : `https://www.uni-koblenz.de${location.pathname}`;

  const functionsUrl = `${content['@id']}/@functions-of-person`;
  const contentSubrequests = useSelector((state) => state.content.subrequests);
  const functions = contentSubrequests?.[functionsUrl]?.data?.items || [];
  const primaryFunction = functions.length > 0 ? functions[0] : false;
  if (primaryFunction) jsonObj.jobTitle = primaryFunction.title;

  return jsonObj;
};

export default Person;
