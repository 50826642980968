import React from 'react';
import PropTypes from 'prop-types';
import DefaultBody from './DefaultBody';
import { SummaryItem } from '@package/components';

const TeaserBody = (props) => {
  if (
    props.data?.variation === 'summary' &&
    props.data?.href?.length > 0 &&
    props.data.href[0]
  ) {
    const item = {
      ...JSON.parse(JSON.stringify(props.data.href[0])),
      ...props.data,
    };
    const previewImage =
      props.data.preview_image?.length > 0
        ? props.data.preview_image[0]
        : false;
    return (
      <SummaryItem
        item={item}
        isEditMode={props.isEditMode}
        hideImages={props.data?.hide_image || false}
        hideDescriptions={props.data?.hide_description || false}
        ctaText={props.data?.cta_text}
        overrideImage={previewImage}
      />
    );
  }
  return <DefaultBody {...props} />;
};

TeaserBody.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  isEditMode: PropTypes.bool,
};

export default TeaserBody;
