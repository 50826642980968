import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Message } from 'semantic-ui-react';
import InlineForm from '@plone/volto/components/manage/Form/InlineForm';
import { Icon, SidebarPortal } from '@plone/volto/components';
import videoSVG from '@plone/volto/icons/video.svg';
import PanoptoVideoSchema from './schema';
import View from './View';

const Edit = (props) => {
  const schema = PanoptoVideoSchema(props.intl);
  return (
    <>
      <SidebarPortal selected={props.selected}>
        <InlineForm
          schema={schema}
          title={schema.title}
          icon={<Icon name={videoSVG} />}
          onChangeField={(id, value) => {
            props.onChangeBlock(props.block, {
              ...props.data,
              [id]: value,
            });
          }}
          formData={props.data}
        />
      </SidebarPortal>
      <Message secondary>
        <FormattedMessage
          id="Click here to move this block or edit settings."
          defaultMessage="Click here to move this block or edit settings."
        />
      </Message>
      <View data={props.data} isEditMode={true} />
    </>
  );
};

export default Edit;
