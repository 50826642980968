import { ConditionalLink } from '@plone/volto/components';
import { flattenToAppURL } from '@plone/volto/helpers';
import { DegreeProgramMaterials } from '@package/components';

const DegreeProgramSummary = ({
  content,
  showDescription = false,
  showMaterials = false,
  linkDisabled = false,
}) => {
  const addClasses =
    content.faculties?.length === 1
      ? content.faculties.map((f) => f.token)
      : [];
  const imageSrc = content.preview_image
    ? flattenToAppURL(content.preview_image.scales.large.download)
    : false;
  const resultStyle = imageSrc
    ? {
        backgroundImage: `url("${imageSrc}")`,
      }
    : {};
  const MAX_DESCR_CHARS = 250;
  return (
    <div className="degree-program-card-wrapper">
      <ConditionalLink
        to={flattenToAppURL(content['@id'])}
        condition={!linkDisabled}
      >
        <div className="degree-program-summary">
          <div className="top">
            <div className="image" style={resultStyle}></div>
            <div className={['header', ...addClasses].join(' ')}>
              <div className="meta">
                {content.degree?.map((i) => i.title).join(', ')}
              </div>
              <div className="title awh">{content.title}</div>
            </div>
          </div>
          {showDescription && (
            <div className="description">
              {content.description.length > MAX_DESCR_CHARS
                ? content.description.slice(0, MAX_DESCR_CHARS) + ' ...'
                : content.description}
            </div>
          )}
        </div>
      </ConditionalLink>
      {showMaterials && (
        <DegreeProgramMaterials materials={content.materials} />
      )}
    </div>
  );
};

export default DegreeProgramSummary;
