import { defineMessages } from 'react-intl';

const messages = defineMessages({
  labelSettings: {
    id: 'Settings',
    defaultMessage: 'Settings',
  },
  labelHeading: {
    id: 'Heading',
    defaultMessage: 'Heading',
  },
});

export const RelatdItemsSchema = (intl) => {
  return {
    title: intl.formatMessage(messages.labelSettings),
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: ['heading'],
      },
    ],

    properties: {
      heading: {
        title: intl.formatMessage(messages.labelHeading),
      },
    },
    required: [],
  };
};

export default RelatdItemsSchema;
