import { defineMessages } from 'react-intl';
import {
  getBlocksFieldname,
  getBlocksLayoutFieldname,
} from '@plone/volto/helpers';
import { getToCEntries } from '@package/helpers';

const messages = defineMessages({
  labelQuicklinks: {
    id: 'Quicklinks',
    defaultMessage: 'Quicklinks',
  },
  labelDefault: {
    id: 'Default',
    defaultMessage: 'Default',
  },
  labelVariant: {
    id: 'Variant',
    defaultMessage: 'Variant',
  },
  labelPills: {
    id: 'Pills',
    defaultMessage: 'Pills',
  },
  labelBoxes: {
    id: 'Boxes',
    defaultMessage: 'Boxes',
  },
  labelItem: {
    id: 'Item',
    defaultMessage: 'Item',
  },
  labelAddItem: {
    id: 'Add item',
    defaultMessage: 'Add item',
  },
  labelItems: {
    id: 'Items',
    defaultMessage: 'Items',
  },
  source: {
    id: 'Source',
    defaultMessage: 'Source',
  },
  descrSource: {
    id: 'Select content or copy an URL into this field.',
    defaultMessage: 'Select content or copy an URL into this field.',
  },
  labelAnchor: {
    id: 'Anchor',
    defaultMessage: 'Anchor',
  },
  descrAnchor: {
    id: 'Link to a heading within this page.',
    defaultMessage: 'Link to a heading within this page.',
  },
  title: {
    id: 'Title',
    defaultMessage: 'Title',
  },
  labelShowBreadcrumbs: {
    id: 'Show breadcrumbs',
    defaultMessage: 'Show breadcrumbs',
  },
  labelPosition: {
    id: 'Position',
    defaultMessage: 'Position',
  },
  descrPosition: {
    id: "Has only effect on variant 'Pills'",
    defaultMessage: "Has only effect on variant 'Pills'",
  },
  labelPosLeft: {
    id: 'Left',
    defaultMessage: 'Left',
  },
  labelPosCenter: {
    id: 'Center',
    defaultMessage: 'Center',
  },
  labelPosRight: {
    id: 'Right',
    defaultMessage: 'Right',
  },
});

const ItemSchema = (intl, content) => {
  /* get headings of current page as choices for anchor field */
  const blocksFieldname = getBlocksFieldname(content);
  const blocksLayoutFieldname = getBlocksLayoutFieldname(content);
  const anchorChoices =
    blocksFieldname && blocksLayoutFieldname
      ? getToCEntries(
          content[blocksFieldname],
          content[blocksLayoutFieldname],
          /* note: we do not store the `slug` field here,
             since this could change when referenced block
             is updated (see `override_toc`).
             So we compute `slug` dynamically in View-component,
             based on block id.
        */
        ).map((h) => [{ id: h.id, title: h.text }, h.text])
      : [];

  return {
    title: intl.formatMessage(messages.labelItem),
    addMessage: intl.formatMessage(messages.labelAddItem),

    fieldsets: [
      {
        id: 'default',
        title: intl.formatMessage(messages.labelDefault),
        fields: ['href', 'anchor', 'title'],
      },
    ],

    properties: {
      href: {
        title: intl.formatMessage(messages.source),
        description: intl.formatMessage(messages.descrSource),
        widget: 'object_browser',
        mode: 'link',
        selectedItemAttrs: ['Title', 'Description'],
        allowExternals: true,
      },
      anchor: {
        title: intl.formatMessage(messages.labelAnchor),
        description: intl.formatMessage(messages.descrAnchor),
        type: 'object',
        factory: 'Choice',
        choices: anchorChoices,
      },
      title: {
        title: intl.formatMessage(messages.title),
      },
    },

    required: [],
  };
};

const QuicklinksSchema = (intl, properties) => {
  const MyItemSchema = ItemSchema(intl, properties);
  return {
    title: intl.formatMessage(messages.labelQuicklinks),

    fieldsets: [
      {
        id: 'default',
        title: intl.formatMessage(messages.labelDefault),
        fields: ['variant', 'show_breadcrumbs', 'position', 'items'],
      },
    ],

    properties: {
      variant: {
        title: intl.formatMessage(messages.labelVariant),
        type: 'string',
        factory: 'Choice',
        choices: [
          ['pills', intl.formatMessage(messages.labelPills)],
          ['boxes', intl.formatMessage(messages.labelBoxes)],
        ],
        default: 'pills',
      },
      show_breadcrumbs: {
        title: intl.formatMessage(messages.labelShowBreadcrumbs),
        type: 'integer',
        default: 0,
      },
      items: {
        title: intl.formatMessage(messages.labelItems),
        schema: MyItemSchema,
        widget: 'object_list',
      },
      position: {
        title: intl.formatMessage(messages.labelPosition),
        description: intl.formatMessage(messages.descrPosition),
        type: 'string',
        factory: 'Choice',
        choices: [
          ['left', intl.formatMessage(messages.labelPosLeft)],
          ['center', intl.formatMessage(messages.labelPosCenter)],
          ['right', intl.formatMessage(messages.labelPosRight)],
        ],
        default: 'left',
      },
    },

    required: [],
  };
};

export default QuicklinksSchema;
