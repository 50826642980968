import React from 'react';
import { defineMessages } from 'react-intl';

import ObjectListWidget from '@plone/volto/components/manage/Widgets/ObjectListWidget';

const messages = defineMessages({
  link: {
    id: 'Link',
    defaultMessage: 'Link',
  },
  addLink: {
    id: 'Add link',
    defaultMessage: 'Add link',
  },
  title: {
    id: 'Title',
    defaultMessage: 'Title',
  },
  url: {
    id: 'URL',
    defaultMessage: 'URL',
  },
});

const ItemSchema = (intl) => {
  return {
    title: intl.formatMessage(messages.link),
    addMessage: intl.formatMessage(messages.addLink),
    properties: {
      title: {
        title: intl.formatMessage(messages.title),
      },
      url: {
        title: intl.formatMessage(messages.url),
        widget: 'url',
      },
    },
    fieldsets: [
      {
        id: 'default',
        title: intl.formatMessage(messages.link),
        fields: ['title', 'url'],
      },
    ],
    required: ['title', 'url'],
  };
};

const FurtherLinksWidget = (props) => {
  const itemSchema = ItemSchema(props.intl);
  return (
    <ObjectListWidget
      schema={itemSchema}
      {...props}
      value={props.value?.items || props.default?.items || []}
      onChange={(id, value) => props.onChange(id, { items: value })}
    />
  );
};

export default FurtherLinksWidget;
