/*
  Receives list of folder contents (prop `items`),
  checks whether they are pages, if yes full objects
  are loaded and content is rendered.
*/
import { useSelector } from 'react-redux';
import PersonSubPagesAccordion from './PersonSubPagesAccordion';
import PersonSubPagesTabs from './PersonSubPagesTabs';
import AddSubPageButton from './AddSubPageButton';

const PersonSubPages = ({ url }) => {
  const actions = useSelector((state) => state.actions?.actions);
  const editAction = actions
    ? actions.object?.findIndex((o) => o.id === 'edit') > -1
    : false;

  /* we have a content expansion endpoint for `subpages` */
  const subPages = useSelector(
    (state) =>
      (state.content?.data &&
        (state.content.data['@components'] ?? {}).subpages) ||
      false,
  );

  return subPages?.length > 0 ? (
    <div className="subpages">
      <PersonSubPagesAccordion
        personUrl={url}
        subpages={subPages}
        editAction={editAction}
      />
      <PersonSubPagesTabs
        personUrl={url}
        subpages={subPages}
        editAction={editAction}
      />
    </div>
  ) : editAction ? (
    <AddSubPageButton url={url} />
  ) : (
    <></>
  );
};

export default PersonSubPages;
