/* 
  Component that renders a toast notification when the content is updated,
  asking the user if they want to update the translation as well.
*/
import { useEffect, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Button } from 'semantic-ui-react';
import { Toast } from '@plone/volto/components';
import { flattenToAppURL } from '@plone/volto/helpers';

const EditWatcher = () => {
  const updateRequest = useSelector((state) => state.content.update);

  const translations = useSelector(
    (state) => state.content.data?.['@components']?.translations?.items,
  );
  const translation = translations?.length > 0 ? translations[0] : false;

  const prevUpdateLoaded = useRef();
  useEffect(() => {
    prevUpdateLoaded.current = updateRequest.loaded;
  });

  const onEditTranslation = () => {
    /* deliberately not using history.push to make sure we get a full page reload,
       otherwise the `compare-translation` button might not work ... (yes, ugly) */
    window.location.pathname = `${flattenToAppURL(translation['@id'])}/edit`;
  };

  if (translation && !prevUpdateLoaded.current && updateRequest.loaded) {
    toast.warn(
      <Toast
        info
        title={
          <FormattedMessage
            id="Update translation?"
            defaultMessage="Update translation?"
          />
        }
        content={
          <div>
            <p>
              <FormattedMessage
                id="update_translation_message"
                defaultMessage="This content has a translation. Do you want to update it as well?"
              />
            </p>
            <Button primary block onClick={onEditTranslation}>
              <FormattedMessage
                id="Yes, update translation"
                defaultMessage="Yes, update translation"
              />
            </Button>
          </div>
        }
      />,
      { autoClose: 10000 },
    );
  }

  return <></>;
};

export default EditWatcher;
