import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Container } from 'semantic-ui-react';
import { getContent } from '@plone/volto/actions';
import { Pagination } from '@plone/volto/components';
import { flattenToAppURL } from '@plone/volto/helpers';
/* eslint-disable-next-line import/no-unresolved */

const withPagination = (Component) => ({ ...props }) => {
  const dispatch = useDispatch();

  const [currentPage, setCurrentPage] = useState(0);
  /* fixed, since `getContent` is using global 
     `defaultPageSize` value from config (which is 25)
  */
  const pageSize = 25;

  const { content } = props;

  const onChangePage = (e, { value }) => {
    setCurrentPage(value);
    dispatch(
      getContent(flattenToAppURL(content['@id']), null, null, value + 1),
    );
  };

  return (
    <>
      <Component {...props} />
      <Container>
        <br />
        {content.items.length < content.items_total && (
          <Pagination
            current={currentPage}
            total={Math.ceil(content.items_total / pageSize)}
            pageSize={pageSize}
            pageSizes={[pageSize]}
            onChangePage={onChangePage}
          />
        )}
      </Container>
    </>
  );
};

export default withPagination;
