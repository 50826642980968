import { useEffect, useState } from 'react';
import { SelectWidget } from '@plone/volto/components';
import { ECAMPUS_API_URL } from '@package/components/Blocks/Publications/constants';
import { normalizeECampusSearchResultItem } from '@package/components/Blocks/Publications/utils';

const ECampusProjectSelectorWidget = (props) => {
  const [choices, setChoices] = useState([]);

  useEffect(() => {
    const url = `${ECAMPUS_API_URL}/cs/sys/genericSearch/loadSearchResult`;
    const postData = {
      search: { groups: [], configFile: 'fs/res/searchProject.xml' },
      offset: 0,
      limit: -1,
      sortCriteria: [],
    };
    fetch(url, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(postData),
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setChoices(
          data.data.map((item) => {
            const nItem = normalizeECampusSearchResultItem(item, data.columns);
            return [
              nItem['Project.lid'].toString(),
              nItem['Project.defaulttext'],
            ];
          }),
        );
      });
  }, []);

  return <SelectWidget {...props} choices={choices} />;
};

export default ECampusProjectSelectorWidget;
