import { toPublicURL } from '@plone/volto/helpers';
import { extractDescription } from './utils';

const Event = (content) => {
  const jsonObj = {
    '@context': 'https://schema.org',
    '@type': 'Event',
    mainEntityOfPage: {
      '@type': 'WebPage',
      '@id': toPublicURL(content['@id']),
    },
    name: content.title,
    description:
      content.description?.length > 0
        ? content.description
        : extractDescription(content),
    image: [],
    startDate: content.start,
    endDate: content.end,
    eventAttendanceMode: 'https://schema.org/OfflineEventAttendanceMode',
    eventStatus: 'https://schema.org/EventScheduled',
  };

  if (content.location) {
    if (content.location.startsWith('http')) {
      jsonObj.location = {
        '@type': 'VirtualLocation',
        url: content.location,
      };
      jsonObj.eventAttendanceMode =
        'https://schema.org/OnlineEventAttendanceMode';
    } else {
      jsonObj.location = content.location;
    }
  }

  if (content.preview_image?.download) {
    jsonObj.image.push(toPublicURL(content.preview_image.download));
  }
  if (content.preview_image?.scales) {
    for (const scale in content.preview_image.scales) {
      jsonObj.image.push(
        toPublicURL(content.preview_image.scales[scale].download),
      );
    }
  }

  if (content.contact_name) {
    jsonObj.organizer = {
      '@type': 'Person',
      ...(content.contact_name && { name: content.contact_name }),
      ...(content.contact_email && { email: content.contact_email }),
      ...(content.contact_phone && { telephone: content.contact_phone }),
    };
  }

  return jsonObj;
};

export default Event;
