import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import config from '@plone/volto/registry';
import BlockChooserOrig from '@plone/volto-original/components/manage/BlockChooser/BlockChooser';

/* extend BlockChooser to display html block
   if its allowed within current content
*/
export default React.forwardRef((props, ref) => {
  const location = useLocation();

  const allowHTMLBlock = useSelector(
    (state) => state.content?.data?.allow_html_block || false,
  );

  if (!location.pathname.endsWith('add') && allowHTMLBlock === true) {
    config._data.blocks.blocksConfig.html.restricted = false;
    if (props.allowedBlocks?.length > 0) props.allowedBlocks.push('html');
  } else config._data.blocks.blocksConfig.html.restricted = true;

  return <BlockChooserOrig {...props} ref={ref} />;
});
