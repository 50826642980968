import { MaybeWrap, UniversalLink } from '@plone/volto/components';
import { flattenToAppURL } from '@plone/volto/helpers';
import { isUrl } from '@plone/volto/helpers/Url/Url';

const AlbumListing = ({ items }) => {
  return (
    <div className="album-listing">
      {items
        .filter((i) => i['@type'] === 'Image')
        .map((i) => (
          <div className="item" key={i['@id']}>
            <MaybeWrap
              condition={isUrl(i.description)}
              as={UniversalLink}
              href={i.description}
              target="_blank"
            >
              <img
                src={flattenToAppURL(`${i['@id']}/@@images/image/preview`)}
                alt={i.title}
              />
            </MaybeWrap>
          </div>
        ))}
    </div>
  );
};

export default AlbumListing;
