import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Loader, Message, Segment } from 'semantic-ui-react';
import { flattenToAppURL } from '@plone/volto/helpers';
import { getResearchProjects } from '@package/actions';
import { ResearchProjectCard } from '@package/components';

const View = ({ data, isEditMode }) => {
  const dispatch = useDispatch();
  const url = data.href && data.href.length > 0 ? data.href[0]['@id'] : false;
  const sortBy = data.sort_by || 'title';
  const sortReverse = data.sort_reverse || false;

  const subrequest = useSelector(
    (state) => state.research_projects.subrequests?.[url],
  );
  useEffect(() => {
    if (url && subrequest?.loaded !== true)
      dispatch(
        getResearchProjects(flattenToAppURL(url), sortBy, sortReverse, url),
      );
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [dispatch, url, sortBy, sortReverse]);

  if (!url)
    return (
      <Message warning>
        <FormattedMessage
          id="Please select a person in the sidebar."
          defaultMessage="Please select a person in the sidebar."
        />
      </Message>
    );

  const projects = subrequest?.items;
  return projects ? (
    projects.length > 0 ? (
      <Grid container columns={data.grid_columns || 1} stackable>
        {projects.map((project) => (
          <Grid.Column key={project['@id']}>
            <ResearchProjectCard project={project} linksDisabled={isEditMode} />
          </Grid.Column>
        ))}
      </Grid>
    ) : (
      <Message warning>
        <FormattedMessage
          id="No matching projects found."
          defaultMessage="No matching projects found."
        />
      </Message>
    )
  ) : (
    <Segment>
      <Loader size="large" inline="centered" active>
        <FormattedMessage
          id="Loading projects ..."
          defaultMessage="Loading projects ..."
        />
      </Loader>
    </Segment>
  );
};

export default View;
