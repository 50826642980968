import React from 'react';
import { defineMessages } from 'react-intl';
import ObjectListWidget from '@plone/volto/components/manage/Widgets/ObjectListWidget';

const messages = defineMessages({
  labelPerson: {
    id: 'Person',
    defaultMessage: 'Person',
  },
  labelAddPerson: {
    id: 'Add person',
    defaultMessage: 'Add person',
  },
  labelSelectPerson: {
    id: 'Select person',
    defaultMessage: 'Select person',
  },
  labelOverridePhone: {
    id: 'Override phone',
    defaultMessage: 'Override phone',
  },
  labelOverrideEmail: {
    id: 'Override email',
    defaultMessage: 'Override email',
  },
});

const ItemSchema = (intl) => {
  return {
    title: intl.formatMessage(messages.labelPerson),
    addMessage: intl.formatMessage(messages.labelAddPerson),
    properties: {
      href: {
        title: intl.formatMessage(messages.labelSelectPerson),
        widget: 'object_browser',
        mode: 'link',
        widgetOptions: {
          pattern_options: { selectableTypes: ['Person'] },
        },
        selectedItemAttrs: ['title', 'Title'],
        allowExternals: false,
      },
      override_phone: {
        title: intl.formatMessage(messages.labelOverridePhone),
      },
      override_email: {
        title: intl.formatMessage(messages.labelOverrideEmail),
      },
    },
    fieldsets: [
      {
        id: 'default',
        title: intl.formatMessage(messages.labelPerson),
        fields: ['href', 'override_phone', 'override_email'],
      },
    ],
    required: [],
  };
};

const PersonsOverrideWidget = (props) => {
  let schema = ItemSchema(props.intl);
  return (
    <ObjectListWidget
      schema={schema}
      {...props}
      value={props.value?.items || props.default?.items || []}
      onChange={(id, value) => props.onChange(id, { items: value })}
    />
  );
};

export default PersonsOverrideWidget;
