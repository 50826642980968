/**
 * Header component.
 * @module components/theme/Header/Header
 */

import React, { useState } from 'react';
import { Container, Segment } from 'semantic-ui-react';
import { Logo } from '@plone/volto/components';
import { MegaContainer } from '@package/components';
import cx from 'classnames';

/**
 * Header component class.
 * @class Header
 * @extends Component
 */
const Header = () => {
  const [isScrolled, setIsScrolled] = useState(false);

  const updateIsScolled = () => {
    setIsScrolled(window.scrollY > 113);
  };

  React.useEffect(() => {
    window.addEventListener('scroll', updateIsScolled);

    return () => {
      window.removeEventListener('scroll', updateIsScolled);
    };
  }, []);

  return (
    <Segment
      basic
      className={cx('header-wrapper', { shrinked: isScrolled })}
      role="banner"
    >
      <Container id="uniko-header" className="uniko-header">
        <MegaContainer />
        <div className="logo">
          <Logo />
        </div>
      </Container>
    </Segment>
  );
};

export default Header;
