import { useIntl } from 'react-intl';
import { UniversalLink } from '@plone/volto/components';
import { List } from 'semantic-ui-react';
import { ppMaterial } from './utils';

/* As appendix for Degree Program Blocks */
const DegreeProgramMaterials = ({ materials }) => {
  const intl = useIntl();
  const items = (
    materials?.items?.length > 0 ? materials.items.filter((i) => i.url) : []
  ).sort((a, b) => (a.kind < b.kind ? -1 : a.kind > b.kind ? 1 : 0));
  return items.length > 0 ? (
    <List className="degree-program-block-appendix">
      {items.map((i, index) => {
        return (
          <List.Item key={`material-li-${index}`}>
            <UniversalLink href={i.url}>
              {ppMaterial(i.kind, intl)}
            </UniversalLink>
          </List.Item>
        );
      })}
    </List>
  ) : (
    <></>
  );
};

export default DegreeProgramMaterials;
