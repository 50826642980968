import { useState } from 'react';
import { Button, Icon, Input, Select } from 'semantic-ui-react';

const View = () => {
  const [value, setValue] = useState('');
  const [searchField, setSearchField] = useState('any');

  const onChange = (evt) => {
    setValue(evt.target.value);
  };

  const onSubmit = () => {
    const params = {
      search_field: searchField,
      vid: '49HBZ_UKO:VU1',
      institution: '49HBZ_UKO',
      query: `${searchField},contains,${value}`,
      offset: 0,
      search_scope: 'MyInst',
    };
    const base = 'https://primo.uni-koblenz.de/discovery/search';
    const paramsStr = Object.entries(params)
      .map(([k, v]) => `${k}=${v}`)
      .join('&');
    const url = `${base}?${paramsStr}`;
    window.location = url;
  };

  const onKeyDown = (evt) => {
    if (evt.key === 'Enter') onSubmit();
  };

  const onChangeSearchField = (_, el) => {
    setSearchField(el.value);
  };

  const options = [
    {
      key: 'any',
      text: 'Alle Felder',
      value: 'any',
    },
    {
      key: 'title',
      text: 'Titel',
      value: 'title',
    },
    {
      key: 'creator',
      text: 'Autor/Herausgeber',
      value: 'creator',
    },
    {
      key: 'sub',
      text: 'Schlagwort',
      value: 'sub',
    },
    {
      key: 'holding_call_number',
      text: 'Signatur',
      value: 'holding_call_number',
    },
    {
      key: 'isbn',
      text: 'ISBN',
      value: 'isbn',
    },
    {
      key: 'issn',
      text: 'ISSN',
      value: 'issn',
    },
    {
      key: 'course_name',
      text: 'Seminarapparat',
      value: 'course_name',
    },
  ];
  return (
    <Input
      className="input-primo-search"
      label={
        <Select
          value={searchField}
          options={options}
          onChange={onChangeSearchField}
        />
      }
      labelPosition="left"
      placeholder="katalogPLUS durchsuchen"
      fluid
      action={
        <>
          <Button onClick={onSubmit} primary>
            Suchen
          </Button>
          <Button onClick={onSubmit} icon primary>
            <Icon name="search" />
          </Button>
        </>
      }
      onChange={onChange}
      value={value}
      onKeyDown={onKeyDown}
    />
  );
};

export default View;
