import { FormattedMessage } from 'react-intl';
import {
  Icon as VoltoIcon,
  MaybeWrap,
  UniversalLink,
} from '@plone/volto/components';
import { Icon } from '@package/components';
import { getAnchorSlugOfBlock } from '@package/helpers';
import arrowSVG from '@package/svgs/icons/arrow-uniko.svg';

const View = ({ data, isEditMode, metadata, properties }) => {
  const content = metadata ? metadata : properties;
  const linkedItem = data?.href?.[0] || false;
  const href = data.phone
    ? `tel:${data.phone}`
    : data.mailto && data.mail_subject
    ? `mailto:${data.mailto}?subject=${data.mail_subject}`
    : data.mailto
    ? `mailto:${data.mailto}`
    : linkedItem
    ? linkedItem['@id'] + (data.href_anchor || '')
    : data.anchor
    ? `#${getAnchorSlugOfBlock(data.anchor.id, content)}`
    : '#';

  const title =
    data?.title?.length > 0 ? (
      data.title
    ) : linkedItem.Title?.length > 0 ? (
      linkedItem.Title
    ) : data?.anchor?.title ? (
      data.anchor.title
    ) : (
      <FormattedMessage id="Enter title ..." defaultMessage="Enter title ..." />
    );
  let wrapperClasses = ['c2a-wrapper'];
  if (data?.position) wrapperClasses.push(data.position);
  if (data?.variant) wrapperClasses.push(data.variant);
  return (
    <div className={wrapperClasses.join(' ')}>
      <div className="c2a-button">
        <MaybeWrap
          condition={!isEditMode}
          as={UniversalLink}
          href={href}
          target={data.openLinkInNewTab ? '_blank' : null}
          aria-label={data.title?.trim() || data.icon || href}
        >
          {data.show_uniko_arrow && <VoltoIcon name={arrowSVG} />}
          {data.icon && (
            <>
              <Icon name={data.icon} />{' '}
            </>
          )}
          {title}
        </MaybeWrap>
      </div>
    </div>
  );
};

export default View;
