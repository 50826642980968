/**
 * LecturesSelectWidget component.
 * @module components/LecturesSelectWidget/LecturesSelectWidget
 */

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { ArrayWidget } from '@plone/volto/components';
import { getLSFLectures } from '@package/actions';

/**
 * LecturesSelectWidget component class.
 * @function LecturesSelectWidget
 * @returns {string} Markup of the component.
 */
const LecturesSelectWidget = (props) => {
  /* Problem: Context sensitive vocabularies/sources are not working with Volto,
              since in `getVocabulary` action Volto always uses the Base URL.
     Solution: Use custom service `@lsf-lectures` to load options manually into the choice field.
  */
  const contextURL = props.formData['@id'];
  const lectures = useSelector((state) => state.lsf_lectures);

  const dispatch = useDispatch();
  React.useEffect(() => {
    if (contextURL) {
      const curPerson = props.formData.person_id || null;
      const curHeading = curPerson ? null : props.formData.lsf_heading;
      const curSemester =
        typeof props.formData.semester_id === 'object'
          ? props.formData.semester_id.token
          : props.formData.semester_id;
      dispatch(
        getLSFLectures(contextURL, false, curHeading, curPerson, curSemester),
      );
    }
  }, [dispatch, contextURL, props.formData]);

  /* `content` contains the object which is currently in edit mode,
     in its original state
  const content = useSelector((state) => state.content.data);
  */

  var choices = false;
  var value = false;
  if (lectures && props.value) {
    let items = lectures.items;
    /* in ArrayWidget `choices` contains list of selectable items */
    choices = items.map((item) => {
      return [item.id, item.title];
    });

    /* and `value` contains already selected items */
    value = props.value.map((lectureId) => {
      /* convert pure values (list of ids) into objects to make sure
          the labels contain titles (instead of ids)
        */
      let found = items.find((item) => {
        return item.id === lectureId;
      });
      if (found) {
        return {
          token: found.id,
          title: found.title,
        };
      } else {
        return false;
      }
    });
    value = value.filter((v) => v !== false);
  }

  if (value) {
    return (
      <ArrayWidget {...props} choices={choices} value={value}></ArrayWidget>
    );
  } else {
    return <ArrayWidget {...props} value={null}></ArrayWidget>;
  }
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
LecturesSelectWidget.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  required: PropTypes.bool,
  error: PropTypes.arrayOf(PropTypes.string),
  value: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string])),
  onChange: PropTypes.func,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
};

/**
 * Default properties.
 * @property {Object} defaultProps Default properties.
 * @static
 */
LecturesSelectWidget.defaultProps = {
  description: null,
  required: false,
  error: [],
  value: null,
  onChange: null,
  onEdit: null,
  onDelete: null,
};

export default injectIntl(LecturesSelectWidget);
