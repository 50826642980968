import { Container, Grid } from 'semantic-ui-react';
import { PersonCard } from '@package/components';

const PersonFunctionView = (props) => {
  const { content } = props;
  return (
    <Container id="person-function">
      <h1 className="documentFirstHeading">{content.title}</h1>
      {content.description && (
        <p className="documentDescription">{content.description}</p>
      )}
      {content.additionalText?.data?.length > 12 && (
        <div
          className="description"
          dangerouslySetInnerHTML={{ __html: content.additionalText.data }}
        />
      )}
      <Grid columns={2} stackable>
        {content.personsWithFunction?.map((person) => {
          return person ? (
            <Grid.Column key={person['@id']}>
              <PersonCard
                key={person['@id']}
                person={person}
                override={content}
                hide-function={true}
                no-hydration={true}
              />
            </Grid.Column>
          ) : (
            <></>
          );
        })}
      </Grid>
    </Container>
  );
};

export default PersonFunctionView;
