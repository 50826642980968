import { useEffect } from 'react';
import ViewOrig from '@eeacms/volto-accordion-block-original/components/manage/Blocks/Accordion/View';

const View = (props) => {
  useEffect(() => {
    if (__CLIENT__) {
      /* track accordion toggles via Plausible Custom Event Goals
       (https://plausible.io/docs/custom-event-goals)
    */
      const titles = document.getElementsByClassName('accordion-title');
      for (let i = 0; i < titles.length; i++) {
        const eventClass = 'plausible-event-name=Clicked+Accordion';
        if (titles[i].classList.contains(eventClass)) continue;
        titles[i].classList.add(eventClass);
        /* use encodeURIComponent on the title to avoid InvalidCharacterError in Safari */
        const titleEncoded = encodeURIComponent(titles[i].innerText);
        const page = window.location.pathname.endsWith('/')
          ? window.location.pathname.slice(0, -1)
          : window.location.pathname;
        titles[i].classList.add(`plausible-event-page=${page}#${titleEncoded}`);
      }
    }
  });
  return props?.data?.show_uniko_arrow ? (
    <div className="acc-with-arrow">
      <ViewOrig {...props} />
    </div>
  ) : (
    <ViewOrig {...props} />
  );
};

export default View;
