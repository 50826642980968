/**
 * @module components/theme/Unauthorized/Unauthorized
 */

import React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import { Container, Dimmer, Icon } from 'semantic-ui-react';
import { withServerErrorCode } from '@plone/volto/helpers/Utils/Utils';
import { getBaseUrl } from '@plone/volto/helpers';
import { ContentPlaceholder } from '@package/components';

/**
 * unauthorized function.
 * @function Unauthorized
 * @returns {string} Markup of the unauthorized page.
 */
const Unauthorized = ({ userId }) => {
  const error_message = useSelector((state) => state.apierror.message);
  const location = useLocation();
  return (
    <Container className="view-wrapper">
      {userId ? (
        <div>
          <Dimmer.Dimmable as={Container} dimmed={true}>
            <ContentPlaceholder />
            <Dimmer active={true} className="embedded">
              <h3>
                <FormattedMessage
                  id="Logged in as {userId}, you do not have the permission to access this page."
                  defaultMessage="Logged in as {userId}, you do not have the permission to access this page."
                  values={{
                    userId: <i>{userId}</i>,
                  }}
                />
              </h3>
              <p>
                <FormattedMessage
                  id="If you want to log in with a different email address, {click_here}."
                  defaultMessage="If you want to log in with a different email address, {click_here}."
                  values={{
                    click_here: (
                      <Link
                        to={`/logout?return_url=${getBaseUrl(
                          location.pathname,
                        )}`}
                        className="no-faculty"
                      >
                        <FormattedMessage
                          id="click here"
                          defaultMessage="click here"
                        />
                      </Link>
                    ),
                  }}
                />
              </p>
              <p>
                <FormattedMessage
                  id="If you think this is a mistake, please contact the {site_admin}."
                  defaultMessage="If you think this is a mistake, please contact the {site_admin}."
                  values={{
                    site_admin: (
                      <Link
                        to={`/contact-form?from=${location.pathname}&error=401`}
                        className="no-faculty"
                      >
                        <FormattedMessage
                          id="Site Administration"
                          defaultMessage="Site Administration"
                        />
                      </Link>
                    ),
                  }}
                />
              </p>
              <br />
              <br />
              <br />
              <code>{error_message}</code>
              <br />
              <br />
              <br />
            </Dimmer>
          </Dimmer.Dimmable>
        </div>
      ) : (
        <div>
          <Dimmer.Dimmable as={Container} dimmed={true}>
            <ContentPlaceholder />
            <Dimmer active={true} className="embedded">
              <h1>
                <FormattedMessage
                  id="Login required"
                  defaultMessage="Login required"
                />
              </h1>
              <p>
                <FormattedMessage
                  id="In order to access this page you need to log in."
                  defaultMessage="In order to access this page you need to log in."
                />
              </p>
              {/* intentionally no Link component, since we need a real
                  page reload to trigger Shibboleth login
              */}
              <a
                className="ui primary button big"
                href={`${getBaseUrl(location.pathname)}/login`}
              >
                <Icon name="sign in" />{' '}
                <FormattedMessage id="log in" defaultMessage="log in" />
              </a>
              <br />
              <br />
              <br />
              <br />
              <Link to="/" className="no-faculty">
                <FormattedMessage
                  id="To the site root"
                  defaultMessage="To the site root"
                />
              </Link>
            </Dimmer>
          </Dimmer.Dimmable>
        </div>
      )}
    </Container>
  );
};

export default withServerErrorCode(401)(
  connect((state) => ({
    userId: state.userSession.token
      ? jwtDecode(state.userSession.token).sub
      : '',
  }))(Unauthorized),
);
