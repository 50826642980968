import { useState } from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Icon as SemanticUIIcon,
  Loader,
  Message,
  Popup,
  Table,
} from 'semantic-ui-react';
import { transitionWorkflow } from '@plone/volto/actions';
import { Icon, ListingBlockBody } from '@plone/volto/components';
import { flattenToAppURL } from '@plone/volto/helpers';
import CircleBottomSVG from '@plone/volto/icons/circle-bottom.svg';
import CircleDismissSVG from '@plone/volto/icons/circle-dismiss.svg';
import './worklist.less';

const messages = defineMessages({
  labelPublishExternally: {
    id: 'Publish externally',
    defaultMessage: 'Publish externally',
  },
  labelReject: {
    id: 'Reject',
    defaultMessage: 'Reject',
  },
});

const WorklistListingVariation = ({ items }) => {
  const [isLoading, setIsLoading] = useState([]);
  const [isProcessed, setIsProcessed] = useState([]);

  const dispatch = useDispatch();
  const intl = useIntl();

  const transitionItem = (item, transition) => {
    setIsLoading(isLoading.concat(item['@id']));
    const url = `${item['@id']}/@workflow/${transition}`;
    dispatch(transitionWorkflow(url));
    setTimeout(() => {
      setIsLoading([]);
      setIsProcessed(isProcessed.concat(item['@id']));
    }, 750);
  };

  items = items.filter((i) => isProcessed.indexOf(i['@id']) === -1);

  if (items.length === 0)
    return (
      <Message positive>
        <FormattedMessage
          id="Everything done"
          defaultMessage="Everything done"
        />{' '}
        <SemanticUIIcon name="birthday cake" size="large" />
      </Message>
    );

  return (
    <Table className="table-worklist" compact singleLine>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>
            <FormattedMessage id="Title" defaultMessage="Title" />
          </Table.HeaderCell>
          <Table.HeaderCell>
            <FormattedMessage id="Type" defaultMessage="Type" />
          </Table.HeaderCell>
          <Table.HeaderCell>
            {intl.formatMessage(messages.labelPublishExternally)}
          </Table.HeaderCell>
          <Table.HeaderCell>
            {intl.formatMessage(messages.labelReject)}
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {items.map((item) => {
          const url = flattenToAppURL(item['@id']);
          return (
            <Table.Row key={item['@id']}>
              <Table.Cell>
                <Popup
                  content={url}
                  trigger={<Link to={url}>{item.title}</Link>}
                />
              </Table.Cell>
              <Table.Cell>{item.Type}</Table.Cell>
              <Table.Cell>
                {isLoading.find((i) => i === item['@id']) ? (
                  <Loader active inline />
                ) : (
                  <Icon
                    className="toggle-transition"
                    name={CircleBottomSVG}
                    title={intl.formatMessage(messages.labelPublishExternally)}
                    onClick={() => {
                      transitionItem(item, 'publish_externally');
                    }}
                  />
                )}
              </Table.Cell>
              <Table.Cell>
                {isLoading.find((i) => i === item['@id']) ? (
                  <Loader active inline />
                ) : (
                  <Icon
                    className="toggle-transition"
                    name={CircleDismissSVG}
                    title={intl.formatMessage(messages.labelReject)}
                    onClick={() => {
                      transitionItem(item, 'reject');
                    }}
                  />
                )}
              </Table.Cell>
            </Table.Row>
          );
        })}
      </Table.Body>
    </Table>
  );
};

const Worklist = (props) => {
  const data = {};
  data.querystring = {
    query: [
      {
        i: 'review_state',
        o: 'plone.app.querystring.operation.selection.any',
        v: ['pending'],
      },
    ],
    sort_order: 'ascending',
  };
  data.block = 'blockid';

  const variation = {
    id: 'worklist',
    isDefault: false,
    title: 'Worklist Items',
    template: WorklistListingVariation,
  };

  return (
    <>
      <h1>
        <FormattedMessage id="Worklist" defaultMessage="Worklist" />
      </h1>
      <ListingBlockBody
        data={data}
        path={'/'}
        isEditMode={false}
        variation={variation}
      />
    </>
  );
};

export default Worklist;
