/* Widget for Person page to order persons functions */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEqual } from 'lodash';
import move from 'lodash-move';
import { getContent } from '@plone/volto/actions';
import { Icon, FormFieldWrapper } from '@plone/volto/components';
import { flattenToAppURL } from '@plone/volto/helpers';
import { DragDropList } from '@plone/volto/components';

import dragSVG from '@plone/volto/icons/drag.svg';

import './editor.less';

const FunctionsOrderWidget = (props) => {
  const { fieldSet, id, onChange, formData } = props;

  /* get all functions of current person */
  const functionsUrl = `${flattenToAppURL(
    formData['@id'],
  )}/@functions-of-person`;
  const subrequest = useSelector(
    (state) => state.content.subrequests?.[functionsUrl],
  );
  const dispatch = useDispatch();
  const functions = subrequest?.data?.items || [];
  const functionsUUIDs = functions.map((item) => item.uuid);
  useEffect(() => {
    if (subrequest?.loaded !== true)
      dispatch(getContent(flattenToAppURL(functionsUrl), null, functionsUrl));
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [dispatch, functionsUrl]);

  /* compare UUIDs of existing functions with UUIDs stored in `functions_order`,
     and make sure they are in sync  */
  let uuidsOrdered = functionsUUIDs;
  if (formData.functions_order) {
    uuidsOrdered = formData.functions_order.filter(
      /* only keep existing functions */
      (item) => functionsUUIDs.indexOf(item) > -1,
    );
    if (uuidsOrdered.length < functionsUUIDs.length) {
      /* add new functions */
      const missing = functionsUUIDs.filter(
        (uuid) => uuidsOrdered.indexOf(uuid) === -1,
      );
      uuidsOrdered = uuidsOrdered.concat(missing);
    }
  }
  /* if UUIDs have changed: update widget value */
  if (!isEqual(uuidsOrdered, formData.functions_order)) {
    onChange(id, uuidsOrdered);
  }

  const itemsList = uuidsOrdered.map((uuid, index) => [
    index + 1,
    { uuid: uuid, title: functions.filter((f) => f.uuid === uuid)[0].title },
  ]);

  const onMoveItem = (source, destination) => {
    return move(itemsList, source, destination).map(([id, item]) => item.uuid);
  };

  return (
    <div className="functions_order-widget">
      <FormFieldWrapper
        {...props}
        noForInFieldLabel
        draggable={false}
      ></FormFieldWrapper>
      <div className="items-area">
        <DragDropList
          forwardedAriaLabelledBy={`fieldset-${
            fieldSet || 'default'
          }-field-label-${id}`}
          childList={itemsList}
          onMoveItem={(result) => {
            const { source, destination } = result;
            if (!destination) {
              return;
            }
            const updatedItemsList = onMoveItem(
              source.index,
              destination.index,
            );
            onChange(id, updatedItemsList);
            return true;
          }}
        >
          {(dragProps) => {
            const { child, childId, index, draginfo } = dragProps;
            return (
              <div
                ref={draginfo.innerRef}
                {...draginfo.draggableProps}
                key={childId}
              >
                <div className="panel-item" style={{ position: 'relative' }}>
                  <button
                    style={{
                      visibility: 'visible',
                      display: 'inline-block',
                    }}
                    {...draginfo.dragHandleProps}
                    className="drag handle"
                    onClick={(e) => e.preventDefault()}
                  >
                    <Icon name={dragSVG} size="18px" />
                  </button>
                  <div className="label">
                    {child.title || `Panel ${index + 1}`}
                  </div>
                </div>
              </div>
            );
          }}
        </DragDropList>
      </div>
    </div>
  );
};

export default FunctionsOrderWidget;
