import { FormattedMessage, useIntl } from 'react-intl';
import { Card, Grid, Label, Message } from 'semantic-ui-react';
import { ConditionalLink, Icon } from '@plone/volto/components';
import arrowSVG from '@package/svgs/icons/arrow-uniko.svg';
import { flattenToAppURL } from '@plone/volto/helpers';
import { injectLazyLibs } from '@plone/volto/helpers/Loadable/Loadable';

const GridItem = ({
  item,
  isEditMode,
  moment,
  hideImages = false,
  featured = false,
}) => {
  return (
    <ConditionalLink to={item['@id']} condition={!isEditMode}>
      <Card className={featured ? 'featured' : ''}>
        {!hideImages && (
          <>
            {item.image_field ? (
              <img
                alt={item.title}
                src={flattenToAppURL(
                  `${item['@id']}/@@images/${item.image_field}/huge`,
                )}
              />
            ) : (
              <div className="img-placeholder"></div>
            )}
          </>
        )}
        <Card.Content>
          <Card.Meta>
            <span className="date">
              {moment(item.currentFrom || item.Date).format('ll')}
            </span>
          </Card.Meta>
          <Card.Header className="awh">{item.title}</Card.Header>
          <div className="description">
            {item.description}
            <Icon name={arrowSVG} />
          </div>
          <div className="labels">
            <Label className="type">{item.typeLabel}</Label>
          </div>
        </Card.Content>
        <div className="box-shadow-bottom"></div>
      </Card>
    </ConditionalLink>
  );
};

const NewsItemsGrid = ({
  items,
  isEditMode,
  moment: momentlib,
  feature_first_item = false,
  no_images = false,
  group_by_month = false,
}) => {
  const intl = useIntl();
  const moment = momentlib.default;
  moment.locale(intl.locale);

  const getGroupsByMonth = (items) => {
    let groups = {};
    if (items.length === 0) return groups;
    items.forEach((item) => {
      let month = moment(item.currentFrom || item.created).format('MMMM YYYY');
      if (Object.keys(groups).indexOf(month) === -1) {
        groups[month] = [item];
      } else {
        groups[month].push(item);
      }
    });
    return groups;
  };

  const groups = group_by_month ? getGroupsByMonth(items) : [items];

  return (
    <div className={no_images ? 'newsgrid no-images' : 'newsgrid'}>
      {items.length === 0 ? (
        <Message>
          <FormattedMessage
            id="No matching contents found."
            defaultMessage="No matching contents found."
          />
        </Message>
      ) : (
        Object.keys(groups).map((key) => {
          const items = groups[key];
          return (
            <div key={`nig-key-${key}`}>
              {group_by_month && <h2>{key}</h2>}
              <Grid>
                <Grid.Row>
                  <Grid.Column
                    key={items[0]['@id']}
                    mobile={16}
                    tablet={feature_first_item ? 16 : 6}
                    computer={feature_first_item ? 16 : 6}
                    largeScreen={feature_first_item ? 6 : 3}
                    widescreen={feature_first_item ? 6 : 3}
                  >
                    <GridItem
                      item={items[0]}
                      isEditMode={isEditMode}
                      moment={moment}
                      hideImages={no_images}
                      featured={feature_first_item}
                    />
                  </Grid.Column>
                  {items.slice(1).map((item) => {
                    return (
                      <Grid.Column
                        key={item['@id']}
                        mobile={16}
                        tablet={6}
                        computer={6}
                        largeScreen={3}
                        widescreen={3}
                      >
                        <GridItem
                          item={item}
                          isEditMode={isEditMode}
                          moment={moment}
                          hideImages={no_images}
                        />
                      </Grid.Column>
                    );
                  })}
                </Grid.Row>
              </Grid>
            </div>
          );
        })
      )}
    </div>
  );
};

export default injectLazyLibs(['moment'])(NewsItemsGrid);
