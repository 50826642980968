import { GET_TRANSLATE } from '../../constants/ActionTypes';

export function translate(path) {
  const url = `${path}/@translate`;
  const subrequest = path;
  return {
    type: GET_TRANSLATE,
    subrequest,
    request: {
      op: 'get',
      path: url,
    },
  };
}
