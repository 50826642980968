import { FormattedMessage } from 'react-intl';
import { ConditionalLink, Icon as VoltoIcon } from '@plone/volto/components';
import 'react-multi-carousel/lib/styles.css';
import arrowSVG from '@package/svgs/icons/arrow-uniko.svg';
import EventCard from './EventCard';
import CarouselListing from './CarouselListing';

const EventsCarousel = ({
  items,
  isEditMode,
  link_to_all,
  show_time,
  show_description,
  no_infinity_scroll,
}) => {
  const responsive = {
    desktop: {
      breakpoint: { max: 9999999, min: 1400 },
      items: 3,
      partialVisibilityGutter: 0,
    },
    tablet: {
      breakpoint: { max: 1399, min: 992 },
      items: 2,
      partialVisibilityGutter: 30,
    },
    mobile: {
      breakpoint: { max: 991, min: 0 },
      items: 1,
      partialVisibilityGutter: 90,
    },
  };

  link_to_all &&
    link_to_all.length > 0 &&
    items.push({ link_to_all: link_to_all[0] });

  return (
    <CarouselListing
      items={items}
      responsive={responsive}
      no_infinity_scroll={no_infinity_scroll}
      renderItem={(item) =>
        item.link_to_all ? (
          <ConditionalLink to={item.link_to_all['@id']} condition={!isEditMode}>
            <div className="overview-item">
              <span>
                <FormattedMessage id="All events" defaultMessage="All events" />
                <VoltoIcon name={arrowSVG} className="link" size="24px" />
              </span>
            </div>
          </ConditionalLink>
        ) : (
          <EventCard
            key={item['@id']}
            event={item}
            isEditMode={isEditMode}
            showTime={show_time}
            showDescription={show_description}
          />
        )
      }
    />
  );
};

export default EventsCarousel;
