import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { Icon } from '@plone/volto/components';
import { Plug } from '@plone/volto/components/manage/Pluggable';
import PendingSVG from '@plone/volto/icons/pending.svg';

const WorklistWidget = (props) => {
  const intl = useIntl();
  const msgWorklist = {
    id: 'Worklist',
    defaultMessage: 'Worklist',
  };
  return (
    <Plug pluggable="main.toolbar.bottom" id="worklist">
      <Link
        id="toolbar-worklist"
        to="/worklist"
        title={intl.formatMessage(msgWorklist)}
      >
        <Icon name={PendingSVG} />
      </Link>
    </Plug>
  );
};

export default WorklistWidget;
