import { ResearchProjectCard } from '@package/components';

const ProjectsListing = ({ items, isEditMode }) => {
  const projects = items.filter((i) => i['@type'] === 'ResearchProject');
  return (
    <div className="items projects">
      {projects.map((project) => (
        <ResearchProjectCard
          key={project['@id']}
          project={project}
          linksDisabled={isEditMode}
        />
      ))}
    </div>
  );
};

export default ProjectsListing;
