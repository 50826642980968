import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Icon, Message } from 'semantic-ui-react';
import { getLSFHeadings, getLSFLectures } from '@package/actions';
import { getCurrentSemesterID, scrollToAnchor } from '@package/utils';
import LecturesSorted from './LecturesSorted';
import VeranstaltungPlaceholder from './VeranstaltungPlaceholder';

const SubHeadings = ({ headingId, onSelect, onNoResults, isEditMode }) => {
  const subrequest = useSelector(
    (state) => state.lsf_headings.subrequests?.[headingId],
  );
  const headings = subrequest?.items || [];
  const dispatch = useDispatch();
  useEffect(() => {
    if (subrequest?.loaded !== true) dispatch(getLSFHeadings(headingId));
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [dispatch, headingId]);

  const onClick = (heading) => {
    if (!isEditMode) onSelect(heading);
  };

  if (subrequest?.loaded && headings?.length === 0) onNoResults();

  return subrequest?.loading ? (
    <VeranstaltungPlaceholder />
  ) : (
    <>
      {headings
        .sort((a, b) => (a.title < b.title ? -1 : a.title > b.title ? 1 : 0))
        .map((heading) => (
          <nav className="nav-boxes compact" key={heading.id}>
            <div
              className="nav-box awh"
              onClick={() => {
                onClick(heading);
              }}
              onKeyDown={() => {
                onClick(heading);
              }}
              role="button"
              tabIndex={0}
            >
              {heading.title}
            </div>
          </nav>
        ))}
    </>
  );
};

const Lectures = ({
  headingId,
  onNoResults,
  sortMode,
  filterLanguage,
  isEditMode,
}) => {
  const semester = getCurrentSemesterID();

  const subrequestKey = `h${headingId}`;
  const subrequest = useSelector(
    (state) => state.lsf_lectures.subrequests?.[subrequestKey],
  );
  const dispatch = useDispatch();
  useEffect(() => {
    if (headingId && subrequest?.loaded !== true) {
      dispatch(getLSFLectures('/', true, subrequestKey, headingId));
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [dispatch, headingId, semester, subrequestKey]);

  const lectures = subrequest?.items;
  if (subrequest?.loaded && lectures?.length === 0) onNoResults();
  return subrequest?.loading ? (
    <VeranstaltungPlaceholder />
  ) : lectures?.length > 0 ? (
    <LecturesSorted
      lectures={lectures}
      sortMode={sortMode}
      filterLanguage={filterLanguage}
      isEditMode={isEditMode}
    />
  ) : (
    <></>
  );
};

const View = ({ data, isEditMode }) => {
  const [headingHistory, setHeadingHistory] = useState([]);
  const [noHeadingsFound, setNoHeadingsFound] = useState(false);
  const [noLecturesFound, setNoLecturesFound] = useState(false);

  const initial_heading = data.heading_data?.lsf_heading || false;

  useEffect(() => {
    if (initial_heading) {
      setHeadingHistory([
        {
          id: initial_heading,
          title: data.heading_data?.lsf_heading_title || '',
        },
      ]);
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [initial_heading]);

  const curHeading = headingHistory.length > 0 ? headingHistory.at(-1) : false;

  const goBack = () => {
    setNoHeadingsFound(false);
    setNoLecturesFound(false);
    setHeadingHistory(headingHistory.slice(0, -1)); // remove last element
  };

  const onSelect = (heading) => {
    setNoHeadingsFound(false);
    setNoLecturesFound(false);
    setHeadingHistory(headingHistory.concat(heading));
    setTimeout(() => {
      scrollToAnchor(heading.id);
    });
  };

  return curHeading ? (
    <div id={curHeading.id} className="block-lectures">
      {headingHistory.length > 1 && (
        <nav className="nav-pills">
          <div
            className="nav-pill"
            onClick={goBack}
            onKeyDown={goBack}
            role="button"
            tabIndex={0}
          >
            <Icon name="arrow left" />
            <FormattedMessage id="Back" defaultMessage="Back" />
          </div>
        </nav>
      )}
      <h3>{curHeading.title}</h3>
      {noLecturesFound && (data.hide_sub_headings || noHeadingsFound) ? (
        <Message warning>
          <FormattedMessage
            id="No matching lectures found."
            defaultMessage="No matching lectures found."
          />
        </Message>
      ) : (
        <>
          <Lectures
            headingId={curHeading.id}
            onNoResults={() => {
              setNoLecturesFound(true);
            }}
            sortMode={data.sort_mode}
            filterLanguage={data.filter_language}
            isEditMode={isEditMode}
          />
          {!(data.hide_sub_headings || false) && (
            <SubHeadings
              headingId={curHeading.id}
              onSelect={(heading) => onSelect(heading)}
              onNoResults={() => {
                setNoHeadingsFound(true);
              }}
              isEditMode={isEditMode}
            />
          )}
        </>
      )}
    </div>
  ) : (
    <></>
  );
};

export default View;
