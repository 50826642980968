import { useLocation } from 'react-router-dom';
import { Container } from 'semantic-ui-react';
import { Error } from '@plone/volto/components';

/* Helper component to allow rerouting of 
   unwanted Volto default routes.
*/
const RerouteHelper = () => {
  const location = useLocation();
  const targetUrl =
    location.pathname === '/passwordreset'
      ? 'https://profile.uni-koblenz.de/newpass.cgi'
      : location.pathname === '/register'
      ? 'https://profile.uni-koblenz.de/register.cgi'
      : location.pathname.startsWith('/Service')
      ? `https://www.uni-koblenz.de${location.pathname}`
      : location.pathname.startsWith('/search')
      ? `https://www.uni-koblenz.de/de/erweiterte-suche${location.search || ''}`
      : false;

  if (targetUrl === false) return <Error error={{ status: 404 }} />;

  // we use `.replace()` instead of `.href=`, because we do not want this
  // route to be in the history (can not navigate here via back button)
  if (typeof window !== 'undefined') window.location.replace(targetUrl);
  return (
    <Container>
      <a href={targetUrl}>{targetUrl}</a>
    </Container>
  );
};

export default RerouteHelper;
