/* This component renders a dropdown field which items are
   selectable via checkboxes.
*/
import { useRef } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { Icon } from '@plone/volto/components';
import { Checkbox, Dropdown } from 'semantic-ui-react';
import ArrowSVG from '@package/svgs/icons/arrow-uniko.svg';

const messages = defineMessages({
  all: {
    id: 'All',
    defaultMessage: 'All',
  },
  countSelected: {
    id: '{count} selected',
    defaultMessage: '{count} selected',
  },
});

const DropdownWithCheckboxes = ({
  title,
  items, // should be array of objects with key and value fields
  onChange,
  filterStatus,
  keyField = 'title',
  valueField = 'token',
  computeChecked = false,
  menuDirection = 'right',
}) => {
  const intl = useIntl();
  const checked =
    computeChecked === false
      ? (item) => filterStatus.indexOf(item[valueField]) > -1
      : computeChecked;
  const checkedCount = items.filter(checked).length;
  const text =
    checkedCount > 0
      ? intl.formatMessage(messages.countSelected, { count: checkedCount })
      : intl.formatMessage(messages.all);
  const itemsRef = useRef([]);
  return (
    <div className="dropdown-wrapper uniko">
      <label>{title}</label>
      <Dropdown
        item
        simple
        text={text}
        icon={<Icon name={ArrowSVG} />}
        /* if not explicitly set to `false` here, `open` can
             become `true` in the components state. But then the
             menu wont close on blur, which simply feels wrong.
          */
        open={false}
      >
        <Dropdown.Menu direction={menuDirection}>
          {items.map((item, i) => (
            <Dropdown.Item
              onClick={(e) => {
                /* spoof click on the actual checkbox since its very frustrating
                      if you click the Dropdown.Item an nothing happens ... */
                itemsRef.current[i].inputRef.current.click();
              }}
              key={`dd-${title.toLowerCase()}-${item[valueField]}`}
            >
              <Checkbox
                label={item[keyField]}
                value={item[valueField]}
                onChange={onChange}
                checked={checked(item)}
                ref={(el) => (itemsRef.current[i] = el)}
              />
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default DropdownWithCheckboxes;
