import { Container, Icon as SemanticUiIcon } from 'semantic-ui-react';
import { ConditionalLink, Icon } from '@plone/volto/components';
import arrowSVG from '@package/svgs/icons/arrow-uniko.svg';

const PreviewHero = ({
  heroImage,
  caption,
  subCaption,
  copyright,
  copyrightUrl,
  editable,
}) => {
  return (
    <figure
      className={editable ? 'preview-hero' : 'preview-hero fullwidth capped'}
    >
      {heroImage}
      {caption && (
        <Container id="preview-caption-container">
          <div className="preview-caption awh">
            <div className="preview-caption-arrow">
              <span>
                <Icon name={arrowSVG} className="link" color="white" />
              </span>
            </div>
            <div className="preview-caption-text">
              <h1
                className="title"
                dangerouslySetInnerHTML={{
                  __html: caption?.replaceAll('\\n', '<br/>'),
                }}
              ></h1>
              {subCaption && (
                <span
                  className="description"
                  dangerouslySetInnerHTML={{
                    __html: subCaption?.replaceAll('\\n', '<br/>'),
                  }}
                ></span>
              )}
            </div>
          </div>
        </Container>
      )}
      {copyright && (
        <figcaption className="copyright" title={copyright}>
          <ConditionalLink href={copyrightUrl} condition={copyrightUrl}>
            {copyright}
            {copyrightUrl && (
              <>
                {' '}
                <SemanticUiIcon name="external" />
              </>
            )}
          </ConditionalLink>
        </figcaption>
      )}
    </figure>
  );
};

export default PreviewHero;
