import { Card } from 'semantic-ui-react';
import PublicationByCitationStyle from './PublicationByCitationStyle';
import { ECAMPUS_BASE_URL } from './constants';

const Publication = ({
  data,
  columns,
  linksDisabled,
  language,
  citationStyle = false,
}) => {
  const creatorTypes = {
    1: 'Autor/-in',
    2: 'Hrsg.',
    3: 'Autor/-in',
    4: 'Hrsg.',
    5: 'Autor/-in',
    6: 'Hrsg.',
  };
  const getData = (field) => {
    if (columns) {
      const idx = columns.findIndex((c) => c.id === field);
      if (idx < 0 || idx >= data.length) return false;
      return data[idx];
    }
    /* if no columns mapping is provided, we assume
       data is already a well-formed dictionary
    */
    return data[field];
  };

  const pubId = getData('Publication.id');
  const langSuffix = language === 'en' ? '?sessionlanguage=en' : '';
  const url = `${ECAMPUS_BASE_URL}/qisserver/a/fs.res.frontend/pub/view/${pubId}${langSuffix}`;

  return citationStyle ? (
    <PublicationByCitationStyle
      pubUrl={url}
      pubId={pubId}
      citationStyle={citationStyle}
    />
  ) : (
    <Card
      className="publication"
      href={!linksDisabled ? url : false}
      target="_blank"
    >
      <Card.Content>
        <Card.Content extra>
          {getData('Publication.publicationType.defaulttext')}
        </Card.Content>
        <Card.Header>{getData('Publication.title')}</Card.Header>
        <Card.Meta>
          <span className="date">{getData('Publication.releaseYear')}</span>
        </Card.Meta>
        <Card.Description>
          {creatorTypes[getData('Publication.publicationCreatorType.hiskeyId')]}
          :{' '}
          {getData(
            "CONCAT2(Publication.creators.firstname,' ',Publication.creators.creatorname)",
          )}
        </Card.Description>
        {getData('Publication.journal.defaulttext') && (
          <Card.Content extra>
            In: {getData('Publication.journal.defaulttext')}
          </Card.Content>
        )}
      </Card.Content>
    </Card>
  );
};

export default Publication;
