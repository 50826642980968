import { defineMessages } from 'react-intl';

const messages = defineMessages({
  labelEmbedDegreeProgram: {
    id: 'Embed degree program',
    defaultMessage: 'Embed degree program',
  },
  labelSelectDegreeProgram: {
    id: 'Select degree program',
    defaultMessage: 'Select degree program',
  },
  labelShowDescription: {
    id: 'Show description',
    defaultMessage: 'Show description',
  },
  labelShowMaterials: {
    id: 'Show materials',
    defaultMessage: 'Show materials',
  },
  labelVariant: {
    id: 'Variant',
    defaultMessage: 'Variant',
  },
  variantDefault: {
    id: 'Default',
    defaultMessage: 'Default',
  },
  variantSummary: {
    id: 'Summary',
    defaultMessage: 'Summary',
  },
});

const DegreeProgramBlockSchema = (intl) => {
  return {
    title: intl.formatMessage(messages.labelEmbedDegreeProgram),

    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: ['href', 'variant', 'show_description', 'show_materials'],
      },
    ],

    properties: {
      href: {
        title: intl.formatMessage(messages.labelSelectDegreeProgram),
        widget: 'object_browser',
        widgetOptions: {
          pattern_options: {
            selectableTypes: ['DegreeProgram', 'CertificateProgram'],
          },
        },
        mode: 'link',
      },
      variant: {
        title: intl.formatMessage(messages.labelVariant),
        type: 'string',
        factory: 'Choice',
        choices: [
          ['default', intl.formatMessage(messages.variantDefault)],
          ['summary', intl.formatMessage(messages.variantSummary)],
        ],
        default: 'default',
      },
      show_description: {
        title: intl.formatMessage(messages.labelShowDescription),
        type: 'boolean',
        default: false,
      },
      show_materials: {
        title: intl.formatMessage(messages.labelShowMaterials),
        type: 'boolean',
        default: false,
      },
    },

    required: ['href'],
  };
};

export default DegreeProgramBlockSchema;
