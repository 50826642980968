import { FormattedMessage } from 'react-intl';
import { ConditionalLink } from '@plone/volto/components';
import { prettyPrintURL } from '@package/utils';

const PersonContact = ({
  person,
  restrictPhoneNrs = false,
  showMail = true,
  showWeb = false,
  linkDisabled,
  override = {},
}) => {
  let phones =
    override.telephone?.items?.length > 0
      ? override.telephone.items
      : person?.telephone?.items || [];
  if (restrictPhoneNrs) phones = phones.slice(0, restrictPhoneNrs);
  const email = override.email || person.email;
  return (
    <table className="person-contact" role="presentation">
      <tbody>
        {phones.map((item, index) => {
          const fullNr = `${item.country || ''} ${item.area || ''} ${
            item.number || ''
          }`
            .trim()
            .replace(/\(0\)/g, '');
          const fullNrClean = fullNr
            .replace(/ /g, '')
            .replace(/\(0\)/g, '')
            .replace(/-/, '');
          return (
            <tr key={`pl-${index}`}>
              <td>
                {item.remark ? (
                  item.remark
                ) : (
                  <FormattedMessage id="Phone" defaultMessage="Phone" />
                )}
                {':'}
              </td>
              <td>
                <ConditionalLink
                  to={`tel:${fullNrClean}`}
                  condition={!linkDisabled}
                >
                  {fullNr}
                </ConditionalLink>
              </td>
            </tr>
          );
        })}
        {showMail && email && (
          <tr>
            <td>
              <FormattedMessage id="E-Mail" defaultMessage="E-Mail" />
              {':'}
            </td>
            <td>
              <ConditionalLink to={`mailto:${email}`} condition={!linkDisabled}>
                {email.replace('@', '(at)')}
              </ConditionalLink>
            </td>
          </tr>
        )}
        {showWeb && person.homepageUrl && (
          <tr>
            <td>
              <FormattedMessage id="Web" defaultMessage="Web" />
              {':'}
            </td>
            <td>
              <a href={person.homepageUrl} target="_blank" rel="noreferrer">
                {prettyPrintURL(person.homepageUrl)}
              </a>
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
};

export default PersonContact;
