import { Image } from 'semantic-ui-react';
import { ConditionalLink } from '@plone/volto/components';
import { flattenToAppURL } from '@plone/volto/helpers';

const PersonCardImage = ({ person, linkDisabled }) => {
  let profileImageURL = '';
  if (person.photo) {
    profileImageURL = flattenToAppURL(
      person.photo.scales?.preview?.download || person.photo.download || '',
    );
  } else {
    profileImageURL = person.ext_profile_img || '';
  }

  return (
    <ConditionalLink
      to={flattenToAppURL(person['@id'])}
      condition={!linkDisabled}
      title={person.title}
      key={person['@id']}
    >
      {profileImageURL ? (
        <Image floated="left" size="tiny" src={profileImageURL} circular />
      ) : (
        <div className="image person-image-placeholder"></div>
      )}
    </ConditionalLink>
  );
};

export default PersonCardImage;
