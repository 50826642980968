/*
  Partial is a edtiable page fragment to be displayed within other pages.
*/
import { useSelector } from 'react-redux';
import { RenderBlocks } from '@plone/volto/components';
import config from '@plone/volto/registry';

const Partial = ({ name, onloaded }) => {
  /* we have a content expansion endpoint for `partials` */
  const partials = useSelector(
    (state) =>
      (state.content?.data &&
        (state.content.data['@components'] ?? {}).partials) ||
      false,
  );
  const partialContent =
    partials && partials.hasOwnProperty(name) ? partials[name] : false;

  if (onloaded && partialContent) onloaded();

  return partialContent ? (
    <RenderBlocks
      blocksConfig={config.blocks.blocksConfig}
      content={partialContent}
    />
  ) : (
    <></>
  );
};

export default Partial;
