import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { Icon } from '@plone/volto/components';
import addDocumentSVG from '@plone/volto/icons/add-document.svg';

const messages = defineMessages({
  addSubpage: {
    id: 'Add subpage',
    defaultMessage: 'Add subpage',
  },
});

const AddSubPageButton = ({ url, block = false }) => {
  const intl = useIntl();
  let classes = ['c2a-wrapper', 'secondary'];
  if (block) classes.push('block');
  return (
    <div className={classes.join(' ')}>
      <div className="c2a-button">
        <Link
          to={`${url}/add?type=Document`}
          className="add"
          title={intl.formatMessage(messages.addSubpage)}
        >
          <Icon
            name={addDocumentSVG}
            title={intl.formatMessage(messages.addSubpage)}
          />{' '}
          <FormattedMessage id="Add subpage" defaultMessage="Add subpage" />
        </Link>
      </div>
    </div>
  );
};

export default AddSubPageButton;
