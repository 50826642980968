import React from 'react';
import { defineMessages } from 'react-intl';
import ObjectListWidget from '@plone/volto/components/manage/Widgets/ObjectListWidget';

const messages = defineMessages({
  labelPhoneEntry: {
    id: 'Telephone entry',
    defaultMessage: 'Telephone entry',
  },
  labelAddPhoneEntry: {
    id: 'Add telephone entry',
    defaultMessage: 'Add telephone entry',
  },
  labelCountry: {
    id: 'Country',
    defaultMessage: 'Country',
  },
  labelArea: {
    id: 'Area',
    defaultMessage: 'Area',
  },
  labelNumber: {
    id: 'Number',
    defaultMessage: 'Number',
  },
  labelRemark: {
    id: 'Remark',
    defaultMessage: 'Remark',
  },
});

const ItemSchema = (intl) => {
  return {
    title: intl.formatMessage(messages.labelPhoneEntry),
    addMessage: intl.formatMessage(messages.labelAddPhoneEntry),
    properties: {
      country: {
        title: intl.formatMessage(messages.labelCountry),
        defaultValue: '+49',
      },
      area: {
        title: intl.formatMessage(messages.labelArea),
        defaultValue: '(0)261',
      },
      number: {
        title: intl.formatMessage(messages.labelNumber),
        defaultValue: '287 - 0',
      },
      remark: {
        title: intl.formatMessage(messages.labelRemark),
      },
    },
    fieldsets: [
      {
        id: 'default',
        title: intl.formatMessage(messages.labelPhoneEntry),
        fields: ['country', 'area', 'number', 'remark'],
      },
    ],
    required: [],
  };
};

const PhoneWidget = (props) => {
  let schema = ItemSchema(props.intl);
  return (
    <ObjectListWidget
      schema={schema}
      {...props}
      value={props.value?.items || props.default?.items || []}
      onChange={(id, value) => {
        value.forEach((entry) => {
          Object.keys(schema.properties).forEach((key) => {
            if ((entry[key] || '').length === 0) {
              entry[key] = schema.properties[key].defaultValue || '';
            }
          });
        });
        props.onChange(id, { items: value });
      }}
    />
  );
};

export { PhoneWidget };
