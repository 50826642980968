import React from 'react';
import InlineForm from '@plone/volto/components/manage/Form/InlineForm';
import { Icon, SidebarPortal } from '@plone/volto/components';
import tocSVG from '@plone/volto/icons/list-bullet.svg';
import ToCSchema from './schema';
import View from './View';

const Edit = (props) => {
  const schema = ToCSchema({ formData: props.data, intl: props.intl });
  return (
    <>
      <SidebarPortal selected={props.selected}>
        <InlineForm
          schema={schema}
          title={schema.title}
          icon={<Icon name={tocSVG} />}
          onChangeField={(id, value) => {
            props.onChangeBlock(props.block, {
              ...props.data,
              [id]: value,
            });
          }}
          formData={props.data}
        />
      </SidebarPortal>
      <View {...props} isEditMode={true} />
    </>
  );
};

export default Edit;
