import React from 'react';
import InlineForm from '@plone/volto/components/manage/Form/InlineForm';
import { Icon, SidebarPortal } from '@plone/volto/components';
import groupSVG from '@plone/volto/icons/group.svg';
import View from './View';
import PersonFunctionBlockSchema from './schema';
import { FormattedMessage } from 'react-intl';

const Edit = (props) => {
  const schema = PersonFunctionBlockSchema(props.intl);
  return (
    <>
      <SidebarPortal selected={props.selected}>
        <InlineForm
          schema={schema}
          title={schema.title}
          icon={<Icon name={groupSVG} />}
          onChangeField={(id, value) => {
            props.onChangeBlock(props.block, {
              ...props.data,
              [id]: value,
            });
          }}
          formData={props.data}
        />
      </SidebarPortal>
      {props.data.href && props.data.href.length > 0 ? (
        <View data={props.data} link-disabled={true} />
      ) : (
        <div>
          <div>
            <b>
              <FormattedMessage
                id="no_function_selected"
                defaultMessage="No person function selected yet."
              />
            </b>
          </div>
          <span>
            <FormattedMessage
              id="please_select_function_in_sidebar"
              defaultMessage="Please select the person function you want to embed in the right sidebar in the tab `Block`."
            />
          </span>
        </div>
      )}
    </>
  );
};

export default Edit;
