/**
 * View image block.
 *
 * Customized original component to implement following features:
 * - hide image on certain screen sizes (can be defined via block settings)
 * - display image caption
 * - lightbox modal to view detailed image
 *
 * @module components/manage/Blocks/Image/View
 */
import { useState } from 'react';
import PropTypes from 'prop-types';
import { ConditionalLink } from '@plone/volto/components';
import cx from 'classnames';
import {
  flattenToAppURL,
  isInternalURL,
  withBlockExtensions,
} from '@plone/volto/helpers';
import config from '@plone/volto/registry';
import { LightboxModal } from '@package/components';

/**
 * View image block class.
 * @class View
 * @extends Component
 */
export const View = ({ className, data, detached }) => {
  const [lightboxOpen, setLightboxOpen] = useState(false);

  const href =
    data.href && typeof data.href === 'string'
      ? data.href
      : data?.href?.[0]?.['@id'] || '';

  const Image = config.getComponent({ name: 'Image' }).component;

  const hideStr =
    data.hideonscreens?.length > 0
      ? `${data.hideonscreens.map((i) => `${i} hidden`).join(' ')}`
      : false;

  return (
    <div
      className={cx(
        {
          [hideStr]: hideStr !== false,
        },
        'block image align',
        {
          center: !Boolean(data.align),
          detached,
        },
        data.align,
        className,
      )}
    >
      {data.url && (
        <>
          {(() => {
            const image = (
              <Image
                onClick={() => {
                  if (data.lightbox) setLightboxOpen(true);
                }}
                item={
                  data.image_scales
                    ? {
                        '@id': data.url,
                        image_field: data.image_field,
                        image_scales: data.image_scales,
                      }
                    : undefined
                }
                src={
                  data.image_scales
                    ? undefined
                    : isInternalURL(data.url)
                    ? // Backwards compat in the case that the block is storing the full server URL
                      (() => {
                        if (data.size === 'l')
                          return `${flattenToAppURL(data.url)}/@@images/image`;
                        if (data.size === 'm')
                          return `${flattenToAppURL(
                            data.url,
                          )}/@@images/image/preview`;
                        if (data.size === 's')
                          return `${flattenToAppURL(
                            data.url,
                          )}/@@images/image/mini`;
                        return `${flattenToAppURL(data.url)}/@@images/image`;
                      })()
                    : data.url
                }
                sizes={config.blocks.blocksConfig.image.getSizes(data)}
                alt={data.alt || ''}
                loading="lazy"
                responsive={true}
                style={data.lightbox ? { cursor: 'pointer' } : {}}
              />
            );
            return (
              <figure
                className={cx({
                  'full-width': data.align === 'full',
                  large: data.size === 'l',
                  medium: data.size === 'm',
                  small: data.size === 's',
                })}
              >
                <ConditionalLink
                  condition={href.length > 0}
                  to={href}
                  openLinkInNewTab={data.openLinkInNewTab}
                >
                  {image}
                  {data.lightbox && (
                    <LightboxModal
                      src={flattenToAppURL(
                        data.url +
                          '/' +
                          data.image_scales?.image[0]?.download || '',
                      )}
                      caption={data.caption}
                      open={lightboxOpen}
                      setOpen={setLightboxOpen}
                      indicatorPosition={data.align}
                    />
                  )}
                </ConditionalLink>
                {data.caption && (
                  <figcaption className="image-caption">
                    {data.caption}
                  </figcaption>
                )}
              </figure>
            );
          })()}
        </>
      )}
    </div>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
View.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default withBlockExtensions(View);
