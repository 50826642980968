/* adapted ObjectBrowserBody to make sure current location
   is always the base path (instead of site root) when selecting stuff.
*/
/* eslint-disable-next-line import/no-unresolved */
import ObjectBrowserBodyOrig from '@plone/volto-original/components/manage/Sidebar/ObjectBrowserBody';

import { join } from 'lodash';

import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { searchContent } from '@plone/volto/actions/search/search';

/* since ObjectBrowserBodyOrig is a higher order component
   we need to use `.WrappedComponent` as base class here
*/
class ObjectBrowserBody extends ObjectBrowserBodyOrig.WrappedComponent {
  constructor(props) {
    super(props);
    this.state.currentFolder = this.props.contextURL || '/';
    this.state.parentFolder =
      `${join(this.state.currentFolder.split('/').slice(0, -1), '/')}` || '/';
    /* if selectableTypes are restricted, we only want to
       include those types in the search results.
    */
    if (props.widgetOptions?.pattern_options?.selectableTypes)
      this.state.searchableTypes =
        props.widgetOptions.pattern_options.selectableTypes;
  }
}

export default compose(
  injectIntl,
  connect(
    (state) => ({
      searchSubrequests: state.search.subrequests,
    }),
    { searchContent },
  ),
)(ObjectBrowserBody);
