/* Adapted Tags component, since we want to display more than just the Subject field */
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Container, Icon, Label } from 'semantic-ui-react';

const Tags = () => {
  const content = useSelector((state) => state.content?.data || false);
  const tags = content?.subjects || [];
  const org_units = content?.affiliated_org_units || [];
  let hrefSuffix = '';
  if (content['@type'] === 'News Item')
    hrefSuffix +=
      '&portal_type=News Item&sort_on=currentFrom&sort_order=descending';
  if (content['@type'] === 'Event')
    hrefSuffix += '&portal_type=Event&sort_on=start&sort_order=descending';
  return (
    <Container className="tags">
      {tags?.length > 0 && (
        <Label.Group tag>
          {tags.map((tag) => {
            let tagHref = `/de/erweiterte-suche?Subject=${tag.title}${hrefSuffix}`;
            return (
              <Link className="ui label" to={tagHref} key={tag.token}>
                #{tag.title}
              </Link>
            );
          })}
        </Label.Group>
      )}
      {org_units?.length > 0 && (
        <Label.Group>
          {org_units.map((tag) => {
            let orgUnitHref = `/de/erweiterte-suche?affiliated_org_units=${tag.token}${hrefSuffix}`;
            return (
              <Link className="ui label" to={orgUnitHref} key={tag.token}>
                <Icon name="home" />
                {tag.title}
              </Link>
            );
          })}
        </Label.Group>
      )}
    </Container>
  );
};

export default Tags;
