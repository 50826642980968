/**
 * translate reducer.
 * @module reducers/translate/translate
 */

import { GET_TRANSLATE } from '../../constants/ActionTypes';

const initialState = {
  error: null,
  translation: false,
  loaded: false,
  loading: false,
  subrequests: {},
};

/**
 * Get request key
 * @function getRequestKey
 * @param {string} actionType Action type.
 * @returns {string} Request key.
 */
function getRequestKey(actionType) {
  return actionType.split('_')[0].toLowerCase();
}

/**
 * translate reducer.
 * @function lectures
 * @param {Object} state Current state.
 * @param {Object} action Action to be handled.
 * @returns {Object} New state.
 */
export default function translate(state = initialState, action = {}) {
  switch (action.type) {
    case `${GET_TRANSLATE}_PENDING`:
      return action.subrequest
        ? {
            ...state,
            subrequests: {
              ...state.subrequests,
              [action.subrequest]: {
                ...(state.subrequests[action.subrequest] || {
                  data: null,
                }),
                loaded: false,
                loading: true,
                error: null,
              },
            },
          }
        : {
            ...state,
            [getRequestKey(action.type)]: {
              loading: true,
              loaded: false,
              error: null,
            },
          };
    case `${GET_TRANSLATE}_SUCCESS`:
      return action.subrequest
        ? {
            ...state,
            subrequests: {
              ...state.subrequests,
              [action.subrequest]: {
                loading: false,
                loaded: true,
                error: null,
                translation: action.result.translation,
                source_lang: action.result.source_lang,
                target_lang: action.result.target_lang,
              },
            },
          }
        : {
            ...state,
            translation: action.result.translation,
            source_lang: action.result.source_lang,
            target_lang: action.result.target_lang,
            [getRequestKey(action.type)]: {
              loading: false,
              loaded: true,
              error: null,
            },
          };
    case `${GET_TRANSLATE}_FAIL`:
      return action.subrequest
        ? {
            ...state,
            subrequests: {
              ...state.subrequests,
              [action.subrequest]: {
                data: null,
                loading: false,
                loaded: false,
                error: action.error,
              },
            },
          }
        : {
            ...state,
            data: null,
            [getRequestKey(action.type)]: {
              loading: false,
              loaded: false,
              error: action.error,
            },
          };
    default:
      return state;
  }
}
