import ViewOrig from '@plone/volto-original/components/manage/Blocks/HTML/View';

/* 
  adapt original component to make sure HTML is only rendered when
  `allow_html_block` is set on content.
*/
const View = (props) => {
  if (
    props.content?.allow_html_block ||
    props.metadata?.allow_html_block ||
    false
  )
    return <ViewOrig {...props} />;
  return <></>;
};

export default View;
