import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { Container, Icon } from 'semantic-ui-react';
import { flattenHTMLToAppURL } from '@plone/volto/helpers';

const GlobalBanner = () => {
  const intl = useIntl();
  const [isVisible, setIsVisible] = useState(true);
  const [isClientSide, setIsClientSide] = useState(false);

  const bannerRef = useRef();
  const onHeightUpdated = () => {
    if (isVisible && bannerRef.current) {
      const height = bannerRef.current.clientHeight;
      const main = document.getElementById('main');
      const headerWrapper = document.querySelector('.header-wrapper');
      main.style.paddingTop = `${height}px`;
      headerWrapper.style.top = `${height}px`;
    } else {
      const main = document.getElementById('main');
      const headerWrapper = document.querySelector('.header-wrapper');
      main.style.paddingTop = '0';
      headerWrapper.style.top = '0';
    }
  };

  useEffect(() => {
    setIsClientSide(true);
  }, []);

  /* we have a content expansion endpoint for `globalbanner` */
  const globalBanner = useSelector(
    (state) =>
      (state.content?.data &&
        (state.content.data['@components'] ?? {}).globalbanner) ||
      false,
  );

  const handleDismiss = () => {
    setIsVisible(false);
    onHeightUpdated();
  };

  const nl2br = (text) => {
    return text.split('\n').join('<br/>');
  };

  if (globalBanner && isClientSide) onHeightUpdated();

  if (globalBanner && globalBanner.content && isVisible) {
    return (
      <div
        ref={bannerRef}
        className="global-banner"
        style={{
          backgroundColor: globalBanner.color || '#f8d7da',
        }}
      >
        <Container>
          <span
            dangerouslySetInnerHTML={{
              __html: flattenHTMLToAppURL(
                nl2br(globalBanner.content[intl.locale]),
              ),
            }}
          ></span>
        </Container>
        {globalBanner.closable && <Icon name="close" onClick={handleDismiss} />}
      </div>
    );
  }

  return <></>;
};

export default GlobalBanner;
