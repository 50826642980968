/*
  Renders an Accordion which items have
  blocks as content (for example Pages).
*/
import React, { useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import AnimateHeight from 'react-animate-height';
import { Accordion, Placeholder } from 'semantic-ui-react';
import { Icon, RenderBlocks } from '@plone/volto/components';
import arrowSVG from '@package/svgs/icons/arrow-uniko.svg';
import penSVG from '@plone/volto/icons/pen.svg';
import { flattenToAppURL } from '@plone/volto/helpers';
import config from '@plone/volto/registry';
import AddSubPageButton from './AddSubPageButton';

const messages = defineMessages({
  edit: {
    id: 'Edit',
    defaultMessage: 'Edit',
  },
});

const Loading = () => (
  <>
    <br />
    <Placeholder fluid>
      <Placeholder.Paragraph>
        <Placeholder.Line />
        <Placeholder.Line />
        <Placeholder.Line />
        <Placeholder.Line />
        <Placeholder.Line />
        <Placeholder.Line />
        <Placeholder.Line />
      </Placeholder.Paragraph>
    </Placeholder>
  </>
);

const PersonSubPagesAccordion = ({
  personUrl,
  subpages,
  editAction = false,
  exclusive = false,
}) => {
  const intl = useIntl();
  const [openPanels, setOpenPanels] = useState([]);

  const handleClick = (e, titleProps) => {
    const { index } = titleProps;
    if (openPanels.indexOf(index) > -1) {
      setOpenPanels(openPanels.filter((item) => item !== index));
    } else {
      if (exclusive) {
        setOpenPanels([index]);
      } else {
        setOpenPanels(openPanels.concat(index));
      }
    }
  };

  const isPanelOpen = (panelId) => {
    return openPanels.indexOf(panelId) > -1;
  };

  return (
    <Accordion className="subpages-acc" fluid exclusive={exclusive}>
      {subpages.map((item, index) => {
        return (
          <React.Fragment key={item['@id']}>
            <Accordion.Title
              active={isPanelOpen(index)}
              index={index}
              onClick={handleClick}
            >
              <Icon name={arrowSVG} />
              {item.title}
              {editAction && (
                <Link
                  to={flattenToAppURL(item['@id']) + '/edit'}
                  className="edit"
                  title={intl.formatMessage(messages.edit)}
                >
                  <Icon
                    name={penSVG}
                    size="18px"
                    className="circled"
                    title={intl.formatMessage(messages.edit)}
                  />
                </Link>
              )}
            </Accordion.Title>
            <AnimateHeight
              animateOpacity
              duration={500}
              height={isPanelOpen(index) ? 'auto' : 0}
            >
              <Accordion.Content active={isPanelOpen(index)}>
                {item.blocks ? (
                  <RenderBlocks
                    blocksConfig={config.blocks.blocksConfig}
                    content={item}
                  />
                ) : (
                  <Loading />
                )}
              </Accordion.Content>
            </AnimateHeight>
          </React.Fragment>
        );
      })}
      {editAction && <AddSubPageButton url={personUrl} block={true} />}
    </Accordion>
  );
};

export default PersonSubPagesAccordion;
