/**
 * Logo component.
 * @module components/theme/Logo/Logo
 */

import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { defineMessages, useIntl } from 'react-intl';
import { Image } from 'semantic-ui-react';

import LogoDE from '@package/svgs/logos/LogoDE.svg';
import LogoEN from '@package/svgs/logos/LogoEN.svg';
import LogoNdF from '@package/pngs/ndf_120.png';
import LogoNdFMobile from '@package/pngs/ndf_mobile.png';
import LogoFB1DE from '@package/svgs/logos/fb1/LogoDE.svg';
import LogoFB1EN from '@package/svgs/logos/fb1/LogoEN.svg';
import LogoFB2DE from '@package/svgs/logos/fb2/LogoDE.svg';
import LogoFB2EN from '@package/svgs/logos/fb2/LogoEN.svg';
import LogoFB3DE from '@package/svgs/logos/fb3/LogoDE.svg';
import LogoFB3EN from '@package/svgs/logos/fb3/LogoEN.svg';
import LogoFB4DE from '@package/svgs/logos/fb4/LogoDE.svg';
import LogoFB4EN from '@package/svgs/logos/fb4/LogoEN.svg';
import LogoBibDE from '@package/svgs/logos/bib/LogoDE.svg';
import LogoHpaDE from '@package/svgs/logos/hpa/LogoDE.svg';
import LogoIkarusDE from '@package/svgs/logos/ikarus/LogoDE.svg';
import LogoIfgpzDE from '@package/svgs/logos/ifgpz/LogoDE.svg';
import LogoZimtDE from '@package/svgs/logos/zimt/LogoDE.svg';
import LogoIzlDE from '@package/svgs/logos/izl/LogoDE.svg';
import LogoZflDE from '@package/svgs/logos/zfl/LogoDE.svg';
import LogoZfuwDE from '@package/svgs/logos/zfuw/LogoDE.svg';

const messages = defineMessages({
  logo: {
    id: 'Logo',
    defaultMessage: 'Logo',
  },
});

const Logo = () => {
  const intl = useIntl();
  const location = useLocation();

  /* map logo str to component */
  const logoMappings = {
    logode: LogoDE,
    logoen: LogoEN,
    logofb1de: LogoFB1DE,
    logofb2de: LogoFB2DE,
    logofb3de: LogoFB3DE,
    logofb4de: LogoFB4DE,
    logofb1en: LogoFB1EN,
    logofb2en: LogoFB2EN,
    logofb3en: LogoFB3EN,
    logofb4en: LogoFB4EN,
    logobibde: LogoBibDE,
    logohpade: LogoHpaDE,
    logoikarusde: LogoIkarusDE,
    logoifgpzde: LogoIfgpzDE,
    logozimtde: LogoZimtDE,
    logoizlde: LogoIzlDE,
    logozflde: LogoZflDE,
    logozfuwde: LogoZfuwDE,
    logondf: LogoNdF,
  };

  /* we have a content expansion endpoint for `customizations` */
  const customizations = useSelector(
    (state) =>
      (state.content?.data &&
        (state.content.data['@components'] ?? {}).customizations) ||
      false,
  );

  let logoImage = LogoDE;
  let logoMobile = false;
  let logoLink = '/de';
  let logoClass = '';

  const updateLogo = () => {
    /* find longest matching key */
    let longestMatch = false;
    let matchLength = 0;
    Object.keys(customizations.institutions_logos).forEach((key) => {
      if (location.pathname.startsWith(key) && key.length > matchLength) {
        longestMatch = key;
      }
    });

    if (longestMatch) {
      const logoId =
        customizations.institutions_logos[longestMatch].toLowerCase();
      if (logoId in logoMappings) {
        logoImage = logoMappings[logoId];
        logoLink = longestMatch;
        /* make sure multi line subtitles are still readable */
        if (logoId === 'logoifgpzde') logoClass = 'triple';
        if (logoId === 'logoikarusde') logoClass = 'double';
        if (logoId === 'logozfuwde') logoClass = 'double';
        if (logoId === 'logozimtde') logoClass = 'double';
        if (logoId === 'logondf') {
          logoClass = 'ndf';
          logoMobile = LogoNdFMobile;
        }
      }
    }
  };
  if (customizations?.institutions_logos) updateLogo();

  return (
    <Link to={logoLink}>
      <Image
        src={logoImage}
        alt={intl.formatMessage(messages.logo)}
        className={logoClass}
      />
      {logoMobile && (
        <Image
          src={logoMobile}
          alt={intl.formatMessage(messages.logo)}
          className={`${logoClass} mobile`}
        />
      )}
    </Link>
  );
};

export default Logo;
