import { FormattedMessage } from 'react-intl';
import { Card, Icon, List } from 'semantic-ui-react';
import { ConditionalLink } from '@plone/volto/components';

const LSF_BASE_URL = 'https://klips.uni-koblenz.de';

/* why are those components named in german?
   -> each component corresponds to a (originally XML, now JSON)
      field of the original SOAP LSF response.
*/

const VTRaum = ({ data }) => {
  const parts = [];
  if (data.VTRaumBezeichnung) parts.push(data.VTRaumBezeichnung);
  if (data.VTGebaeudeBezeichnung) parts.push(data.VTGebaeudeBezeichnung);
  return <span>{parts.join(', ')}</span>;
};

const VTermin = ({ data }) => {
  let dates = [];
  if (data.VTBeginnDatum) dates.push(data.VTBeginnDatum);
  if (data.VTEndeDatum) dates.push(data.VTEndeDatum);
  let datesStr = dates.length > 0 ? dates.join(' - ') + ', ' : '';
  const isExam = data.TerminPruef ? data.TerminPruef === 'J' : false;
  return (
    <div className="date">
      <div>
        <Icon name={isExam ? 'bell outline' : 'calendar outline'} /> {datesStr}
        {data.VTBeginnUhrzeit} - {data.VTEndeUhrzeit} Uhr
      </div>
      <div>
        {data.VTWochenTag.VTWochenTagBezeichnung} ({data.VTRhythmus}){' '}
      </div>
      <div>
        {data.VLehrperson && '(' + data.VLehrperson.VLehrpersonNachname + ')'}
      </div>
      {data.VTRaum && (
        <div>
          <VTRaum data={data.VTRaum} />
        </div>
      )}
    </div>
  );
};

const VTermine = ({ data }) => {
  if (data.length === 0) return <></>;
  return (
    <List divided relaxed>
      {data.map((item, index) => {
        return (
          <List.Item key={`vt-${index}`}>
            {item && <VTermin data={item} />}
          </List.Item>
        );
      })}
    </List>
  );
};

const Lehrperson = ({ data, isEditMode }) => {
  let nameParts = [];
  if (data.VLehrpersonTitel)
    nameParts.push(data.VLehrpersonTitel.VLehrpersonTitelBezeichnung);
  if (data.VLehrpersonVorname) nameParts.push(data.VLehrpersonVorname);
  if (data.VLehrpersonNachname) nameParts.push(data.VLehrpersonNachname);

  let href = '';
  if (data.VLehrpersonID) {
    href = `${LSF_BASE_URL}/qisserver/rds?state=verpublish&moduleCall=webInfo&publishConfFile=webInfoPerson&publishSubDir=personal&personal.pid=${data.VLehrpersonID}`;
  }
  return (
    <ConditionalLink to={href} condition={!isEditMode && href.length > 0}>
      {nameParts.join(' ')}
    </ConditionalLink>
  );
};

const VLehrperson = ({ data, lectureId, isEditMode }) => {
  if (Array.isArray(data)) {
    return data.map((person, index) => (
      <span key={`${lectureId}-${person.VLehrpersonID}`}>
        <Lehrperson data={person} isEditMode={isEditMode} />
        {index < data.length - 1 && ', '}
      </span>
    ));
  }
  return <Lehrperson data={data} isEditMode={isEditMode} />;
};

const Veranstaltung = ({
  data,
  isEditMode,
  hideHeader = false,
  hideDates = false,
  hideComment = false,
  hideKLIPSLink = false,
}) => {
  const klipsHref = `${LSF_BASE_URL}/v/${data.VeranstaltungID}`;
  const href =
    data.VeranstaltungLink && data.VeranstaltungLink.length > 0
      ? data.VeranstaltungLink
      : klipsHref;

  const dates = (Array.isArray(data.VTermine.VTermin)
    ? data.VTermine.VTermin
    : [data.VTermine.VTermin]
  ).filter((t) => t);

  const examDates = dates.filter((t) => t.TerminPruef === 'J');
  const notExamDates = dates.filter((t) => t.TerminPruef !== 'J');

  return (
    data && (
      <Card fluid className="lsf-lecture">
        <Card.Content>
          {!hideHeader && (
            <>
              <Card.Header>
                <ConditionalLink
                  to={href}
                  target="_blank"
                  condition={!isEditMode}
                >
                  {data.VBezeichnung}
                </ConditionalLink>
              </Card.Header>
              <Card.Meta>
                {data.VArt}{' '}
                {(data.usprache === '2' || data.VeranstSprache === '2') && (
                  <span>
                    (<FormattedMessage id="english" defaultMessage="english" />)
                  </span>
                )}
              </Card.Meta>
            </>
          )}
          {data.VLehrperson && (
            <Card.Description>
              <VLehrperson
                data={data.VLehrperson}
                lectureId={data.VeranstaltungID}
                isEditMode={isEditMode}
              />
            </Card.Description>
          )}
        </Card.Content>
        {!hideDates && (examDates?.length > 0 || notExamDates?.length > 0) && (
          <>
            <Card.Content extra>
              <VTermine data={notExamDates} />
            </Card.Content>
            {examDates?.length > 0 && (
              <Card.Content extra>
                <strong>
                  {examDates.length > 1 ? (
                    <FormattedMessage
                      id="Exam dates"
                      defaultMessage="Exam dates"
                    />
                  ) : (
                    <FormattedMessage
                      id="Exam date"
                      defaultMessage="Exam date"
                    />
                  )}
                </strong>
                <VTermine data={examDates} />
              </Card.Content>
            )}
          </>
        )}
        {!hideComment && data.VeranstKurzKommentar && (
          <Card.Content extra>
            <div
              dangerouslySetInnerHTML={{
                __html: data.VeranstKurzKommentar.FormattedText,
              }}
            />
          </Card.Content>
        )}
        {!hideKLIPSLink && (
          <Card.Content extra>
            <FormattedMessage id="KLIPS" defaultMessage="KLIPS" />
            {': '}
            <a href={klipsHref}>{klipsHref}</a>
          </Card.Content>
        )}
      </Card>
    )
  );
};

export default Veranstaltung;
