const UniKoblenz = () => {
  return {
    '@context': 'https://schema.org',
    '@type': 'EducationalOrganization',
    name: 'Universität Koblenz',
    telephone: '+49 261 287 0',
    email: 'service@uni-koblenz.de',
    address: {
      '@type': 'PostalAddress',
      addressLocality: 'Koblenz, Deutschland',
      postalCode: '56070',
      streetAddress: 'Universitätsstraße 1',
    },
    url: 'https://www.uni-koblenz.de',
    image: 'https://www.uni-ko.de/logo',
  };
};

export default UniKoblenz;
