import { defineMessages } from 'react-intl';

const messages = defineMessages({
  labelEventsSettings: {
    id: 'Settings',
    defaultMessage: 'Settings',
  },
  labelOnlyUpcoming: {
    id: 'Show only upcoming events',
    defaultMessage: 'Show only upcoming events',
  },
  descrOnlyUpcoming: {
    id: 'Otherwise, all dates up to one year in the past are shown.',
    defaultMessage:
      'Otherwise, all dates up to one year in the past are shown.',
  },
  labelLimit: {
    id: 'Limit to',
    defaultMessage: 'Limit to',
  },
  descrLimit: {
    id: 'Otherwise, all dates up to one year in the future are shown.',
    defaultMessage:
      'Otherwise, all dates up to one year in the future are shown.',
  },
  labelShowTime: {
    id: 'Show time',
    defaultMessage: 'Show time',
  },
  labelShowDescription: {
    id: 'Show description',
    defaultMessage: 'Show description',
  },
  descriptionIcsUrl: {
    id: 'URL to an external iCalendar file.',
    defaultMessage: 'URL to an external iCalendar file.',
  },
  labelNoInfinityScroll: {
    id: 'No infinite carousel',
    defaultMessage: 'No infinite carousel',
  },
  descrNoInfinityScroll: {
    id: 'Carousel ends with the last event.',
    defaultMessage: 'Carousel ends with the last event.',
  },
});

const EventsBlockSchema = (intl) => {
  return {
    title: intl.formatMessage(messages.labelEventsSettings),

    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: [
          'ics_url',
          'only_upcoming',
          'limit',
          'show_time',
          'show_description',
          'no_infinity_scroll',
        ],
      },
    ],

    properties: {
      ics_url: {
        title: 'iCal/ics-URL',
        description: intl.formatMessage(messages.descriptionIcsUrl),
      },
      only_upcoming: {
        title: intl.formatMessage(messages.labelOnlyUpcoming),
        description: intl.formatMessage(messages.descrOnlyUpcoming),
        type: 'boolean',
        default: true,
      },
      limit: {
        title: intl.formatMessage(messages.labelLimit),
        description: intl.formatMessage(messages.descrLimit),
        type: 'number',
        minimum: 1,
      },
      show_time: {
        title: intl.formatMessage(messages.labelShowTime),
        type: 'boolean',
        default: false,
      },
      show_description: {
        title: intl.formatMessage(messages.labelShowDescription),
        type: 'boolean',
        default: false,
      },
      no_infinity_scroll: {
        title: intl.formatMessage(messages.labelNoInfinityScroll),
        description: intl.formatMessage(messages.descrNoInfinityScroll),
        type: 'boolean',
        default: false,
      },
    },
    required: [],
  };
};

export default EventsBlockSchema;
