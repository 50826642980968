import React from 'react';
import { PersonCard } from '@package/components';

const View = (props) => {
  const data = props['data'];
  const linkDisabled = props['link-disabled'] || false;

  return data.href && data.href.length > 0 ? (
    <PersonCard
      person={data.href[0]}
      hide-image={data.hide_image}
      hide-description={data.hide_description}
      hide-remark={data.hide_remark}
      hide-function={data.hide_function}
      link-disabled={linkDisabled}
    />
  ) : data.external_href ? (
    /* external person */
    <PersonCard
      is-external={true}
      additional-classes={['uniko-hide-uk']}
      person={{
        '@id': data.external_href || '#',
        title: data.external_name || '',
        description: data.external_description || '',
        remark: data.external_remark || '',
        ext_profile_img: data.external_image || '',
      }}
      no-hydration={true}
      override={{
        function: data.external_function || '',
        telephone: {
          items: data.external_phone
            ? [
                {
                  number: data.external_phone,
                },
              ]
            : [],
        },
        email: data.external_email || '',
      }}
      hide-image={data.hide_image}
      hide-description={data.hide_description}
      hide-remark={data.hide_remark}
      hide-function={data.hide_function}
      link-disabled={linkDisabled}
    />
  ) : (
    <></>
  );
};

export default View;
