/**
 * HeadingsSelectWidget component.
 * @module components/HeadingsSelectWidget/HeadingsSelectWidget
 */

import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { SelectWidget } from '@plone/volto/components';
import { getLSFHeadings } from '@package/actions';
import { SemestersSelectWidget } from '@package/components';

/* lsf_heading_path attribute contains path of headings to currently
   selected heading. This allows us to prefill select boxes when
   editing existing LSF lectures object.
*/
const HEADING_PATH_GLUE = ',';

function updateHeadingPath(pathStr, level, value) {
  var path = pathStr.split(HEADING_PATH_GLUE);
  path = path.slice(0, level - 1);
  path.push(value);
  return path.join(HEADING_PATH_GLUE);
}

function getHeadingFromPath(pathStr, level) {
  var path = pathStr.split(HEADING_PATH_GLUE);
  if (level > path.length) return false;
  return path[level - 1];
}

/**
 * SubHeadingSelectWidget component class.
 * @function HeadingsSelectWidget
 * @returns {string} Markup of the component.
 *                   If `prop.heading` contains subheadings,
 *                   returns recursively another SubHeadingSelectWidget for those.
 */
const SubHeadingSelectWidget = (props) => {
  const [selectedHeading, setSelectedHeading] = useState(false);
  const onChange = (elid, value) => {
    const found = headings.find((h) => h.id === value);
    setSelectedHeading(value);
    /* call original onChange method with original field id
       to ensure heading id is stored properly in form data: */
    props.onChangeOriginal(props.id, {
      lsf_heading: value,
      lsf_heading_title: found?.title || '',
      lsf_heading_path: updateHeadingPath(
        props.headingPath,
        props.level,
        value,
      ),
      semester_id: props.semesterId,
    });
  };

  /* load possible subheadings */
  var headings = false;
  const subrequest = useSelector(
    (state) => state.lsf_headings.subrequests?.[props.heading],
  );
  headings = subrequest?.items;
  const dispatch = useDispatch();
  React.useEffect(() => {
    setSelectedHeading(false);
    if (subrequest?.loaded !== true) dispatch(getLSFHeadings(props.heading));
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [dispatch, props.heading]);

  var choices = false;
  var selectedChoice = false;
  if (headings && headings.length > 0) {
    /* in ArrayWidget `choices` contains list of selectable items */
    let valueFromPath = getHeadingFromPath(props.headingPath, props.level);
    choices = headings.map((item) => {
      if (valueFromPath === item.id) selectedChoice = item;
      return [item.id, item.title];
    });
    if (selectedChoice && selectedChoice.id !== selectedHeading) {
      setSelectedHeading(selectedChoice.id);
    }
  }

  return (
    <>
      {choices && (
        <SelectWidget
          title={`${props.title} ${props.level}`}
          id={`${props.id} ${props.level}`}
          choices={choices}
          value={selectedChoice}
          onChange={onChange}
        ></SelectWidget>
      )}
      {selectedHeading && (
        <SubHeadingSelectWidget
          title={props.title}
          id={props.id}
          level={props.level + 1}
          heading={selectedHeading}
          headingPath={props.headingPath}
          semesterId={props.semesterId}
          onChangeOriginal={props.onChangeOriginal}
        ></SubHeadingSelectWidget>
      )}
    </>
  );
};

/**
 * HeadingsSelectWidget component class.
 * @function HeadingsSelectWidget
 * @returns {string} Markup of the component.
 */
const HeadingsSelectWidget = (props) => {
  const initialValues = props.value || props.default;
  const [selectedHeading, setSelectedHeading] = useState(
    initialValues.lsf_heading,
  );
  const onChange = (elid, value) => {
    const found = headings.find((h) => h.id === value);
    setSelectedHeading(value);
    /* call original onChange method with original field id
       to ensure heading id is stored properly in form data: */
    props.onChange(props.id, {
      lsf_heading: value,
      lsf_heading_title: found?.title || '',
      lsf_heading_path: updateHeadingPath(
        initialValues.lsf_heading_path,
        1,
        value,
      ),
      semester_id: initialValues.semester_id,
    });
  };

  /* load root headings of selected semester */
  var semesterID = initialValues['semester_id'];
  if (semesterID !== null && typeof semesterID === 'object')
    semesterID = semesterID.token;
  var headings = false;
  const subrequest = useSelector(
    (state) => state.lsf_headings.subrequests?.[semesterID],
  );
  headings = subrequest?.items;
  const dispatch = useDispatch();
  React.useEffect(() => {
    setSelectedHeading(false);
    if (subrequest?.loaded !== true) dispatch(getLSFHeadings(null, semesterID));
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [dispatch, semesterID]);

  var choices = false;
  var selectedChoice = false;
  if (headings && headings.length > 0) {
    /* in ArrayWidget `choices` contains list of selectable items */
    let valueFromPath = getHeadingFromPath(initialValues.lsf_heading_path, 1);
    choices = headings.map((item) => {
      if (valueFromPath === item.id) selectedChoice = item;
      return [item.id, item.title];
    });
    if (selectedChoice && selectedChoice.id !== selectedHeading) {
      setSelectedHeading(selectedChoice.id);
    }
  }

  const onChangeSemester = (elid, value) => {
    props.onChange(props.id, {
      lsf_heading: false,
      lsf_heading_path: '',
      semester_id: value,
    });
  };

  return (
    <>
      <SemestersSelectWidget
        value={initialValues.semester_id}
        onChange={onChangeSemester}
      />
      {choices && (
        <SelectWidget
          title={`${props.title} 1`}
          id={`${props.id}-1`}
          choices={choices}
          value={selectedChoice}
          onChange={onChange}
        ></SelectWidget>
      )}
      {selectedHeading && (
        <SubHeadingSelectWidget
          title={props.title}
          id={props.id}
          level={2}
          heading={selectedHeading}
          headingPath={initialValues.lsf_heading_path}
          semesterId={initialValues.semester_id}
          onChangeOriginal={props.onChange}
        ></SubHeadingSelectWidget>
      )}
    </>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
HeadingsSelectWidget.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  required: PropTypes.bool,
  error: PropTypes.arrayOf(PropTypes.string),
  value: PropTypes.object,
  onChange: PropTypes.func,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
};

/**
 * Default properties.
 * @property {Object} defaultProps Default properties.
 * @static
 */
HeadingsSelectWidget.defaultProps = {
  description: null,
  required: false,
  error: [],
  value: null,
  onChange: null,
  onEdit: null,
  onDelete: null,
};

export default injectIntl(HeadingsSelectWidget);
