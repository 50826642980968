import React from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { Container, List } from 'semantic-ui-react';
import { Icon } from 'semantic-ui-react';

const SocialIcon = ({ item }) => {
  const guessName = (url) => {
    const lower = url.toLowerCase();
    const mappings = {
      bluesky: 'bluesky',
      bsky: 'bluesky',
      facebook: 'facebook',
      instagram: 'instagram',
      linkedin: 'linkedin',
      mastodon: 'mastodon',
      twitter: 'x-twitter',
      youtube: 'youtube',
    };
    const found = Object.keys(mappings).filter(
      (key) => lower.indexOf(key) > -1,
    );
    if (found.length === 0) return 'question';
    return mappings[found[0]];
  };
  const name = item.icon || guessName(item.url);
  return (
    <a href={item.url} target="_blank" rel="noreferrer" aria-label={name}>
      <Icon name={name.toLowerCase()} size="large" />
    </a>
  );
};

const MegaFooter = ({ socialUrls }) => {
  const currentLang = useSelector((state) => state.intl.locale);

  const location = useLocation();
  const url = location.pathname.endsWith('/')
    ? location.pathname
    : `${location.pathname}/`;
  const loginUrl =
    url.indexOf('/login/') > -1
      ? location.pathname
      : url.split('/').length > 2
      ? `${url}login`
      : `/${currentLang}/login`;

  return (
    <Container id="mega-footer" className="mega-footer">
      {currentLang === 'de' && (
        <List>
          <List.Item className="mobile tablet only">
            {/* intentionally no Link component, since we need a real
                page reload to trigger Shibboleth login
            */}
            <a href={loginUrl}>
              Anmelden <Icon name="sign-in" />
            </a>
          </List.Item>
          <List.Item>
            <Link to="/de/impressum">Impressum</Link>
          </List.Item>
          <List.Item>
            <Link to="/de/datenschutz">Datenschutz</Link>
          </List.Item>
          <List.Item>
            <Link to="/de/barrierefreiheit">Barrierefreiheit</Link>
          </List.Item>
          <List.Item>
            <Link to="/de/gleichstellung">Gleichstellungsvertretung</Link>
          </List.Item>
        </List>
      )}
      {currentLang === 'en' && (
        <List>
          <List.Item className="mobile tablet only">
            {/* intentionally no Link component, since we need a real
                page reload to trigger Shibboleth login
            */}
            <a href={loginUrl}>
              Login <Icon name="sign-in" />
            </a>
          </List.Item>
          <List.Item>
            <Link to="/en/legal-notice">Impressum</Link>
          </List.Item>
          <List.Item>
            <Link to="/en/data-privacy">Data Privacy</Link>
          </List.Item>
          <List.Item>
            <Link to="/en/accessibility">Accessibility</Link>
          </List.Item>
          <List.Item>
            <Link to="/de/gleichstellung">Equal Opportunities</Link>
          </List.Item>
        </List>
      )}
      {socialUrls?.length > 0 && (
        <List>
          {socialUrls.map((item) => {
            return (
              <List.Item key={item.url}>
                <SocialIcon item={item} />
              </List.Item>
            );
          })}
        </List>
      )}
    </Container>
  );
};

export default MegaFooter;
