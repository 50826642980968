import { ConditionalLink } from '@plone/volto/components';
import { flattenToAppURL } from '@plone/volto/helpers';
import { DegreeProgramMaterials } from '@package/components';

const Inner = ({ content, addClasses, showDescription, maxDescrChars }) => {
  return (
    <div
      className={['degree-program-card', ...addClasses].join(' ')}
      style={content.color ? { backgroundColor: content.color } : {}}
    >
      <div className="meta">
        {content.degree?.map((i) => i.title).join(', ')}
      </div>
      <div
        className="title awh"
        dangerouslySetInnerHTML={{
          __html: (content.formatted_title || content.title || '').replaceAll(
            '\\n',
            '<br/>',
          ),
        }}
      ></div>
      {showDescription && (
        <div className="description">
          {content.description.length > maxDescrChars
            ? content.description.slice(0, maxDescrChars) + ' ...'
            : content.description}
        </div>
      )}
    </div>
  );
};

const DegreeProgramCard = ({
  content,
  showDescription = false,
  showMaterials = false,
  linkDisabled = false,
}) => {
  let addClasses =
    content.faculties?.length === 1
      ? content.faculties.map((f) => f.token)
      : [];
  if (content['@type'] === 'SubjectProfile' || content['@type'] === 'Document')
    addClasses.push('subject');
  const maxDescrChars = 250;

  const isZFUW =
    (content.program_format || []).findIndex((i) => i.token === 'distance') >
    -1;
  const onClickZFUW = (e) => {
    /* currently ZFUW degree programs are redirected to the old pages,
       those redirections are defined via nginx rules, so they do
       not apply when using in-app routing ...
    */
    window.location = `https://www.uni-koblenz.de${flattenToAppURL(
      content['@id'],
    )}`;
  };
  return (
    <div className="degree-program-card-wrapper">
      {isZFUW ? (
        <div
          onClick={onClickZFUW}
          onKeyDown={onClickZFUW}
          role="link"
          tabIndex="0"
          style={{ cursor: 'pointer' }}
        >
          <Inner
            content={content}
            addClasses={addClasses}
            showDescription={showDescription}
            maxDescrChars={maxDescrChars}
          />
        </div>
      ) : (
        <ConditionalLink
          to={flattenToAppURL(content['@id'])}
          condition={!linkDisabled}
        >
          <Inner
            content={content}
            addClasses={addClasses}
            showDescription={showDescription}
            maxDescrChars={maxDescrChars}
          />
        </ConditionalLink>
      )}
      {showMaterials &&
        ((showDescription = false),
        (<DegreeProgramMaterials materials={content.materials} />))}
    </div>
  );
};

export default DegreeProgramCard;
