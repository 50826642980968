import { defineMessages } from 'react-intl';
import {
  getBlocksFieldname,
  getBlocksLayoutFieldname,
} from '@plone/volto/helpers';
import { getToCEntries } from '@package/helpers';

const messages = defineMessages({
  button: {
    id: 'Button',
    defaultMessage: 'Button',
  },
  source: {
    id: 'Link to',
    defaultMessage: 'Link to',
  },
  descrSource: {
    id: 'Select content or copy an URL into this field.',
    defaultMessage: 'Select content or copy an URL into this field.',
  },
  openLinkInNewTab: {
    id: 'Open in a new tab',
    defaultMessage: 'Open in a new tab',
  },
  mailTo: {
    id: 'Mail to',
    defaultMessage: 'Mail to',
  },
  mailSubject: {
    id: 'Mail subject',
    defaultMessage: 'Mail subject',
  },
  descrMailTo: {
    id: 'Creates a mail-to link.',
    defaultMessage: 'Creates a mail-to link.',
  },
  phone: {
    id: 'Phone',
    defaultMessage: 'Phone',
  },
  descrPhone: {
    id: 'Creates a phone link.',
    defaultMessage: 'Creates a phone link.',
  },
  title: {
    id: 'Title',
    defaultMessage: 'Title',
  },
  labelPosition: {
    id: 'Position',
    defaultMessage: 'Position',
  },
  labelPosLeft: {
    id: 'Left',
    defaultMessage: 'Left',
  },
  labelPosCenter: {
    id: 'Center',
    defaultMessage: 'Center',
  },
  labelPosRight: {
    id: 'Right',
    defaultMessage: 'Right',
  },
  labelPosBlock: {
    id: 'Block',
    defaultMessage: 'Block',
  },
  labelVariant: {
    id: 'Variant',
    defaultMessage: 'Variant',
  },
  labelPrimary: {
    id: 'Primary',
    defaultMessage: 'Primary',
  },
  labelSecondary: {
    id: 'Secondary',
    defaultMessage: 'Secondary',
  },
  labelIcon: {
    id: 'FontAwesome Icon',
    defaultMessage: 'FontAwesome Icon',
  },
  descrIcon: {
    id: 'Full list of available icons (copy & paste name): https://react.semantic-ui.com/elements/icon',
    defaultMessage:
      'Full list of available icons (copy & paste name): https://react.semantic-ui.com/elements/icon',
  },
  labelUniKoArrow: {
    id: 'Display UniKo-Arrow',
    defaultMessage: 'Display UniKo-Arrow',
  },
  labelAnchor: {
    id: 'Anchor',
    defaultMessage: 'Anchor',
  },
  descrAnchor: {
    id: 'Link to a heading within this page.',
    defaultMessage: 'Link to a heading within this page.',
  },
  labelHrefAnchor: {
    id: 'Link target anchor',
    defaultMessage: 'Link target anchor',
  },
  descrHrefAnchor: {
    id: 'Has to start with a `#` and will be appended to the link target.',
    defaultMessage:
      'Has to start with a `#` and will be appended to the link target.',
  },
});

export const ButtonSchema = (intl, content, data) => {
  /* get headings of current page as choices for anchor field */
  const blocksFieldname = getBlocksFieldname(content);
  const blocksLayoutFieldname = getBlocksLayoutFieldname(content);
  const anchorChoices =
    blocksFieldname && blocksLayoutFieldname
      ? getToCEntries(
          content[blocksFieldname],
          content[blocksLayoutFieldname],
          /* note: we do not store the `slug` field here,
             since this could change when referenced block
             is updated (see `override_toc`).
             So we compute `slug` dynamically in View-component,
             based on block id.
          */
        ).map((h) => [{ id: h.id, title: h.text }, h.text])
      : [];

  return {
    title: intl.formatMessage(messages.button),
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: [
          ...(data.anchor ? [] : ['href']),
          ...(data.href?.length > 0 ? ['href_anchor'] : ['anchor']),
          'title',
          'openLinkInNewTab',
          'position',
          'variant',
          'show_uniko_arrow',
          'icon',
          'mailto',
          'mail_subject',
          'phone',
        ],
      },
    ],

    properties: {
      href: {
        title: intl.formatMessage(messages.source),
        description: intl.formatMessage(messages.descrSource),
        widget: 'object_browser',
        mode: 'link',
        selectedItemAttrs: ['Title', 'Description'],
        allowExternals: true,
      },
      href_anchor: {
        title: intl.formatMessage(messages.labelHrefAnchor),
        description: intl.formatMessage(messages.descrHrefAnchor),
      },
      anchor: {
        title: intl.formatMessage(messages.labelAnchor),
        description: intl.formatMessage(messages.descrAnchor),
        type: 'object',
        factory: 'Choice',
        choices: anchorChoices,
      },
      mailto: {
        title: intl.formatMessage(messages.mailTo),
        description: intl.formatMessage(messages.descrMailTo),
      },
      mail_subject: {
        title: intl.formatMessage(messages.mailSubject),
      },
      phone: {
        title: intl.formatMessage(messages.phone),
        description: intl.formatMessage(messages.descrPhone),
      },
      title: {
        title: intl.formatMessage(messages.title),
      },
      openLinkInNewTab: {
        title: intl.formatMessage(messages.openLinkInNewTab),
        type: 'boolean',
      },
      position: {
        title: intl.formatMessage(messages.labelPosition),
        type: 'string',
        factory: 'Choice',
        choices: [
          ['left', intl.formatMessage(messages.labelPosLeft)],
          ['center', intl.formatMessage(messages.labelPosCenter)],
          ['right', intl.formatMessage(messages.labelPosRight)],
          ['block', intl.formatMessage(messages.labelPosBlock)],
        ],
        default: 'left',
      },
      variant: {
        title: intl.formatMessage(messages.labelVariant),
        type: 'string',
        factory: 'Choice',
        choices: [
          ['primary', intl.formatMessage(messages.labelPrimary)],
          ['secondary', intl.formatMessage(messages.labelSecondary)],
        ],
        default: 'primary',
      },
      show_uniko_arrow: {
        title: intl.formatMessage(messages.labelUniKoArrow),
        type: 'boolean',
        default: false,
      },
      icon: {
        title: intl.formatMessage(messages.labelIcon),
        description: intl.formatMessage(messages.descrIcon),
      },
    },
    required: [],
  };
};

export default ButtonSchema;
