export const prettyPrintURL = (url, maxlen = 30) => {
  if (url === null) return '';
  const clean = url
    .replace('https://', '')
    .replace('http://', '')
    .replace('www.', '');
  return clean.length > maxlen ? clean.slice(0, maxlen) + '...' : clean;
};

export const replaceImgShortCodes = (html) => {
  /* To insert image from specified url
     replace shortcodes of this schema
      [img=url]
      or
      [img=url,alignment,size]

      alignment can be:
      - left
      - right
      - center

      size can be:
      - small
      - medium
      - large

      (following the options of the image block)

    Attention: The `url` parameter can NOT start with `https://`, because
               then Volto automatically renders it as HTML link!
               The protocol part should be omitted & we insert it in here.
  */
  return html.replace(
    /\[img=([^,\]]+)(?:,(\w+))?(?:,(\w+))?\]/g,
    `<div><p class="block image align $2"><img src="https://$1" class="$3" loading="lazy" /></p></div>`,
  );
};

export const replaceVideoShortCodes = (html, videos) => {
  /* see src/components/Blocks/PanoptoVideo/View.jsx */
  videos
    .filter(
      (video) =>
        video.url &&
        video.url.startsWith(
          'https://videoakademie.ko-ld.de/Panopto/Pages/Viewer.aspx?id=',
        ),
    )
    .forEach((video, index) => {
      const ratios = {
        landscape: '56.25', // 16:9
        square: '100.0', // 1:1
        portrait: '177.77', // 9:16
      };
      const alignment = video.alignment || 'center';
      const aspectRatio = ratios[video.aspect_ratio || 'landscape'];
      const maxWidth = video.max_width || '100%';
      const interactivity = video.interactivity || 'none';
      const hideTitle = video.hide_title || false;
      const src = `${video.url.replace(
        'Viewer.aspx',
        'Embed.aspx',
      )}&autoplay=false&offerviewer=false&showtitle=${!hideTitle}&showbrand=false&captions=false&interactivity=${interactivity}`;
      let wrapperStyle = `max-width: ${maxWidth};`;
      if (alignment === 'center')
        wrapperStyle += ' margin-left: auto; margin-right: auto;';
      if (alignment === 'left') wrapperStyle += ' margin-right: auto;';
      if (alignment === 'right') wrapperStyle += ' margin-left: auto;';
      const toFind = `[video${index + 1}]`;
      html = html.replaceAll(
        toFind,
        `<div class="panopto-video-wrapper" style="${wrapperStyle}"><div class="panopto-video-container" style="padding-bottom: ${aspectRatio}%;"><iframe width="auto" src=${src} allowFullScreen title="Embedded Video"></iframe></div></div>`,
      );
    });
  return html;
};

export const getCurrentSemesterID = () => {
  const now = new Date();
  const month = now.getMonth();
  const year = now.getFullYear();
  /* Summer semester (1) is between April and September */
  if (month >= 3 && month <= 8) return `${year}1`;
  /* Winter semester (2) of last year */
  if (month < 3) return `${year - 1}2`;
  /* Winter semester (2) of current year */
  return `${year}2`;
};

/* property `offsetTop` is relative to parent.
     in order to get the absolute value we need to iterate.
  */
export const getOffsetTop = (element) => {
  let offsetTop = 0;
  while (element) {
    offsetTop += element.offsetTop;
    element = element.offsetParent;
  }
  return offsetTop;
};

export const scrollToAnchor = (id) => {
  if (document === undefined) return;
  const el = document.getElementById(id);
  if (el === null) return;
  window.scrollTo({
    top: getOffsetTop(el) - 80,
    behavior: 'smooth',
  });
};
