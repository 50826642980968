import { defineMessages } from 'react-intl';

const messages = defineMessages({
  labelLectures: {
    id: 'Lectures',
    defaultMessage: 'Lectures',
  },
  labelPerson: {
    id: 'Person',
    defaultMessage: 'Person',
  },
  labelAddPerson: {
    id: 'Add person',
    defaultMessage: 'Add person',
  },
  labelDefault: {
    id: 'Default',
    defaultMessage: 'Default',
  },
  labelSelectPerson: {
    id: 'Select person',
    defaultMessage: 'Select person',
  },
  labelPersons: {
    id: 'Persons',
    defaultMessage: 'Persons',
  },
  labelIsSearchable: {
    id: 'Is searchable?',
    defaultMessage: 'Is searchable?',
  },
  descrPerson: {
    id: 'Not more than 10 persons.',
    defaultMessage: 'Not more than 10 persons.',
  },
  labelLSFHeading: {
    id: 'LSF heading',
    defaultMessage: 'LSF heading',
  },
  labelLSFHeadings: {
    id: 'LSF headings',
    defaultMessage: 'LSF headings',
  },
  labelFilterLanguage: {
    id: 'Language filter',
    defaultMessage: 'Language filter',
  },
  descrFilterLanguage: {
    id: 'Refers to the language of instruction.',
    defaultMessage: 'Refers to the language of instruction.',
  },
  labelSortMode: {
    id: 'Sort by',
    defaultMessage: 'Sort by',
  },
  descrSortMode: {
    id: '`Grouped` tries to group lectures and associated exercises.',
    defaultMessage:
      '`Grouped` tries to group lectures and associated exercises.',
  },
  labelSortModeId: {
    id: 'sort_mode_lecture_id',
    defaultMessage: 'Lecture id',
  },
  labelSortModeTitle: {
    id: 'Title',
    defaultMessage: 'Title',
  },
  labelSortModeTitleGrouped: {
    id: 'Title (grouped)',
    defaultMessage: 'Title (grouped)',
  },
  labelSortModeType: {
    id: 'sort_mode_lecture_type',
    defaultMessage: 'Kind',
  },
  labelGerman: {
    id: 'German',
    defaultMessage: 'German',
  },
  labelEnglish: {
    id: 'English',
    defaultMessage: 'English',
  },
  labelHideSubHeadings: {
    id: 'Hide sub-headings',
    defaultMessage: 'Hide sub-headings',
  },
  descrHideSubHeadings: {
    id:
      'Only lectures that have been directly assigned to the selected heading are shown.',
    defaultMessage:
      'Only lectures that have been directly assigned to the selected heading are shown.',
  },
});

const PersonSchema = (intl) => {
  return {
    title: intl.formatMessage(messages.labelPerson),
    addMessage: intl.formatMessage(messages.labelAddPerson),

    fieldsets: [
      {
        id: 'default',
        title: intl.formatMessage(messages.labelDefault),
        fields: ['href'],
      },
    ],

    properties: {
      href: {
        title: intl.formatMessage(messages.labelSelectPerson),
        widget: 'object_browser',
        widgetOptions: {
          pattern_options: { selectableTypes: ['Person'] },
        },
        mode: 'link',
        selectedItemAttrs: ['his_person_id'],
      },
    },
  };
};

const LecturesBlockSchema = (intl) => {
  const MyPersonSchema = PersonSchema(intl);
  return {
    title: intl.formatMessage(messages.labelLectures),

    fieldsets: [
      {
        id: 'default',
        title: intl.formatMessage(messages.labelDefault),
        fields: ['searchable', 'filter_language', 'sort_mode'],
      },
      {
        id: 'headings',
        title: intl.formatMessage(messages.labelLSFHeadings),
        fields: ['heading_data', 'hide_sub_headings'],
      },
      {
        id: 'persons',
        title: intl.formatMessage(messages.labelPersons),
        fields: ['items'],
      },
    ],

    properties: {
      searchable: {
        title: intl.formatMessage(messages.labelIsSearchable),
        type: 'boolean',
        default: true,
      },
      filter_language: {
        title: intl.formatMessage(messages.labelFilterLanguage),
        description: intl.formatMessage(messages.descrFilterLanguage),
        type: 'string',
        factory: 'Choice',
        choices: [
          ['de', intl.formatMessage(messages.labelGerman)],
          ['en', intl.formatMessage(messages.labelEnglish)],
        ],
      },
      sort_mode: {
        title: intl.formatMessage(messages.labelSortMode),
        description: intl.formatMessage(messages.descrSortMode),
        type: 'string',
        factory: 'Choice',
        choices: [
          [
            'title_grouped',
            intl.formatMessage(messages.labelSortModeTitleGrouped),
          ],
          ['title', intl.formatMessage(messages.labelSortModeTitle)],
          ['id', intl.formatMessage(messages.labelSortModeId)],
          ['type', intl.formatMessage(messages.labelSortModeType)],
        ],
        default: 'title_grouped',
      },
      heading_data: {
        title: intl.formatMessage(messages.labelLSFHeading),
        widget: 'lsf_heading',
        widgetOptions: {
          semester_id: '20231',
        },
        default: {
          lsf_heading: false,
          lsf_heading_title: false,
          lsf_heading_path: '',
          semester_id: false,
        },
      },
      hide_sub_headings: {
        title: intl.formatMessage(messages.labelHideSubHeadings),
        description: intl.formatMessage(messages.descrHideSubHeadings),
        type: 'boolean',
        default: false,
      },
      items: {
        title: intl.formatMessage(messages.labelPersons),
        desciption: intl.formatMessage(messages.descrPerson),
        schema: MyPersonSchema,
        widget: 'object_list',
      },
    },

    required: [''],
  };
};

export default LecturesBlockSchema;
