import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { readAsDataURL } from 'promise-file-reader';
import { Button, Dimmer, Input, Loader, Message } from 'semantic-ui-react';
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl';
import loadable from '@loadable/component';
import { isEqual } from 'lodash';

import { Icon, SidebarPortal, Toast } from '@plone/volto/components';
import { withBlockExtensions } from '@plone/volto/helpers';
import { createContent } from '@plone/volto/actions';
import {
  flattenToAppURL,
  getBaseUrl,
  isInternalURL,
  validateFileUploadSize,
} from '@plone/volto/helpers';

import AudioSidebar from './AudioSidebar';

import volumeSVG from '@plone/volto/icons/volume.svg';
import clearSVG from '@plone/volto/icons/clear.svg';
import navTreeSVG from '@plone/volto/icons/nav.svg';
import aheadSVG from '@plone/volto/icons/ahead.svg';
import uploadSVG from '@plone/volto/icons/upload.svg';

import { toast } from 'react-toastify';

const Dropzone = loadable(() => import('react-dropzone'));

const messages = defineMessages({
  AudioBlockInputPlaceholder: {
    id: 'Browse the site, drop an audio file, or type an URL',
    defaultMessage: 'Browse the site, drop an audio file, or type an URL',
  },
  uploadingAudioFile: {
    id: 'Uploading audio file',
    defaultMessage: 'Uploading audio file',
  },
  fileTooBig: {
    id: 'File too big',
    defaultMessage: 'File too big',
  },
  fileTooBigDescr: {
    id: 'File size must be less than 50 MB.',
    defaultMessage: 'File size must be less than 50 MB.',
  },
});

/**
 * Edit audio block class.
 * @class Edit
 * @extends Component
 */
class Edit extends Component {
  /**
   * Property types.
   * @property {Object} propTypes Property types.
   * @static
   */
  static propTypes = {
    selected: PropTypes.bool.isRequired,
    block: PropTypes.string.isRequired,
    index: PropTypes.number.isRequired,
    data: PropTypes.objectOf(PropTypes.any).isRequired,
    content: PropTypes.objectOf(PropTypes.any).isRequired,
    request: PropTypes.shape({
      loading: PropTypes.bool,
      loaded: PropTypes.bool,
    }).isRequired,
    pathname: PropTypes.string.isRequired,
    onChangeBlock: PropTypes.func.isRequired,
    onSelectBlock: PropTypes.func.isRequired,
    onDeleteBlock: PropTypes.func.isRequired,
    onFocusPreviousBlock: PropTypes.func.isRequired,
    onFocusNextBlock: PropTypes.func.isRequired,
    handleKeyDown: PropTypes.func.isRequired,
    createContent: PropTypes.func.isRequired,
    openObjectBrowser: PropTypes.func.isRequired,
  };

  state = {
    uploading: false,
    href: '',
    dragging: false,
  };

  /**
   * Component will receive props
   * @method componentWillReceiveProps
   * @param {Object} nextProps Next properties
   * @returns {undefined}
   */
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      this.props.request.loading &&
      nextProps.request.loaded &&
      this.state.uploading
    ) {
      this.setState({
        uploading: false,
      });
      this.props.onChangeBlock(this.props.block, {
        ...this.props.data,
        href: nextProps.content['@id'],
      });
    }
  }

  /**
   * @param {*} nextProps
   * @returns {boolean}
   * @memberof Edit
   */
  shouldComponentUpdate(nextProps) {
    return (
      this.props.selected ||
      nextProps.selected ||
      !isEqual(this.props.data, nextProps.data)
    );
  }

  /**
   * Upload audio file handler (not used), but useful in case that we want a button
   * not powered by react-dropzone
   * @method onUploadAudioFile
   * @returns {undefined}
   */
  onUploadAudioFile = (e) => {
    e.stopPropagation();
    const file = e.target.files[0];
    /* Frontend should catch too big files, before backend does after the upload.
       (see: https://gitlab.uni-koblenz.de/cms-developer/uniko.types/-/commit/20b35aa85e88c43a906bbc879842f446ee1043b9)
    */
    if (file.size > 50000000) {
      toast.error(
        <Toast
          error
          title={this.props.intl.formatMessage(messages.fileTooBig)}
          content={this.props.intl.formatMessage(messages.fileTooBigDescr)}
        />,
      );
      return;
    }
    if (!validateFileUploadSize(file, this.props.intl.formatMessage)) return;
    this.setState({
      uploading: true,
    });
    readAsDataURL(file).then((data) => {
      const fields = data.match(/^data:(.*);(.*),(.*)$/);
      this.props.createContent(
        getBaseUrl(this.props.pathname),
        {
          '@type': 'File',
          title: file.name,
          file: {
            data: fields[3],
            encoding: fields[2],
            'content-type': fields[1],
            filename: file.name,
          },
        },
        this.props.block,
      );
    });
  };

  /**
   * Change href handler
   * @method onChangeHref
   * @param {Object} target Target object
   * @returns {undefined}
   */
  onChangeHref = ({ target }) => {
    this.setState({
      href: target.value,
    });
  };

  /**
   * Submit href handler
   * @method onSubmitHref
   * @param {object} e Event
   * @returns {undefined}
   */
  onSubmitHref = () => {
    this.props.onChangeBlock(this.props.block, {
      ...this.props.data,
      href: flattenToAppURL(this.state.href),
    });
  };

  /**
   * Drop handler
   * @method onDrop
   * @param {array} files File objects
   * @returns {undefined}
   */
  onDrop = (files) => {
    if (!validateFileUploadSize(files[0], this.props.intl.formatMessage)) {
      this.setState({ dragging: false });
      return;
    }
    this.setState({ uploading: true });

    readAsDataURL(files[0]).then((data) => {
      const fields = data.match(/^data:(.*);(.*),(.*)$/);
      this.props.createContent(
        getBaseUrl(this.props.pathname),
        {
          '@type': 'File',
          title: files[0].name,
          file: {
            data: fields[3],
            encoding: fields[2],
            'content-type': fields[1],
            filename: files[0].name,
          },
        },
        this.props.block,
      );
    });
  };

  /**
   * Keydown handler on Variant Menu Form
   * This is required since the ENTER key is already mapped to a onKeyDown
   * event and needs to be overriden with a child onKeyDown.
   * @method onKeyDownVariantMenuForm
   * @param {Object} e Event object
   * @returns {undefined}
   */
  onKeyDownVariantMenuForm = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      e.stopPropagation();
      this.onSubmitHref();
    } else if (e.key === 'Escape') {
      e.preventDefault();
      e.stopPropagation();
      // TODO: Do something on ESC key
    }
  };
  onDragEnter = () => {
    this.setState({ dragging: true });
  };
  onDragLeave = () => {
    this.setState({ dragging: false });
  };

  node = React.createRef();

  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
  render() {
    const { data } = this.props;
    const placeholder =
      this.props.data.placeholder ||
      this.props.intl.formatMessage(messages.AudioBlockInputPlaceholder);
    const src = data.href
      ? isInternalURL(data.href)
        ? flattenToAppURL(data.href + '/@@download/file')
        : data.href
      : false;
    return (
      <div>
        {src ? (
          <audio
            disabled
            controls
            src={src}
            style={{
              width: (this.props.data.width || 'auto') === 'full' ? '100%' : '',
              pointerEvents: 'none',
            }}
          >
            <a href={src}>
              <FormattedMessage
                id="Download audio file."
                defaultMessage="Download audio file."
              />
            </a>
            {/* Empty track element to indicate no subtitles/captions available */}
            <track
              kind="captions"
              src=""
              srclang={this.props.intl.locale || 'de'}
              default
            />
          </audio>
        ) : (
          <div>
            {this.props.editable && (
              <Dropzone
                noClick
                onDrop={this.onDrop}
                onDragEnter={this.onDragEnter}
                onDragLeave={this.onDragLeave}
                className="dropzone"
              >
                {({ getRootProps, getInputProps }) => (
                  <div {...getRootProps()}>
                    <Message>
                      {this.state.dragging && <Dimmer active></Dimmer>}
                      {this.state.uploading && (
                        <Dimmer active>
                          <Loader indeterminate>
                            {this.props.intl.formatMessage(
                              messages.uploadingAudioFile,
                            )}
                          </Loader>
                        </Dimmer>
                      )}
                      <div className="no-audio-wrapper">
                        <Icon
                          name={volumeSVG}
                          style={{
                            height: '100px',
                            display: 'block',
                            fill: 'rgb(184, 198, 200)',
                            marginLeft: 'auto',
                            marginRight: 'auto',
                            width: 'auto',
                          }}
                        />
                        <div className="toolbar-inner">
                          <Button.Group>
                            <Button
                              basic
                              icon
                              onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                this.props.openObjectBrowser({
                                  mode: 'link',
                                  searchableTypes: ['File'],
                                  selectableTypes: ['File'],
                                });
                              }}
                            >
                              <Icon name={navTreeSVG} size="24px" />
                            </Button>
                          </Button.Group>
                          <Button.Group>
                            <label className="ui button basic icon">
                              <Icon name={uploadSVG} size="24px" />
                              <input
                                {...getInputProps({
                                  type: 'file',
                                  onChange: this.onUploadAudioFile,
                                  style: { display: 'none' },
                                })}
                              />
                            </label>
                          </Button.Group>
                          <Input
                            onKeyDown={this.onKeyDownVariantMenuForm}
                            onChange={this.onChangeHref}
                            placeholder={placeholder}
                            value={this.state.href}
                            onClick={(e) => {
                              e.target.focus();
                            }}
                            onFocus={(e) => {
                              this.props.onSelectBlock(this.props.id);
                            }}
                          />
                          {this.state.href && (
                            <Button.Group>
                              <Button
                                basic
                                className="cancel"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  this.setState({ href: '' });
                                }}
                              >
                                <Icon name={clearSVG} size="30px" />
                              </Button>
                            </Button.Group>
                          )}
                          <Button.Group>
                            <Button
                              basic
                              primary
                              disabled={!this.state.href}
                              onClick={(e) => {
                                e.stopPropagation();
                                this.onSubmitHref();
                              }}
                            >
                              <Icon name={aheadSVG} size="30px" />
                            </Button>
                          </Button.Group>
                        </div>
                      </div>
                    </Message>
                  </div>
                )}
              </Dropzone>
            )}
          </div>
        )}
        <SidebarPortal selected={this.props.selected}>
          <AudioSidebar {...this.props} />
        </SidebarPortal>
      </div>
    );
  }
}

export default compose(
  injectIntl,
  withBlockExtensions,
  connect(
    (state, ownProps) => ({
      request: state.content.subrequests[ownProps.block] || {},
      content: state.content.subrequests[ownProps.block]?.data,
    }),
    { createContent },
  ),
)(Edit);
