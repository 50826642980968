import React from 'react';
import InlineForm from '@plone/volto/components/manage/Form/InlineForm';
import { Icon, SidebarPortal } from '@plone/volto/components';
import userSVG from '@plone/volto/icons/user.svg';
import View from './View';
import PersonBlockSchema from './schema';
import { FormattedMessage } from 'react-intl';

const Edit = (props) => {
  const schema = PersonBlockSchema(props.intl);
  return (
    <>
      <SidebarPortal selected={props.selected}>
        <InlineForm
          schema={schema}
          title={schema.title}
          icon={<Icon name={userSVG} />}
          onChangeField={(id, value) => {
            props.onChangeBlock(props.block, {
              ...props.data,
              [id]: value,
            });
          }}
          formData={props.data}
        />
      </SidebarPortal>
      {(props.data.href && props.data.href.length > 0) ||
      props.data.external_href ? (
        <View data={props.data} link-disabled={true} />
      ) : (
        <div>
          <div>
            <b>
              <FormattedMessage
                id="no_person_selected"
                defaultMessage="No person selected yet."
              />
            </b>
          </div>
          <span>
            <FormattedMessage
              id="Please select a person in the sidebar."
              defaultMessage="Please select a person in the sidebar."
            />
          </span>
        </div>
      )}
    </>
  );
};

export default Edit;
