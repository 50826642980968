import { GET_CARD } from '../../constants/ActionTypes';

export function getCard(path) {
  const url = `${path}/@card`;
  const subrequest = path;
  return {
    type: GET_CARD,
    subrequest,
    request: {
      op: 'get',
      path: url,
    },
  };
}
