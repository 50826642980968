import { FormattedMessage, injectIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';
import {
  Container,
  Grid,
  Icon as SemanticUIIcon,
  List,
  Popup,
  Segment,
  Table,
} from 'semantic-ui-react';
import { ConditionalLink, UniversalLink } from '@plone/volto/components';
import { BodyClass, flattenToAppURL } from '@plone/volto/helpers';
import { NavPills, PreviewHero, UniKOAccordion } from '@package/components';
import ToCView from '@package/components/Blocks/ToC/View';
import placeholderJPG from '@package/assets/placeholder_project.jpg';
import placeholderWEBP from '@package/assets/placeholder_project.webp';
import { replaceImgShortCodes, replaceVideoShortCodes } from '@package/utils';
import cx from 'classnames';
import FormattedDateWrapper from './FormattedDateWrapper';
import {
  ContactForm,
  getPlausibleClassForApplyButton,
  messages,
  ppMaterial,
  ppValue,
} from './utils';
import './program.less';

/* Modifies year to make sure printed date is always
   in the future (used for application deadlines). */
const FormattedDateFuturized = ({ date }) => {
  let dateObj = new Date(date);
  const now = new Date();
  const curYear = now.getFullYear();
  dateObj.setFullYear(curYear);
  if (dateObj < now) dateObj.setFullYear(curYear + 1);
  return <FormattedDateWrapper date={dateObj} />;
};

const RichTextFieldBase = ({
  id,
  title,
  html,
  hideDivider = false,
  appendix,
  videos = [],
}) => {
  return (
    <div className={cx('field', id)}>
      <h2 id={id}>{title}</h2>
      {html.length > 12 && (
        <div
          dangerouslySetInnerHTML={{
            __html: replaceVideoShortCodes(replaceImgShortCodes(html), videos),
          }}
        />
      )}
      {appendix && appendix}
      {!hideDivider && <hr className="divider fullwidth" />}
    </div>
  );
};

const RichTextField = ({ id, schema, content, hideDivider = false }) => {
  const title = schema.properties[id]?.title;
  let html = content[id]?.data || '';
  return (
    <RichTextFieldBase
      id={id}
      title={title}
      html={html}
      hideDivider={hideDivider}
      videos={content.videos?.items || []}
    />
  );
};

const ProgramView = ({
  content,
  intl,
  schemaDE,
  schemaEN,
  MetaBox,
  simpleFields,
  ContactSection,
}) => {
  const lang = intl.locale || 'de';
  const schema = lang === 'en' ? schemaEN : schemaDE;

  const richTextFields = [
    'contents_structure',
    'modules',
    'prospects',
    'further_info',
    'target_group_pains',
  ].filter((id) =>
    id === 'modules'
      ? content.modules?.items?.length > 0
      : content[id]?.data?.length > 12 || false,
  );

  const materials = content.materials?.items?.filter((i) => i.url);

  const further_links = content.further_links?.items?.filter((i) => i.url);

  const hasForm =
    (content.contact_form?.length > 0 && content.contact_form[0]) ||
    content.contact_form_html;

  const further_further_links =
    content && schema
      ? ['passt', 'student_council']
          .filter((id) => content[id])
          .map((id) => {
            return { url: content[id], title: schema.properties[id].title };
          })
      : [];

  const fields_remarks =
    content.fields_remarks?.items?.length > 0
      ? content.fields_remarks?.items
      : [];

  let tocIds = ['overview'].concat(richTextFields).map((id) => id);
  if (content.further_free_text_fields?.items?.length > 0)
    content.further_free_text_fields.items.forEach((i) => {
      if (i.title?.length > 0) {
        tocIds.push(i['@id']);
        schema.properties[i['@id']] = {
          title: i.title,
        };
      }
    });
  if (
    content.further_links?.items?.length > 0 ||
    further_further_links?.length > 0
  )
    tocIds.push('further_links');
  if (content.faqs?.items?.length > 0) tocIds.push('faqs');
  if (hasForm) {
    tocIds.push('form');
    schema.properties['form'] = {
      title: content.form_title || intl.formatMessage(messages.form),
    };
  }

  const bodyClass =
    content.faculties?.length === 1
      ? `uniko-${content.faculties[0].token}`
      : false;

  const image = content.preview_image || content.image || false;

  const location = useLocation();
  const isMockup = location.pathname.endsWith('/fiktiver-studiengang');
  const MOCKUP_MODULES_TITLE = 'Deine Studienschwerpunkte';

  return (
    <Container id="program-container">
      {bodyClass && <BodyClass className={bodyClass} />}
      {image ? (
        <PreviewHero
          heroImage={
            <img src={flattenToAppURL(image.scales.huge.download)} alt="" />
          }
          caption={content.formatted_title || content.title}
          subCaption={content.degree.map((d) => d.title).join(', ')}
        />
      ) : (
        <PreviewHero
          heroImage={
            <picture>
              <source type="image/webp" srcSet={placeholderWEBP} />
              <img src={placeholderJPG} alt="" />
            </picture>
          }
          caption={content.title}
        />
      )}
      <MetaBox content={content} intl={intl} schema={schema} />
      <Grid reversed="tablet computer">
        <Grid.Row>
          <Grid.Column mobile={12} tablet={2} computer={4}>
            <ToCView
              data={{ position: 'fixed' }}
              items={tocIds
                .map((id) => {
                  return {
                    key: id,
                    text:
                      id === 'modules' && isMockup
                        ? MOCKUP_MODULES_TITLE
                        : schema.properties[id].title,
                    type: 'h2',
                  };
                })
                .concat(
                  isMockup
                    ? {}
                    : {
                        key: 'contact',
                        text: intl.formatMessage(messages.contact),
                        type: 'h2',
                      },
                )}
            />
          </Grid.Column>
          <Grid.Column mobile={12} tablet={10} computer={8}>
            <div className="program-description">{content.description}</div>
            <RichTextField
              id="overview"
              content={content}
              schema={schema}
              hideDivider={true}
            />
            <Table striped className="facts">
              <Table.Body>
                {simpleFields
                  .filter((id) =>
                    !id.endsWith('_eu') && content[id]
                      ? Array.isArray(content[id])
                        ? content[id].length > 0
                        : content[id]
                      : false,
                  )
                  .map((id, index) => {
                    const title = schema.properties[id]?.title;
                    const value =
                      schema.properties[id]?.widget === 'date' ? (
                        <FormattedDateWrapper date={content[id]} />
                      ) : id === 'school_subjects' ? (
                        <List>
                          {content[id].map((item, index) => {
                            const subjectProfile = content.items.find(
                              (i) =>
                                i['@type'] === 'SubjectProfile' &&
                                i.title === item,
                            );
                            const restricted =
                              (
                                content.school_subjects_with_admission_restrictions ||
                                []
                              ).indexOf(item) > -1;
                            return (
                              <List.Item key={`subject-${index}`}>
                                <ConditionalLink
                                  to={flattenToAppURL(
                                    subjectProfile ? subjectProfile['@id'] : '',
                                  )}
                                  condition={subjectProfile}
                                >
                                  {item}
                                </ConditionalLink>{' '}
                                {restricted && (
                                  <Popup
                                    hoverable
                                    content={
                                      <>
                                        <br />
                                        <UniversalLink href="/de/studium/studienangebot/faecheruebersicht">
                                          <FormattedMessage
                                            id="Further information on the admission restriction."
                                            defaultMessage="Further information on the admission restriction."
                                          />
                                        </UniversalLink>
                                        <br />
                                        <br />
                                        <i>
                                          <FormattedMessage
                                            id="Please note the different application deadline."
                                            defaultMessage="Please note the different application deadline."
                                          />
                                        </i>
                                      </>
                                    }
                                    key={item}
                                    header={intl.formatMessage(
                                      messages.admission_restriction,
                                    )}
                                    trigger={<SemanticUIIcon name="lock" />}
                                  />
                                )}
                              </List.Item>
                            );
                          })}
                        </List>
                      ) : id === 'related_subjects' ? (
                        <List bulleted>
                          {(content.related_subjects || []).map((subject) => {
                            return (
                              <List.Item key={subject['@id']}>
                                <UniversalLink
                                  href={flattenToAppURL(subject['@id'])}
                                >
                                  {subject.title}
                                </UniversalLink>
                              </List.Item>
                            );
                          })}
                        </List>
                      ) : (
                        ppValue(content[id], intl, true)
                      );

                    const remark =
                      id.startsWith('application_deadline') &&
                      content[`${id}_restricted`]
                        ? {
                            remark: (
                              <>
                                <FormattedMessage
                                  id="For subjects with admission restrictions"
                                  defaultMessage="For subjects with admission restrictions"
                                />
                                :<br />
                                <FormattedDateFuturized
                                  date={content[`${id}_restricted`]}
                                />
                              </>
                            ),
                          }
                        : fields_remarks.find((r) => r.field === id) || false;

                    const row = (
                      <Table.Row
                        key={`facts-row-${index}`}
                        style={
                          content.color
                            ? {
                                '--row-hover-color': content.color,
                                '--row-even-color': `${content.color}0d`,
                                '--row-odd-color': `${content.color}1a`,
                              }
                            : {}
                        }
                      >
                        <Table.Cell>{title}</Table.Cell>
                        <Table.Cell>
                          {value}
                          {remark && (
                            <div>
                              <span className="remark">{remark.remark}</span>
                            </div>
                          )}
                        </Table.Cell>
                      </Table.Row>
                    );

                    if (
                      id.startsWith('application_deadline') &&
                      (content[`${id}_eu`] || content[`${id}_non_eu`])
                    )
                      return (
                        <>
                          {row}
                          <Table.Row>
                            <Table.Cell>
                              {title}{' '}
                              <FormattedMessage
                                id="for internationals"
                                defaultMessage="for internationals"
                              />
                            </Table.Cell>
                            <Table.Cell>
                              <Table compact>
                                {content[`${id}_eu`] && (
                                  <Table.Row
                                    title={`${title} ${intl.formatMessage(
                                      messages.for_eu_applicants,
                                    )}`}
                                  >
                                    <Table.Cell>
                                      <FormattedMessage
                                        id="EU citizens"
                                        defaultMessage="EU citizens"
                                      />
                                      :
                                    </Table.Cell>
                                    <Table.Cell>
                                      <FormattedDateFuturized
                                        date={content[`${id}_eu`]}
                                      />
                                    </Table.Cell>
                                  </Table.Row>
                                )}
                                {content[`${id}_non_eu`] && (
                                  <Table.Row
                                    title={`${title} ${intl.formatMessage(
                                      messages.for_non_eu_applicants,
                                    )}`}
                                  >
                                    <Table.Cell>
                                      <FormattedMessage
                                        id="Non-EU citizens"
                                        defaultMessage="Non-EU citizens"
                                      />
                                      :
                                    </Table.Cell>
                                    <Table.Cell>
                                      <FormattedDateFuturized
                                        date={content[`${id}_non_eu`]}
                                      />
                                    </Table.Cell>
                                  </Table.Row>
                                )}
                              </Table>
                              <span
                                className="float-right"
                                style={{ float: 'right' }}
                              >
                                <SemanticUIIcon name="question circle" />
                                <a
                                  href="https://www.uni-koblenz.de/en/international-relations-office/incomings/bachelor-master-students/how-to-apply"
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  <FormattedMessage
                                    id="How to apply"
                                    defaultMessage="How to apply"
                                  />
                                </a>
                              </span>
                            </Table.Cell>
                          </Table.Row>
                        </>
                      );

                    return row;
                  })}
              </Table.Body>
            </Table>
            <hr className="divider fullwidth" />
            {richTextFields.map((id, index) => (
              <div key={`field-richtext-${index}`}>
                {id === 'modules' ? (
                  <>
                    {content.modules?.items?.length > 0 && (
                      <>
                        <h2 id="modules">
                          {isMockup
                            ? MOCKUP_MODULES_TITLE
                            : schema.properties['modules'].title}
                        </h2>
                        <UniKOAccordion
                          items={content.modules.items.map((i) => {
                            return {
                              title: i.title,
                              content: i.text?.data || '',
                            };
                          })}
                          htmlContent={true}
                        />
                        <hr className="divider fullwidth" />
                      </>
                    )}
                  </>
                ) : (
                  <>
                    <RichTextField
                      id={id}
                      content={content}
                      schema={schema}
                      hideDivider={id === 'contents_structure'}
                    />
                    {id === 'contents_structure' && materials?.length > 0 && (
                      <>
                        <NavPills
                          items={materials.map((i) => {
                            return {
                              url: i.url,
                              title:
                                i.remark?.length > 0
                                  ? `${ppMaterial(i.kind, intl)} (${i.remark})`
                                  : ppMaterial(i.kind, intl),
                            };
                          })}
                        />
                      </>
                    )}
                    {id === 'contents_structure' && (
                      <hr className="divider fullwidth" />
                    )}
                  </>
                )}
              </div>
            ))}
            {content.further_free_text_fields?.items?.length > 0 &&
              content.further_free_text_fields?.items.map((item, index) => (
                <>
                  <RichTextFieldBase
                    id={item['@id']}
                    key={`rtfb-${index}`}
                    title={item.title}
                    html={item.text?.data || ''}
                    appendix={
                      (item.title || '').indexOf('Bewerbung') > -1 && (
                        <div className="c2a-wrapper block primary">
                          <div className="c2a-button">
                            <UniversalLink
                              className={getPlausibleClassForApplyButton(
                                content,
                              )}
                              href={
                                content.cta_link
                                  ? content.cta_link
                                  : 'https://bewerbung.uni-koblenz.de'
                              }
                              target="_blank"
                            >
                              <FormattedMessage
                                id="Do apply now!"
                                defaultMessage="Do apply now!"
                              />
                            </UniversalLink>
                          </div>
                        </div>
                      )
                    }
                    videos={content.videos?.items || []}
                  />
                </>
              ))}
            {(further_links?.length > 0 ||
              further_further_links?.length > 0) && (
              <>
                <h2 id="further_links">
                  {schema.properties['further_links'].title}
                </h2>
                {further_links?.length > 0 && (
                  <NavPills items={further_links} />
                )}
                {further_further_links?.length > 0 && (
                  <NavPills items={further_further_links} />
                )}
                <hr className="divider fullwidth" />
              </>
            )}
            {content.faqs?.items?.length > 0 && (
              <>
                <h2 id="faqs">{schema.properties['faqs'].title}</h2>
                <UniKOAccordion
                  items={content.faqs.items.map((faq) => {
                    return { title: faq.question, content: faq.answer };
                  })}
                />
              </>
            )}
            {hasForm && (
              <>
                <hr className="divider fullwidth" />
                <h2 id="form">
                  {content.form_title || intl.formatMessage(messages.form)}
                </h2>
                {content.contact_form?.length > 0 &&
                  content.contact_form[0] && (
                    <ContactForm
                      url={flattenToAppURL(content.contact_form[0]['@id'])}
                    />
                  )}
                {content.contact_form_html && (
                  <Segment className="external-form">
                    <div
                      className="ui form"
                      dangerouslySetInnerHTML={{
                        __html: content.contact_form_html,
                      }}
                    ></div>
                  </Segment>
                )}
              </>
            )}
            <hr className="divider fullwidth" />
            {!isMockup && (
              <ContactSection content={content} schema={schema} intl={intl} />
            )}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Container>
  );
};

export default injectIntl(ProgramView);
