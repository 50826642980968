import DegreeProgramDefault from './DegreeProgramDefault';
import DegreeProgramZfuw from './DegreeProgramZfuw';

const DegreeProgram = (props) => {
  const isZfuw =
    (props.content.program_format || []).findIndex(
      (f) => f.token === 'distance',
    ) > -1;
  return isZfuw ? (
    <DegreeProgramZfuw {...props} />
  ) : (
    <DegreeProgramDefault {...props} />
  );
};

export default DegreeProgram;
