import { defineMessages } from 'react-intl';

const messages = defineMessages({
  labelSettings: {
    id: 'Settings',
    defaultMessage: 'Settings',
  },
  labelDate: {
    id: 'Date',
    defaultMessage: 'Date',
  },
  labelTextAbove: {
    id: 'Text above',
    defaultMessage: 'Text above',
  },
  labelTextBelow: {
    id: 'Text below',
    defaultMessage: 'Text below',
  },
});

export const CountdownSchema = (intl) => {
  return {
    title: intl.formatMessage(messages.labelSettings),
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: ['date', 'text_above', 'text_below'],
      },
    ],

    properties: {
      date: {
        title: intl.formatMessage(messages.labelDate),
        type: 'datetime',
        widget: 'datetime',
        widgetOptions: {
          dateOnly: false,
        },
      },
      text_above: {
        title: intl.formatMessage(messages.labelTextAbove),
      },
      text_below: {
        title: intl.formatMessage(messages.labelTextBelow),
      },
    },
    required: [],
  };
};

export default CountdownSchema;
