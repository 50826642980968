import { memo, useEffect, useMemo, useState } from 'react';
import { defineMessages, injectIntl } from 'react-intl';
import { List } from 'semantic-ui-react';
import cx from 'classnames';

import { UniversalLink } from '@plone/volto/components';
import {
  getBlocksFieldname,
  getBlocksLayoutFieldname,
} from '@plone/volto/helpers';

import { FixedToC } from '@package/components';
import { getToCEntries } from '@package/helpers';

const messages = defineMessages({
  toc: {
    id: 'Table of Contents',
    defaultMessage: 'Table of Contents',
  },
});

const View = ({
  data,
  properties,
  intl,
  isEditMode,
  items = [],
  metadata = false,
}) => {
  const heading = data?.heading || intl.formatMessage(messages.toc);
  const [isOpen, setIsOpen] = useState(true);

  /* if ToC is within coloumns block the relevat data lives in `metadata`,
     otherwise its in `properties`.
  */
  const content = metadata ? metadata : properties;
  const blocksFieldname = getBlocksFieldname(content);
  const blocksLayoutFieldname = getBlocksLayoutFieldname(content);

  const headings = useMemo(() => {
    return items.length > 0
      ? items
      : getToCEntries(content[blocksFieldname], content[blocksLayoutFieldname]);
  }, [items, content, blocksFieldname, blocksLayoutFieldname]);

  useEffect(() => {
    setIsOpen(false);
  }, []);

  return (
    <>
      <div className="block table-of-contents mobile">
        <div className="table-of-contents-ham">
          <button
            onClick={() => setIsOpen(!isOpen)}
            className={cx('hamburger hamburger--spin', {
              'is-active': isOpen,
            })}
          >
            <span className="hamburger-box">
              <span className="hamburger-inner"></span>
            </span>
            <span className="table-of-contents-header">{heading}</span>
          </button>
        </div>
        <div
          className={
            isOpen ? 'table-of-contents-list' : 'table-of-contents-list hide'
          }
        >
          <List bulleted>
            {headings.map((heading) => (
              <List.Item
                role="link"
                key={heading.key}
                className={heading.type + ' awh'}
              >
                <UniversalLink href={'#' + heading.key}>
                  {heading.text}
                </UniversalLink>
              </List.Item>
            ))}
          </List>
        </div>
      </div>
      {data.position === 'fixed' ? (
        <FixedToC
          items={headings.map((h) => [h.key, h.text, h.type || ''])}
          isEditMode={isEditMode}
        />
      ) : (
        <div
          className={cx(
            'block',
            'table-of-contents',
            'web',
            {
              left: data.position === 'left',
            },
            {
              right: data.position === 'right',
            },
          )}
        >
          <div className="title awh">{heading}</div>
          <List bulleted>
            {headings.map((heading) => (
              <List.Item
                role="link"
                key={heading.key}
                className={heading.type + ' awh'}
              >
                <UniversalLink href={'#' + heading.key}>
                  {heading.text}
                </UniversalLink>
              </List.Item>
            ))}
          </List>
        </div>
      )}
    </>
  );
};

export default memo(injectIntl(View));
