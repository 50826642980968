import React from 'react';
import { defineMessages } from 'react-intl';

import ObjectListWidget from '@plone/volto/components/manage/Widgets/ObjectListWidget';

const messages = defineMessages({
  faq: {
    id: 'FAQ',
    defaultMessage: 'FAQ',
  },
  addFaq: {
    id: 'Add item',
    defaultMessage: 'Add item',
  },
  question: {
    id: 'Question',
    defaultMessage: 'Question',
  },
  answer: {
    id: 'Answer',
    defaultMessage: 'Answer',
  },
});

const ItemSchema = (intl) => {
  return {
    title: intl.formatMessage(messages.faq),
    addMessage: intl.formatMessage(messages.addFaq),
    properties: {
      question: {
        title: intl.formatMessage(messages.question),
      },
      answer: {
        title: intl.formatMessage(messages.answer),
        widget: 'textarea',
      },
    },
    fieldsets: [
      {
        id: 'default',
        title: intl.formatMessage(messages.faq),
        fields: ['question', 'answer'],
      },
    ],
    required: ['question', 'answer'],
  };
};

const FAQsWidget = (props) => {
  const itemSchema = ItemSchema(props.intl);
  return (
    <ObjectListWidget
      schema={itemSchema}
      {...props}
      value={props.value?.items || props.default?.items || []}
      onChange={(id, value) => props.onChange(id, { items: value })}
    />
  );
};

export default FAQsWidget;
