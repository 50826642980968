import { List } from 'semantic-ui-react';
import { UniversalLink } from '@plone/volto/components';
import { flattenToAppURL } from '@plone/volto/helpers';

const LinkList = ({ items, isEditMode }) => {
  return (
    <List>
      {items.map((item) => (
        <List.Item key={item['@id']}>
          <UniversalLink href={isEditMode ? '#' : flattenToAppURL(item['@id'])}>
            {item.title}
          </UniversalLink>
        </List.Item>
      ))}
    </List>
  );
};

export default LinkList;
