import { defineMessages } from 'react-intl';

const messages = defineMessages({
  labelEmbedPerson: {
    id: 'Embed person',
    defaultMessage: 'Embed person',
  },
  labelSelectPerson: {
    id: 'Select person',
    defaultMessage: 'Select person',
  },
  labelHideImage: {
    id: 'Hide image',
    defaultMessage: 'Hide image',
  },
  labelHideDescription: {
    id: 'Hide description',
    defaultMessage: 'Hide description',
  },
  labelHideRemark: {
    id: 'Hide remark',
    defaultMessage: 'Hide remark',
  },
  labelHideFunction: {
    id: 'Hide function',
    defaultMessage: 'Hide function',
  },
  labelSettings: {
    id: 'Settings',
    defaultMessage: 'Settings',
  },
  labelExternalPerson: {
    id: 'External person',
    defaultMessage: 'External person',
  },
  labelExternalHref: {
    id: 'Link',
    defaultMessage: 'Link',
  },
  descrExternalHref: {
    id: 'Link to external person page.',
    defaultMessage: 'Link to external person page.',
  },
  labelExternalImage: {
    id: 'Image',
    defaultMessage: 'Image',
  },
  labelExternalName: {
    id: 'Name',
    defaultMessage: 'Name',
  },
  labelExternalFunction: {
    id: 'Function',
    defaultMessage: 'Function',
  },
  labelExternalDescription: {
    id: 'Description',
    defaultMessage: 'Description',
  },
  labelExternalPhone: {
    id: 'Phone',
    defaultMessage: 'Phone',
  },
  labelExternalEmail: {
    id: 'Email',
    defaultMessage: 'Email',
  },
  labelExternalRemark: {
    id: 'Remark',
    defaultMessage: 'Remark',
  },
});

const PersonBlockSchema = (intl) => {
  return {
    title: intl.formatMessage(messages.labelEmbedPerson),

    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: ['href'],
      },
      {
        id: 'settings',
        title: intl.formatMessage(messages.labelSettings),
        fields: [
          'hide_image',
          'hide_description',
          'hide_remark',
          'hide_function',
        ],
      },
      {
        id: 'external',
        title: intl.formatMessage(messages.labelExternalPerson),
        fields: [
          'external_href',
          'external_image',
          'external_name',
          'external_function',
          'external_description',
          'external_phone',
          'external_email',
          'external_remark',
        ],
      },
    ],

    properties: {
      href: {
        title: intl.formatMessage(messages.labelSelectPerson),
        widget: 'object_browser',
        widgetOptions: {
          pattern_options: { selectableTypes: ['Person'] },
        },
        mode: 'link',
        selectedItemAttrs: ['Title', 'Description'],
      },
      hide_image: {
        title: intl.formatMessage(messages.labelHideImage),
        type: 'boolean',
        default: false,
      },
      hide_description: {
        title: intl.formatMessage(messages.labelHideDescription),
        type: 'boolean',
        default: false,
      },
      hide_remark: {
        title: intl.formatMessage(messages.labelHideRemark),
        type: 'boolean',
        default: false,
      },
      hide_function: {
        title: intl.formatMessage(messages.labelHideFunction),
        type: 'boolean',
        default: false,
      },
      external_href: {
        title: intl.formatMessage(messages.labelExternalHref),
        description: intl.formatMessage(messages.descrExternalHref),
      },
      external_image: {
        title: intl.formatMessage(messages.labelExternalImage),
      },
      external_name: {
        title: intl.formatMessage(messages.labelExternalName),
      },
      external_function: {
        title: intl.formatMessage(messages.labelExternalFunction),
      },
      external_description: {
        title: intl.formatMessage(messages.labelExternalDescription),
      },
      external_phone: {
        title: intl.formatMessage(messages.labelExternalPhone),
      },
      external_email: {
        title: intl.formatMessage(messages.labelExternalEmail),
      },
      external_remark: {
        title: intl.formatMessage(messages.labelExternalRemark),
      },
    },

    required: ['href'],
  };
};

export default PersonBlockSchema;
