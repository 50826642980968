import { defineMessages, useIntl } from 'react-intl';
import { Container } from 'semantic-ui-react';
import { ConditionalLink, Icon as VoltoIcon } from '@plone/volto/components';
import config from '@plone/volto/registry';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { SummaryItem } from '@package/components';
import arrowSVG from '@package/svgs/icons/arrow-uniko.svg';

const messages = defineMessages({
  previousItems: {
    id: 'Show previous items',
    defaultMessage: 'Show previous items',
  },
  nextItems: {
    id: 'Show next items',
    defaultMessage: 'Show next items',
  },
});

const CustomLeftArrow = ({ onClick, intl }) => {
  return (
    <button
      onClick={() => onClick()}
      className="react-multiple-carousel__arrow react-multiple-carousel__arrow--left"
      aria-label={intl.formatMessage(messages.previousItems)}
    >
      <VoltoIcon name={arrowSVG} className="link" size="24px" />
    </button>
  );
};

const CustomRightArrow = ({ onClick, intl }) => {
  return (
    <button
      onClick={() => onClick()}
      className="react-multiple-carousel__arrow react-multiple-carousel__arrow--right"
      aria-label={intl.formatMessage(messages.nextItems)}
    >
      <VoltoIcon name={arrowSVG} className="link" size="24px" />
    </button>
  );
};

const CustomButtonGroupAsArrows = ({ next, previous, carouselState }) => {
  const intl = useIntl();
  return (
    <>
      {carouselState.currentSlide > 0 && (
        <CustomLeftArrow onClick={previous} intl={intl} />
      )}
      {carouselState.currentSlide + carouselState.slidesToShow <
        carouselState.totalItems && (
        <CustomRightArrow onClick={next} intl={intl} />
      )}
    </>
  );
};

const CarouselListing = ({
  items,
  no_infinity_scroll,
  isEditMode,
  renderItem = false,
  responsive = false,
  cta_text,
  hide_preview_images,
  hide_descriptions,
  autoplay_speed = 0,
  max_image_height = 0,
}) => {
  const defaultResponsive = {
    desktop: {
      breakpoint: { max: 9999999, min: 1400 },
      items: 3,
      partialVisibilityGutter: 0,
    },
    tablet: {
      breakpoint: { max: 1399, min: 992 },
      items: 2,
      partialVisibilityGutter: 30,
    },
    mobile: {
      breakpoint: { max: 991, min: 0 },
      items: 1,
      partialVisibilityGutter: 90,
    },
  };

  if (typeof responsive === 'string') {
    try {
      responsive = JSON.parse(responsive);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(`Error parsing 'responsive' prop: ${e.message}`);
      responsive = defaultResponsive;
    }
  }

  const Image = config.getComponent({ name: 'Image' }).component;
  return (
    <div className="carousel fullwidth">
      <Container>
        <Carousel
          key={
            JSON.stringify(responsive || defaultResponsive) +
            max_image_height.toString()
          }
          autoPlay={autoplay_speed && autoplay_speed !== '0' ? true : false}
          autoPlaySpeed={
            autoplay_speed && autoplay_speed !== '0'
              ? parseInt(autoplay_speed)
              : 3000
          }
          shouldResetAutoplay={false}
          responsive={responsive || defaultResponsive}
          ssr={true}
          infinite={no_infinity_scroll ? false : items.length > 1}
          arrows={false}
          renderButtonGroupOutside
          customButtonGroup={
            items.length > 1 ? <CustomButtonGroupAsArrows /> : <></>
          }
          partialVisible={items.length > 1}
        >
          {items.map((item) => {
            const target =
              item.external_relations?.length > 0
                ? item.external_relations[0]
                : item.relatedItems?.length > 0
                ? item.relatedItems[0]['@id']
                : false;
            if (renderItem) return renderItem(item);
            if (item['@type'] === 'Image') {
              return (
                <div key={item['@id']} className="carousel-item-image">
                  <ConditionalLink
                    to={target}
                    condition={!isEditMode && target}
                  >
                    <Image
                      item={
                        item.image_scales
                          ? {
                              '@id': item['@id'],
                              image_field: item.image_field,
                              image_scales: item.image_scales,
                            }
                          : undefined
                      }
                      style={{
                        maxHeight:
                          max_image_height > 0
                            ? `${parseInt(max_image_height)}px`
                            : 'auto',
                        width: 'auto',
                      }}
                    />
                  </ConditionalLink>
                </div>
              );
            }
            return (
              <div key={item['@id']} className="carousel-item">
                <SummaryItem
                  item={item}
                  hideImages={hide_preview_images}
                  hideDescriptions={hide_descriptions}
                  ctaText={cta_text}
                  isEditMode={isEditMode}
                />
              </div>
            );
          })}
        </Carousel>
        <div className="box-shadow-left"></div>
        <div className="box-shadow-right"></div>
      </Container>
    </div>
  );
};

export default CarouselListing;
