import { FormattedMessage } from 'react-intl';
import { Button, Grid } from 'semantic-ui-react';
import { UniversalLink } from '@plone/volto/components';
import { PersonCard } from '@package/components';
import Program from './Program';
/* we could use voltos `getSchema` action to retrieve it
   dynamically. But this endpoint is not available for anons,
   and causes uneccessary loading time.
   Can be updated like this:
      - edit a DegreeProgram
      - catch response of API call to `/@types/DegreeProgram`
      - (or use React components inspector on the form and copy value of `schema` props)
      - prettyfied with mohsen1.prettify-json
*/
import schemaDE from './program_schema_de.json';
import schemaEN from './program_schema_en.json';
import {
  getPlausibleClassForApplyButton,
  FakePersonCard,
  messages,
  MetaBoxItem,
  ppValue,
} from './utils';

const MetaBoxDefault = ({ content, intl, schema }) => {
  const isCertificate =
    schema.properties.title.behavior ===
    'uniko.types.content.certificate_program.ICertificateProgram';
  return (
    <div
      className="metabox"
      style={content.color ? { backgroundColor: content.color } : {}}
    >
      <MetaBoxItem
        title={schema.properties.study_period.title}
        value={
          isCertificate
            ? content.study_period
            : `${content.study_period} ${intl.formatMessage(
                messages.semesters,
              )}`
        }
        intl={intl}
      />
      <MetaBoxItem
        title={intl.formatMessage(messages.language)}
        value={content.teaching_language}
        intl={intl}
      />
      <MetaBoxItem
        title={intl.formatMessage(messages.format)}
        value={
          content.program_format?.length > 0
            ? content.program_format
            : intl.formatMessage(messages.in_presence)
        }
        intl={intl}
      />
      <MetaBoxItem
        title={intl.formatMessage(messages.admission_restriction)}
        value={
          content.admission_restriction === true
            ? intl.formatMessage(messages.yes)
            : ppValue(content.admission_restriction, intl)
        }
        intl={intl}
      />
      {content.cta_2_link && content.cta_2_label && (
        <UniversalLink href={content.cta_2_link}>
          <Button target="_blank">{content.cta_2_label}</Button>
        </UniversalLink>
      )}
      <UniversalLink
        href={
          content.cta_link
            ? content.cta_link
            : 'https://bewerbung.uni-koblenz.de'
        }
        target="_blank"
      >
        <Button className={getPlausibleClassForApplyButton(content)}>
          {content?.cta_label
            ? content.cta_label
            : intl.formatMessage(messages.apply_program)}
        </Button>
      </UniversalLink>
    </div>
  );
};

const ContactDefault = ({ content, schema, intl }) => {
  const subject_advisory_service = content.subject_advisory_service?.items
    ?.filter((p) => p?.href?.length > 0 && p.href[0]['@id'])
    .map((p) => {
      return {
        id: p.href[0]['@id'],
        override_phone: p.override_phone,
        override_email: p.override_email,
      };
    });
  const advisors = content.advisors?.items
    ?.filter((p) => p?.href?.length > 0 && p.href[0]['@id'])
    .map((p) => {
      return {
        id: p.href[0]['@id'],
        override_phone: p.override_phone,
        override_email: p.override_email,
      };
    });
  const isTeaching = content.type_of_school?.length > 0;
  return (
    <>
      <h2 id="contact">{intl.formatMessage(messages.contact)}</h2>
      <Grid doubling stackable>
        <Grid.Row>
          <Grid.Column mobile={12} computer={6}>
            <h3>
              <FormattedMessage
                id="Study office"
                defaultMessage="Study office"
              />
            </h3>
            <FakePersonCard
              head="Martina Hermanns"
              subhead="Emil-Schüller-Str. 12, EG, R. 034"
              phone="+49 261 287 - 1607"
              email="studienbuero@uni-koblenz.de"
            />
          </Grid.Column>
          <Grid.Column mobile={12} computer={6}>
            <h3>
              <FormattedMessage
                id="Study Advisory Service"
                defaultMessage="Study Advisory Service"
              />
            </h3>
            <FakePersonCard
              head="Petra Meinerz"
              subhead="Emil-Schüller-Str. 12, EG, R. 032"
              phone="+49 261 287 - 1751"
              email="pmeinerz@uni-koblenz.de"
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
      {isTeaching && (
        <>
          <h3>Lehramtsbezogene Beratung</h3>
          <Grid doubling stackable>
            <Grid.Row>
              <Grid.Column mobile={12} computer={6}>
                <FakePersonCard
                  head="Zentrum für Lehrerbildung"
                  subhead="Team Beratung"
                  url="https://www.uni-koblenz.de/de/zfl"
                  email="zfl-beratung@uni-koblenz.de"
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </>
      )}
      {subject_advisory_service?.length > 0 && (
        <>
          <h3>
            {content.id === 'zwei-fach-bachelor'
              ? 'Studiengangsberatung'
              : schema.properties['subject_advisory_service'].title}
          </h3>
          <Grid>
            <Grid.Row>
              {subject_advisory_service.map((p) => (
                <Grid.Column mobile={12} computer={6} key={`sas-${p.id}`}>
                  <PersonCard
                    person={{ '@id': p.id }}
                    hide-function={true}
                    hide-link={true}
                    override={{
                      ...(p.override_phone && {
                        telephone: {
                          items: [{ number: p.override_phone }],
                        },
                      }),
                      ...(p.override_email && {
                        email: p.override_email,
                      }),
                    }}
                  />
                </Grid.Column>
              ))}
            </Grid.Row>
          </Grid>
        </>
      )}
      {advisors?.length > 0 && (
        <>
          <h3>{schema.properties['advisors'].title}</h3>
          <Grid>
            <Grid.Row>
              {advisors.map((p) => (
                <Grid.Column mobile={12} computer={6} key={`adv-${p.id}`}>
                  <PersonCard
                    person={{ '@id': p.id }}
                    hide-function={true}
                    hide-link={true}
                    override={{
                      ...(p.override_phone && {
                        telephone: {
                          items: [{ number: p.override_phone }],
                        },
                      }),
                      ...(p.override_email && {
                        email: p.override_email,
                      }),
                    }}
                  />
                </Grid.Column>
              ))}
            </Grid.Row>
          </Grid>
        </>
      )}
    </>
  );
};

const DegreeProgramDefault = ({ content, intl }) => {
  const simpleFields = [
    'program_format',
    'degree',
    'study_period',
    'program_start',
    'application_deadline_sose',
    'application_deadline_wise',
    'application_deadline_sose_internationals',
    'application_deadline_wise_internationals',
    'teaching_language',
    'type_of_school',
    'school_subjects',
    // temporary disable display of subjects:
    // 'related_subjects',
    'admission_restriction',
    'admission_requirements',
    'further_requirements',
    'tuition_fee',
  ];
  return (
    <Program
      content={content}
      intl={intl}
      schemaDE={schemaDE}
      schemaEN={schemaEN}
      MetaBox={MetaBoxDefault}
      simpleFields={simpleFields}
      ContactSection={ContactDefault}
    />
  );
};

export default DegreeProgramDefault;
