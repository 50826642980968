export function getStyle(props) {
  let style = {
    verticalAlign: props.grid_vertical_align,
    style: {
      backgroundColor: props.backgroundColor,
    },
  };
  style.className = props.column_class ? props.column_class : '';
  if (props.backgroundColor != null && props.backgroundColor !== '#ececec') {
    style.className += ' uniko-dark';
  }
  return style;
}
