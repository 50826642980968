import React from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { Icon, Segment } from 'semantic-ui-react';
import { flattenToAppURL } from '@plone/volto/helpers';
import PreviewHero from './PreviewHero';

const messages = defineMessages({
  previewImage: {
    id: 'Preview image',
    defaultMessage: 'Preview image',
  },
});

const PreviewViewDefault = ({ heroImage, caption, editable }) => {
  return (
    <div className="preview-default">
      {heroImage}
      {caption && <div className="image-caption">{caption}</div>}
    </div>
  );
};

const View = ({ data, properties, editable }) => {
  const content = properties;
  const intl = useIntl();

  const imgField = content['image'] ? 'image' : 'preview_image';
  const captionField = content['image_caption']
    ? 'image_caption'
    : 'preview_caption';

  let HeroImage = false;

  if (content[imgField] && content[imgField].data) {
    HeroImage = (
      <img
        src={`data:${content[imgField]['content-type']};${content[imgField].encoding},${content[imgField].data}`}
        alt={content[captionField] || intl.formatMessage(messages.previewImage)}
      />
    );
  }

  if (content[imgField] && content[imgField].scales) {
    const srcSet = [
      `${flattenToAppURL(
        content[imgField].scales.preview?.download || '',
      )} 400w`,
      `${flattenToAppURL(
        content[imgField].scales.teaser?.download || '',
      )} 600w`,
      `${flattenToAppURL(content[imgField].scales.large?.download || '')} 800w`,
      `${flattenToAppURL(
        content[imgField].scales.larger?.download || '',
      )} 1000w`,
      `${flattenToAppURL(
        content[imgField].scales.great?.download || '',
      )} 1200w`,
      `${flattenToAppURL(content[imgField].scales.huge?.download || '')} 1210w`,
    ];

    HeroImage = (
      <img
        src={flattenToAppURL(content[imgField].scales.great?.download || '')}
        srcSet={srcSet.join(', ')}
        alt={content[captionField] || intl.formatMessage(messages.previewImage)}
      />
    );
  }

  if (HeroImage) {
    if (
      content['@type'] === 'Document' ||
      content['@type'] === 'OrgUnit' ||
      content['@type'] === 'studiengang' ||
      content['@type'] === 'LRF'
    ) {
      return (
        <PreviewHero
          heroImage={HeroImage}
          caption={content[captionField]}
          subCaption={content?.head_title || ''}
          copyright={content.preview_image_copyright}
          copyrightUrl={content.preview_image_copyright_url}
          editable={editable}
        />
      );
    } else {
      return (
        <PreviewViewDefault
          heroImage={HeroImage}
          caption={content[captionField]}
        />
      );
    }
  }

  return editable ? (
    <Segment secondary>
      <Icon name="image outline" size="large" />
      <FormattedMessage
        id="previewteaserplaceholder"
        defaultMessage="Preview/Teaser Image appears here."
      />
    </Segment>
  ) : (
    <></>
  );
};

export default View;
